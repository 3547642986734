/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsSubscriptionStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Cancelled: 'Cancelled',
    Failed: 'Failed',
    FreeTrial: 'FreeTrial',
    EnabledByAdmin: 'EnabledByAdmin'
} as const;

export type EnumsSubscriptionStatus = typeof EnumsSubscriptionStatus[keyof typeof EnumsSubscriptionStatus];



