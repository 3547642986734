<template>
  <img :src="`${$filePath}/${path}`" :alt="alt" :class="css" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';

const { getLanguageCode } = useUserStore();

const props = defineProps({
  alt: {
    type: String,
    default: '',
    required: false,
  },
  src: {
    type: String,
    default: '',
    required: true,
  },
  isLocalized: {
    type: Boolean,
    default: false,
    required: false,
  },
  css: {
    type: String,
    default: '',
    required: false,
  },
});

const path = computed(() => {
  const translatedImages = ['cs', 'en', 'hu', 'it', 'ro', 'sk'];

  if (translatedImages.includes(getLanguageCode)) {
    return props.isLocalized ? `localisedImages/${getLanguageCode}/${props.src}` : props.src;
  } else {
    return props.isLocalized ? `localisedImages/en/${props.src}` : props.src;
  }
});
</script>
