<template>
  <img v-if="showPDF" @click="generate('pdf')" :src="$filePath + '/icons/pdf.svg'" alt="..." class="ml-5 inline-flex h-8 cursor-pointer text-center sm:h-8" />
  <img v-if="showXLS" @click="generate('xls')" :src="$filePath + '/icons/xls.svg'" alt="..." class="ml-5 inline-flex h-8 cursor-pointer text-center sm:h-8" />
  <img v-if="showCSV" @click="generate('csv')" :src="$filePath + '/icons/csv.svg'" alt="..." class="ml-5 inline-flex h-8 cursor-pointer text-center sm:h-8" />
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const { t } = useI18n();
const loadingStore = useLoadingStore();
const toast = useToast();
const props = defineProps({
  dates: { type: Object, default: () => {} },
  marketingFormat: { type: undefined, default: null },
  statisticsType: { type: String, default: '' },
  showPDF: { type: Boolean, default: true },
  showXLS: { type: Boolean, default: true },
  showCSV: { type: Boolean, default: true },
  filters: { type: Object, default: () => {} },
  totalRows: { type: Number, default: 0 },
});

// TODO Add check if statistics are empty

const generate = async (type) => {
  try {
    loadingStore.updateLoading(true);
    let response;
    if (props.statisticsType === 'campaign') {
      response = await exportCampaignStats(type);
    } else if (props.statisticsType === 'order') {
      response = await exportOrderStats(type);
    } else if (props.statisticsType === 'product') {
      response = await exportProductStats(type);
    } else if (props.statisticsType === 'traffic') {
      response = await exportTrafficStats(type);
    } else if (props.statisticsType === 'visitSource') {
      response = await exportVisitSourceStats(type);
    } else if (props.statisticsType === 'categories') {
      response = await exportCategoriesStats(type);
    }

    await generateFile(response.data.file);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const generateFile = async (file) => {
  const encodedContent = file.content;
  const binaryString = atob(encodedContent);

  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: file.contentType });
  const generatedFile = new File([blob], file.downloadName, {
    type: file.contentType,
  });

  const element = document.createElement('a');
  const url = URL.createObjectURL(generatedFile);

  element.setAttribute('href', url);
  element.setAttribute('download', file.downloadName);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();

  URL.revokeObjectURL(url);

  document.body.removeChild(element);
};

const exportCampaignStats = async (type) => {
  return await api.clientReportExportCampaignStats({
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    marketingFormat: props.marketingFormat,
  });
};

const exportOrderStats = async (type) => {
  return await api.clientReportExportOrderStats({
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    first: 0,
    rows: props.totalRows,
    filters: props.filters.filters,
    sortOrder: props.filters.sortOrder,
    sortField: props.filters.sortField,
  });
};

const exportProductStats = async (type) => {
  return await api.clientReportExportProductStats({
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    first: 0,
    rows: props.totalRows,
    filters: props.filters.filters,
    sortOrder: props.filters.sortOrder,
    sortField: props.filters.sortField,
  });
};

const exportTrafficStats = async (type) => {
  return await api.clientReportExportTrafficStats({
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    first: 0,
    rows: props.totalRows,
    filters: props.filters.filters,
    sortOrder: props.filters.sortOrder,
    sortField: props.filters.sortField,
  });
};

const exportVisitSourceStats = async (type) => {
  return await api.clientReportExportVisitSourceStats({
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    first: 0,
    rows: props.totalRows,
    filters: props.filters.filters,
    sortOrder: props.filters.sortOrder,
    sortField: props.filters.sortField,
  });
};

const exportCategoriesStats = async (type) => {
  return await api.clientReportExportProductCategoryStats({
    fileFormat: type,
    utcOffset: new Date().getTimezoneOffset() / -60,
    dateFrom: props.dates.dateFrom,
    dateTo: props.dates.dateTo,
    first: 0,
    rows: props.totalRows,
    filters: props.filters.filters,
    sortOrder: props.filters.sortOrder,
    sortField: props.filters.sortField,
  });
};
</script>
