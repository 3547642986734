<template>
  <div v-if="can(EnumsClientPermission.GetDividedStats)">
    <div class="div-primary mx-auto p-1" id="chart">
      <h1 class="mb-2 text-center text-xl font-bold text-main md:text-2xl 2xl:text-3xl" v-html="$t('dashboard.competitionMetric.graphHeader')"></h1>
      <VueApexCharts ref="expenes" type="area" height="350" :options="chartOptions" :series="series"></VueApexCharts>
    </div>
    <div class="div-primary mx-auto mt-4">
      <h1 class="mb-4 text-center text-xl font-bold text-main md:text-2xl 2xl:text-3xl" v-html="$t('dashboard.competitionMetric.header')"></h1>
      <div class="mt-8 max-w-5xl">
        <p class="text-main" v-html="$t('dashboard.competitionMetric.subHeader')"></p>
      </div>
      <div class="mx-auto grid max-w-sm gap-x-5 md:max-w-5xl md:grid-cols-3">
        <div class="relative mt-8 h-32 w-full rounded-2xl border">
          <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
            <h1 class="text-center text-lg font-bold text-main xl:text-xl" v-html="$t('dashboard.competitionMetric.PNO')"></h1>
            <h2 class="mt-4 text-center text-main">
              <LocalizedValue :value="CompetitiveMetrics[defaultCurrency].cos"></LocalizedValue>
            </h2>
          </div>
        </div>
        <div class="relative mt-8 h-32 w-full rounded-2xl border">
          <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
            <h1 class="text-center text-lg font-bold text-main xl:text-xl" v-html="$t('dashboard.competitionMetric.conversionFraction')"></h1>
            <h2 class="mt-4 text-center text-main">
              <LocalizedValue :value="CompetitiveMetrics[defaultCurrency].conversionFraction"></LocalizedValue>
            </h2>
          </div>
        </div>
        <div class="relative mt-8 h-32 w-full rounded-2xl border">
          <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
            <h1 class="text-center text-lg font-bold text-main xl:text-xl" v-html="$t('dashboard.competitionMetric.orderValue')"></h1>
            <h2 class="mt-4 text-center text-main">
              <LocalizedValue :value="CompetitiveMetrics[defaultCurrency].orderValue"></LocalizedValue>
            </h2>
          </div>
        </div>
      </div>
      <div class="mx-auto grid max-w-sm gap-x-5 md:max-w-5xl md:grid-cols-2">
        <div class="relative mt-8 h-32 w-full rounded-2xl border">
          <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
            <h1 class="text-center text-lg font-bold text-main xl:text-xl" v-html="$t('dashboard.competitionMetric.averageDailyExpense')"></h1>
            <h2 class="mt-4 text-center text-main">
              <LocalizedValue :value="CompetitiveMetrics[defaultCurrency].averageDailyExpense"></LocalizedValue>
            </h2>
          </div>
        </div>
        <div class="relative mt-8 h-32 w-full rounded-2xl border">
          <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
            <h1 class="text-center text-lg font-bold text-main xl:text-xl" v-html="$t('dashboard.competitionMetric.averageDailyCashFlow')"></h1>
            <h2 class="mt-4 text-center text-main">
              <LocalizedValue :value="CompetitiveMetrics[defaultCurrency].averageDailyCashFlow"></LocalizedValue>
            </h2>
          </div>
        </div>
      </div>
      <p class="mt-8 text-right text-xs text-main" v-html="$t('dashboard.competitionMetric.footNote')"></p>
    </div>
  </div>
  <div v-else>
    <SkeletonForTables />
  </div>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';
import CompetitiveMetrics from '@/modules/dashboard/codebooks/CompetitiveMetricCodebook';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import SkeletonForTables from '@/modules/statistics/components/SkeletonForTables.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { can } from '@/utils/can';
import { EnumsClientPermission } from '../../../../generated/api';

onMounted(async () => {
  getGmvTrendChartData();
});

const eshopsStore = useEshopsStore();
const userStore = useUserStore();
const toast = useToast();
const { t } = useI18n();

const defaultCurrency = computed(() => {
  return eshopsStore.getSelectedEshop?.currency || 'CZK';
});

// APEX CHARTS
const series = ref([
  {
    name: 'Trend vývoje e-commerce',
    data: [],
  },
]);
const chartOptions = ref({
  chart: {
    height: 300,
    type: 'area',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    show: false,
    stepSize: 5,
    type: 'category',
    tickPlacement: 'on',
    labels: {
      show: true,
      style: {
        colors: ['gray'],
      },
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
      style: {
        colors: 'gray',
      },
    },
  },
  colors: ['#075695'],
});
watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await userStore.getPermissions();
    if (!can(EnumsClientPermission.GetDividedStats)) {
      userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog;
    }
  }
);

const getGmvTrendChartData = async () => {
  try {
    const { data } = await api.clientStatisticsGetGmvTrendChartData();
    updateApexChart('', data.labels, data.pseudoGmv);
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

function updateApexChart(name, labels, data) {
  series.value[0].data = [];
  for (let i = 0; i < data.length; i++) {
    series.value[0].data.push({ x: labels[i], y: data[i].toFixed(2) });
    chartOptions.value.xaxis.labels.style.colors[i] = 'gray';
  }

  series.value[0].name = name;
}
</script>
