import { useUserStore } from '@/stores/user';
import themes from '@/themes';

export interface ITheme {
    (key: string): boolean;
}

const themesPlugin = {
    install: (app) => {
        const userStore = useUserStore();

        app.config.globalProperties.$theme = (key) => {
            const theme = userStore.theme;
            if (themes[theme]) {
                return themes[theme][key];
            } else {
                return true;
            }
        };

        app.provide('themes', themes);
    },
};

export default themesPlugin;
