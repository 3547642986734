<template>
  <div class="mt-4 rounded-md bg-white p-5 dark:bg-mainDark">
    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'mx-auto my-5 flex max-w-5xl flex-col transition-opacity delay-100 duration-1000']">
      <h1 class="mb-2 mr-2 inline-flex flex-1 text-sm font-bold text-gray-800 dark:bg-mainDark md:text-xl lg:text-2xl">{{ $t('rechargeCredit.paymentHistory.title') }}</h1>
      <div v-if="paymentsHistory != null && paymentsHistory.length > 0">
        <DataTable :value="paymentsHistory" stripedRows tableStyle="min-width: 50rem" :rowsPerPageOptions="[5, 10, 20, 50, 100]" paginator :rows="10">
          <Column field="invoiceNumber" :header="$t('rechargeCredit.paymentHistory.invoiceNumber')" class="uppercase">
            <template #body="slotProps">
              <span class="">{{ slotProps.data.invoiceNumber.value }}</span>
            </template>
          </Column>
          <Column field="date" :header="$t('rechargeCredit.paymentHistory.date')" class="uppercase">
            <template #body="slotProps">
              <LocalizedValue
                :value="{
                  type: 'Date',
                  value: new Date(slotProps.data.date),
                  format: 'short',
                }"
              ></LocalizedValue>
            </template>
          </Column>
          <Column field="amount" :header="$t('rechargeCredit.paymentHistory.dph')" class="uppercase">
            <template #body="slotProps">
              <LocalizedValue :value="slotProps.data.amount"></LocalizedValue>
            </template>
          </Column>
          <Column field="quantity" :header="$t('rechargeCredit.paymentHistory.status')" class="uppercase">
            <template #body="slotProps">
              <span v-show="slotProps.data.status === EnumsInvoiceStatus.Canceled" class="whitespace-nowrap py-4 text-sm text-gray-600 dark:text-[#cbd5e1]">
                <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  {{ $t('rechargeCredit.paymentHistory.statusStorno') }}
                </span>
              </span>
              <span v-show="slotProps.data.status === EnumsInvoiceStatus.Paid" class="whitespace-nowrap py-4 text-sm text-green-600 dark:text-[#cbd5e1]">
                <span class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">
                  {{ $t('rechargeCredit.paymentHistory.statusOK') }}
                </span>
              </span>
              <span v-show="slotProps.data.status === EnumsInvoiceStatus.NotPaid" class="whitespace-nowrap py-4 text-sm font-semibold text-red-600 dark:text-red-700">
                <span v-if="slotProps.data.type === 'CreditNote'" class="inline-flex items-center rounded-md bg-orange-200 px-2 py-1 text-xs font-medium text-orange-800 ring-1 ring-inset ring-red-600/10">
                  {{ $t('rechargeCredit.paymentHistory.handled') }}
                </span>
                <span v-else class="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800 ring-1 ring-inset ring-red-600/10">
                  {{ $t('rechargeCredit.paymentHistory.statusNOK') }}
                </span>
              </span>
            </template>
          </Column>
          <Column :header="$t('rechargeCredit.paymentHistory.action')" class="uppercase">
            <template #body="slotProps">
              <span class="flex flex-row justify-center gap-1">
                <span v-tooltip.top="$t('rechargeCredit.paymentHistory.downloadInvoiceTooltip')">
                  <img @click="downloadInvoice(slotProps.data.id, slotProps.data.type)" :src="`${$filePath}/icons/pdf.svg`" alt="pdf" class="h-7 w-7 cursor-pointer" />
                </span>
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
      <h1 v-else class="mt-2 dark:bg-mainDark">{{ $t('rechargeCredit.paymentHistory.emptyBlank') }}</h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { EnumsInvoiceStatus } from '../../../../generated/api';

const router = useRouter();
const eshopsStore = useEshopsStore();
const toast = useToast();
const loadingStore = useLoadingStore();
const { t } = useI18n();

const paymentsHistory = ref();

// Transition
const transition = ref(false);

onMounted(async () => {
  await getData();
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getData();
  }
);

const getData = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data: paymentHistory } = await api.clientPaymentHistoryGetPaymentHistory();

    paymentsHistory.value = paymentHistory.payments;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
    transition.value = true;
  }
};

const downloadInvoice = async (invoiceId, type) => {
  try {
    const { data: response } = await api.clientPaymentHistoryGetInvoicePdfUrl({
      invoiceId: invoiceId,
      invoiceType: type,
    });

    const url = response.url;
    const name = response.fileName;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${name}`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);
      });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};
</script>
