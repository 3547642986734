<template>
    <h1 class="'mb-2 text-center text-2xl font-bold text-main transition-opacity delay-100 duration-1000 dark:bg-[#232d45]">
        {{ $t('affiliateProgram.pages.affiliateProgram.title') }}
    </h1>
    <NotAffiliatePartner v-if="!showAffiliate" />
    <AffiliateActive v-if="showAffiliate" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AffiliateActive from '@/modules/affiliateProgram/components/AffiliateActivePartners.vue';
import NotAffiliatePartner from '@/modules/affiliateProgram/components/NotAffiliatePartner.vue';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
const eshopsStore = useEshopsStore();

const showAffiliate = computed(() => {
    return userStore.getIsAffiliate && eshopsStore.getSelectedEshop?.isAffil;
});
</script>
<style lang="scss" scoped></style>
