<template>
  <!--<div :class="[userStore.getIsDarkMode ? 'dark' : userStore.getTheme]">-->
  <div :class="[userStore.getTheme]">
    <div>
      <Loading></Loading>
      <!-- <DarkMode></DarkMode> -->
      <!-- <Cookie></Cookie>-->
      <Toast
        :pt-options="{ mergeProps: true }"
        :pt="{
          root: {
            class: [' sentry-unmask'],
          },
        }"
      />
      <BlockUI :blocked="blocked" fullScreen />
      <component :is="$route.meta.layout">
        <router-view />
      </component>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { storeToRefs } from 'pinia';
import BlockUI from 'primevue/blockui';
import { usePrimeVue } from 'primevue/config';
import Toast from 'primevue/toast';
import { onMounted } from 'vue';
import LanguagesCodebook from '@/codebooks/LanguagesCodebook';
import { useBlockDocumentUI } from '@/composables/useBlockDocumentUI';
import { ThemeEnum } from '@/enums/ThemeEnum';
import Loading from '@/modules/global/components/Loading.vue';
import { useRegisterStore } from '@/stores/register';
import { useUserStore } from '@/stores/user';
import { EnumsRegistrationSource } from '../generated/api';

const gtm = useGtm();
gtm?.enable(true);

const { blocked } = useBlockDocumentUI();
const registerStore = useRegisterStore();
const userStore = useUserStore();
const primevue = usePrimeVue();

const { promoCode } = storeToRefs(registerStore);

const url = new URL(window.location.href);
const PARAM_LANG = 'lang';
const PARAM_PROMO_CODE = 'promo_code';
const PARAM_HOSTING_CODE = 'hosting_code';

const defaultLanguage = 'en';

onMounted(async () => {
  userStore.setSentryUser();
  initLanguage();
  setTheme();
  setPromoCode();
  setHostingCode();
});

const initLanguage = () => {
  const setLanguageFromUrlParam = () => {
    if (url.searchParams.has(PARAM_LANG)) {
      const URLLang = url.searchParams.get(PARAM_LANG);
      const language = LanguagesCodebook.find((c) => c.code === URLLang);
      if (language) {
        userStore.setLanguage(language.code, primevue);
      } else {
        userStore.setLanguage(defaultLanguage, primevue);
      }
      return true;
    }
    return false;
  };

  const setLanguageFromLocalStorage = () => {
    if (userStore.languageCode) {
      userStore.setLanguage(userStore.languageCode, primevue);
      return true;
    }
    return false;
  };

  const setLanguageFromUrlPath = () => {
    const langCodeFromUrl = url.href.split('/')[3];
    if (langCodeFromUrl) {
      const language = LanguagesCodebook.find((c) => c.code === langCodeFromUrl);
      if (language) {
        userStore.setLanguage(language.code, primevue);
      } else {
        userStore.setLanguage(defaultLanguage, primevue);
      }
      return true;
    }
    return false;
  };

  const setLanguageFromBrowserPreferences = () => {
    const browserPreferredLanguages = navigator.languages;

    if (browserPreferredLanguages && browserPreferredLanguages.length > 0) {
      for (let i = 0; i < browserPreferredLanguages.length; i++) {
        const languageCode = browserPreferredLanguages[i].split('-')[0] || defaultLanguage;

        const isLanguageSupported = LanguagesCodebook.find((c) => c.code === languageCode);
        if (isLanguageSupported) {
          userStore.setLanguage(languageCode, primevue);
          return true;
        }
      }
    }
    userStore.setLanguage(defaultLanguage, primevue);
    return false;
  };

  if (setLanguageFromUrlParam()) return;
  if (setLanguageFromLocalStorage()) return;
  if (setLanguageFromUrlPath()) return;
  setLanguageFromBrowserPreferences();
};

// Set theme
const setTheme = () => {
  const theme = (url.searchParams.get('theme') || url.hostname.split('.')[0]).toLowerCase();

  const isThemeSupported = Object.values(ThemeEnum).includes(theme as ThemeEnum);
  if (theme && isThemeSupported) {
    userStore.theme = theme as ThemeEnum;
    if (theme === ThemeEnum.ESHOP_RYCHLE) {
      document.getElementById('body')?.classList.add(theme);
    }
  }
};

// Set promo code
const setPromoCode = () => {
  if (url.searchParams.has(PARAM_PROMO_CODE)) {
    promoCode.value = url.searchParams.get(PARAM_PROMO_CODE) as string;
  }
};

// Set hosting code
const setHostingCode = () => {
  const hostingCode = url.searchParams.get(PARAM_HOSTING_CODE); // hosting_code
  const code = url.searchParams.get('code');
  const theme = (url.searchParams.get('theme') || url.hostname.split('.')[0]).toLowerCase();

  const hostingCodeMapping = {
    eshoprychle: EnumsRegistrationSource.EshopRychle,
    exitshop: EnumsRegistrationSource.ExitShop,
    '69a5d9f05ee64c1385cdcd97e406cf37': EnumsRegistrationSource.ExitShop,
    cd13710861314b1c9d505759321b201e: EnumsRegistrationSource.Webareal,
    '50e5328961784597a3e5be5515ee17fe': EnumsRegistrationSource.Upgates,
  };

  const mapValue = (value) => hostingCodeMapping[value];

  userStore.hostingCode = mapValue(hostingCode) || mapValue(code) || mapValue(theme) || EnumsRegistrationSource.Tanganica;
};
</script>
