import {EnumsLocale} from "../../../../generated/api";

interface IAnswers {
  value: string;
}
interface IXMLFeedTutorial {
  name: string;
  logo: string;
  blog: string;
  answers: IAnswers[];
}

type IXMLFeedTutorialObject = {
  [key in EnumsLocale]: IXMLFeedTutorial[];
};

const defaultAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.defaultInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.defaultInstruction.two',
  },
];
const shoptetAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.three',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.four',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.five',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.six',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.seven',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shoptetInstruction.eight',
  },
];
const upgatesAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.three',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.four',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.five',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.upgatesInstruction.six',
  },
];
const eshopRychleAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.three',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.four',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.five',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.six',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.seven',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.eshopRychleInstruction.eight',
  },
];
const shopifyAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shopifyInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shopifyInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.shopifyInstruction.three',
  },
];
const wooCommerceAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.wooCommerceInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.wooCommerceInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.wooCommerceInstruction.three',
  },
];
const prestaShopAnswers = [
  {
    value: 'wizard.codebooks.XMLFeedTutorials.prestaShopInstruction.one',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.prestaShopInstruction.two',
  },
  {
    value: 'wizard.codebooks.XMLFeedTutorials.prestaShopInstruction.three',
  },
];
const XMLFeedTutorialsCodebook: IXMLFeedTutorialObject = {
  cs: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  sk: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  pl: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  hu: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  ro: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  en: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  it: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  da: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  de: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  el: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  es: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  fi: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  fr: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  nl: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  nn: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  pt: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  sv: [
    {
      name: 'Default',
      logo: '/eshopSolutions/default.png',
      blog: '',
      answers: defaultAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-get-product-feed-from-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-e-shop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
};

export default XMLFeedTutorialsCodebook;
