<template>
  <Disclosure as="div" class="pt-6" v-slot="{ open }">
    <dt class="text-lg">
      <DisclosureButton class="flex w-full items-center justify-between text-left text-gray-400 dark:text-[#cbd5e1]">
        <div class="font-bold text-gray-900 dark:text-[#cbd5e1]">
          <div class="flex items-center">
            <div class="relative mr-2 h-9 w-9 rounded-full bg-main">
              <KeyIcon class="absolute left-1/2 top-1/2 block h-6 w-6 -translate-x-1/2 -translate-y-1/2 text-white"></KeyIcon>
            </div>
            <h1 class="mr-2 text-2xl font-bold text-gray-800">
              {{ $t('userSettings.changePassword.title') }}
            </h1>
          </div>
        </div>
        <span class="ml-6 flex h-7 items-center">
          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6']" aria-hidden="true" />
        </span>
      </DisclosureButton>
    </dt>
    <DisclosurePanel as="dd" class="mt-2 pr-12 dark:text-[#cbd5e1]">
      <form @submit.prevent="onSubmit" autocomplete="off" class="">
        <div class="w-full py-5 lg:w-[25rem]">
          <div class="relative w-full">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="oldPassword">{{ $t('userSettings.changePassword.pass') }}<span class="ml-1 text-red-600">*</span></label>
            <Password toggleMask id="oldPassword" :feedback="false" v-model="oldPassword" />
            <small id="text-error" class="text-sm text-red-600">{{ errors.oldPassword || '&nbsp;' }}</small>
          </div>
          <div class="relative w-full">
            <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="newPassword">
              <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
              {{ $t('userSettings.changePassword.passNew') }}<span class="ml-1 text-red-600">*</span></label
            >
            <Password
              id="newPassword"
              v-model="newPassword"
              toggleMask
              :strong-regexp="strongPasswordRegex"
              :medium-regexp="mediumPasswordRegex"
              :promptLabel="$t('passwordComplexity.prompt')"
              :weakLabel="$t('passwordComplexity.weak')"
              :mediumLabel="$t('passwordComplexity.weak')"
              :strongLabel="$t('passwordComplexity.ok')"
            >
              <template #footer>
                <Divider />
                <p class="mt-2 font-bold">
                  {{ $t('passwordComplexity.requirements') }}
                </p>
                <ul class="ml-2 mt-0 pl-2" style="line-height: 1.5">
                  <li class="flex items-center">
                    <CheckCircleIcon v-if="hasLowercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                    <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                    {{ $t('passwordComplexity.rule1') }}
                  </li>
                  <li class="flex items-center">
                    <CheckCircleIcon v-if="hasUppercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                    <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                    {{ $t('passwordComplexity.rule2') }}
                  </li>
                  <li class="flex items-center">
                    <CheckCircleIcon v-if="hasNumeric" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                    <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                    {{ $t('passwordComplexity.rule3') }}
                  </li>
                  <li class="flex items-center">
                    <CheckCircleIcon v-if="isMinLength" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                    <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                    {{ $t('passwordComplexity.rule4') }}
                  </li>
                </ul>
              </template>
            </Password>
            <small class="text-sm text-red-600">{{ errors.password || '&nbsp;' }}</small>
            <small id="text-error" class="text-sm text-red-600">{{ errors.newPassword || '&nbsp;' }}</small>
          </div>
          <div class="relative w-full">
            <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="confirmPassword">
              <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
              {{ $t('userSettings.changePassword.passNewAgain') }}
              <span class="ml-1 text-red-600">*</span></label
            >
            <Password toggleMask v-model="confirmPassword" :feedback="false" :inputProps="{ autocomplete: 'new-password' }" />
            <small id="text-error" class="text-sm text-red-600">{{ errors.confirmPassword || '&nbsp;' }}</small>
          </div>

          <div class="inline-flex justify-end">
            <MainButton type="submit" :text="$t('userSettings.changePassword.save')" class="m-auto mb-0 inline-flex justify-center p-1 text-sm" />
          </div>
        </div>
      </form>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon, KeyIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import Divider from 'primevue/divider';
import Password from 'primevue/password';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    oldPassword: yup.string().required(() => t('validations.required')),
    newPassword: yup.string().required(() => t('validations.required')),
    confirmPassword: yup
      .string()
      .required(() => t('validations.required'))
      .oneOf([yup.ref('newPassword'), ''], () => t('validations.confirmed')),
  }),
});

const [oldPassword] = defineField('oldPassword');
const [newPassword] = defineField('newPassword');
const [confirmPassword] = defineField('confirmPassword');

const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();

const newPasswordValue = computed(() => newPassword.value);
const strongPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
const mediumPasswordRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
const hasLowercase = computed(() => /[a-z]/.test(newPasswordValue.value));
const hasUppercase = computed(() => /[A-Z]/.test(newPasswordValue.value));
const hasNumeric = computed(() => /\d/.test(newPasswordValue.value));
const isMinLength = computed(() => {
  if (newPasswordValue.value === undefined) return false;
  return newPasswordValue.value.length >= 8;
});

const isChanged = computed(() => {
  return oldPassword.value !== newPassword.value;
});

const onSubmit = handleSubmit(async (formData) => {
  if (!isChanged.value) {
    toast.add({
      severity: 'error',
      summary: t('userSettings.changePassword.passwordSame'),
      life: 3000,
    });
    return;
  }

  loadingStore.updateLoading(true);

  const payload = {
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
    confirmPassword: formData.confirmPassword,
  };
  try {
    await api.clientUserSettingsChangePassword(payload, {
      metadata: {
        accessToken: 'user',
      },
    });

    toast.add({
      severity: 'success',
      summary: t('userSettings.changePassword.passwordSuccess'),
      life: 3000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    resetForm();
    loadingStore.updateLoading(false);
  }
});
</script>
