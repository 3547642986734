import {EnumsLocale} from "../../../../generated/api";

interface IAnswers {
  value: string; // i18n key
}
interface IPixelTutorial {
  name: string;
  logo: string;
  blog: string;
  answers: IAnswers[];
}

type IPixelTutorialObject = {
  [key in EnumsLocale]: IPixelTutorial[];
};

const googleTagManagerAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.six',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.seven',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.eight',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.nine',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeDefaultInstruction.ten',
  },
];
const shoptetAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShoptetInstruction.six',
  },
];
const upgatesAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeUpgatesInstruction.six',
  },
];
const eshopRychleAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.six',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.seven',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeEshopRychleInstruction.eight',
  },
];
const shopifyAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.six',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.seven',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeShopifyInstruction.eight',
  },
];
const wooCommerceAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.two',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.three',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.four',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.five',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodeWooCommerceInstruction.six',
  },
];
const prestaShopAnswers = [
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodePrestaShopInstruction.one',
  },
  {
    value: 'wizard.codebooks.pixelTutorials.howAddCodePrestaShopInstruction.two',
  },
];

const PixelTutorialsCodebook: IPixelTutorialObject = {
  cs: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/cs/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/cs/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/cs/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  sk: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/cs/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/cs/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  pl: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  hu: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  ro: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  en: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  it: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  da: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  de: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  el: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  es: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  fi: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  fr: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  nl: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  nn: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  pt: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
  sv: [
    {
      name: 'Google Tag Manager',
      logo: '/eshopSolutions/gtm.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-implement-tanganica-pixel-with-gtm',
      answers: googleTagManagerAnswers,
    },
    {
      name: 'Shopify',
      logo: '/eshopSolutions/shopify.png',
      blog: 'https://www.tanganica.com/en/blog/how-to-install-tanganica-pixel-to-shopify',
      answers: shopifyAnswers,
    },
    {
      name: 'WooCommerce',
      logo: '/eshopSolutions/wooCommerce.png',
      blog: '',
      answers: wooCommerceAnswers,
    },
    {
      name: 'PrestaShop',
      logo: '/eshopSolutions/prestaShop.png',
      blog: '',
      answers: prestaShopAnswers,
    },
    {
      name: 'Shoptet',
      logo: '/eshopSolutions/shoptet.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-shoptet',
      answers: shoptetAnswers,
    },
    {
      name: 'Upgates',
      logo: '/eshopSolutions/upgates.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-upgates',
      answers: upgatesAnswers,
    },
    {
      name: 'EshopRychle',
      logo: '/eshopSolutions/eshopRychle.png',
      blog: 'https://www.tanganica.com/en/blog/tanganica-pixel-for-eshop-rychle',
      answers: eshopRychleAnswers,
    },
  ],
};

export default PixelTutorialsCodebook;
