export const selectbutton = {
    root: ({ props }) => ({
        class: [
            'mt-2 grid grid-cols-2 sm:grid-cols-4 gap-1',
            {
                'opacity-60 select-none pointer-events-none cursor-not-allowed': props.disabled,
            },
        ],
    }),
    button: ({ context }) => ({
        class: [
            'inline-flex cursor-pointer select-none items-center align-bottom text-center items-center overflow-hidden relative',
            'p-1.5 lg:py-3',
            'transition duration-200 border',
            'rounded-lg',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-gray-300 dark:border-blue-900/40 hover:bg-gray-50 dark:hover:bg-gray-800/80 hover:border-2': !context.active,
                'border-main border-2': context.active,
                'opacity-60 select-none pointer-events-none cursor-not-allowed': context.disabled,
            },
        ],
    }),
    label: 'block text-xs sm:text-sm font-medium text-gray-900 dark:text-[#cbd5e1] whitespace-nowrap text-center mx-auto',
};
