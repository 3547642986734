<template>
  <div class="relative h-fit">
    <div class="mx-auto mt-5 max-h-full w-full rounded-3xl text-center lg:left-0 lg:w-[60%] xl:mt-1">
      <div class="mx-auto w-full px-6 lg:px-8">
        <div class="mx-auto max-w-xl lg:max-w-3xl">
          <h2 class="text-2xl font-bold tracking-tight text-main lg:text-4xl">
            {{ $t('wizard.pages.wizardPixel.title') }}
          </h2>
          <p class="mt-2 text-center text-xs text-main lg:mt-3 lg:text-base">
            {{ $t('wizard.pages.wizardPixel.subtitle') }}
          </p>
          <p class="mt-10 text-justify text-xs font-bold text-main lg:text-base">
            {{ $t('wizard.pages.wizardPixel.insertCode') }}
          </p>
          <div class="w-full rounded-lg border border-gray-300 dark:bg-[#28334e] dark:text-[#cbd5e1]">
            <blockquote class="col-span-1 flex w-full items-center justify-between break-words rounded-lg bg-gray-50 p-2 shadow dark:bg-[#1b253b] dark:text-[#cbd5e1]" data-rounded="rounded-lg" data-rounded-max="rounded-full">
              <div class="scrollbar flex max-h-16 w-full flex-col overflow-hidden overflow-y-visible p-0.5">
                <div class="relative lg:pl-12">
                  <svg class="absolute left-0 hidden h-10 w-10 text-main lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                  </svg>
                  <p class="mt-2 break-all text-justify align-middle text-[0.5rem] text-gray-600 dark:text-[#cbd5e1] sm:text-xs lg:text-sm">
                    <!-- eslint-disable -->
                    <code
                      >&lt!-- Tanganica pixel --&gt<br />&ltscript&gt(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':<br />new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],<br />j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=<br />'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);<br />})(window,document,'script','dataLayer','GTM-TRS5RSN');&lt/script&gt<br />&lt!--
                      End Tanganica pixel --&gt
                    </code>
                    <!-- eslint-enable -->
                  </p>
                </div>
              </div>
            </blockquote>
          </div>
          <div class="mt-2 flex justify-end py-2">
            <MainButton type="button" @click="copyTanganicaPixelCode" :text="$t('wizard.pages.wizardPixel.copyCode')"></MainButton>
          </div>
          <form @submit.prevent="onSubmit()" class="mt-2 lg:mt-2">
            <div class="grid w-full grid-cols-1 gap-x-8 gap-y-4 text-left sm:grid-cols-2">
              <div class="sm:col-span-2">
                <div class="rounded-lg bg-white dark:bg-mainDark">
                  <div class="scrollbar mx-auto mt-2 h-44 overflow-scroll overflow-y-auto overflow-x-hidden p-3 lg:mt-5">
                    <p class="text-sm font-bold text-main sm:text-base">
                      {{ $t('wizard.pages.wizardPixel.howAddCode') }}
                    </p>
                    <div v-for="(pixelTutorial, index) in PixelTutorialsCodebook[userStore.getLanguageCode]" :key="pixelTutorial.name" class="border-b last:border-0">
                      <button type="button" @click="handleClick(index)" :ref="(el) => (buttonRefs[index] = el as HTMLButtonElement)" class="flex w-full items-start justify-between py-3 text-left text-gray-900 dark:text-gray-300">
                        <img :src="`${$filePath}${pixelTutorial.logo}`" :alt="pixelTutorial.name" class="w-1/8 h-9 rounded-lg p-1 dark:bg-gray-300" />
                        <ChevronDownIcon v-if="activeIndex !== index" class="h-6 w-6" aria-hidden="true" />
                        <ChevronUpIcon v-else class="h-6 w-6" aria-hidden="true" />
                      </button>
                      <div v-show="activeIndex === index" class="pr-12 text-main">
                        <p v-for="(answer, index) in pixelTutorial.answers" :key="index" class="ml-5 text-sm leading-7">
                          {{ $t(answer.value) }}
                        </p>
                        <div v-show="pixelTutorial.blog" class="my-2">
                          <a :href="pixelTutorial.blog" target="_blank" class="my-4 ml-5 cursor-pointer rounded-full bg-main p-1 text-sm leading-7 text-white hover:opacity-60 hover:ring-1 sm:p-2">{{ $t('wizard.pages.wizardPixel.moreHere') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <WizardButtons></WizardButtons>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="isPixelNotSet" modal :closable="false" :draggable="false">
    <ExclamationTriangleIcon class="mx-auto h-12 w-12 text-[#F90]" aria-hidden="true" />
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header mt-2">
        {{ $t('wizard.pages.wizardPixel.pixelTestModal.notSetTitle') }}
      </h1>
      <p class="dialog-description mt-2" v-html="$t('wizard.pages.wizardPixel.pixelTestModal.notSetSubtitle')"></p>
    </div>
    <template #footer>
      <div class="mx-auto mt-5 flex flex-col items-center justify-between gap-y-4 md:w-[30rem] md:flex-row md:gap-x-4 md:gap-y-0">
        <MainButton @click="needHelp" class="min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] font-bold hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('wizard.pages.wizardPixel.pixelTestModal.needHelp')"></MainButton>
        <MainButton class="min-w-[12.5rem] font-bold hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.pixelTestModal.close')" @click="isPixelNotSet = false"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="isNeedHelpSet" modal :closable="false" :draggable="false">
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header">
        {{ $t('wizard.pages.wizardPixel.needHelpModal.title') }}
      </h1>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.needHelpModal.subtitle') }}
      </p>
      <p class="mt-2 text-center font-bold text-main md:text-lg">
        {{ ContactInfoEnum.PHONE }}
      </p>
      <p class="mt-2 text-center font-bold text-main md:text-lg">
        {{ ContactInfoEnum.EMAIL }}
      </p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.needHelpModal.close')" @click="isNeedHelpSet = false"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showInformationForRedirect" modal :closable="true" :draggable="false">
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header text-main">
        {{ $t('wizard.pages.wizardPixel.warning') }}
      </h1>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.warningText') }}
      </p>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.warningText2') }}
      </p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.warningButton')" @click="checkPixel()"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showPixelIsSet" modal :closable="false" :draggable="false">
    <template #header>
      <CheckCircleIcon class="mx-auto h-12 w-12 text-green-500" aria-hidden="true" />
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.pixelSuccess') }}
      </p>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.pixelSuccess2') }}
      </p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('wizard.pages.wizardPixel.successButton')" @click="finishWizard()"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showHelpOpenNewWindow" modal :closable="false" :draggable="false">
    <ExclamationTriangleIcon class="mx-auto h-12 w-12 text-[#F90]" aria-hidden="true" />
    <div class="flex flex-col items-center">
      <h1 class="dialog-header mt-2 text-center">
        {{ $t('wizard.pages.wizardPixel.blockedWindow') }}
      </h1>
      <div class="mt-2">
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.eshopLink') }}
          <a class="underline hover:opacity-80" target="_blank" :href="pixelUrl">{{ pixelUrl }}</a>
        </p>
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.blockedWindowText1') }}
        </p>
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.blockedWindowText2') }}
        </p>
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.blockedWindowText3') }}
        </p>
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.blockedWindowText4') }}
        </p>
        <p class="mt-2 text-left text-main">
          {{ $t('wizard.pages.wizardPixel.blockedWindowText5') }}
        </p>
      </div>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('wizard.pages.wizardPixel.blockedButton')" @click="checkPixelOnly()"></MainButton>
      </div>
    </template>
  </Dialog>
  <div v-if="showGif" class="fixed inset-0 h-screen w-screen bg-white dark:bg-lightDark">
    <img :src="$filePath + '/wizard/wizard.gif'" alt="" class="m-auto h-full w-[43.75rem] rounded-lg object-contain" />
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/vue/24/solid';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, Ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ContactInfoEnum } from '@/enums/ContactInfoEnum';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import PixelTutorialsCodebook from '@/modules/wizard/codebooks/PixelTutorialsCodebook';
import WizardButtons from '@/modules/wizard/components/WizardButtons.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';
import { EnumsWizardStatus } from '../../../../generated/api';

const gtm = useGtm();
const { t } = useI18n();
const router = useRouter();
const wizardStore = useWizardStore();
const userStore = useUserStore();
const eshopsStore = useEshopsStore();
const loadingStore = useLoadingStore();
const toast = useToast();

const animate = ref(false);

const activeIndex = ref(-1);
const buttonRefs: Ref<(HTMLElement | null)[]> = ref([]);
const isPixelNotSet = ref(false);
const isNeedHelpSet = ref(false);
const showGif = ref(false);
const showInformationForRedirect = ref(false);
const showPixelIsSet = ref(false);
const showHelpOpenNewWindow = ref(false);
const pixelUrl = ref();

/* eslint-disable */
const tanganicaPixelCode =
  "<!-- Tanganica pixel --><script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]\,j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TRS5RSN')\;</script\><!-- End Tanganica pixel -->";
/* eslint-enable */

onMounted(async () => {
  if (eshopsStore.selectedEshop.wizardStatus === EnumsWizardStatus.AddedPixel) {
    await router.push({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
  }
  wizardStore.currentStep = 3;
  await getValidEshopUrl();
});

const handleClick = async (index: number) => {
  animate.value = false;
  setTimeout(() => {
    animate.value = true;
  }, 1000);
  activeIndex.value = activeIndex.value === index ? -1 : index;
  await nextTick();
  if (buttonRefs.value[index] !== null) {
    const element = buttonRefs.value[index] as HTMLElement;

    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const copyTanganicaPixelCode = async () => {
  await navigator.clipboard.writeText(tanganicaPixelCode);
  toast.add({
    severity: 'success',
    detail: t('wizard.pages.wizardPixel.messages.pixelCopyDescription'),
    life: 3000,
  });
};

const needHelp = () => {
  isPixelNotSet.value = false;
  nextTick(() => {
    isNeedHelpSet.value = true;
  });
};

const onSubmit = async () => {
  // Open dialog with information for redirect
  showInformationForRedirect.value = true;
};

const checkPixel = async () => {
  loadingStore.updateLoading(true);
  showInformationForRedirect.value = false;

  const myWindow = window.open(pixelUrl.value, '_blank', 'width=500,height=500');
  if (myWindow) {
    await new Promise((resolve) => setTimeout(resolve, 9000));
    myWindow.close();
  } else {
    showHelpOpenNewWindow.value = true;
    loadingStore.updateLoading(false);
    return;
  }

  await checkPixelOnly();
};

const getValidEshopUrl = async () => {
  try {
    const { data: pixelUrlResponse } = await api.clientWizardGetEshopUrl();
    const hasProtocol = pixelUrlResponse.eshopUrl.startsWith('http://') || pixelUrlResponse.eshopUrl.startsWith('https://'); // Samovina
    const normalizedUrl = hasProtocol ? pixelUrlResponse.eshopUrl : `https://${pixelUrlResponse.eshopUrl}`; // Samovina
    pixelUrl.value = normalizedUrl;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const checkPixelOnly = async () => {
  showHelpOpenNewWindow.value = false;
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientWizardCheckPixel();
    if (data.contains) {
      loadingStore.updateLoading(false);
      await finishWizard();
    } else {
      gtm?.trackEvent({
        event: 'gtm.pixelInstallation',
        value: 'PixelHasNotBeenDeployed',
      });
      isPixelNotSet.value = true;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const finishWizard = async () => {
  showGif.value = true;
  showPixelIsSet.value = false;
  // Reset wizard
  wizardStore.resetWizard();

  // Set wizard finished
  wizardStore.isWizardFinished = true;
  eshopsStore.selectedEshop.wizardStatus = EnumsWizardStatus.AddedPixel;

  gtm?.trackEvent({
    event: 'gtm.pixelInstallation',
    value: 'PixelDeployed',
  });

  try {
    await api.clientWizardInitEshop();
  } catch (error: any) {
    console.warn(error);
  } finally {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    showGif.value = false;
    await router.push({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
  }
};
</script>
