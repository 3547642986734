import { defineStore } from 'pinia';
import { IStepFour } from '@/modules/wizard/interfaces/IStepFour';
import { IStepOne } from '@/modules/wizard/interfaces/IStepOne';
import { IStepThree } from '@/modules/wizard/interfaces/IStepThree';
import { IStepTwo } from '@/modules/wizard/interfaces/IStepTwo';

type step = [IStepOne, IStepTwo, IStepThree, IStepFour];

interface State {
    trialPeriodDate: number;
    currentStep: number;
    isWizardFinished: boolean;
    steps: step;
}

export const useWizardStore = defineStore('wizard', {
    state: (): State => ({
        trialPeriodDate: 0,
        currentStep: 0,
        isWizardFinished: false,
        steps: [
            {
                isValid: false,
                values: {
                    url: '',
                    country: null,
                    contactName: '',
                    contactPhoneCountry: null,
                    contactPhone: undefined,
                },
            },
            {
                isValid: false,
                values: {
                    companyName: '',
                    companyAddress: '',
                    companyCity: '',
                    companyZipCode: '',
                    companyId: '',
                    companyVat: '',
                    country: null,
                    facturationalEmail: '',
                },
            },
            {
                isValid: false,
                values: {
                    xmlFeed: '',
                },
            },
            {
                isValid: false,
            },
        ],
    }),
    getters: {
        getCurrentStep(): number {
            return this.currentStep;
        },
        getStepOne(): IStepOne {
            return this.steps[0];
        },
        getStepTwo(): IStepTwo {
            return this.steps[1];
        },
        getStepThree(): IStepThree {
            return this.steps[2];
        },
        getStepFour(): IStepFour {
            return this.steps[3];
        },
    },
    actions: {
        resetStepsValidity() {
            for (let i = this.currentStep; i < this.steps.length; i++) {
                if (this.steps[i]) {
                    this.steps[i].isValid = false;
                }
            }
        },
        resetWizard() {
            this.$reset();
        },
    },
});
