<template>
  <div class="fixed inset-0 mx-auto h-screen w-screen overflow-scroll bg-white dark:bg-mainDark">
    <div class="absolute right-0 top-0 z-10 mt-2 w-fit sm:mt-4">
      <SelectLanguage></SelectLanguage>
    </div>
    <!-- Tanganica Logo -->
    <router-link to="/" class="absolute left-2 top-2 z-30 w-fit cursor-pointer sm:top-[0.938] lg:top-[0.938]">
      <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="jungle" class="h-full max-h-[2.5rem] w-auto object-cover object-left sm:max-h-[3.125rem] xl:max-h-[3.75rem]" />
    </router-link>
    <div class="mt-2 flex min-h-full flex-1 flex-col justify-center py-12 sm:mt-0 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full">
        <h1 class="text-center text-xl font-extrabold text-main dark:bg-mainDark sm:text-4xl lg:text-5xl">
          {{ $t('authentication.login.text1') }}
        </h1>
        <!--<h2
          class="mt-2 text-center text-sm font-bold tracking-tight text-[#FF9900] sm:text-lg"
        >
          {{ $t("authentication.login.text2") }}
        </h2>-->
      </div>
      <input type="text" name="email" style="display: none;">
      <input type="password" name="password" autocomplete="new-password" style="display: none;">

      <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-[36.25rem]">
        <div class="bg-white p-6 dark:bg-mainDark sm:rounded-lg sm:px-12">
          <form @submit.prevent="onSubmit">
            <div class="grid w-full grid-cols-2 gap-x-2 lg:gap-6 lg:gap-y-3">
              <div class="col-span-2 mt-0 lg:col-span-2">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="email">{{ $t('authentication.login.email') }}</label>
                <InputText v-model="email" name="email" type="text" autocomplete="email" class="!rounded-full"></InputText>
                <small class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
              </div>
              <div class="col-span-2 mt-0 lg:col-span-2">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="password">{{ $t('authentication.login.password') }}</label>
                <Password v-model="password" name="password" autocomplete="current-password" toggleMask :feedback="false" :input-class="'!rounded-full'"></Password>
                <small class="text-sm text-red-600">{{ errors.password || '&nbsp;' }}</small>
              </div>
            </div>
            <div class="flex items-center justify-center text-sm">
              <router-link
                :to="{
                  name: PUBLIC_ROUTES.FORGOTTEN_PASSWORD_PAGE,
                }"
                class="font-medium text-main hover:text-indigo-600 hover:underline dark:text-gray-300 dark:hover:text-gray-400"
              >
                {{ $t('authentication.login.forgotPass') }}
              </router-link>
            </div>
            <button @click="toast.removeAllGroups()" type="submit" class="mx-auto mt-5 block w-full rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] py-1 hover:from-[#FF7294] hover:to-[#FF9900] sm:py-1.5">
              <span class="font-bold text-white sm:text-xl">{{ $t('authentication.login.login') }}</span>
            </button>
          </form>
          <div v-if="$theme('socialLogin')">
            <div class="relative mt-5">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200" />
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-gray-900 dark:bg-mainDark dark:text-gray-300">{{ $t('authentication.login.orContinue') }}</span>
              </div>
            </div>
            <div class="mt-5">
              <div v-if="$theme('socialLogin')">
                <SocialAuth :type="SocialAuthType.LOGIN" />
              </div>
            </div>
          </div>
        </div>
        <p class="text-center text-sm text-gray-500">
          <router-link
            :to="{
              name: PUBLIC_ROUTES.REGISTER_PAGE,
            }"
            class="text-gray-400 hover:text-[#FF9900] hover:underline dark:text-gray-300 dark:hover:text-gray-400"
          >
            <span
              >{{ $t('authentication.login.regLink') }} <span class="text-[#FF9900]">{{ $t('authentication.login.regLinkText') }}</span></span
            >
          </router-link>
        </p>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showDialogForUpdateUserPassword" :closable="true" modal :breakpoints="{ '90vw': '85vw', '85vw': '90vw' }" :pt-options="{ mergeProps: true }" :pt="{ root: 'lg:!w-[56.25rem]' }">
    <template #header>
      <h1 class="dialog-header text-[#FF9900]">
        {{ $t('authentication.login.dialogHeader') }}
      </h1>
    </template>
    <p class="text-center text-sm text-main md:text-lg">
      {{ $t('authentication.login.dialogText1') }}
    </p>
    <p class="mt-4 text-center text-sm text-main md:text-lg">
      {{ $t('authentication.login.dialogText2') }}
    </p>
    <p class="mt-4 text-center text-sm font-black text-main md:text-lg">
      <strong style="font-weight: bold; font-size: 1.125em">{{ $t('authentication.login.dialogText3', { email }) }}</strong>
    </p>
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import { usePrimeVue } from 'primevue/config';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import SelectLanguage from '@/modules/authentication/components/SelectLanguage.vue';
import SocialAuth from '@/modules/authentication/components/SocialAuth.vue';
import { SocialAuthType } from '@/modules/authentication/enums/SocialAuthType';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { handleLoginAndEshopList } from '@/utils/handleLoginAndEshopList';

const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();
const primeVue = usePrimeVue();

const showDialogForUpdateUserPassword = ref(false);

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .required(() => t('validations.required'))
      .email(() => t('validations.email')),
    password: yup.string().required(() => t('validations.required')),
  }),
  initialValues: {
    email: '',
    password: '',
  },
});

// Form fields
const [email] = defineField('email');
const [password] = defineField('password');

const onSubmit = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);
  try {
    const loginResponse = await api.clientAuthLogin(formData, {
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });
    if (loginResponse.status === 250) {
      const payload = {
        email: formData.email,
        redirectUrl: import.meta.env.VITE_RESET_PASSWORD_URL as string,
      };
      await api.clientAuthForgottenPassword(payload, {
        metadata: {
          requestType: RequestTypeEnum.PUBLIC,
        },
      });
      showDialogForUpdateUserPassword.value = true;
    } else {
      await handleLoginAndEshopList(primeVue, loginResponse.data.accessToken, loginResponse.data.refreshToken);
      resetForm();
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});
</script>
<style scoped lang="css">
.safariBold {
  font-weight: bold;
}
</style>
