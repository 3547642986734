<template>
  <div class="mt-4 rounded-md bg-white p-5 dark:bg-mainDark">
    <p class="pl-4 font-bold dark:bg-mainDark">{{ $t('rechargeCredit.rechargeCredit.choosePaymentMethod') }}</p>
    <div class="mb-4">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" @change="handleTabChange($event)">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <RouterLink v-for="tab in tabs" :key="tab.name" :to="{ name: tab.href }">
              <template #default="{ isActive }">
                <span
                  :class="[isActive ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium']"
                  :aria-current="isActive ? 'page' : undefined"
                >
                  <component :is="tab.icon" :class="[isActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                  <span>{{ tab.name }}</span>
                </span>
              </template>
            </RouterLink>
          </nav>
        </div>
      </div>
    </div>
    <RouterView></RouterView>
  </div>
</template>

<script setup lang="ts">
import { ArrowPathIcon, CreditCardIcon, QrCodeIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const router = useRouter();
const user = useUserStore();

const tabs = [
  {
    name: t('rechargeCredit.rechargeCredit.paymentGateway'),
    href: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.PAYMENT_GATEWAY_PAGE : CLIENT_ROUTES.PAYMENT_GATEWAY_PAGE,
    icon: CreditCardIcon,
    current: true,
  }, // Iframe hacks
  {
    name: t('rechargeCredit.rechargeCredit.bankTransfer'),
    href: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.PAYMENT_BANK_TRANSFER_PAGE : CLIENT_ROUTES.PAYMENT_BANK_TRANSFER_PAGE,
    icon: QrCodeIcon,
    current: false,
  }, // Iframe hacks
  {
    name: t('rechargeCredit.rechargeCredit.automaticRecharge'),
    href: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.PAYMENT_AUTOMATIC_RECHARGE_PAGE : CLIENT_ROUTES.PAYMENT_AUTOMATIC_RECHARGE_PAGE,
    icon: ArrowPathIcon,
    current: false,
  }, // Iframe hacks
];

const handleTabChange = (event) => {
  const selectedIndex = event.target.selectedIndex;
  const selectedTab = tabs[selectedIndex] as { href: string };

  router.push({ name: selectedTab.href });
};
</script>
