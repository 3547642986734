<template>
  <div v-if="showSection" class="div-primary mx-auto mt-4 xl:grid xl:grid-cols-12">
    <div class="col-span-12 mb-4 text-center xl:text-left">
      <h1 class="text-xl font-bold text-main md:text-3xl 2xl:text-4xl">{{ $t('dashboard.firstSteps.title') }}</h1>
    </div>
    <div class="m-auto mt-8 w-fit px-5 xl:col-span-7 xl:my-auto xl:ml-0">
      <Steps :dashboardStatus="status" />
    </div>
    <div class="m-auto mt-8 w-full text-center sm:mt-16 xl:col-span-5 xl:mt-0 xl:text-left">
      <h1 class="text-lg font-bold text-main md:text-xl" v-html="message.title"></h1>
      <p class="mt-3 text-sm text-main" v-html="message.content"></p>
      <div class="mx-auto mt-4 flex w-fit xl:ml-0">
        <button
          v-if="userStore.getTheme === ThemeEnum.DEFAULT"
          @click="message.onClick"
          class="btn relative mx-auto mt-4 w-fit overflow-hidden rounded-full bg-button_main bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-xs font-bold leading-none tracking-wider text-white hover:from-[#FF7294] hover:to-[#FF9900] hover:text-white sm:px-4 sm:py-3 md:text-sm"
        >
          {{ message.button }}
        </button>
        <img v-if="message.rocket" :src="`${$filePath}/icons/rocket.svg`" alt="rocket" class="ml-2 mt-4 w-6" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { ThemeEnum } from '@/enums/ThemeEnum';
import Steps from '@/modules/dashboard/components/Steps.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { EnumsDashboardStatus, EnumsMarketingFormat, EnumsCampaignDesiredStatus } from '../../../../generated/api';
import CreditValuesCodebook from '../../../codebooks/CreditValuesCodebook';

const { t, n } = useI18n();

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const toast = useToast();
const eshopStore = useEshopsStore();
const userStore = useUserStore();
const loadingStore = useLoadingStore();

const message = computed<{
  title: string;
  content: string;
  button: string;
  rocket: boolean;
  onClick: () => void;
}>(() => {
  switch (props.status) {
    case EnumsDashboardStatus.EshopNotSetUp:
      return {
        title: t('dashboard.firstSteps.eshopNotSetUp.title'),
        content: t('dashboard.firstSteps.eshopNotSetUp.content'),
        button: t('dashboard.firstSteps.eshopNotSetUp.button'),
        rocket: false,
        onClick: addEshop,
      };
    case EnumsDashboardStatus.EshopSetUpNoCredit:
      return {
        title: t('dashboard.firstSteps.eshopSetUpNoCredit.title'),
        content: getLocalizedNumberText.value,
        button: t('dashboard.firstSteps.eshopSetUpNoCredit.button'),
        rocket: false,
        onClick: addCredit,
      };
    case EnumsDashboardStatus.EshopSetUpWithCreditNotTurnedOn:
      return {
        title: t('dashboard.firstSteps.eshopSetUpWithCreditNotTurnedOn.title'),
        content: t('dashboard.firstSteps.eshopSetUpWithCreditNotTurnedOn.content'),
        button: t('dashboard.firstSteps.eshopSetUpWithCreditNotTurnedOn.button'),
        rocket: true,
        onClick: turnOnCampaigns,
      };
    default:
      return {
        title: t('dashboard.firstSteps.eshopNotSetUp.title'),
        content: t('dashboard.firstSteps.eshopNotSetUp.content'),
        button: t('dashboard.firstSteps.eshopNotSetUp.button'),
        rocket: false,
        onClick: addEshop,
      };
  }
});

const getLocalizedNumberText = computed(() => {
  const currencyCode = eshopStore.getSelectedEshop?.currency || 'EUR';
  const minimumCreditValue = CreditValuesCodebook.find((creditValue) => creditValue.currencyCode === currencyCode)?.recommendCreditValue || 0;
  const localizedNumber = n(minimumCreditValue, {
    style: 'currency',
    currency: currencyCode,
  });

  return t('dashboard.firstSteps.eshopSetUpNoCredit.content', { minimumCreditValue: localizedNumber });
});

const addEshop = () => {
  router.push({ name: WIZARD_ROUTES.ADD_ESHOP_PAGE });
};

const addCredit = () => {
  router.push({ name: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE });
};

const turnOnCampaigns = async () => {
  loadingStore.updateLoading(true);

  const payload = {
    desiredStatus: EnumsCampaignDesiredStatus.On,
  };

  try {
    await api.clientCampaignsSetStatus(EnumsMarketingFormat.Pla, payload);
    toast.add({ severity: 'success', summary: t('campaigns.google.prx.successfulStartCampaign'), life: 6000 });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const showSection = computed(() => {
  return [EnumsDashboardStatus.EshopNotSetUp, EnumsDashboardStatus.EshopSetUpNoCredit, EnumsDashboardStatus.EshopSetUpWithCreditNotTurnedOn].includes(props.status);
});
</script>
