<template>
  <div class="div-primary relative mx-auto w-full xl:my-0">
    <h1 class="mb-1 text-xl font-bold text-main md:text-3xl 2xl:text-4xl">
      {{ $t('dashboard.learningPhase.header') }}
    </h1>
    <p @click="openModal = true" class="mb-1 max-w-fit cursor-pointer text-sm font-bold text-[#F67424] hover:underline sm:text-base" v-html="$t('dashboard.learningPhase.button')"></p>
    <p class="mb-10 max-w-full text-left text-sm">
      {{ $t('dashboard.learningPhase.text') }}
    </p>
    <div v-for="(channel, index) in formats" :key="index" class="mx-auto mt-2 flex w-full items-center xl:ml-0 xl:mr-auto">
      <div class="mr-2 w-[22.87rem] rounded-2xl bg-slate-100 xl:w-full">
        <div class="flex h-8 items-center px-1 pl-3 md:h-12">
          <img v-if="channel.marketingChannel === 'Facebook'" :src="`${$filePath}/previewFormat/meta.png`" alt="eshop" class="mr-2 w-6" />
          <img v-else-if="channel.marketingChannel === 'Microsoft'" :src="`${$filePath}/logos/MicrosoftLogo.png`" alt="eshop" class="mr-2 w-6" />
          <img v-else :src="`${$filePath}/previewFormat/google.png`" alt="eshop" class="mr-2 w-6" />
          <span class="text-[0.62rem] text-main md:text-sm" v-html="$t('dashboard.campaigns.' + channel.marketingFormat)"></span>
        </div>
      </div>
      <div class="relative mx-auto w-full overflow-hidden rounded-full bg-gray-300 dark:bg-gray-700">
        <div
          v-if="channel?.data?.value < requiredNumberOrders"
          class="min-w-max rounded-full bg-[#f9522f] py-0.5 leading-none"
          :style="{
            width: (channel?.data?.value / requiredNumberOrders) * 100 + '%',
          }"
        >
          <p class="px-2 text-center text-xs font-bold text-white sm:text-sm">
            {{ channel?.data?.value }}
          </p>
        </div>
        <div v-else class="min-w-max rounded-full bg-[#359F03] py-0.5 leading-none" :style="{ width: 100 }">
          <p class="text-center text-xs font-bold text-white sm:text-sm">
            {{ channel?.data?.value }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="openModal" modal :closable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t('dashboard.campaigns.helpModal.learningPhase')"></h1>
    </template>
    <template #default>
      <p class="dialog-description" v-html="$t('dashboard.campaigns.helpModal.learningPhaseDescription')"></p>
    </template>
    <template #footer>
      <div class="mx-auto flex flex-col items-center justify-between gap-y-4 md:w-[25rem] md:flex-row md:gap-y-0">
        <MainButton @click="openModal = false" class="mx-auto text-xs font-bold md:w-[11.875rem] md:text-sm" type="button" :text="$t('dashboard.campaigns.helpModal.closeButton')"></MainButton>
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { ref, onMounted, computed, watch } from 'vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';

const requiredNumberOrders = 30;
const openModal = ref(false);
const eshopStore = useEshopsStore();

onMounted(async () => {
  await getOrdersData();
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await getOrdersData();
  }
);

const ordersData = ref({
  googlePlaOrderCount: {
    value: 0,
    type: 'Number',
  },
  googlePrxOrderCount: {
    value: 0,
    type: 'Number',
  },
  googleDsaOrderCount: {
    value: 0,
    type: 'Number',
  },
  facebookDpaOrderCount: {
    value: 0,
    type: 'Number',
  },
  microsoftPlaOrderCount: {
    value: 0,
    type: 'Number',
  },
  microsoftPrxOrderCount: {
    value: 0,
    type: 'Number',
  },
  microsoftDsaOrderCount: {
    value: 0,
    type: 'Number',
  },
});

const formats = computed(() => [
  {
    marketingChannel: 'Google',
    marketingFormat: 'PLA',
    isActive: true,
    data: ordersData.value.googlePlaOrderCount,
  },
  {
    marketingChannel: 'Google',
    marketingFormat: 'PRX',
    isActive: false,
    data: ordersData.value.googlePrxOrderCount,
  },
  {
    marketingChannel: 'Google',
    marketingFormat: 'DSA',
    isActive: false,
    data: ordersData.value.googleDsaOrderCount,
  },
  {
    marketingChannel: 'Facebook',
    marketingFormat: 'DPA',
    isActive: false,
    data: ordersData.value.facebookDpaOrderCount,
  },
  {
    marketingChannel: 'Microsoft',
    marketingFormat: 'BING_PLA',
    isActive: false,
    data: ordersData.value.microsoftPlaOrderCount,
  },
  {
    marketingChannel: 'Microsoft',
    marketingFormat: 'BING_PRX',
    isActive: false,
    data: ordersData.value.microsoftPrxOrderCount,
  },
  {
    marketingChannel: 'Microsoft',
    marketingFormat: 'BING_DSA',
    isActive: false,
    data: ordersData.value.microsoftDsaOrderCount,
  },
]);

const getOrdersData = async () => {
  try {
    const { data } = await api.clientDashboardGetLearningPhaseOrders();
    ordersData.value = data;
  } catch (error: any) {
    console.error(error);
  }
};
</script>
<style scoped lang="css">
.recommended {
  background: linear-gradient(180deg, #ff8f29 0%, #ff6887 100%);
}
</style>
