<template>
  <div class="relative h-fit">
    <div class="mx-auto mt-5 max-h-full w-full rounded-3xl text-center lg:left-0 lg:w-[60%] xl:mt-1">
      <div class="mx-auto max-w-4xl px-6 lg:w-full lg:px-8">
        <div class="mx-auto max-w-xl lg:max-w-4xl">
          <h2 class="text-2xl font-bold tracking-tight text-main lg:text-4xl">
            {{ $t('wizard.pages.wizardBillInfo.title') }}
          </h2>
          <form @submit.prevent="onSubmit" class="mt-5 sm:mt-10">
            <div class="grid w-full grid-cols-1 gap-x-8 gap-y-1 text-left sm:grid-cols-2 lg:gap-y-4">
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyName">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardBillInfo.company') }}<span class="ml-1 text-red-600">*</span></label
                >
                <InputText id="companyName" v-model="companyName" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="organization" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyName || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyAddress">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardBillInfo.street') }}<span class="ml-1 text-red-600">*</span></label
                >
                <InputText id="companyAddress" v-model="companyAddress" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="street-address" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyAddress || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyCity">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardBillInfo.city') }}<span class="ml-1 text-red-600">*</span></label
                >
                <InputText id="companyCity" v-model="companyCity" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="address-level2" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyCity || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyZipCode">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardBillInfo.postalCode') }}<span class="ml-1 text-red-600">*</span></label
                >
                <InputText id="companyZipCode" v-model="companyZipCode" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="postal-code" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyZipCode || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyId">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardBillInfo.companyId') }}<span class="ml-1 text-red-600">*</span></label
                >
                <InputText id="companyId" v-model="companyId" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="company-id" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyId || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyVat">{{ $t('wizard.pages.wizardBillInfo.vatNumber') }}</label>
                <InputText id="companyVat" v-model="companyVat" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="company-vat" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.companyVat || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('wizard.pages.wizardBillInfo.country') }}<span class="ml-1 text-red-600">*</span></label>
                <SelectCountry v-model="country" />
                <small id="text-error" class="text-sm text-red-600">{{ errors.country || '&nbsp;' }}</small>
              </div>
              <div class="mt-1 sm:col-span-1">
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="facturationalEmail">{{ $t('wizard.pages.wizardBillInfo.email') }}</label>
                <InputText id="facturationalEmail" v-model="facturationalEmail" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="bill-email" />
                <small id="text-error" class="text-xs text-red-600">{{ errors.facturationalEmail || '&nbsp;' }}</small>
              </div>
            </div>
            <WizardButtons></WizardButtons>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import SelectCountry from '@/modules/global/components/SelectCountry.vue';
import WizardButtons from '@/modules/wizard/components/WizardButtons.vue';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useWizardStore } from '@/stores/wizard';
import { EnumsCountryOfAdvertisement } from '../../../../generated/api';

const gtm = useGtm();
const { t } = useI18n();
const router = useRouter();
const wizardStore = useWizardStore();
const toast = useToast();
const dataSaving = ref<boolean>(false);

// Form
const { defineField, handleSubmit, errors } = useForm({
  validationSchema: yup.object({
    companyName: yup.string().required(() => t('validations.required')),
    companyAddress: yup.string().required(() => t('validations.required')),
    companyCity: yup.string().required(() => t('validations.required')),
    companyZipCode: yup.string().required(() => t('validations.required')),
    companyId: yup
      .string()
      .required(() => t('validations.required'))
      .companyID(),
    companyVat: yup.string().notRequired(),
    country: yup.object().required(() => t('validations.required')),
    facturationalEmail: yup.string().email(() => t('validations.email')),
  }),
  initialValues: wizardStore.getStepTwo.values,
});

// Form fields
const [companyName] = defineField('companyName');
const [companyAddress] = defineField('companyAddress');
const [companyCity] = defineField('companyCity');
const [companyZipCode] = defineField('companyZipCode');
const [companyId] = defineField('companyId');
const [companyVat] = defineField('companyVat');
const [country] = defineField('country');
const [facturationalEmail] = defineField('facturationalEmail');

onMounted(async () => {
  wizardStore.currentStep = 1;
  const { data } = await api.clientWizardGetRemainingTrialPeriod();
  wizardStore.trialPeriodDate = data.daysRemaining ?? 0;
});

const isFormChanged = (formData) => {
  return JSON.stringify(wizardStore.getStepTwo.values) !== JSON.stringify(formData);
};

const onSubmit = handleSubmit(async (formData) => {
  const isThereFormChanges = isFormChanged(formData);

  if (dataSaving.value) {
    return;
  }
  dataSaving.value = true;

  // If form is not changed, go to next step
  if (!isThereFormChanges) {
    wizardStore.steps[1].isValid = true;
    await router.push({ name: WIZARD_ROUTES.XML_PAGE });

    return;
  }

  const payload = {
    companyName: formData.companyName,
    companyAddress: formData.companyAddress,
    companyCity: formData.companyCity,
    companyZipCode: formData.companyZipCode,
    companyId: formData.companyId,
    companyVat: formData.companyVat || null,
    country: formData.country?.code as EnumsCountryOfAdvertisement,
    facturationalEmail: formData.facturationalEmail,
  };

  try {
    await api.clientWizardSetBillingSettings(payload);

    gtm?.trackEvent({
      event: 'gtm.billInfo',
      value: payload,
    });

    // Update wizard store
    wizardStore.steps[1].values.companyName = formData.companyName;
    wizardStore.steps[1].values.companyAddress = formData.companyAddress;
    wizardStore.steps[1].values.companyCity = formData.companyCity;
    wizardStore.steps[1].values.companyZipCode = formData.companyZipCode;
    wizardStore.steps[1].values.companyId = formData.companyId;
    wizardStore.steps[1].values.companyVat = formData.companyVat;
    wizardStore.steps[1].values.country = formData.country;
    wizardStore.steps[1].values.facturationalEmail = formData.facturationalEmail;

    wizardStore.steps[1].isValid = true;

    toast.add({
      severity: 'success',
      summary: t('wizard.pages.wizardBillInfo.messages.saveBillingInfoSuccessTitle'),
      detail: t('wizard.pages.wizardBillInfo.messages.saveBillingInfoSuccessDescription'),
      life: 3000,
    });

    await router.push({ name: WIZARD_ROUTES.XML_PAGE });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    dataSaving.value = false;
  }
});
</script>
