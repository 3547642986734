<template>
  <div class="relative mx-auto mb-5 w-fit max-w-[100%] rounded-lg p-6 pb-10 lg:max-w-7xl">
    <div v-if="subscriptionStatus === EnumsSubscriptionStatus.Failed">
      <div class="relative mb-5 rounded-lg bg-white p-6 shadow">
        <div class="absolute left-1/2 top-0 w-full -translate-x-1/2 rounded-md rounded-b-none bg-red-600 p-1">
          <p class="text-center text-xs text-white md:text-sm">{{ $t('subscription.text1') }}</p>
        </div>
        <h1 class="my-5 text-center text-2xl text-main">{{ $t('subscription.tanganicaPremium') }}</h1>
        <p class="mx-auto mb-2 max-w-3xl text-center text-sm text-red-600 md:text-base">{{ $t('subscription.text2') }}</p>
        <p class="mx-auto mb-2 max-w-3xl text-center text-sm text-main md:text-base">{{ $t('subscription.text3') }} <LocalizedValue :value="{ value: tomorrow, type: 'Date', format: 'short' }"></LocalizedValue>.</p>
        <p class="mx-auto mb-2 max-w-3xl text-center text-sm text-main md:text-base">
          {{ $t('subscription.text4') }} <span @click="showCanceledTanganicaPremium = true" class="cursor-pointer font-bold hover:underline">{{ $t('subscription.text5') }}</span>
        </p>
      </div>
      <SubscriptionSelection :hasSaveCard="hasSaveCard" :monthlySubscriptionAmount="monthlySubscriptionAmount" :yearlySubscriptionAmount="yearlySubscriptionAmount" />
      <SubscriptionHistory />
    </div>
    <div v-else-if="subscriptionStatus === EnumsSubscriptionStatus.Cancelled">
      <h1 class="mb-2 text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
      <div class="relative mb-5 mt-10 rounded-lg bg-white p-6 shadow">
        <p class="text-left text-main">{{ $t('subscription.text6') }} <LocalizedValue :value="{ value: getCurrentSubscriptionData?.endsAt, type: 'Date', format: 'short' }"></LocalizedValue>.</p>
        <p class="mt-2 text-left text-main">{{ $t('subscription.text7') }}</p>
        <button @click="changeSubscriptionStatus()" class="ml-auto mr-0 mt-5 block w-fit rounded-full bg-[#FF914D] px-7 py-1 text-lg text-white shadow hover:scale-110">{{ $t('subscription.text8') }}</button>
      </div>
      <SubscriptionFunction />
    </div>
    <div v-else-if="subscriptionStatus === EnumsSubscriptionStatus.FreeTrial">
      <h1 class="mb-2 text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
      <SubscriptionFreeTrialHeader />
      <SubscriptionSelection :hasSaveCard="hasSaveCard" :monthlySubscriptionAmount="monthlySubscriptionAmount" :yearlySubscriptionAmount="yearlySubscriptionAmount" />
      <SubscriptionFunction />
      <SubscriptionHistory />
    </div>
    <div v-else-if="isActiveSubscription" class="mt-5">
      <div v-if="subscriptionStatus === EnumsSubscriptionStatus.EnabledByAdmin">
        <SubscriptionFunction />
      </div>
      <div class="mx-auto" v-else>
        <div class="relative mx-auto mb-5 rounded-lg bg-white p-6 shadow">
          <div class="flex items-center justify-between">
            <h2 class="mb-5 text-left text-2xl text-main">{{ $t('subscription.text9') }}</h2>
            <button @click="changeCard()" class="text-xs text-main hover:underline">{{ $t('subscription.text10') }}</button>
          </div>

          <ul class="mt-5 flex w-full grow flex-col items-center justify-center gap-2 xl:flex-row">
            <li @click="showCanceledTanganicaPremium = true" class="w-full cursor-pointer text-center text-sm font-bold text-[#FF914D] hover:underline sm:text-lg">{{ $t('subscription.tanganicaPremium') }}</li>
            <li class="w-full text-center text-sm font-bold text-main">{{ $t('subscription.text11') }} {{ $t('subscription.' + getCurrentSubscriptionData?.subscriptionType) }}</li>
            <li class="w-full text-center text-sm font-bold text-main">{{ $t('subscription.text12') }} <LocalizedValue :value="{ value: getCurrentSubscriptionData?.nextPayment, type: 'Date', format: 'short' }"></LocalizedValue></li>
            <li class="w-full text-center text-sm font-bold text-main">
              {{ $t('subscription.text13') }} <LocalizedValue :value="getCurrentSubscriptionData?.nextPaymentAmount"></LocalizedValue><span class="ml-2 text-gray-300">{{ $t('subscription.text14') }}</span>
            </li>
            <li :class="['w-full text-center text-sm font-bold text-green-500 sm:text-base']">{{ $t('subscription.text19') }}</li>
          </ul>
        </div>
        <SubscriptionFunction />
        <SubscriptionHistory />
      </div>
    </div>
    <div v-else class="">
      <h1 class="mb-10 text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
      <SubscriptionSelection :hasSaveCard="hasSaveCard" :monthlySubscriptionAmount="monthlySubscriptionAmount" :yearlySubscriptionAmount="yearlySubscriptionAmount" />
      <SubscriptionFunction />
      <SubscriptionHistory />
    </div>
  </div>
  <Dialog v-model:visible="showCanceledTanganicaPremium" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="mx-auto text-center text-3xl text-[#FF914D] xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
    </template>
    <template #default>
      <p class="text-center text-main">{{ $t('subscription.text15') }}</p>
      <p class="mt-5 text-center text-main">{{ $t('subscription.text16') }}</p>
    </template>
    <template #footer>
      <Button @click="openQuestionnaireReallyWantsSubscription()" type="button" class="mr-2" severity="warning" :label="$t('subscription.text17')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      <Button @click="showCanceledTanganicaPremium = false" type="button" severity="success" :label="$t('subscription.text18')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
    </template>
  </Dialog>
  <Dialog v-model:visible="openCheckout" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
    </template>
    <template #default>
      <Checkout :subscriptionType="subscriptionType" />
    </template>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
  <Dialog v-model:visible="openSaveCard" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
    </template>
    <template #default>
      <SaveCard :isSubscripion="true" :subscriptionType="'changeCard'" />
    </template>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
  <ReallyOffDialog v-model="visibleReallyOffDialog" @reallyOffDialogCallback="cancelSubscription" />
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import Checkout from '@/modules/rechargeCredit/components/Checkout.vue';
import ReallyOffDialog from '@/modules/rechargeCredit/components/ReallyOffSubscriptionDialog.vue';
import SaveCard from '@/modules/rechargeCredit/components/SaveCard.vue';
import SubscriptionFreeTrialHeader from '@/modules/rechargeCredit/components/SubscriptionFreeTrialHeader.vue';
import SubscriptionFunction from '@/modules/rechargeCredit/components/SubscriptionFunction.vue';
import SubscriptionHistory from '@/modules/rechargeCredit/components/SubscriptionHistory.vue';
import SubscriptionSelection from '@/modules/rechargeCredit/components/SubscriptionSelection.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsSubscriptionStatus, ClientDTOGetCurrentSubscriptionResponse } from '../../../../generated/api';

const isActiveSubscription = ref(false);
const subscriptionType = ref();
const openCheckout = ref(false);
const openSaveCard = ref(false);
const subscriptionStatus = ref<EnumsSubscriptionStatus>();
const getCurrentSubscriptionData = ref<ClientDTOGetCurrentSubscriptionResponse>();
const showCanceledTanganicaPremium = ref(false);
const visibleReallyOffDialog = ref(false);
const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
const hasSaveCard = ref(true);
const monthlySubscriptionAmount = ref();
const yearlySubscriptionAmount = ref();
const isDataLoaded = ref(false);

const eshopsStore = useEshopsStore();
const gtm = useGtm();
const toast = useToast();
const { t } = useI18n();

const url = new URL(window.location.href);
const SUBSCRIPTION_TYPE = 'subscriptionType';

onMounted(async () => {
  if (url.searchParams.has(SUBSCRIPTION_TYPE)) {
    subscriptionType.value = url.searchParams.get(SUBSCRIPTION_TYPE);
    if (subscriptionType.value && subscriptionType.value !== 'changeCard') {
      openCheckout.value = true;
    }
  }
  reloadFullComponent();
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    reloadFullComponent();
  }
);

const getSubscriptionStatus = async () => {
  try {
    const { data } = await api.clientRechargeCreditGetSubscriptionStatus();
    subscriptionStatus.value = data.subscriptionStatus;
    isActiveSubscription.value = data.isActiveSubscription ?? false;
  } catch (error) {
    handleError(error);
  }
};

const getCurrentSubscription = async () => {
  try {
    const { data } = await api.clientRechargeCreditGetCurrentSubscription();
    getCurrentSubscriptionData.value = data;
  } catch (error) {
    handleError(error);
  }
};

const openQuestionnaireReallyWantsSubscription = () => {
  showCanceledTanganicaPremium.value = false;
  visibleReallyOffDialog.value = true;
};

const cancelSubscription = async (reallyOffDialogData) => {
  try {
    await api.clientRechargeCreditChangeSubscriptionStatus({ desiredSubscriptionStatus: false });
    gtm?.trackEvent({
      event: 'gtm.reasonTurnOffSubscription',
      value: {
        reason: reallyOffDialogData,
        eshopId: eshopsStore.selectedEshop.id,
        domaiNname: eshopsStore.selectedEshop.domainName,
      },
    });
    showCanceledTanganicaPremium.value = false;
    await getSubscriptionStatus();
    await getCurrentSubscription();
  } catch (error) {
    handleError(error);
  }
};

const getHasSaveCard = async () => {
  try {
    const { data } = await api.clientRechargeCreditHasSavedCard();
    hasSaveCard.value = data.hasCard ?? false;
  } catch (error) {
    handleError(error);
  }
};

const getSubscriptionPrices = async () => {
  try {
    const { data } = await api.clientRechargeCreditGetSubscriptionPrices();
    monthlySubscriptionAmount.value = data.monthlySubscriptionAmount;
    yearlySubscriptionAmount.value = data.yearlySubscriptionAmount;
    yearlySubscriptionAmount.value.value = Number(data.yearlySubscriptionAmount?.value) / 12;
    console.log(data);
  } catch (error) {
    handleError(error);
  }
};

const changeSubscriptionStatus = async () => {
  try {
    await api.clientRechargeCreditChangeSubscriptionStatus({ desiredSubscriptionStatus: true });
    await reloadFullComponent();
  } catch (error) {
    handleError(error);
  }
};

const changeCard = () => {
  openSaveCard.value = true;
};

const reloadFullComponent = async () => {
  isDataLoaded.value = true;
  await getSubscriptionPrices();
  await getSubscriptionStatus();
  await getCurrentSubscription();
  await getHasSaveCard();
  isDataLoaded.value = false;
};

const handleError = (error) => {
  if (error.response) {
    if (error.response.data.status >= 500) {
      toast.add({
        severity: 'error',
        summary: t('serverErrorTitle'),
        detail: error.response.data.requestId,
        life: 20000,
      });
    } else {
      toast.add({
        severity: 'error',
        summary: error.response.data.detail,
        life: 6000,
      });
    }
  } else {
    console.warn(error);
  }
};
</script>
