<template>
  <!-- Mobile Version Menu -->
  <nav class="space-y-1 overflow-x-hidden bg-main px-2 dark:bg-mainDark lg:hidden">
    <router-link :to="{ name: item.href, query: item.queryParams, params: item.pathParams }" v-for="item in navigation" :key="item.name" @click="sidebarOpen = false" class="menu-items group" v-show="item.show" :target="item.target">
      <component :is="item.icon" class="ml-5 mr-4 h-5 w-5 shrink-0" aria-hidden="true" />
      <div class="flex items-center">
        <span>{{ item.name }}</span>
        <span v-if="item.premium" class="ml-1 rounded-full bg-[#ff6540] px-1 text-[0.5rem] text-white">Premium</span>
      </div>
    </router-link>
  </nav>

  <!--Desktop Version Menu-->
  <nav class="hidden flex-1 pb-4 pl-2 lg:block">
    <div v-for="(item, index) in navigation" :key="item.name">
      <template v-if="item.children">
        <div class="group">
          <button @click="item.show = !item.show" class="menu-items group flex w-full items-center">
            <component :is="item.icon" class="ml-5 mr-3 h-5 w-5 shrink-0" aria-hidden="true" />
            {{ item.name }}
            <div class="menu-item-before"></div>
            <div class="menu-item-after"></div>
          </button>
          <div v-show="item.show" class="pl-5">
            <router-link :to="{ name: child.href, query: child.queryParams, params: child.pathParams }" v-for="child in item.children" :key="child.name" class="menu-items group" v-show="child.show" @click="sidebarOpen = false" :target="child.target">
              <component :is="child.icon" class="ml-5 mr-3 h-5 w-5 shrink-0" aria-hidden="true" />
              {{ child.name }}
              <div class="menu-item-before"></div>
              <div class="menu-item-after"></div>
            </router-link>
          </div>
        </div>
      </template>
      <template v-else>
        <router-link :to="{ name: item.href, query: item.queryParams, params: item.pathParams }" :class="[item.name == 'wizard' ? 'text-[#ff6540]' : '', 'menu-items group']" v-show="item.show" @click="item.onClick" :target="item.target">
          <component :is="item.icon" class="ml-5 mr-3 h-5 w-5 shrink-0" aria-hidden="true" />
          <div class="flex w-full items-center justify-between">
            <span>{{ item.name }}</span>
            <span v-if="item.premium" class="ml-1 mr-2 rounded-full bg-[#ff6540] px-1 text-[0.5rem] text-white">Premium</span>
          </div>
          <div class="menu-item-before"></div>
          <div class="menu-item-after"></div>
        </router-link>
        <div v-if="index === 7" class="py-1">
          <div class="max-w-[90%] border-b border-indigo-400"></div>
        </div>
      </template>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { ChartBarIcon, HomeIcon, BarsArrowUpIcon, CreditCardIcon, CogIcon, PhoneIcon, UserGroupIcon, QuestionMarkCircleIcon, AcademicCapIcon, AdjustmentsVerticalIcon, SparklesIcon, ChatBubbleLeftRightIcon } from '@heroicons/vue/24/outline';
import { watch, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { AI_ASSISTANT_ROUTES } from '@/modules/aiAssistant/enums/RoutesEnum';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { useAuthStore } from '@/stores/auth';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { EnumsWizardStatus } from '../../../generated/api';

const { t } = useI18n();
const userStore = useUserStore();
const eshopsStore = useEshopsStore();
const authStore = useAuthStore();
const wizardHelperStore = useWizardHelperStore();

const sidebarOpen = ref(true);
const emit = defineEmits(['sidebarOpen']);

watch(
  () => sidebarOpen.value,
  (newVal) => {
    emit('sidebarOpen', newVal);
  }
);

// Navigation
const navigation = computed(() => {
  return [
    {
      name: t('layout.client.mainMenu.wizard'),
      href: WIZARD_ROUTES.BILLINFO_PAGE,
      icon: AcademicCapIcon,
      show: eshopsStore.getSelectedEshop?.wizardStatus !== EnumsWizardStatus.AddedPixel,
      target: '_self',
      premium: false,
      onClick: () => {
        wizardHelperStore.isNewEshop = false;
      },
    },
    {
      name: t('layout.client.mainMenu.dashboard'),
      href: CLIENT_ROUTES.DASHBOARD_PAGE,
      icon: HomeIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.stats'),
      href: CLIENT_ROUTES.STATISTICS_PAGE,
      icon: ChartBarIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.campaignSetting'),
      href: CAMPAIGNS_ROUTES.INDEX,
      icon: AdjustmentsVerticalIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.rechargeCredit'),
      href: userStore.getTheme === ThemeEnum.DEFAULT ? CLIENT_ROUTES.RECHARGE_CREDIT_PAGE : FULL_PAGE_ROUTES.IFRAME_LOGIN_PAGE,
      queryParams:
        userStore.getTheme === ThemeEnum.DEFAULT
          ? {}
          : {
              access: authStore.getUserAccessToken,
              refresh: authStore.getRefreshToken,
              selectedEshopId: eshopsStore.getSelectedEshop?.id,
              theme: userStore.getTheme,
            },
      target: userStore.getTheme === ThemeEnum.DEFAULT ? '_self' : '_blank', // Iframe hacks
      icon: CreditCardIcon,
      show: true,
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.prioritization'),
      href: CLIENT_ROUTES.PRIORITIZATION_PAGE,
      icon: BarsArrowUpIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.aiContent'),
      href: AICONTENT_ROUTES.INDEX,
      icon: SparklesIcon,
      show: true,
      target: '_self',
      premium: true,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.aiAssistant'),
      href: AI_ASSISTANT_ROUTES.INDEX,
      icon: ChatBubbleLeftRightIcon,
      show: true,
      target: '_self',
      premium: true,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.partnerProgramme'),
      href: CLIENT_ROUTES.AFFILIATE_REPORT_PAGE,
      icon: UserGroupIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.eshopSetting'),
      href: CLIENT_ROUTES.ESHOP_SETTINGS_PAGE,
      icon: CogIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.support'),
      href: CLIENT_ROUTES.SUPPORT_PAGE,
      icon: QuestionMarkCircleIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
    {
      name: t('layout.client.mainMenu.contact'),
      href: CLIENT_ROUTES.CONTACT_PAGE,
      icon: PhoneIcon,
      show: true,
      target: '_self',
      premium: false,
      onClick: () => {},
    },
  ];
});
</script>
