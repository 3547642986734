import { defineStore } from 'pinia';

interface State {
    isNewEshop: boolean;
    isAffiliate: boolean;
}
export const useWizardHelperStore = defineStore('wizardHelper', {
    state: (): State => ({
        isNewEshop: false,
        isAffiliate: false,
    }),
    actions: {
        resetWizardHelper() {
            this.$reset();
        },
    },
});
