import { ThemeEnum } from '@/enums/ThemeEnum';
import { CREDIT_CHECKOUT_TYPES } from '@/modules/rechargeCredit/enums/CreditCheckoutTypes';
import { useUserStore } from '@/stores/user';

export const getStripeRedirectURL = (type, param=''): string => {
  const userStore = useUserStore();
  let redirectUri = '';
  if (CREDIT_CHECKOUT_TYPES.CHECKOUT === type) {
    switch (userStore.theme) {
      case ThemeEnum.IFRAME: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_IFRAME;
        break;
      }
      case ThemeEnum.ESHOP_RYCHLE: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_ESHOPRYCHLE;
        break;
      }
      case ThemeEnum.EXIT_SHOP: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_EXITSHOP;
        break;
      }
      default: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL;
      }
    }
  } else if (CREDIT_CHECKOUT_TYPES.AUTOMATIC_RECHARGE === type) {
    switch (userStore.theme) {
      case ThemeEnum.IFRAME: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_IFRAME_AUTOMATIC_RECHARGE;
        break;
      }
      case ThemeEnum.ESHOP_RYCHLE: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_ESHOPRYCHLE_AUTOMATIC_RECHARGE;
        break;
      }
      case ThemeEnum.EXIT_SHOP: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_EXITSHOP_AUTOMATIC_RECHARGE;
        break;
      }
      default: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_AUTOMATIC_RECHARGE;
      }
    }
  } else if (CREDIT_CHECKOUT_TYPES.SUBSCRIPTION === type) {
    switch (userStore.theme) {
      case ThemeEnum.IFRAME: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_IFRAME_SUBSCRIPTION + '?subscriptionType=' + param;
        break;
      }
      case ThemeEnum.ESHOP_RYCHLE: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_ESHOPRYCHLE_SUBSCRIPTION + '?subscriptionType=' + param;;
        break;
      }
      case ThemeEnum.EXIT_SHOP: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_EXITSHOP_SUBSCRIPTION + '?subscriptionType=' + param;
        break;
      }
      default: {
        redirectUri = import.meta.env.VITE_STRIPE_CALLBACK_URL_SUBSCRIPTION + '?subscriptionType=' + param;
      }
    }
  }

  return redirectUri;
};
