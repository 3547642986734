<template>
  <Menubar class="bg-main" :model="menuItems" :pt-options="{ mergeProps: true }" :pt="{ menu: '!gap-2 sm:justify-center', button: '!text-white !bg-main' }">
    <template #item="{ item, props }">
      <router-link v-if="item.route" v-slot="{ href, navigate, isActive }" :to="{ name: item.route }" custom>
        <a
          :href="href"
          v-bind="props.action"
          @click="navigate"
          :class="[
            'relative flex items-center rounded-md text-sm text-main hover:text-main dark:text-gray-500 hover:dark:text-gray-500 sm:text-white',
            {
              'bg-gray-300 !text-main dark:bg-gray-300 dark:text-gray-500 sm:bg-white sm:dark:bg-gray-200': isActive,
            },
          ]"
        >
          <component :is="item.icon" class="h-5 w-5"> </component>
          <span class="ml-2 h-full">{{ item.label }}</span>
          <span v-if="item.premium" class="right-0 top-0 ml-1 rounded-full bg-[#ff6540] p-0.5 text-[0.5rem] text-white sm:absolute">Premium</span>
        </a>
      </router-link>
    </template>
  </Menubar>
  <div class="mt-4 rounded-md bg-white p-5 dark:bg-mainDark">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { AdjustmentsVerticalIcon, QueueListIcon, BarsArrowUpIcon, UsersIcon, RectangleGroupIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';
import Menubar from 'primevue/menubar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';

const { t } = useI18n();
const router = useRouter();

const menuItems = ref([
  {
    label: t('statistics.pages.statistics.firstMenuItem'),
    icon: AdjustmentsVerticalIcon,
    route: CLIENT_ROUTES.CAMPAIGN_STATISTICS_PAGE,
    premium: false,
  },
  {
    label: t('statistics.pages.statistics.secondMenuItem'),
    icon: QueueListIcon,
    route: CLIENT_ROUTES.ORDERS_STATISTICS_PAGE,
    premium: true,
  },
  {
    label: t('statistics.pages.statistics.thirdMenuItem'),
    icon: BarsArrowUpIcon,
    route: CLIENT_ROUTES.PRODUCTS_STATISTICS_PAGE,
    premium: true,
  },
  {
    label: t('statistics.pages.statistics.fifthMenuItem'),
    icon: RectangleGroupIcon,
    route: CLIENT_ROUTES.CATEGORIES_STATISTICS_PAGE,
    premium: true,
  },
  {
    label: t('statistics.pages.statistics.fourthMenuItem'),
    icon: UsersIcon,
    route: CLIENT_ROUTES.TRAFFIC_DIRECTORY_STATISTICS_PAGE,
    premium: true,
  },
  {
    label: t('statistics.pages.statistics.sixthMenuItem'),
    icon: AdjustmentsHorizontalIcon,
    route: CLIENT_ROUTES.COMPETITIVE_METRICS_STATISTICS_PAGE,
    premium: true,
  },
]);
</script>
