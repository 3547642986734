import * as Sentry from '@sentry/vue';
import { setLocale } from '@vee-validate/i18n';
import { defineStore } from 'pinia';
import { ThemeEnum } from '@/enums/ThemeEnum';
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import {setPrimeVueLanguage} from "@/plugins/primeVue";
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { useEshopsStore } from '@/stores/eshops';
import { useWizardStore } from '@/stores/wizard';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { EnumsClientPermission } from '../../generated/api';

interface IState {
    email: string;
    languageCode: string;
    darkMode: boolean;
    displayCookie: boolean;
    statusCookie: boolean;
    isAffiliate: boolean;
    theme: ThemeEnum;
    hostingCode: string;
    authProvider?: string;
    role?: Array<string>;
    openFirstLoadDialog: boolean;
    permissions: EnumsClientPermission[];
    permissionsLoaded: boolean;
    openSubscriptionDialog: boolean;
    loginAsAdmin: boolean;
}

export const useUserStore = defineStore('user', {
    state: (): IState => ({
        email: '',
        languageCode: '',
        darkMode: false,
        displayCookie: true,
        statusCookie: false,
        isAffiliate: false,
        theme: ThemeEnum.DEFAULT,
        hostingCode: '',
        authProvider: '',
        role: [],
        openFirstLoadDialog: true,
        permissions: [],
        permissionsLoaded: false,
        openSubscriptionDialog: false,
        loginAsAdmin: false,
    }),
    getters: {
        getIsLoggedIn: () => {
            const authStore = useAuthStore();
            return !!authStore.getRefreshToken;
        },
        getLanguageCode: (state) => state.languageCode,
        getIsDarkMode: (state) => state.darkMode,
        getTheme: (state) => state.theme,
        getAuthProvider: (state) => state.authProvider,
        getIsAffiliate: (state) => state.isAffiliate,
        getRole: (state) => state.role,
        getPermissionsLoaded: (state) => state.permissionsLoaded,
    },
    actions: {
        async setLanguage(languageCode: string, primeVueInstance) {
            this.languageCode = languageCode;

            // Load locale messages
            if (!i18n.global.availableLocales.includes(languageCode)) {
                await loadLocaleMessages(i18n, languageCode);
            }

            // Set i18n language
            setI18nLanguage(i18n, languageCode);

            // Set vee-validate language
            setLocale(languageCode);

            // Set primeVue language
            setPrimeVueLanguage(primeVueInstance, languageCode);
        },
        setSentryUser() {
            if (import.meta.env.VITE_SENTRY_ENABLED !== 'true') {
                return;
            }

            if (this.getIsLoggedIn) {
                const eshopStore = useEshopsStore();

                Sentry.setUser({
                    id: eshopStore.selectedEshop?.id ?? '',
                    username: eshopStore.selectedEshop?.domainName ?? '',
                    email: this.email,
                });
            } else {
                Sentry.setUser(null);
            }
        },
        setEmail(email: string) {
            this.email = email;
        },
        setAffiliate(isAffiliate: boolean) {
            this.isAffiliate = isAffiliate;
        },
        setRole(role: Array<string>) {
            if (typeof role === 'string') {
                this.role = [role];
            } else {
                this.role = role;
            }
        },
        async getPermissions() {
            if (!this.getIsLoggedIn) {
              return;
            }
      
            try {
              const { data: permissions } = await api.clientAuthGetClientPermissions();
      
              this.setPermissions(permissions.clientPermissions || []);
      
            } catch (error) {
              console.error('Error fetching permissions', error);
            }
          },
          setPermissions(permissions: EnumsClientPermission[]) {
            this.permissions = permissions;
          },
        setAuthProvider(authProvider: string) {
            this.authProvider = authProvider;
        },
        destroySession() {
            const wizardStore = useWizardStore();
            const wizardHelperStore = useWizardHelperStore();

            this.email = '';
            this.isAffiliate = false;
            this.role = [];
            this.permissions = [];
            this.permissionsLoaded = false;

            wizardStore.resetWizard();
            wizardHelperStore.resetWizardHelper();
        },
        reset() {
            this.$reset();
        },
    },
    persist:  {
        paths: ['languageCode', 'darkMode', 'theme', 'hostingCode', 'openFirstLoadDialog']
    },
});
