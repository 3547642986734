<template>
  <section class="relative text-center">
    <div class="mx-auto flex h-full w-full flex-col justify-center" v-if="!$theme('pixelText')">
      <div class="m-auto flex content-center items-center justify-center text-center align-middle">
        <h1 class="my-auto mr-2 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">
          {{ $t('eshopSettings.tanganicaPixel.title') }}
        </h1>
        <CheckCircleIcon v-if="pixelState" class="h-8 w-8 rounded-full bg-gradient-to-tr from-green-500 to-green-700 text-white xl:h-10 xl:w-10"></CheckCircleIcon>
        <XCircleIcon v-else class="h-8 w-8 rounded-full bg-gradient-to-tr from-red-600 to-red-700 text-white xl:h-10 xl:w-10"></XCircleIcon>
      </div>
      <div>
        <p class="my-6 text-xs text-gray-600 dark:bg-[#28334e] dark:text-[#cbd5e1] lg:text-lg">
          {{ $t('eshopSettings.tanganicaPixel.eshopRychleText1') }}
          <a class="cursor-pointer text-blue-700 hover:text-blue-500 hover:underline" target="_blank" href="https://www.eshop-rychle.cz/admin/script.php?vol=analytics&svol=2">{{ $t('eshopSettings.tanganicaPixel.eshopRychleText2') }}</a>
        </p>
      </div>
    </div>
    <div v-else class="mx-auto block">
      <div class="mx-auto mb-2 flex h-full w-full flex-col justify-center">
        <div class="m-auto flex content-center items-center justify-center text-center align-middle">
          <h1 class="my-auto mr-2 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">
            {{ $t('eshopSettings.tanganicaPixel.title') }}
          </h1>
          <CheckCircleIcon v-if="pixelState" class="h-8 w-8 text-green-700 xl:h-10 xl:w-10"></CheckCircleIcon>
          <XCircleIcon v-else class="h-8 w-8 text-red-700 xl:h-10 xl:w-10"></XCircleIcon>
        </div>
        <p class="my-2 text-xs text-gray-600 dark:bg-[#28334e] dark:text-[#cbd5e1] lg:text-base">
          {{ $t('eshopSettings.tanganicaPixel.text1') }}
        </p>
        <p class="my-2 text-xs text-gray-600 dark:bg-[#28334e] dark:text-[#cbd5e1] lg:text-base">
          {{ $t('eshopSettings.tanganicaPixel.text2') }}
          <!-- eslint-disable-next-line -->
          <b><pre>"&lt/head&gt"</pre></b>
        </p>
        <MainButton type="button" @click="copyCode(code)" :text="$t('eshopSettings.tanganicaPixel.copyCode')" class="mx-auto justify-center px-4 py-3 text-base" />
      </div>
      <p @click="isCopyCodeModalOpen = true" class="mx-auto mb-5 max-w-fit cursor-pointer text-center text-sm font-bold text-main hover:underline sm:text-base">
        {{ $t('eshopSettings.tanganicaPixel.showCode') }}
      </p>
    </div>
    <p class="mt-1 text-xs text-gray-600 dark:bg-[#28334e] dark:text-[#cbd5e1] lg:text-base">
      <!-- eslint-disable-next-line -->
      {{ $t('eshopSettings.tanganicaPixel.testText') }}
      <b
        >{{ $t('eshopSettings.tanganicaPixel.testText2') }}.
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
      </b>
    </p>
    <MainButton type="button" @click="showInformationForRedirect = true" :text="$t('eshopSettings.tanganicaPixel.test')" class="ml-auto mr-0 mt-10 block text-base" />
  </section>
  <Dialog v-model:visible="isCopyCodeModalOpen" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header">
        {{ $t('eshopSettings.tanganicaPixel.copyCodeTitle') }}
      </h1>
    </template>
    <template #default>
      <div class="my-10 w-full dark:bg-[#28334e] dark:text-[#cbd5e1]">
        <blockquote class="col-span-1 mb-14 flex w-full items-center justify-between break-words rounded-lg bg-gray-50 p-2 shadow dark:bg-[#1b253b] dark:text-[#cbd5e1] sm:p-6" data-rounded="rounded-lg" data-rounded-max="rounded-full">
          <div class="flex flex-col sm:pr-8">
            <div class="relative lg:pl-12">
              <svg class="absolute left-0 hidden h-10 w-10 text-main lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
              </svg>
              <p class="mt-2 break-all align-middle text-[0.5rem] text-gray-600 dark:text-[#cbd5e1] sm:text-xs lg:text-sm xl:text-base">
                <!-- eslint-disable -->
                <code>
                  &lt!-- Tanganica pixel --&gt<br />
                  &ltscript&gt(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':<br />
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],<br />
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=<br />
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);<br />
                  })(window,document,'script','dataLayer','GTM-TRS5RSN');&lt/script&gt<br />
                  &lt!-- End Tanganica pixel --&gt
                </code>
                <!-- eslint-enable -->
              </p>
            </div>
          </div>
        </blockquote>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="isPixelNotSet" modal :closable="false" :draggable="false">
    <ExclamationTriangleIcon class="mx-auto h-12 w-12 text-[#F90]" aria-hidden="true" />
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header mt-2">
        {{ $t('wizard.pages.wizardPixel.pixelTestModal.notSetTitle') }}
      </h1>
      <p class="dialog-description mt-2" v-html="$t('wizard.pages.wizardPixel.pixelTestModal.notSetSubtitle')"></p>
    </div>
    <template #footer>
      <div class="mx-auto mt-5 flex flex-col items-center justify-between gap-y-4 md:w-[30rem] md:flex-row md:gap-x-4 md:gap-y-0">
        <MainButton @click="needHelp" class="min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] font-bold hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('wizard.pages.wizardPixel.pixelTestModal.needHelp')"></MainButton>
        <MainButton class="min-w-[12.5rem] font-bold hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.pixelTestModal.close')" @click="isPixelNotSet = false"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="isNeedHelpSet" modal :closable="false" :draggable="false">
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header">
        {{ $t('wizard.pages.wizardPixel.needHelpModal.title') }}
      </h1>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.needHelpModal.subtitle') }}
      </p>
      <p class="mt-2 text-center font-bold text-main md:text-lg">
        {{ ContactInfoEnum.PHONE }}
      </p>
      <p class="mt-2 text-center font-bold text-main md:text-lg">
        {{ ContactInfoEnum.EMAIL }}
      </p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.needHelpModal.close')" @click="isNeedHelpSet = false"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showInformationForRedirect" modal :closable="true" :draggable="false">
    <div class="flex flex-col items-center text-center">
      <h1 class="dialog-header text-main">
        {{ $t('wizard.pages.wizardPixel.warning') }}
      </h1>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.warningText') }}
      </p>
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.warningText2') }}
      </p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton class="mx-auto min-w-[12.5rem] hover:bg-mainhover" :hover="false" :text="$t('wizard.pages.wizardPixel.warningButton')" @click="checkPixel()"></MainButton>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showPixelIsSet" modal :closable="true" :draggable="false">
    <template #header>
      <CheckCircleIcon class="mx-auto h-12 w-12 text-green-500" aria-hidden="true" />
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2">
        {{ $t('wizard.pages.wizardPixel.pixelSuccess') }}
      </p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { XCircleIcon } from '@heroicons/vue/24/solid';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, nextTick, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { ContactInfoEnum } from '@/enums/ContactInfoEnum';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();

const showInformationForRedirect = ref(false);
const showPixelIsSet = ref(false);
const isCopyCodeModalOpen = ref(false);
const isPixelNotSet = ref(false);
const isNeedHelpSet = ref(false);
const pixelState = ref(false);

onMounted(async () => {
  try {
    const { data } = await api.clientEshopSettingsGetPixelStatus();
    pixelState.value = data.pixelStatus ? true : false;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
});

/* eslint-disable */
const code =
  "<!-- Tanganica pixel --><script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]\,j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TRS5RSN')\;</script\><!-- End Tanganica pixel -->";
/* eslint-enable */

const copyCode = async (code) => {
  await navigator.clipboard.writeText(code);
  toast.add({
    severity: 'success',
    summary: t('eshopSettings.tanganicaPixel.copyCodeSuccess'),
    life: 6000,
  });
};

const checkPixel = async () => {
  loadingStore.updateLoading(true);
  showInformationForRedirect.value = false;
  // set 10s timeout for checking pixel
  const { data: pixelUrl } = await api.clientWizardGetEshopUrl();
  const hasProtocol = pixelUrl.eshopUrl.startsWith('http://') || pixelUrl.eshopUrl.startsWith('https://');
  const normalizedUrl = hasProtocol ? pixelUrl.eshopUrl : `https://${pixelUrl.eshopUrl}`;
  const myWindow = window.open(normalizedUrl, '_blank', 'width=500,height=500');
  await new Promise((resolve) => setTimeout(resolve, 6000));
  if (myWindow) {
    myWindow.close();
  }

  try {
    const { data } = await api.clientWizardCheckPixel();
    loadingStore.updateLoading(false);
    if (data.contains) {
      showPixelIsSet.value = true;
    } else {
      isPixelNotSet.value = true;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const needHelp = () => {
  isPixelNotSet.value = false;
  nextTick(() => {
    isNeedHelpSet.value = true;
  });
};
</script>
