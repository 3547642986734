<template>
  <ContentBlock :menuCardItems="menuCardItems" v-if="router.currentRoute.value.name === AICONTENT_ROUTES.EMAIL" />
  <RouterView v-else />
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ContentBlock from '@/modules/aicontent/components/ContentBlock.vue';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
const { t } = useI18n();
const router = useRouter();
const menuCardItems = [
  {
    name: t('socialMedia.GenerateEmailIdeasForNewsletterHeader'),
    description: t('socialMedia.GenerateEmailIdeasForNewsletterDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_EMAIL,
    type: 'GenerateEmailIdeasForNewsletter',
    imgsrc: '/socialContent/EmailIdeasForNewsletter.png',
  },
  {
    name: t('socialMedia.GenerateEmailIdeasForNewsletterTextHeader'),
    description: t('socialMedia.GenerateEmailIdeasForNewsletterTextDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_EMAIL,
    type: 'GenerateEmailIdeasForNewsletterText',
    imgsrc: '/socialContent/EmailIdeasForNewsletterText.png',
  },
  {
    name: t('socialMedia.GenerateEmailContentStrategyHeader'),
    description: t('socialMedia.GenerateEmailContentStrategyDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_EMAIL,
    type: 'GenerateEmailContentStrategy',
    imgsrc: '/socialContent/EmailContentStrategy.png',
  },
];
</script>
