<template>
  <nav class="w-full" aria-label="Progress">
    <div role="list" class="flex items-center space-x-1 space-y-0 align-baseline md:space-x-8">
      <div v-for="(step, index) in store.steps" :key="step.id" class="flex-1">
        <button v-if="step.title" @click="switchStep(index)" :disabled="!canNavigateToStep(index)" :class="stepClass(index)" aria-current="step">
          <span class="hidden font-medium sm:flex sm:text-[0.563rem] md:text-[0.625rem] lg:text-[0.688rem] xl:text-sm" :class="stepTextClass(index)">
            <CheckCircleIcon v-if="step.isValid" class="h-5 w-5"></CheckCircleIcon>
            <span class="-pt-1" v-else :class="stepNumberClass(index)">
              <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.625rem]">{{ index + 1 }}</span>
            </span>
            {{ $t(step.title) }}
          </span>
          <span class="sm:hidden">
            <span :class="stepNumberClass(index)">
              <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.625rem]">{{ index + 1 }}</span>
            </span>
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { loadWizardStore } from '@/modules/campaigns/utils/loadWizardStore';
import { EnumsMarketingChannel, EnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<EnumsMarketingChannel>,
    required: true,
  },
  format: {
    type: String as PropType<EnumsMarketingFormat>,
    required: true,
  },
});

const router = useRouter();

const store = await loadWizardStore(props.campaign, props.format);

const switchStep = async (index: number) => {
  store.currentStep = index;
  const route = store.steps[index]?.route;
  await router.push({ name: route.name, params: { step: route.params.step } });
};

const canNavigateToStep = (stepIndex: number) => {
  // If all steps before current step are valid, allow navigation
  return store.steps.slice(0, stepIndex).every((step) => step.isValid);
};

const stepClass = (index: number) => {
  if (index !== store.getCurrentStep && store.steps[index]?.isValid) {
    return 'w-full group flex flex-col border-green-400 py-2 pl-4 hover:border-green-600 border-t-4 pb-0 pl-0 sm:pt-4';
  } else if (index === store.getCurrentStep) {
    return 'w-full flex flex-col border-main py-2 pl-4 border-t-4 pb-0 pl-0 sm:pt-4';
  } else {
    return 'w-full group flex flex-col border-gray-200 py-2 pl-4 hover:border-gray-300 border-t-4 pb-0 pl-0 sm:pt-4';
  }
};

const stepTextClass = (index: number) => {
  if (index !== store.getCurrentStep && store.steps[index]?.isValid) {
    return 'text-green-600';
  } else if (index === store.getCurrentStep) {
    return 'text-main';
  } else {
    return 'text-gray-500';
  }
};

const stepNumberClass = (index: number) => {
  if (index !== store.getCurrentStep && store.steps[index]?.isValid) {
    return 'h-[1.125rem] w-[1.125rem] text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-green-400 hover:border-green-600 mr-1 relative';
  } else if (index === store.getCurrentStep) {
    return 'h-[1.125rem] w-[1.125rem] text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-main mr-1 relative';
  } else {
    return 'h-[1.125rem] w-[1.125rem] text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border mr-1 relative';
  }
};
</script>
