<template>
  <div class="h-screen max-h-screen overflow-hidden overflow-y-scroll bg-white dark:bg-lightDark">
    <div v-if="wizardStore.trialPeriodDate > 0" class="flex items-center justify-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2 text-center sm:px-3">
      <div class="mx-auto flex min-w-max items-center justify-center text-center">
        <p class="text-xs leading-6 text-main sm:text-sm">
          <strong
            ><span>{{ $t('layout.wizardLayout.trial') }}</span></strong
          >
          <span class="mx-2">{{ $t('layout.wizardLayout.trialOff') }}</span
          >{{ wizardStore.trialPeriodDate }}
          <span class="">{{ $t('layout.wizardLayout.trialDays') }}</span>
        </p>
      </div>
    </div>
    <div class="mb-1 flex items-center justify-between pt-2 lg:hidden">
      <router-link v-if="canUserNavigateInsideApp" :to="{ name: CLIENT_ROUTES.ESHOP_LIST_PAGE }">
        <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="logo" class="ml-5 mr-14 h-6 object-cover md:h-10 lg:h-12 2xl:mr-24 2xl:h-16" />
      </router-link>
      <div v-else>
        <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="logo" class="ml-5 mr-14 h-6 object-cover md:h-10 lg:h-16 2xl:mr-24" />
      </div>
      <div class="z-10 w-fit">
        <SelectLanguage :saveToDb="true"></SelectLanguage>
      </div>
    </div>
    <div class="w-full items-center justify-between bg-white text-left dark:bg-lightDark lg:flex lg:pt-5">
      <div class="hidden lg:block">
        <router-link v-if="canUserNavigateInsideApp" :to="{ name: CLIENT_ROUTES.ESHOP_LIST_PAGE }">
          <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="logo" class="ml-5 mr-14 h-6 object-cover md:h-10 lg:h-12 2xl:mr-24 2xl:h-16" />
        </router-link>
        <div v-else>
          <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="logo" class="ml-5 mr-14 h-6 object-cover md:h-10 lg:h-16 2xl:mr-24" />
        </div>
      </div>
      <div class="mt-3 w-full px-5">
        <nav class="w-full" aria-label="Progress">
          <div role="list" class="flex items-center space-x-1 space-y-0 align-baseline md:space-x-8">
            <div v-for="(step, index) in steps" :key="step.id" class="flex-1">
              <button @click="switchStep(index)" :disabled="!canNavigateToStep(index)" :class="stepClass(index)" aria-current="step">
                <span class="hidden font-medium sm:flex sm:text-[0.56rem] md:text-[0.62rem] lg:text-[0.68rem] xl:text-sm" :class="stepTextClass(index)">
                  <CheckCircleIcon v-if="step.isValid" class="h-5 w-5"></CheckCircleIcon>
                  <span class="-pt-1" v-else :class="stepNumberClass(index)">
                    <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.62rem]">{{ index + 1 }}</span>
                  </span>
                  {{ step.name }}
                </span>
                <span class="sm:hidden">
                  <span :class="stepNumberClass(index)">
                    <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.62rem]">{{ index + 1 }}</span>
                  </span>
                </span>
              </button>
            </div>
            <router-link v-if="canUserNavigateInsideApp" :to="{ name: CLIENT_ROUTES.ESHOP_LIST_PAGE }" class="h-6 w-6 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="my-auto h-6 w-6 font-extrabold text-main sm:h-8 sm:w-8">
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path
                  d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
                />
              </svg>
            </router-link>
            <router-link v-else :to="{ name: CLIENT_ROUTES.LOGOUT_PAGE }" v-tooltip.left="$t('layout.client.userMenu.logout')" class="h-6 w-6 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" class="my-auto h-6 w-6 font-extrabold text-main sm:h-8 sm:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </router-link>
          </div>
        </nav>
      </div>
    </div>
    <div class="z-10 ml-auto mr-0 mt-3 hidden w-fit lg:block">
      <SelectLanguage :saveToDb="true"></SelectLanguage>
    </div>

    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import CountriesCodebook from '@/codebooks/CountriesCodebook';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import SelectLanguage from '@/modules/authentication/components/SelectLanguage.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useWizardStore } from '@/stores/wizard';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { findLastIndex } from '@/utils/findLastIndex';
import { EnumsWizardStatus } from '../../generated/api';

const { t } = useI18n();
const router = useRouter();
const wizardStore = useWizardStore();
const wizardHelperStore = useWizardHelperStore();
const loadingStore = useLoadingStore();
const eshopsStore = useEshopsStore();
const toast = useToast();

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

onMounted(async () => {
  if (!wizardHelperStore.isNewEshop && eshopsStore.managedEshops.length > 0) {
    await initWizard();
    await redirectToActiveStep();
  }
});

const canUserNavigateInsideApp = computed(() => {
  return eshopsStore.managedEshops.some((eshop) => eshop.wizardStatus === EnumsWizardStatus.AddedPixel);
});

const steps = computed(() => [
  {
    id: 1,
    name: t('layout.wizardLayout.eshopInfo'),
    href: WIZARD_ROUTES.ADD_ESHOP_PAGE,
    isValid: wizardStore.steps[0].isValid,
  },
  {
    id: 2,
    name: t('layout.wizardLayout.billingInfo'),
    href: WIZARD_ROUTES.BILLINFO_PAGE,
    isValid: wizardStore.steps[1].isValid,
  },
  {
    id: 3,
    name: t('layout.wizardLayout.productFeed'),
    href: WIZARD_ROUTES.XML_PAGE,
    isValid: wizardStore.steps[2].isValid,
  },
  {
    id: 4,
    name: t('layout.wizardLayout.measuringCode'),
    href: WIZARD_ROUTES.PIXEL_PAGE,
    isValid: wizardStore.steps[3].isValid,
  },
]);

const initEshop = async () => {
  try {
    const { data: dataForName } = await api.clientUserSettingsGetName({
      metadata: {
        accessToken: 'user',
      },
    });
    const { data: dataForPhone } = await api.clientUserSettingsGetPhoneNumber({
      metadata: {
        accessToken: 'user',
      },
    });

    const { data: eshop } = await api.clientWizardGetEshop();

    wizardStore.steps[0].values.url = eshop?.url || '';
    wizardStore.steps[0].values.country = CountriesCodebook.find((country) => country.code === eshop?.country) || null;
    wizardStore.steps[0].values.contactName = dataForName.name || '';

    try {
      const parsedPhoneNumber = phoneUtil.parse(dataForPhone.phoneNumber || '');

      wizardStore.steps[0].values.phoneCountry = CountriesCodebook.find((country) => country.phonePrefix === parsedPhoneNumber.getCountryCode()) || null;

      wizardStore.steps[0].values.phoneNumber = parsedPhoneNumber.getNationalNumber();
    } catch (error) {
      wizardStore.steps[0].values.phoneCountry = null;
      wizardStore.steps[0].values.phoneNumber = undefined;
    }

    // Set valid
    wizardStore.steps[0].isValid = wizardStore.steps[0].values.url !== '' && wizardStore.steps[0].values.country !== null;
  } catch (error) {
    wizardStore.steps[0].isValid = false;
    throw new Error();
  }
};

const initBillInfo = async () => {
  try {
    if (wizardStore.steps[0].isValid) {
      const { data } = await api.clientWizardGetRemainingTrialPeriod();
      wizardStore.trialPeriodDate = data.daysRemaining ?? 0;
    }
    const { data: billingInfo } = await api.clientWizardGetBillingSettings();

    wizardStore.steps[1].values.companyName = billingInfo.companyName || '';
    wizardStore.steps[1].values.companyId = billingInfo.companyId || '';
    wizardStore.steps[1].values.companyVat = billingInfo.companyVat || '';
    wizardStore.steps[1].values.companyAddress = billingInfo.companyAddress || '';
    wizardStore.steps[1].values.companyZipCode = billingInfo.companyZipCode || '';
    wizardStore.steps[1].values.companyCity = billingInfo.companyCity || '';
    wizardStore.steps[1].values.country = CountriesCodebook.find((country) => country.code === billingInfo.country) ?? CountriesCodebook.find((country) => country.code === wizardStore.steps[0].values.country?.code) ?? null;
    wizardStore.steps[1].values.facturationalEmail = billingInfo.facturationalEmail || '';

    // Set valid
    wizardStore.steps[1].isValid =
      wizardStore.steps[1].values.companyName !== '' &&
      wizardStore.steps[1].values.companyId !== '' &&
      wizardStore.steps[1].values.companyAddress !== '' &&
      wizardStore.steps[1].values.companyZipCode !== '' &&
      wizardStore.steps[1].values.companyCity !== '' &&
      wizardStore.steps[1].values.country !== null;
  } catch (error) {
    wizardStore.steps[1].isValid = false;
    throw new Error();
  }
};

const initXml = async () => {
  try {
    const { data: xmlFeed } = await api.clientWizardGetProductFeedUrl();
    wizardStore.steps[2].values.xmlFeed = xmlFeed.feedUrl || '';

    // Set valid
    wizardStore.steps[2].isValid = wizardStore.steps[2].values.xmlFeed !== '';
  } catch (error) {
    wizardStore.steps[2].isValid = false;
    throw new Error();
  }
};

const initWizard = async () => {
  loadingStore.updateLoading(true);
  try {
    await initEshop();
    await initBillInfo();
    await initXml();
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const stepClass = (index: number) => {
  if (index !== wizardStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'w-full group flex flex-col border-green-400 py-2 pl-4 hover:border-green-600 border-t-4 pb-0 pl-0 sm:pt-4';
  } else if (index === wizardStore.getCurrentStep) {
    return 'w-full flex flex-col border-main py-2 pl-4 border-t-4 pb-0 pl-0 sm:pt-4';
  } else {
    return 'w-full group flex flex-col border-gray-200 py-2 pl-4 hover:border-gray-300 border-t-4 pb-0 pl-0 sm:pt-4';
  }
};

const stepTextClass = (index: number) => {
  if (index !== wizardStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'text-green-600';
  } else if (index === wizardStore.getCurrentStep) {
    return 'text-main';
  } else {
    return 'text-gray-500';
  }
};

const stepNumberClass = (index: number) => {
  if (index !== wizardStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'h-[1.12rem] w-[1.12rem]text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-green-400 hover:border-green-600 mr-1 relative';
  } else if (index === wizardStore.getCurrentStep) {
    return 'h-[1.12rem] w-[1.12rem] text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-main mr-1 relative';
  } else {
    return 'h-[1.12rem] w-[1.12rem] text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border mr-1 relative';
  }
};

const switchStep = async (index: number) => {
  await router.push({ name: steps.value[index]?.href });
};

const canNavigateToStep = (stepIndex: number) => {
  // If all steps before current step are valid, allow navigation
  return steps.value.slice(0, stepIndex).every((step) => step.isValid);
};

const redirectToActiveStep = async () => {
  const lastValidStepIndex = findLastIndex(steps.value, (step) => step.isValid);
  if (lastValidStepIndex === -1) {
    await router.push({ name: steps.value[0]?.href });
  }
  await router.push({ name: steps.value[lastValidStepIndex + 1]?.href });
};

const handlePopstate = () => {};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
