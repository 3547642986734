<template>
  <!--Mobile Component-->
  <MenuComponent
    as="div"
    class="z-30 w-12 cursor-pointer border-gray-200 text-white focus:outline-none focus:ring-2 dark:text-[#cbd5e1] lg:hidden"
  >
    <div>
      <MenuButton
        class="flex rounded-full border-white text-sm hover:border focus:outline-none"
      >
        <!-- <img class="h-10 w-10 rounded-full" src="https://ca.slack-edge.com/T020ETD4VL3-U0207TRFSKY-438f0a592dc1-72" alt="FILIP" />-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="h-10 w-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-[100] mr-4 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-white focus:outline-none"
      >
        <MenuItem
          v-for="item in menuItems"
          :key="item.name"
          v-slot="{ active }"
          as="span"
        >
          <template v-if="item.show">
            <button
              @click="item.onClick"
              :class="[
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700',
              ]"
            >
              {{ item.name }}
            </button>
          </template>
        </MenuItem>
      </MenuItems>
    </transition>
  </MenuComponent>

  <!--Desktop Component-->
  <MenuComponent
    as="div"
    class="absolute top-1/2 z-[100] ml-3 -translate-y-1/2"
  >
    <div>
      <MenuButton
        class="hidden rounded-full border border-transparent text-sm hover:border-black focus:outline-none lg:flex"
      >
        <!-- <img class="h-10 w-10 rounded-full" src="https://ca.slack-edge.com/T020ETD4VL3-U0207TRFSKY-438f0a592dc1-72" alt="FILIP" />-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="h-10 w-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black focus:outline-none"
      >
        <MenuItem
          v-for="item in menuItems"
          :key="item.name"
          v-slot="{ active }"
          as="span"
        >
          <template v-if="item.show">
            <button
              @click="item.onClick"
              :class="[
                active ? 'bg-gray-100' : '',
                'block w-full px-4 py-2 text-left text-sm text-gray-700',
              ]"
            >
              {{ item.name }}
            </button>
          </template>
        </MenuItem>
      </MenuItems>
    </transition>
  </MenuComponent>

  <Dialog
    v-model:visible="showPreview"
    :closable="true"
    modal
    :breakpoints="{ '1199px': '85vw', '575px': '90vw' }"
  >
    <div class="w-full">
      <h1 class="mb-5 text-center text-2xl font-bold text-main">
        {{ $t("layout.client.userMenu.provision") }}
      </h1>
      <DataTable :value="eshopsWithProvision" stripedRows showGridlines>
        <Column field="eshopName" :header="$t('layout.client.userMenu.eshop')">
        </Column>
        <Column
          field="affilContribution"
          :header="$t('layout.client.userMenu.commissions')"
        >
          <template #body="slotProps">
            <LocalizedValue
              v-if="slotProps.data.affilContribution"
              :value="slotProps.data.affilContribution"
            ></LocalizedValue>
          </template>
        </Column>
      </DataTable>
      <div class="mt-4 flex px-4 py-2 sm:px-0">
        <dt class="mr-2 text-sm font-medium leading-6 text-gray-900">
          {{ $t("layout.client.userMenu.TotalCZK") }}
        </dt>
        <dd class="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <LocalizedValue :value="totalAffilContributionCZK"></LocalizedValue>
        </dd>
      </div>
      <div class="flex px-4 py-2 sm:px-0">
        <dt class="mr-2 text-sm font-medium leading-6 text-gray-900">
          {{ $t("layout.client.userMenu.TotalEUR") }}
        </dt>
        <dd class="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <LocalizedValue :value="totalAffilContributionEUR"></LocalizedValue>
        </dd>
      </div>
    </div>
    <template #footer>
      <Button
        @click="getProvision()"
        type="button"
        severity="warning"
        :label="'Vybrat provizi'"
        size="small"
        aria-haspopup="true"
        aria-controls="overlay_menu"
    /></template>
  </Dialog>

  <Dialog
    v-model:visible="showInformation"
    :closable="true"
    modal
    :breakpoints="{ '1199px': '85vw', '575px': '90vw' }"
  >
    <h1 class="mb-5 text-center text-2xl font-bold text-main">
      {{ $t("layout.client.userMenu.dialogTitle") }}
    </h1>
    <p class="text-center text-sm text-main">
      {{ $t("layout.client.userMenu.dialogDescription") }}
    </p>
    <p class="mt-2 text-center text-sm text-main">
      {{ $t("layout.client.userMenu.dialogDescription2") }}
      {{ billingInfo.infoEmail }}
    </p>
    <ul class="mt-5">
      <li
        class="mt-2 flex flex-col text-sm text-gray-900 dark:text-gray-300 sm:flex-row"
      >
        <span class="mr-4 w-32 font-bold"
          >{{ $t("layout.client.userMenu.comapnyName") }}:</span
        ><span>{{ billingInfo.companyName }}</span>
      </li>
      <li
        class="mt-2 flex flex-col text-sm text-gray-900 dark:text-gray-300 sm:flex-row"
      >
        <span class="mr-4 w-32 font-bold"
          >{{ $t("layout.client.userMenu.companyAddress") }}:</span
        ><span>
          {{ billingInfo.companyAddress }}, {{ billingInfo.companyZip }}
          {{ billingInfo.companyCity }}</span
        >
      </li>
      <li
        class="mt-2 flex flex-col text-sm text-gray-900 dark:text-gray-300 sm:flex-row"
      >
        <span class="mr-4 w-32 font-bold"
          >{{ $t("layout.client.userMenu.companyID") }}:</span
        ><span>{{ billingInfo.companyIco }}</span>
      </li>
      <li
        class="mt-2 flex flex-col text-sm text-gray-900 dark:text-gray-300 sm:flex-row"
      >
        <span class="mr-4 w-32 font-bold"
          >{{ $t("layout.client.userMenu.companyVAT") }}:</span
        ><span>{{ billingInfo.companyDic }}</span>
      </li>
    </ul>
    <template #footer></template>
  </Dialog>
</template>
<script setup lang="ts">
import {
  MenuButton,
  Menu as MenuComponent,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { CLIENT_ROUTES } from "@/enums/Routes/ClientRoutesEnum";
import LocalizedValue from "@/modules/global/components/LocalizedValue.vue";
import router from "@/router";
import { api } from "@/services/api";
import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";

const showPreview = ref(false);
const showInformation = ref(false);
const eshopsWithProvision = ref();
const totalAffilContributionCZK = ref();
const totalAffilContributionEUR = ref();
const billingInfo = ref();
const userStore = useUserStore();
const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();

const menuItems = computed(() => [
  {
    show: true,
    name: t("layout.client.userMenu.set"),
    onClick: async () => {
      await router.push({ name: CLIENT_ROUTES.USER_SETTINGS_PAGE });
    },
  },
  {
    show: userStore.isAffiliate,
    name: t("layout.client.userMenu.affil"),
    onClick: async () => {
      try {
        loadingStore.updateLoading(true);
        const { data } =
          await api.clientUserAffiliateGetAffilContributionForAllOwnedEshops({
            metadata: {
              accessToken: "user",
            },
          });
        eshopsWithProvision.value = data.eshops;
        totalAffilContributionCZK.value = data.totalAffilContributionCzk;
        totalAffilContributionEUR.value = data.totalAffilContributionEur;
        showPreview.value = true;
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: "error",
              summary: t("serverErrorTitle"),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: "error",
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        loadingStore.updateLoading(false);
      }
    },
  },
  {
    show: true,
    name: t("layout.client.userMenu.logout"),
    onClick: async () => {
      await router.push({ name: CLIENT_ROUTES.LOGOUT_PAGE });
    },
  },
]);

const getProvision = async () => {
  try {
    const { data } = await api.clientUserAffiliateGetCompanyInfoForAffilInvoice(
      {
        metadata: {
          accessToken: "user",
        },
      }
    );
    billingInfo.value = data;
    showInformation.value = true;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: "error",
          summary: t("serverErrorTitle"),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};
</script>
