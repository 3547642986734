/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsContentCommunicationStyle = {
    Friendly: 'Friendly',
    Informal: 'Informal',
    Personal: 'Personal',
    Relaxed: 'Relaxed',
    Formal: 'Formal',
    Professional: 'Professional',
    Expert: 'Expert',
    Humorous: 'Humorous',
    Lighthearted: 'Lighthearted',
    Inspiring: 'Inspiring',
    Encouraging: 'Encouraging',
    Authoritative: 'Authoritative',
    Serious: 'Serious',
    Caring: 'Caring',
    Elegant: 'Elegant',
    Sophisticated: 'Sophisticated',
    Controversial: 'Controversial',
    Storytelling: 'Storytelling',
    Optimistic: 'Optimistic'
} as const;

export type EnumsContentCommunicationStyle = typeof EnumsContentCommunicationStyle[keyof typeof EnumsContentCommunicationStyle];



