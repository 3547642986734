<template>
  <div class="h-full w-full">
    <h1 class="mb-5 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">{{ $t('eshopSettings.emailNotification.title') }}</h1>
    <div class="w-full">
      <p class="mb-5 text-sm font-extrabold text-gray-900 dark:text-[#cbd5e1]">{{ $t('eshopSettings.emailNotification.text') }}</p>
      <form @submit.prevent="addNewNotificationEmail" class="w-full">
        <label for="email" class="mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('eshopSettings.emailNotification.email') }}</label>
        <div class="flex items-center">
          <InputText name="email" v-model="email" />
          <XCircleIcon @click="openRemoveInformationDialog = true" :class="[email ? 'opacity-100' : 'opacity-0', 'ml-3 h-10 w-10 cursor-pointer text-red-600 hover:text-red-700']" />
        </div>
        <small class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
        <p v-if="!email" class="mt-3 text-xs font-extrabold text-gray-900 dark:text-[#cbd5e1] sm:text-sm">{{ $t('eshopSettings.emailNotification.noEmail') }}</p>
        <div class="ml-auto mr-0 mt-5 w-fit">
          <MainButton type="submit" :text="$t('eshopSettings.emailNotification.save')" />
        </div>
      </form>
    </div>
  </div>
  <Dialog v-model:visible="openRemoveInformationDialog" modal :closable="true" :draggable="false">
    <template #header
      ><span class="mx-auto text-center text-lg text-[#F67424]">{{ $t('eshopSettings.emailNotification.warning') }}</span></template
    >
    <template #default>
      <p class="fotn-bold mx-auto text-center text-main">{{ $t('eshopSettings.emailNotification.warningText') }}</p>
      <p class="fotn-bold mx-auto mb-5 text-center text-main">
        {{ email }}
      </p>
      <p class="mx-auto text-center text-main">{{ $t('eshopSettings.emailNotification.warningText2') }}</p>
    </template>
    <template #footer
      ><Button @click="openRemoveInformationDialog = false" type="button" severity="success" :label="$t('eshopSettings.emailNotification.no')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      <Button @click="removeNotificationEmail()" class="ml-5" type="button" severity="warning" :label="$t('eshopSettings.emailNotification.yes')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { XCircleIcon } from '@heroicons/vue/24/solid';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';

const eshopStore = useEshopsStore();
const loadingStore = useLoadingStore();
const openRemoveInformationDialog = ref(false);
const { t } = useI18n();

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .required(() => t('validations.required'))
      .email(() => t('validations.email')),
  }),
});

const [email] = defineField('email');

onMounted(async () => {
  await getNotificationEmail();
});

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await getNotificationEmail();
  }
);
const toast = useToast();

const getNotificationEmail = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientEshopSettingsGetNotificationEmail();
    resetForm({
      values: {
        email: data.notificationEmail,
      },
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 20000,
        });
      }
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const addNewNotificationEmail = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);
  try {
    const payload = {
      notificationEmail: formData.email,
    };
    await api.clientEshopSettingsSetNotificationEmail(payload);
    toast.add({
      severity: 'success',
      summary: t('eshopSettings.emailNotification.emailNotificationAddSuccess'),
      life: 7000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});

const removeNotificationEmail = async () => {
  loadingStore.updateLoading(true);
  try {
    const payload = {
      notificationEmail: null,
    };
    await api.clientEshopSettingsSetNotificationEmail(payload);
    resetForm({
      values: {
        email: null,
      },
    });
    openRemoveInformationDialog.value = false;
    toast.add({
      severity: 'success',
      summary: t('eshopSettings.emailNotification.emailNotificationRemoveSuccess'),
      life: 7000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};
</script>
