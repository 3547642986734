import { transitions } from '@/plugins/primeVue/pt/transitions';

export const calendar = {
    root: ({ props }) => ({
        class: [
            'flex justify-center w-full relative',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
            },
        ],
    }),
    input: {
        class: [
            'font-sans w-full text-center text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-2 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-full',
            'hover:border-blue-500', // Hover
        ],
    },
    panel: ({ props }) => ({
        class: [
            'bg-white dark:bg-gray-900',
            'min-w-[21.875rem]',
            {
                'shadow-md border-0 absolute': !props.inline,
                'inline-block overflow-x-auto border border-gray-300 dark:border-blue-900/40 p-2 rounded-lg': props.inline,
            },
        ],
    }),
    header: {
        class: ['flex items-center justify-between', 'p-2 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-semibold m-0 border-b border-gray-300 dark:border-blue-900/40 rounded-t-lg'],
    },
    previousbutton: {
        class: [
            'flex items-center justify-center cursor-pointer overflow-hidden relative',
            'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
            'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
        ],
    },
    title: 'leading-8 mx-auto',
    monthTitle: {
        class: ['text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-2', 'mr-2', 'hover:text-blue-500'],
    },
    yearTitle: {
        class: ['text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-2', 'hover:text-blue-500'],
    },
    nextbutton: {
        class: [
            'flex items-center justify-center cursor-pointer overflow-hidden relative',
            'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
            'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
        ],
    },
    table: {
        class: ['border-collapse w-full', 'my-2'],
    },
    tableheadercell: 'p-2',
    weekday: 'text-gray-600 dark:text-white/70',
    day: 'p-2',
    daylabel: ({ context }) => ({
        class: [
            'w-10 h-10 rounded-full transition-shadow duration-200 border-transparent border',
            'flex items-center justify-center mx-auto overflow-hidden relative',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'opacity-40 cursor-not-allowed': context.disabled,
                'cursor-pointer': !context.disabled,
            },
            {
                'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
            },
        ],
    }),
    monthpicker: 'my-2',
    month: ({ context }) => ({
        class: [
            'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
            'p-2 transition-shadow duration-200 rounded-lg',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
            },
        ],
    }),
    yearpicker: {
        class: ['my-2'],
    },
    year: ({ context }) => ({
        class: [
            'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
            'p-2 transition-shadow duration-200 rounded-lg',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
            },
        ],
    }),
    timepicker: {
        class: ['flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2'],
    },
    separatorcontainer: 'flex items-center flex-col px-2',
    separator: 'text-xl',
    hourpicker: 'flex items-center flex-col px-2',
    minutepicker: 'flex items-center flex-col px-2',
    ampmpicker: 'flex items-center flex-col px-2',
    incrementbutton: {
        class: [
            'flex items-center justify-center cursor-pointer overflow-hidden relative',
            'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
            'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
        ],
    },
    decrementbutton: {
        class: [
            'flex items-center justify-center cursor-pointer overflow-hidden relative',
            'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
            'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
        ],
    },
    groupcontainer: 'flex',
    group: {
        class: ['flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0'],
    },
    transition: transitions.overlay,
};
