∑
<template>
  <div class="fixed inset-0 h-screen w-screen bg-white dark:bg-mainDark">
    <!-- Tanganica Logo -->
    <router-link to="/" class="absolute left-2 top-2 z-30 w-fit cursor-pointer sm:top-[0.938rem] lg:top-[0.938rem]">
      <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="jungle" class="h-full max-h-[2.5rem] w-auto object-cover object-left sm:max-h-[3.125rem] xl:max-h-[3.75rem]" />
    </router-link>
    <div class="relative left-1/2 top-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2">
      <h2 class="ml-5 h-fit justify-center text-sm font-bold text-[#00467D] dark:text-gray-300 sm:mb-5 sm:text-3xl md:text-4xl lg:flex lg:text-4xl">
        {{ $t('authentication.forgottenPassword.forgotPass') }}
      </h2>
      <div class="relative mx-5 h-fit rounded-[2.5rem] bg-white p-2 pt-5 dark:bg-lightDark sm:mx-10 sm:p-5 lg:mx-20 lg:px-12">
        <div class="w-full flex-none justify-center px-10 py-4 lg:px-20 xl:px-24">
          <div class="mx-auto w-80 sm:w-[37.5rem]">
            <form @submit.prevent="onSubmit" class="space-y-6">
              <div>
                <div class="">
                  <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="email">{{ $t('authentication.forgottenPassword.insertEmail') }}</label>
                </div>
                <div class="mt-1">
                  <InputText id="email" v-model="email" name="email" autocomplete="email" class="!rounded-full" />
                  <small class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
                </div>
              </div>
              <button type="submit" class="mx-auto mt-5 block w-3/4 rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] lg:w-2/4 lg:text-base">
                <strong>{{ $t('authentication.forgottenPassword.resetPass') }}</strong>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="openDialog" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t('authentication.forgottenPassword.dialogTitle')"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="$t('authentication.forgottenPassword.dialogDescription')"></p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

// For Dialog
const openDialog = ref(false);

const toast = useToast();
const loadingStore = useLoadingStore();
const { t } = useI18n();

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .email(() => t('validations.email'))
      .required(() => t('validations.required')),
  }),
  initialValues: {
    email: '',
  },
});

// Form field
const [email] = defineField('email');

const onSubmit = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);

  const payload = {
    email: formData.email,
    redirectUrl: import.meta.env.VITE_RESET_PASSWORD_URL as string,
  };

  try {
    await api.clientAuthForgottenPassword(payload, {
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });
    openDialog.value = true;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});
</script>

<style lang="scss" scoped></style>
