<template>
  <div v-if="invoiceLink">
    <div class="mt-5 grid py-2 md:grid-cols-2">
      <div class="px-5">
        <h3 class="hidden text-left text-xl font-bold text-main sm:block">{{ $t('rechargeCredit.bankTransfer.data') }}</h3>
        <div class="mt-1">
          <div class="flex items-center">
            <ExclamationTriangleIcon class="h-6 w-6 text-orange-600" aria-hidden="true" />
            <p class="ml-1 flex items-center text-sm text-main">{{ $t('rechargeCredit.bankTransfer.qrInfo5') }}</p>
          </div>
        </div>
        <ul class="mt-5">
          <li class="mt-8 flex justify-between text-sm font-bold text-gray-900 dark:text-gray-300 xl:text-base">
            <span>{{ $t('rechargeCredit.bankTransfer.amountToPay') }}</span
            ><span><LocalizedValue :value="amountToPay"></LocalizedValue></span>
          </li>
          <li class="mt-8 flex justify-between text-sm text-gray-900 dark:text-gray-300 xl:text-base">
            <span>{{ $t('rechargeCredit.bankTransfer.bankAccount') }}</span
            ><span>{{ bankAccount }}</span>
          </li>
          <li class="mt-8 flex justify-between text-sm text-gray-900 dark:text-gray-300 xl:text-base">
            <span>{{ $t('rechargeCredit.bankTransfer.variableSymbol') }}</span
            ><span>{{ variableSymbol }}</span>
          </li>
          <li class="mt-8 flex justify-between text-sm text-gray-900 dark:text-gray-300 xl:text-base">
            <span>{{ $t('rechargeCredit.bankTransfer.iban') }}</span
            ><span>{{ iban }}</span>
          </li>
          <li class="mt-8 flex justify-between text-sm text-gray-900 dark:text-gray-300 xl:text-base">
            <span>{{ $t('rechargeCredit.bankTransfer.swift') }}</span
            ><span>{{ swift }}</span>
          </li>
        </ul>
      </div>
      <div class="mt-10 flex items-center px-5 md:mt-0 md:block md:px-0">
        <div class="md:pl-[20%]">
          <h3 class="hidden text-left text-xl font-bold text-main sm:block">{{ $t('rechargeCredit.bankTransfer.variableSymbol') }}</h3>
          <p class="mt-1 text-sm text-gray-900 dark:text-gray-300 xl:text-base">{{ $t('rechargeCredit.bankTransfer.qrInfo1') }}</p>
          <p class="mt-1 text-sm text-gray-900 dark:text-gray-300 xl:text-base">{{ $t('rechargeCredit.bankTransfer.qrInfo2') }}</p>
          <p class="mt-1 text-sm text-gray-900 dark:text-gray-300 xl:text-base">{{ $t('rechargeCredit.bankTransfer.qrInfo3') }}</p>
          <p class="mt-1 text-sm text-gray-900 dark:text-gray-300 xl:text-base">{{ $t('rechargeCredit.bankTransfer.qrInfo4') }}</p>
        </div>
        <img :src="qrcode.value" alt="QR Code" class="ml-auto mr-0 w-52 md:mx-auto" />
      </div>
    </div>
    <div class="mx-auto mt-10 w-fit">
      <button class="h-12 rounded-full bg-main px-12 text-base text-white hover:bg-mainhover sm:col-span-2" @click="downloadInvoice(invoiceLink)">{{ $t('rechargeCredit.bankTransfer.dowloadInvoice') }}</button>
    </div>
  </div>
  <div v-else class="grid w-fit items-center sm:grid-cols-2">
    <div v-if="dataIsLoaded" class="px-5">
      <label for="amount" class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 lg:text-base">{{ $t('rechargeCredit.bankTransfer.label') }}</label>
      <label for="amount" class="mt-1 block text-xs font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-xs">{{ $t('rechargeCredit.bankTransfer.amountWithoutVat') }}</label>
      <div class="items-cemter mt-1 flex w-full">
        <InputNumber class="" v-if="amountCurrency" name="limit" @input="changeCalculatedValues($event)" v-model="amount" mode="currency" :currency="amountCurrency" :locale="userStore.getLanguageCode" :step="100" :maxFractionDigits="0" />
      </div>
      <p v-if="taxRate > 0" class="mt-2 block text-xs font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-sm">
        {{ $t('rechargeCredit.bankTransfer.amountWithVat') }} <span class="font-bold text-black dark:text-gray-300"><LocalizedValue :value="{ value: amountWithVat, currency: amountCurrency, type: 'Money' }"></LocalizedValue></span>
      </p>
      <div class="mt-5">
        <p class="text-sm text-main">
          {{ $t('rechargeCredit.bankTransfer.text1') }} <LocalizedValue v-if="recommendedAmount" :value="recommendedAmount"></LocalizedValue> {{ $t('rechargeCredit.bankTransfer.text2') }} {{ $t('rechargeCredit.bankTransfer.text3') }}
        </p>
      </div>
      <div class="mt-6 flex items-center">
        <ExclamationTriangleIcon class="h-7 w-7 text-orange-600" aria-hidden="true" />
        <div class="ml-1">
          <p class="flex items-center text-sm text-main">{{ $t('rechargeCredit.bankTransfer.qrInfo5') }}</p>

          <p class="flex items-center text-sm text-main">{{ $t('rechargeCredit.bankTransfer.qrInfo6') }}</p>
        </div>
      </div>
    </div>
    <div v-if="dataIsLoaded" class="mb-auto mt-10 sm:mt-0 sm:px-5">
      <ul class="">
        <li class="mt-3 flex justify-between text-sm text-gray-900 dark:text-gray-300">
          <span>{{ $t('rechargeCredit.bankTransfer.actualCredit') }}</span
          ><span><LocalizedValue :value="credit"></LocalizedValue></span>
        </li>
        <li class="mt-3 flex justify-between text-sm text-gray-900 dark:text-gray-300">
          <span>{{ $t('rechargeCredit.bankTransfer.amountToPayDescription') }}</span
          ><span><LocalizedValue :value="{ value: amount, currency: amountCurrency, type: 'Money' }"></LocalizedValue></span>
        </li>
        <li class="mt-3 flex justify-between text-sm text-gray-900 dark:text-gray-300">
          <span>{{ $t('rechargeCredit.bankTransfer.amoutAfterRecharge') }}</span
          ><span><LocalizedValue :value="{ value: creditAfterRecharge, currency: amountCurrency, type: 'Money' }"></LocalizedValue></span>
        </li>
      </ul>
    </div>
    <button v-if="dataIsLoaded" class="glowing-button ml-auto mr-0 h-10 rounded-full bg-main px-3 text-sm text-white hover:bg-mainhover sm:col-span-2" @click="generateBankTransfer()">{{ $t('rechargeCredit.bankTransfer.button') }}</button>
  </div>
  <BankTransferSkeleton v-if="!dataIsLoaded && !invoiceLink" class="col-span-2"></BankTransferSkeleton>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import InputNumber from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import BankTransferSkeleton from '@/modules/rechargeCredit/components/BankTransferSkeleton.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { sleep } from '@/utils/sleep';

const { t } = useI18n();
const eshopsStore = useEshopsStore();
const userStore = useUserStore();
const loadingStore = useLoadingStore();
const toast = useToast();
const gtm = useGtm();

const dataIsLoaded = ref(false);

const amountCurrency = ref();
const amount = ref();
const invoiceLink = ref();
const qrcode = ref();
const recommendedAmount = ref();
const credit = ref();
const creditAfterRecharge = ref();
const amountWithVat = ref();
const taxRate = ref();
const bankAccount = ref();
const variableSymbol = ref();
const invoiceFileName = ref();
const iban = ref();
const swift = ref();
const amountToPay = ref();

onMounted(async () => {
  await initBankTransfer();
  await getCredit();
  setCalculatedValues();
  await sleep(1000);
  dataIsLoaded.value = true;
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await initBankTransfer();
    await getCredit();
    setCalculatedValues();
  }
);

const changeCalculatedValues = (event) => {
  amount.value = event.value;
  setCalculatedValues();
};

const setCalculatedValues = () => {
  creditAfterRecharge.value = parseFloat(credit.value.value) + parseFloat(amount.value);
  amountWithVat.value = parseFloat(amount.value) * (1 + taxRate.value);
};

const initBankTransfer = async () => {
  try {
    const { data: taxRateData } = await api.clientRechargeCreditGetEshopTaxRate();
    taxRate.value = taxRateData.taxRate?.value;
    const { data } = await api.clientRechargeCreditGetPaymentDefaultAmount();
    amount.value = data.amount?.value;
    amountCurrency.value = data.amount?.currency;
    recommendedAmount.value = data.amount;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getCredit = async () => {
  try {
    const { data } = await api.clientTopMenuGetCredit();
    credit.value = data.credit;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateBankTransfer = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientRechargeCreditBankTransferPayment({
      amount: amount.value,
    });
    qrcode.value = useQRCode(String(data.qrCodeData));
    invoiceLink.value = data.invoicePresignedUrl;
    bankAccount.value = data.tgncaBankAccountNumber;
    variableSymbol.value = data.variableSymbol;
    invoiceFileName.value = data.fileName;
    amountToPay.value = data.amountToPay;
    iban.value = data.tgncaIban;
    swift.value = data.tgncaSwift;
    gtm?.trackEvent({
      event: 'gtm.BankTransferGenerateButton',
      value: {
        amount: amount.value,
        currency: amountCurrency.value,
      },
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const downloadInvoice = async (url) => {
  try {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${invoiceFileName.value}`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);
      });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};
</script>
<style scoped>
@keyframes shine {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.glowing-button {
  background: linear-gradient(90deg, #1290f7, #075695, #1290f7);
  background-size: 200% 100%;
  animation:
    shine 1.5s infinite,
    shine-delay 5s infinite;
  color: white;
  transition: color 1s ease;
}

@keyframes shine-delay {
  0%,
  100% {
    background-position: 0% 0;
  }
  50% {
    background-position: 200% 0;
  }
}
</style>
