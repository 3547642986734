<template>
  <component v-if="stepComponent" :is="stepComponent"/>
</template>

<script setup lang="ts">
import { PropType, shallowRef, watch } from 'vue';
import {EnumsMarketingChannel, EnumsMarketingFormat} from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<EnumsMarketingChannel>,
    required: true,
  },
  format: {
    type: String as PropType<EnumsMarketingFormat>,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
});

const stepComponent = shallowRef<any>(null);

const loadComponent = async () => {
  try {
    const module = await import(`@/modules/campaigns/pages/${props.campaign?.toLowerCase()}/${props.format?.toUpperCase()}/${props.step?.charAt(0).toUpperCase() + props.step?.slice(1)}.vue`);
    stepComponent.value = module.default || module;
  } catch (error) {
    console.warn('Failed to load step component:', error);
  }
};

watch(() => props.step, loadComponent, { immediate: true });
</script>
