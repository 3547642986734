<template>
    <div class="div-primary mx-auto mt-4 min-h-fit xl:grid xl:grid-cols-2">
        <div class="mx-auto px-5">
            <h1 class="text-xl font-bold text-main sm:text-3xl 2xl:text-4xl" v-html="$t('dashboard.information.headerOne')"></h1>
            <h3 class="mt-4 text-sm text-main xl:text-base" v-html="$t('dashboard.information.headerTwo')"></h3>
            <div class="mt-10 flex items-center">
                <div class="w-6 flex-none 2xl:w-8">
                    <img :src="`${$filePath}/icons/circle.svg`" class="h-full w-full" alt="" />
                </div>
                <div>
                    <h3 class="ml-4 text-sm text-main" v-html="$t('dashboard.information.headerThree')"></h3>
                </div>
            </div>
            <div class="mt-10 flex items-center">
                <div class="w-6 flex-none 2xl:w-8">
                    <img :src="`${$filePath}/icons/circle.svg`" class="h-full w-full" alt="" />
                </div>
                <div>
                    <h3 class="ml-4 text-sm text-main" v-html="$t('dashboard.information.headerFour')"></h3>
                </div>
            </div>
            <div class="mt-10 flex items-center">
                <div class="w-6 flex-none 2xl:w-8">
                    <img :src="`${$filePath}/icons/circle.svg`" class="h-full w-full" alt="" />
                </div>
                <div>
                    <h3 class="ml-4 text-sm text-main" v-html="$t('dashboard.information.headerFive')"></h3>
                </div>
            </div>
            <div class="mt-10 flex items-center">
                <div class="w-6 flex-none 2xl:w-8">
                    <img :src="`${$filePath}/icons/circle.svg`" class="h-full w-full" alt="" />
                </div>
                <div>
                    <h3 class="ml-4 text-sm text-main" v-html="$t('dashboard.information.headerSix')"></h3>
                </div>
            </div>
        </div>
        <div class="mx-auto mt-10 px-5 xl:mt-0">
            <h1 class="text-xl font-bold text-main sm:text-3xl 2xl:text-4xl" v-html="$t('dashboard.information.lowPNO')"></h1>
            <h3 class="mt-4 text-sm text-main xl:text-base" v-html="$t('dashboard.information.PNO')"></h3>
            <h3 class="mt-5 text-sm text-main xl:text-base" v-html="$t('dashboard.information.PNOInformation')"></h3>
            <div class="mt-8 h-full">
                <img :src="`${$filePath}/images/pnoGraphImage.svg`" class="object-contain" alt="" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts"></script>
