<template>
  <div class="relative h-fit">
    <div class="mx-auto mt-5 min-h-full w-full rounded-3xl text-center xl:mt-1">
      <div class="mx-auto max-w-4xl px-6 lg:w-full lg:px-8">
        <div class="mx-auto max-w-xl lg:max-w-4xl">
          <h2 class="text-2xl font-bold tracking-tight text-main lg:text-4xl">
            {{ $t('wizard.pages.wizardAddEshop.title') }}
          </h2>
          <p class="mt-2 text-xs text-main lg:mt-3 lg:text-base lg:leading-8"></p>
          <form @submit.prevent="onSubmit" class="mt-5 sm:mt-10">
            <div class="relative grid w-full grid-cols-1 gap-x-8 gap-y-1 text-left sm:grid-cols-2 lg:gap-y-3">
              <div class="mt-3 sm:col-span-2">
                <label class="relative block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="url">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardAddEshop.url') }}<span class="ml-1 text-red-600">*</span></label
                >
                <span :class="[wizardStore.getStepOne.isValid ? 'cursor-not-allowed' : '', 'p-input-icon-right relative w-full']">
                  <input
                    type="text"
                    v-if="wizardStore.getStepOne.isValid"
                    class="absolute z-10 h-full w-full cursor-not-allowed opacity-0"
                    v-tooltip.top="{
                      value: `${$t('layout.wizardLayout.tooltip')}`,
                      class: 'sm:min-w-[31.25rem] text-center',
                      escape: false,
                    }"
                  />
                  <InputText id="url" v-model="url" :disabled="wizardStore.getStepOne.isValid" placeholder="www.eshop.com" autocomplete="url" />
                  <LockClosedIcon v-if="wizardStore.getStepOne.isValid" class="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-main opacity-50"></LockClosedIcon>
                </span>
                <small class="text-sm text-red-600">{{ errors.url || '&nbsp;' }}</small>
              </div>
              <div :class="['relative w-full sm:col-span-2', { 'cursor-not-allowed': wizardStore.getStepOne.isValid }]">
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('wizard.pages.wizardAddEshop.country') }}<span class="ml-1 text-red-600">*</span></label>
                <input
                  type="text"
                  v-if="wizardStore.getStepOne.isValid"
                  class="absolute z-10 h-full w-full cursor-not-allowed opacity-0"
                  v-tooltip.top="{
                    value: `${$t('layout.wizardLayout.tooltip')}`,
                    class: 'sm:min-w-[31.25rem] text-center',
                    escape: false,
                  }"
                />
                <SelectCountry v-model="country" :lockIcon="wizardStore.getStepOne.isValid" :disabled="wizardStore.getStepOne.isValid" />
                <small class="text-sm text-red-600">{{ errors.country || '&nbsp;' }}</small>
              </div>
              <div v-if="eshopsStore.managedEshops.length < 1" class="sm:col-span-2">
                <label class="mb-2 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="contactName">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardAddEshop.contactName') }}<span class="ml-1 text-red-600">*</span></label
                >
                <span :class="[wizardStore.getStepOne.isValid ? 'cursor-not-allowed' : '', 'p-input-icon-right relative w-full']">
                  <input
                    type="text"
                    v-if="wizardStore.getStepOne.isValid"
                    class="absolute z-10 h-full w-full cursor-not-allowed opacity-0"
                    v-tooltip.top="{
                      value: `${$t('layout.wizardLayout.tooltip')}`,
                      class: 'sm:min-w-[31.25rem] text-center',
                      escape: false,
                    }"
                  />
                  <InputText id="contactName" v-model="contactName" :disabled="wizardStore.getStepOne.isValid" :placeholder="$t('wizard.fillFieldPlaceholder')" />
                  <LockClosedIcon v-if="wizardStore.getStepOne.isValid" class="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-main opacity-50"></LockClosedIcon>
                </span>
                <small class="text-sm text-red-600">{{ errors.contactName || '&nbsp;' }}</small>
              </div>
              <div v-if="eshopsStore.managedEshops.length < 1" class="sm:col-span-2">
                <label class="mb-2 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="contactPhone">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ $t('wizard.pages.wizardAddEshop.contactPhone') }}<span class="ml-1 text-red-600">*</span></label
                >
                <div class="flex">
                  <span :class="[wizardStore.getStepOne.isValid ? 'cursor-not-allowed' : '', 'p-input-icon-right relative min-w-[7.5rem] sm:min-w-[9.375rem]']">
                    <input
                      type="text"
                      v-if="wizardStore.getStepOne.isValid"
                      class="absolute z-10 h-full w-full cursor-not-allowed opacity-0"
                      v-tooltip.top="{
                        value: `${$t('layout.wizardLayout.tooltip')}`,
                        class: 'sm:min-w-[31.25rem] text-center',
                        escape: false,
                      }"
                    />
                    <Dropdown
                      v-model="phoneCountry"
                      :options="countries"
                      filter
                      optionLabel="name"
                      :disabled="wizardStore.getStepOne.isValid"
                      placeholder="-"
                      class="rounded-r-none"
                      :ptOptions="{ mergeProps: true }"
                      :pt="{
                        root: () => {
                          return {
                            class: ['max-w-[7.5rem] sm:max-w-[9.375rem]'],
                          };
                        },
                        header: () => {
                          return {
                            class: ['border'],
                          };
                        },
                        input: () => {
                          return {
                            class: ['!p-1 sm:!p-3'],
                          };
                        },
                      }"
                      :filterFields="[
                        (e) => {
                          return $t(e.name);
                        },
                        'phonePrefix',
                        'code',
                      ]"
                    >
                      <template #value="slotProps">
                        <div v-if="slotProps.value" class="align-items-center flex">
                          <img :alt="slotProps.value.label" :src="`${$filePath}/${slotProps.value.flag}`" class="mr-1 h-5 w-5 rounded-full sm:mr-2 sm:h-6 sm:w-6" />
                          <div class="ml-1 sm:ml-2 sm:text-base">{{ slotProps.value.phonePrefix }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="align-items-center flex">
                          <img :alt="slotProps.option.label" :src="`${$filePath}/${slotProps.option.flag}`" class="mr-1 h-5 w-5 rounded-full sm:mr-2 sm:h-6 sm:w-6" />
                          <div>{{ $t(slotProps.option.name) }}</div>
                          <div class="ml-1 sm:ml-2 sm:text-base">{{ slotProps.option.phonePrefix }}</div>
                        </div>
                      </template>
                    </Dropdown>
                    <LockClosedIcon v-if="wizardStore.getStepOne.isValid" class="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-main opacity-50"></LockClosedIcon>
                  </span>
                  <span :class="[wizardStore.getStepOne.isValid ? 'cursor-not-allowed' : '', 'p-input-icon-right relative w-full']">
                    <input
                      type="text"
                      v-if="wizardStore.getStepOne.isValid"
                      class="absolute z-10 h-full w-full cursor-not-allowed opacity-0"
                      v-tooltip.top="{
                        value: `${$t('layout.wizardLayout.tooltip')}`,
                        class: 'sm:min-w-[31.25rem] text-center',
                        escape: false,
                      }"
                    />
                    <InputNumber
                      class="w-full"
                      v-model="phoneNumber"
                      :disabled="wizardStore.getStepOne.isValid"
                      autocomplete="tel"
                      :useGrouping="false"
                      :ptOptions="{ mergeProps: true }"
                      @input="refocus"
                      :pt="{
                        input: {
                          root: () => {
                            return {
                              class: ['rounded-l-none'],
                            };
                          },
                        },
                      }"
                    />
                    <LockClosedIcon v-if="wizardStore.getStepOne.isValid" class="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-main opacity-50"></LockClosedIcon>
                  </span>
                </div>
                <small class="text-sm text-red-600">{{ errors.phoneNumber || '&nbsp;' }}</small>
              </div>
            </div>
            <WizardButtons :isSubmitDisabled="dataSaving"></WizardButtons>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { LockClosedIcon } from '@heroicons/vue/24/solid';
import Dropdown from 'primevue/dropdown';
import InputNumber, { InputNumberInputEvent } from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import countriesCodebook from '@/codebooks/CountriesCodebook';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import SelectCountry from '@/modules/global/components/SelectCountry.vue';
import WizardButtons from '@/modules/wizard/components/WizardButtons.vue';
import { IStepOneValues } from '@/modules/wizard/interfaces/IStepOne';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { EnumsCountryOfAdvertisement, EnumsRegistrationSource } from '../../../../generated/api';

const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const wizardStore = useWizardStore();
const loadingStore = useLoadingStore();
const userStore = useUserStore();
const wizardHelperStore = useWizardHelperStore();
const eshopsStore = useEshopsStore();
const gtm = useGtm();
const dataSaving = ref(false);

const dynamicValidationSchema = computed(() => {
  return yup.object({
    url: yup
      .string()
      .required(() => t('validations.required'))
      .eshopUrl(),
    country: yup.object().required(() => t('validations.required')),
    contactName: eshopsStore.managedEshops.length > 0 ? yup.string().nullable() : yup.string().required(() => t('validations.required')),
    phoneNumber:
      eshopsStore.managedEshops.length > 0
        ? yup.string().nullable()
        : yup
            .string()
            .required(() => t('validations.required'))
            .phoneNumber(),
  });
});

// Form
const { defineField, handleSubmit, errors } = useForm<IStepOneValues>({
  validationSchema: dynamicValidationSchema,
  initialValues: wizardStore.getStepOne.values,
});

// Form fields
const [url] = defineField('url');
const [country] = defineField('country');
const [contactName] = defineField('contactName');
const [phoneCountry] = defineField('phoneCountry');
const [phoneNumber] = defineField('phoneNumber');

const refocus = ($event: InputNumberInputEvent) => {
  const target = $event.originalEvent.target as HTMLElement;
  target.blur();
  target.focus();
};

onMounted(() => {
  wizardStore.currentStep = 0;
  sortCountries();
  preselectPrefix();
});

const isFormChanged = (formData) => {
  return JSON.stringify(wizardStore.getStepOne.values) !== JSON.stringify(formData);
};

const onSubmit = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);

  dataSaving.value = true;

  const isThereFormChanges = isFormChanged(formData);

  // If form is not changed, go to next step
  if (!isThereFormChanges) {
    wizardStore.steps[0].isValid = true;
    await router.push({ name: WIZARD_ROUTES.BILLINFO_PAGE });

    loadingStore.updateLoading(false);
    return;
  }

  const dataForAddEshop = {
    url: formData.url,
    country: formData.country?.code as EnumsCountryOfAdvertisement,
    registrationSource: userStore.hostingCode as EnumsRegistrationSource,
  };

  const dataForUpdateUser = {
    name: formData.contactName,
    phone: formData.phoneCountry?.phonePrefix + formData.phoneNumber,
  };

  try {
    // Update user data
    if (eshopsStore.managedEshops.length < 1) {
      await api.clientWizardUpdateUser(dataForUpdateUser, {
        metadata: {
          accessToken: 'user',
        },
      });
    }

    const { data: eshop } = await api.clientWizardAddEshop(dataForAddEshop, {
      metadata: {
        accessToken: 'user',
      },
    });

    gtm?.trackEvent({
      event: 'gtm.createEshop',
      value: dataForAddEshop,
    });

    // Update or create eshop
    eshopsStore.updateOrCreateEshop(eshop);

    // Set new access token
    await eshopsStore.setSelectedEshop(eshop);

    // Save data to store
    wizardStore.steps[0].values.url = formData.url;
    wizardStore.steps[0].values.country = formData.country;
    wizardStore.steps[0].values.contactName = formData.contactName;
    wizardStore.steps[0].values.phoneCountry = formData.phoneCountry;
    wizardStore.steps[0].values.phoneNumber = formData.phoneNumber;

    wizardStore.steps[0].isValid = true;

    // Save data to helper store
    wizardHelperStore.isNewEshop = false; // Set that eshop is not new now

    await router.push({ name: WIZARD_ROUTES.BILLINFO_PAGE });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    dataSaving.value = false;
    loadingStore.updateLoading(false);
  }
});

// Custom switch case
const preselectPrefix = () => {
  let countryCode = '';
  switch (userStore.getLanguageCode) {
    case 'cs':
      countryCode = 'CZ';
      break;
    case 'da':
      countryCode = 'DK';
      break;
    case 'de':
      countryCode = 'DE';
      break;
    case 'el':
      countryCode = 'GR';
      break;
    case 'en':
      countryCode = 'GB';
      break;
    case 'es':
      countryCode = 'ES';
      break;
    case 'fi':
      countryCode = 'FI';
      break;
    case 'fr':
      countryCode = 'FR';
      break;
    case 'hu':
      countryCode = 'HU';
      break;
    case 'it':
      countryCode = 'IT';
      break;
    case 'nl':
      countryCode = 'NL';
      break;
    case 'nn':
      countryCode = 'NO';
      break;
    case 'pl':
      countryCode = 'PL';
      break;
    case 'pt':
      countryCode = 'PT';
      break;
    case 'ro':
      countryCode = 'RO';
      break;
    case 'sk':
      countryCode = 'SK';
      break;
    case 'sv':
      countryCode = 'SE';
      break;
    default:
      countryCode = 'CZ';
      break;
  }

  const country = countriesCodebook.find((country) => country.code === countryCode);
  if (country) {
    phoneCountry.value = country;
  }
};

watch(
  () => userStore.getLanguageCode,
  () => {
    preselectPrefix();
  }
);

const countries = ref();

const sortCountries = () => {
  const copy = [...countriesCodebook];

  // Define neighboring countries of Czechia
  const czechiaPrefix = '+420';
  const neighborsOfCzechia = new Set(['+421', '+43', '+49', '+48']);

  countries.value = copy.sort((a, b) => {
    const prefixA = a.phonePrefix;
    const prefixB = b.phonePrefix;

    // Calculate the length of digits in the prefix (excluding the + sign)
    const digitCountA = prefixA.length - 1;
    const digitCountB = prefixB.length - 1;

    // 1. Prioritize Czechia first
    if (prefixA === czechiaPrefix && prefixB !== czechiaPrefix) {
      return -1;
    }
    if (prefixA !== czechiaPrefix && prefixB === czechiaPrefix) {
      return 1;
    }

    // 2. Prioritize neighboring countries of Czechia
    const isANeighbor = neighborsOfCzechia.has(prefixA);
    const isBNeighbor = neighborsOfCzechia.has(prefixB);

    if (isANeighbor && !isBNeighbor) {
      return -1;
    }
    if (!isANeighbor && isBNeighbor) {
      return 1;
    }

    // 3. Within +4 group, prioritize longer prefixes over shorter ones
    const isPrefixA4 = prefixA.startsWith('+4');
    const isPrefixB4 = prefixB.startsWith('+4');
    const isPrefixA3 = prefixA.startsWith('+3');
    const isPrefixB3 = prefixB.startsWith('+3');

    if (isPrefixA4 && isPrefixB4) {
      if (digitCountA > digitCountB) {
        return -1;
      }
      if (digitCountA < digitCountB) {
        return 1;
      }
      return 0;
    }

    if (isPrefixA3 && isPrefixB3) {
      if (digitCountA > digitCountB) {
        return -1;
      }
      if (digitCountA < digitCountB) {
        return 1;
      }
      return 0;
    }

    // 4. Prioritize +4 group over +3 group
    if (isPrefixA4 && !isPrefixB4) {
      return -1;
    }
    if (!isPrefixA4 && isPrefixB4) {
      return 1;
    }

    if (isPrefixA3 && !isPrefixB3) {
      return -1;
    }
    if (!isPrefixA3 && isPrefixB3) {
      return 1;
    }

    // 5. Fallback: Sort alphabetically by code
    return a.code.localeCompare(b.code);
  });
};
</script>
