<template>
  <Dialog v-model:visible="visibleReallyOffDialog" :closable="false" modal :header="$t('campaigns.modalOffCampaingChannel.campaignOffTitle')" :style="{ width: '28rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div class="px-5">
      <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
          <ExclamationTriangleIcon class="h-6 w-6 text-orange-700" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <p class="text-sm text-gray-500">{{ $t('campaigns.modalOffCampaingChannel.campaignOffDescription') }}</p>
          </div>
          <div class="mt-8">
            <p class="text-sm font-bold text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.campaignOffResult') }}</p>
          </div>
        </div>
      </div>
      <fieldset class="my-4 border-y border-gray-200">
        <div class="divide-y divide-gray-200">
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="fastSpend" class="font-medium text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.answer1') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input v-model="reallyOffDialogData.fastSpend" id="fastSpend" aria-describedby="fastSpend-description" name="fastSpend" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="badFormat" class="font-medium text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.answer2') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input v-model="reallyOffDialogData.badFormat" id="badFormat" aria-describedby="badFormat-description" name="badFormat" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="worsePerformance" class="font-medium text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.answer3') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.worsePerformance"
                id="worsePerformance"
                aria-describedby="worsePerformance-description"
                name="worsePerformance"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <div>
        <label for="reason" class="block text-sm font-medium text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.answer4') }}</label>
        <div class="mt-1">
          <textarea v-model="reallyOffDialogData.reason" rows="2" name="reason" id="reason" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="align-items-center justify-content-center inline-flex gap-2">
        <Button @click="visibleReallyOffDialog = false" type="button" severity="success" :label="$t('campaigns.modalOffCampaingChannel.campaignOn')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
        <Button @click="reallyOff()" type="button" severity="warning" :label="$t('campaigns.modalOffCampaingChannel.campaignOff')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const visibleReallyOffDialog = defineModel<boolean>({ required: true });
const emit = defineEmits(['reallyOffDialogCallback']);

const toast = useToast();
const { t } = useI18n();

const reallyOffDialogData = reactive({
  fastSpend: false,
  badFormat: false,
  worsePerformance: false,
  reason: '',
});

const reallyOff = () => {
  if (reallyOffDialogData.fastSpend || reallyOffDialogData.badFormat || reallyOffDialogData.worsePerformance || reallyOffDialogData.reason.length > 0) {
    emit('reallyOffDialogCallback', reallyOffDialogData);
  } else {
    toast.add({
      severity: 'error',
      summary: t('campaigns.modalOffCampaingChannel.reasonRequired'),
      life: 6000,
    });
  }
};
</script>
