<template>
  <!--<div class="mb-10 flex gap-3 text-[0.438rem]">
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.CampaignOffPixelIsNotSet">CampaignOffPixelIsNotSet</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.CampaignOffWrongProducts">CampaignOffWrongProducts</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopActive">EshopActive</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopActiveUnderLimit">EshopActiveUnderLimit</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopInactiveBecauseOfCredit">EshopInactiveBecauseOfCredit</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopNotSetUp">EshopNotSetUp</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopSetUpNoCredit">EshopSetUpNoCredit</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopSetUpWithCreditNotTurnedOn">EshopSetUpWithCreditNotTurnedOn</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopTurnedOff">EshopTurnedOff</div>
    <div class="cursor-pointer" @click="status = EnumsDashboardStatus.EshopTurnedOnNoAdSpend">EshopTurnedOnNoAdSpend</div>
  </div>--->
  <template v-if="isLoading">
    <DashboardSekeleton></DashboardSekeleton>
  </template>
  <template v-else>
    <div v-show="waitForLoadData">
      <DashboardSekeleton></DashboardSekeleton>
    </div>
    <div v-show="!waitForLoadData">
      <Banner :status="status"></Banner>
      <FirstSteps :status="status"></FirstSteps>
      <ProblemSectionXmlFeed v-if="showProblemSectionXmlFeed" />
      <InsufficientCredit v-if="showInsufficientCredit" />
      <ProblemSectionPixel v-if="showProblemSectionPixel" @initDashboardEvent="initDashboard" />
      <Trial></Trial>
      <div class="mx-auto h-full lg:max-w-7xl xl:flex xl:gap-x-4">
        <Campaigns></Campaigns>
        <LearningPhase></LearningPhase>
      </div>
      <ConditionMesurement></ConditionMesurement>
      <ConditionProducts v-if="showConditionProducts"></ConditionProducts>
      <Statistics></Statistics>
      <Information></Information>
    </div>
  </template>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Banner from '@/modules/dashboard/components/Banner.vue';
import Campaigns from '@/modules/dashboard/components/Campaigns.vue';
import ConditionMesurement from '@/modules/dashboard/components/ConditionMeasurement.vue';
import ConditionProducts from '@/modules/dashboard/components/ConditionProducts.vue';
import DashboardSekeleton from '@/modules/dashboard/components/DashboardSekeleton.vue';
import FirstSteps from '@/modules/dashboard/components/FirstSteps.vue';
import Information from '@/modules/dashboard/components/Information.vue';
import InsufficientCredit from '@/modules/dashboard/components/InsufficientCredit.vue';
import LearningPhase from '@/modules/dashboard/components/LearningPhase.vue';
import ProblemSectionPixel from '@/modules/dashboard/components/ProblemSectionPixel.vue';
import ProblemSectionXmlFeed from '@/modules/dashboard/components/ProblemSectionXmlFeed.vue';
import Statistics from '@/modules/dashboard/components/Statistics.vue';
import Trial from '@/modules/dashboard/components/Trial.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { sleep } from '@/utils/sleep';
import { EnumsDashboardStatus } from '../../../../generated/api';

const eshopsStore = useEshopsStore();
const toast = useToast();
const isLoading = ref(false);
const waitForLoadData = ref(false);
const { t } = useI18n();

const status = ref<EnumsDashboardStatus>(EnumsDashboardStatus.EshopSetUpNoCredit);

onMounted(async () => {
  await initDashboard();
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await initDashboard();
  }
);

const initDashboard = async () => {
  try {
    isLoading.value = true;
    waitForLoadData.value = true;
    const { data } = await api.clientDashboardGetStatus();
    status.value = data.status;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    isLoading.value = false;
    await sleep(1500);
    waitForLoadData.value = false;
  }
};

const showProblemSectionXmlFeed = computed(() => {
  return status.value === EnumsDashboardStatus.CampaignOffWrongProducts;
});

const showConditionProducts = computed(() => {
  return status.value !== EnumsDashboardStatus.CampaignOffWrongProducts;
});

const showInsufficientCredit = computed(() => {
  return status.value === EnumsDashboardStatus.EshopInactiveBecauseOfCredit;
});

const showProblemSectionPixel = computed(() => {
  return status.value === EnumsDashboardStatus.CampaignOffPixelIsNotSet;
});
</script>
