import { transitions } from '@/plugins/primeVue/pt/transitions';

export const multiselect = {
    root: ({ props }) => ({
        class: [
            'inline-flex cursor-pointer select-none',
            'bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40  transition-colors duration-200 ease-in-out rounded-md',
            'w-full md:w-80',
            { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled },
        ],
    }),
    labelContainer: {
        class: 'overflow-hidden flex flex-auto cursor-pointer',
    },
    label: ({ props }) => ({
        class: [
            'block overflow-hidden whitespace-nowrap cursor-pointer text-ellipsis',
            'text-gray-800 dark:text-white/80',
            'p-3 transition duration-200',
            {
                'p-3': props.display !== 'chip' && (props?.modelValue == null || props?.modelValue == undefined),
                'py-1.5 px-3': props.display == 'chip' && props?.modelValue !== null,
                'p-2.5': props.display == 'chip' && props?.modelValue == null,
            },
        ],
    }),
    token: {
        class: ['py-1 px-2 mr-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white/80 rounded-full', 'cursor-default inline-flex items-center'],
    },
    removeTokenIcon: {
        class: 'ml-2',
    },
    trigger: {
        class: ['flex items-center justify-center shrink-0', 'bg-transparent text-gray-600 dark:text-white/70 w-12 rounded-tr-lg rounded-br-lg'],
    },
    panel: {
        class: ['bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-0 rounded-md shadow-lg'],
    },
    header: {
        class: ['p-3 border-b border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 bg-gray-100 dark:bg-gray-800 rounded-t-lg', 'flex items-center justify-between'],
    },
    headerCheckboxContainer: {
        class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6'],
    },
    headerCheckbox: ({ context }) => ({
        class: [
            'flex items-center justify-center',
            'border-2 w-6 h-6 text-gray-600 dark:text-white/70 rounded-lg transition-colors duration-200',
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'border-gray-300 dark:border-blue-900/40 bg-white dark:bg-gray-900': !context?.selected,
                'border-blue-500 bg-blue-500': context?.selected,
            },
        ],
    }),
    headercheckboxicon: {
        class: 'w-4 h-4 transition-all duration-200 text-white text-base',
    },
    closeButton: {
        class: [
            'flex items-center justify-center overflow-hidden relative',
            'w-8 h-8 text-gray-500 dark:text-white/70 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
            'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        ],
    },
    closeButtonIcon: {
        class: 'w-4 h-4 inline-block',
    },
    wrapper: {
        class: ['max-h-[12.5rem] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg', 'dark:bg-gray-900 dark:text-white/80'],
    },
    list: {
        class: 'py-3 list-none m-0',
    },
    item: ({ context }) => ({
        class: [
            'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
            'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
            {
                'text-gray-700 hover:text-gray-700 hover:bg-gray-300 dark:text-white/80 dark:hover:bg-gray-800': !context.focused && !context.selected,
                'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800': context.focused && !context.selected,
                'bg-blue-100 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
                'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
            },
        ],
    }),
    checkboxContainer: {
        class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-5 h-5'],
    },
    checkbox: ({ context }) => ({
        class: [
            'flex items-center justify-center',
            'border-2 w-5 h-5 text-gray-600 dark:text-white/80 rounded-lg transition-colors duration-200',
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            {
                'border-gray-300 dark:border-blue-900/40  bg-white dark:bg-gray-900': !context?.selected,
                'border-blue-500 bg-blue-500': context?.selected,
            },
        ],
    }),
    checkboxicon: {
        class: 'w-4 h-4 transition-all duration-200 text-white text-base',
    },
    itemgroup: {
        class: ['m-0 p-2 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
    },
    filtercontainer: {
        class: 'relative w-full px-1 sm:px-5',
    },
    filterinput: {
        class: [
            'pl-7 -mr-7',
            'w-full',
            'font-sans text-base text-gray-700 bg-white py-1 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
            'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300 dark:text-white/80',
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        ],
    },
    filtericon: {
        class: '-mt-2 absolute top-1/2 ml-2',
    },
    clearicon: {
        class: 'text-gray-500 right-12 -mt-2 absolute top-1/2',
    },
    transition: transitions.overlay,
};
