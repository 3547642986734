<template>
  <div v-if="errorType !== EnumsFeedErrorType.None" class="div-primary mx-auto mt-4 xl:grid xl:grid-cols-12">
    <div class="col-span-7">
      <div class="mb-4 flex items-center justify-center text-center xl:justify-start xl:text-left">
        <ExclamationTriangleIcon class="my-auto mr-1 block h-8 text-[#F67424]" />
        <h1 class="text-xl font-bold text-main md:text-3xl 2xl:text-4xl">{{ $t('dashboard.problemSectionXmlFeed.header') }}</h1>
      </div>
      <div class="">
        <p class="text-center text-main xl:text-left">
          {{ $t('dashboard.problemSectionXmlFeed.text1') }} <span class="font-extrabold">{{ getErrorNameByType(errorType) }}</span>
        </p>
        <p class="text-center text-main xl:text-left">{{ $t('dashboard.problemSectionXmlFeed.text2') }}</p>
        <div class="mx-auto mt-5 hidden max-w-[25rem] xl:ml-0 xl:block xl:w-full xl:max-w-full">
          <label class="text-[#F67424]">{{ $t('dashboard.problemSectionXmlFeed.label') }}</label>
          <div class="mt-1 flex gap-4 pr-10">
            <InputText v-model="fixedUrl" class="w-full !rounded-full !py-2 shadow" />
            <Button
              :loading="loading"
              @click="saveFixedUrl()"
              type="submit"
              :label="$t('dashboard.problemSectionXmlFeed.buttonSave')"
              class="w-[7.5rem] !rounded-full !border-none bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 !text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-5 xl:mt-0">
      <p class="mt-2 hidden text-center font-extrabold text-main xl:block xl:text-left xl:text-lg">{{ getErrorNameByType(errorType) }}</p>
      <IncorretUrlFormat v-if="EnumsFeedErrorType.IncorrectUrlFormat === errorType" />
      <CannotAccessPage v-else-if="EnumsFeedErrorType.CannotAccessPage === errorType" />
      <InvalidContentType v-else-if="EnumsFeedErrorType.InvalidContentType === errorType" />
      <ItemTagMissing v-else-if="EnumsFeedErrorType.ItemTagMissing === errorType" />
      <WrongFeedFormat v-else-if="EnumsFeedErrorType.WrongFeedFormat === errorType" />
      <SystemException v-else-if="EnumsFeedErrorType.SystemException === errorType" />
    </div>
    <div class="mx-auto mt-5 block max-w-[25rem] xl:ml-0 xl:hidden xl:w-full xl:max-w-full">
      <label class="text-[#F67424]">{{ $t('dashboard.problemSectionXmlFeed.label') }}</label>
      <div class="mt-1 flex gap-4 pr-10">
        <InputText v-model="fixedUrl" class="w-full !rounded-full !py-2 shadow" />
        <Button
          :loading="loading"
          @click="saveFixedUrl()"
          type="submit"
          :label="$t('dashboard.problemSectionXmlFeed.buttonSave')"
          class="w-[7.5rem] !rounded-full !border-none bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 !text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base"
        >
        </Button>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showFeedProcesingInformation" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <p class="text-left text-lg font-bold text-main">{{ $t('dashboard.problemSectionXmlFeed.dialogTitle') }}</p>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dashboard.problemSectionXmlFeed.dialogText') }}</p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CannotAccessPage from '@/modules/dashboard/components/XmlFeedErrors/CannotAccessPage.vue';
import IncorretUrlFormat from '@/modules/dashboard/components/XmlFeedErrors/IncorretUrlFormat.vue';
import InvalidContentType from '@/modules/dashboard/components/XmlFeedErrors/InvalidContentType.vue';
import ItemTagMissing from '@/modules/dashboard/components/XmlFeedErrors/ItemTagMissing.vue';
import SystemException from '@/modules/dashboard/components/XmlFeedErrors/SystemException.vue';
import WrongFeedFormat from '@/modules/dashboard/components/XmlFeedErrors/WrongFeedFormat.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { sleep } from '@/utils/sleep';
import { EnumsFeedErrorType } from '../../../../generated/api';

const state = ref();
const toast = useToast();
const eshopStore = useEshopsStore();
const { t } = useI18n();

const errorType = ref<EnumsFeedErrorType>(EnumsFeedErrorType.None);
const fixedUrl = ref();
const loading = ref(false);
const showFeedProcesingInformation = ref(false);

onMounted(async () => {
  await getFeedStatus();
  await getLastFeedError();
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await getFeedStatus();
    await getLastFeedError();
  }
);

const getFeedStatus = async () => {
  try {
    const response = await api.clientDashboardGetFeedState();
    state.value = response.data.state;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getLastFeedError = async () => {
  if (state.value) {
    errorType.value = EnumsFeedErrorType.None;
    return;
  }
  try {
    const response = await api.clientDashboardGetLastFeedError();
    errorType.value = response.data.errorType || EnumsFeedErrorType.None;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const saveFixedUrl = async () => {
  try {
    loading.value = true;
    await api.clientDashboardFixFeedUrl({ url: fixedUrl.value });
    showFeedProcesingInformation.value = true;
    await sleep(900000);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const getErrorNameByType = (errorType: EnumsFeedErrorType) => {
  switch (errorType) {
    case EnumsFeedErrorType.IncorrectUrlFormat:
      return t('dashboard.problemSectionXmlFeed.incorrectUrlFormat');
    case EnumsFeedErrorType.CannotAccessPage:
      return t('dashboard.problemSectionXmlFeed.cannotAccessPage');
    case EnumsFeedErrorType.InvalidContentType:
      return t('dashboard.problemSectionXmlFeed.invalidContentType');
    case EnumsFeedErrorType.ItemTagMissing:
      return t('dashboard.problemSectionXmlFeed.itemTagMissing');
    case EnumsFeedErrorType.WrongFeedFormat:
      return t('dashboard.problemSectionXmlFeed.wrongFeedFormat');
    case EnumsFeedErrorType.SystemException:
      return t('dashboard.problemSectionXmlFeed.systemException');
    case EnumsFeedErrorType.None:
      return '';
    default:
      return '';
  }
};
</script>
