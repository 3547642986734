export const datatable = {
  root: ({ props }) => ({
    class: [
      "relative",
      {
        "flex flex-col h-full":
          props.scrollable && props.scrollHeight === "flex",
      },
    ],
  }),
  loadingoverlay: {
    class: [
      "fixed w-full h-full t-0 l-0 bg-gray-400/40",
      "transition duration-200",
      "absolute flex items-center justify-center z-10",
    ],
  },
  loadingicon: "w-8 h-8 animate-spin",
  wrapper: ({ props }) => ({
    class: [
      {
        relative: props.scrollable,
        "flex flex-col grow h-full":
          props.scrollable && props.scrollHeight === "flex",
      },
    ],
  }),
  header: ({ props }) => ({
    class: [
      "bg-slate-50 text-slate-700 border-gray-300 font-semibold p-4",
      props.showGridlines
        ? "border-x border-t border-b-0"
        : "border-y border-x-0",
    ],
  }),
  table: "w-full border-spacing-0",
  thead: ({ context }) => ({
    class: [
      {
        "bg-slate-50 top-0 z-[1]": context.scrollable,
      },
    ],
  }),
  tbody: ({ instance, context }) => ({
    class: [
      {
        "sticky z-[1]": instance.frozenRow && context.scrollable,
      },
    ],
  }),
  tfoot: ({ context }) => ({
    class: [
      {
        "bg-slate-50 bottom-0 z-[1]": context.scrollable,
      },
    ],
  }),
  footer: {
    class: [
      "bg-slate-50 text-slate-700 border-t-0 border-b border-x-0 border-gray-300 font-bold p-4",
    ],
  },
  column: {
    headercell: ({ context, props }) => ({
      class: [
        "text-left border-0 border-b border-solid border-gray-300 font-bold text-xs",
        "transition duration-200 p-2",
        context.sorted
          ? "bg-blue-50 text-blue-700"
          : "bg-slate-50 text-slate-700", // Sort
        {
          "sticky z-[1]": props.frozen || props.frozen === "", // Frozen Columns
          "border-x border-y": context?.showGridlines,
          "overflow-hidden space-nowrap border-y relative bg-clip-padding":
            context.resizable, // Resizable
        },
      ],
    }),
    headercontent: "flex items-center justify-center",
    headertitle: "flex text-xs justify-center",
    bodycell: ({ props, context }) => ({
      class: [
        "border-0 border-b border-solid border-gray-300 text-xs text-center",
        context?.size === "small"
          ? "p-2"
          : context?.size === "large"
            ? "p-5"
            : "p-4", // Size
        {
          "sticky bg-inherit": props.frozen || props.frozen === "", // Frozen Columns
          "border-x border-y": context?.showGridlines,
          "font-bold ": props.bold,
        },
      ],
    }),
    footercell: ({ context }) => ({
      class: [
        "text-left border-0 border-b border-solid border-gray-300 font-bold",
        "bg-slate-50 text-slate-700",
        "transition duration-200",
        context?.size === "small"
          ? "p-2"
          : context?.size === "large"
            ? "p-5"
            : "p-4", // Size
        {
          "border-x border-y": context?.showGridlines,
        },
      ],
    }),
    sorticon: ({ context }) => ({
      class: ["ml-2", context.sorted ? "text-blue-700" : "text-slate-700"],
    }),
    sortbadge: {
      class: [
        "flex items-center justify-center align-middle",
        "rounded-[50%] w-[1.143rem] leading-[1.143rem] ml-2",
        "text-blue-700 bg-blue-50",
      ],
    },
    columnfilter: "inline-flex items-center mx-auto justify-center w-full",
    filteroverlay: {
      class: ["bg-gray-50 text-gray-600 border rounded-md min-w-[12.5rem]"],
    },
    filtermatchmodedropdown: {
      root: "min-[0rem]:flex mb-2",
    },
    filterrowitems: "m-0 p-0 py-3 list-none ",
    filterrowitem: ({ context }) => ({
      class: [
        "m-0 py-3 px-5 bg-transparent",
        "transition duration-200",
        context?.highlighted
          ? "text-blue-700 bg-blue-100"
          : "text-gray-600 bg-transparent cursor-pointer",
      ],
    }),
    filteroperator: {
      class: [
        "px-5 py-3 border-b border-solid border-gray-300 text-slate-700 bg-slate-50 rounded-t-md",
      ],
    },
    filteroperatordropdown: {
      root: "min-[0rem]:flex",
    },
    filterconstraint: "p-5 border-b border-solid border-gray-300",
    filteraddrule: "py-3 px-5",
    filteraddrulebutton: {
      root: "justify-center w-full min-[0rem]:text-sm",
      label: "flex-auto grow-0",
      icon: "mr-2",
    },
    filterremovebutton: {
      root: "ml-2",
      label: "grow-0",
    },
    filterbuttonbar: "flex items-center justify-between p-5",
    filterclearbutton: {
      root: "w-auto min-[0rem]:text-sm border-blue-500 text-blue-500 px-4 py-3",
    },
    filterapplybutton: {
      root: "w-auto min-[0rem]:text-sm px-4 py-3",
    },
    filtermenubutton: ({ context }) => ({
      class: [
        "inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative ml-2",
        "w-8 h-8 rounded-[50%]",
        "transition duration-200",
        "hover:text-slate-700 hover:bg-gray-300/20", // Hover
        "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // Focus
        {
          "bg-blue-50 text-blue-700": context.active,
        },
      ],
    }),
    headerfilterclearbutton: ({ context }) => ({
      class: [
        "inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative",
        "text-left bg-transparent m-0 p-0 border-none select-none ml-2",
        {
          invisible: !context.hidden,
        },
      ],
    }),
    columnresizer:
      "block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent",
    rowreordericon: "cursor-move",
    roweditorinitbutton: {
      class: [
        "inline-flex items-center justify-center overflow-hidden relative",
        "text-left cursor-pointer select-none",
        "w-8 h-8 border-0 rounded-[50%]",
        "transition duration-200",
        "text-slate-700 border-transparent",
        "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // Focus
        "hover:text-slate-700 hover:bg-gray-300/20", // Hover
      ],
    },
    roweditorsavebutton: {
      class: [
        "inline-flex items-center justify-center overflow-hidden relative",
        "text-left cursor-pointer select-none",
        "w-8 h-8 border-0 rounded-[50%]",
        "transition duration-200",
        "text-slate-700 border-transparent",
        "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // Focus
        "hover:text-slate-700 hover:bg-gray-300/20", // Hover
      ],
    },
    roweditorcancelbutton: {
      class: [
        "inline-flex items-center justify-center overflow-hidden relative",
        "text-left cursor-pointer select-none",
        "w-8 h-8 border-0 rounded-[50%]",
        "transition duration-200",
        "text-slate-700 border-transparent",
        "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // Focus
        "hover:text-slate-700 hover:bg-gray-300/20", // Hover
      ],
    },
    radiobuttonwrapper: {
      class: [
        "relative inline-flex cursor-pointer select-none align-bottom",
        "w-6 h-6",
      ],
    },
    radiobutton: ({ context }) => ({
      class: [
        "flex justify-center items-center",
        "border-2 w-6 h-6 text-gray-700 rounded-full transition duration-200 ease-in-out",
        context.checked
          ? "border-blue-500 bg-blue-500"
          : "border-gray-300 bg-white ",
        {
          "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]":
            !context.disabled,
          "cursor-default opacity-60": context.disabled,
        },
      ],
    }),
    radiobuttonicon: ({ context }) => ({
      class: [
        "transform rounded-full",
        "block w-3 h-3 transition duration-200 bg-white",
        {
          "backface-hidden scale-10 invisible": context.checked === false,
          "transform scale-100 visible": context.checked === true,
        },
      ],
    }),
    headercheckboxwrapper: {
      class: [
        "cursor-pointer inline-flex relative select-none align-bottom",
        "w-6 h-6",
      ],
    },
    headercheckbox: ({ context }) => ({
      class: [
        "flex items-center justify-center",
        "border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200",
        context.checked
          ? "border-blue-500 bg-blue-500"
          : "border-gray-300 bg-white",
        {
          "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]":
            !context.disabled,
          "cursor-default opacity-60": context.disabled,
        },
      ],
    }),
    headercheckboxicon:
      "w-4 h-4 transition-all duration-200 text-white text-base",
    checkboxwrapper: {
      class: [
        "cursor-pointer inline-flex relative select-none align-bottom",
        "w-6 h-6",
      ],
    },
    checkbox: ({ context }) => ({
      class: [
        "flex items-center justify-center",
        "border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200",
        context.checked
          ? "border-blue-500 bg-blue-500"
          : "border-gray-300 bg-white",
        {
          "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]":
            !context.disabled,
          "cursor-default opacity-60": context.disabled,
        },
      ],
    }),
    checkboxicon: "w-4 h-4 transition-all duration-200 text-white text-base",
  },
  bodyrow: ({ context }) => ({
    class: [
      context.stripedRows
        ? context.index % 2 === 0
          ? "bg-white text-gray-600"
          : "bg-gray-100 text-gray-600"
        : "",
      "transition duration-200",
      "focus:outline focus:outline-[0.15rem] focus:outline-blue-200 focus:outline-offset-[-0.15rem]", // Focus
      {
        "cursor-pointer": context.selectable,
        "hover:bg-gray-300/20 hover:text-gray-600":
          context.selectable && !context.selected, // Hover
      },
    ],
  }),
  rowexpansion: "bg-white text-gray-600",
  rowgroupheader: {
    class: [
      "sticky z-[1]",
      "bg-white text-gray-600",
      "transition duration-200",
    ],
  },
  rowgroupfooter: {
    class: [
      "sticky z-[1]",
      "bg-white text-gray-600",
      "transition duration-200",
    ],
  },
  rowgrouptoggler: {
    class: [
      "text-left m-0 p-0 cursor-pointer select-none",
      "inline-flex items-center justify-center overflow-hidden relative",
      "w-8 h-8 text-gray-500 border-0 bg-transparent rounded-[50%]",
      "transition duration-200",
    ],
  },
  rowgrouptogglericon: "inline-block w-4 h-4",
  resizehelper: "absolute hidden w-px z-10 bg-blue-500",
  rowexpansioncell: "p-4 m-0 border border-gray-300",
};
