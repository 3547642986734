<template>
  <ul role="list" class="my-1 grid grid-cols-1 gap-6 rounded-lg px-5 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5">
    <li
      @click="onselect(item.hrefName, item.type)"
      v-for="item in menuCardItems"
      :key="item.name"
      class="relative col-span-1 flex flex-col divide-gray-200 rounded-2xl border border-transparent bg-white text-center shadow-xl hover:cursor-pointer hover:border-blue-600"
    >
      <div class="flex flex-1 flex-col p-4">
        <div class="bg-gray-10 mx-auto h-20 w-20 rounded-none bg-transparent p-1">
          <img class="object-containr mx-auto" :src="$filePath + item.imgsrc" alt="" />
        </div>
        <h3 class="mt-4 text-base font-bold text-main">{{ item.name }}</h3>
        <p class="mt-4 text-sm text-gray-500">{{ item.description }}</p>
      </div>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';
const props = defineProps<{
  menuCardItems: {
    name: string;
    description: string;
    hrefName: string;
    type: string;
    imgsrc: string;
  }[];
}>();

const router = useRouter();

const onselect = async (hrefName, type) => {
  // if hrefName is null => Open Ai content creator page
  // if hrefName is not null => Open SubPage components
  if (type) {
    await router.push({ name: hrefName, params: { type: type } });
  } else {
    await router.push({ name: hrefName });
  }
};
</script>
