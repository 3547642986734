<template>
  <Dialog v-model:visible="showPreviewModal" modal header="" :style="{ width: '32rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <!-- cant put global filePath to setup so i need to do it like this -->
      <img :src="$filePath + previewImage" alt="image" class="w-full" />
    </div>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { computed, PropType } from 'vue';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';

const showPreviewModal = defineModel<boolean>({ required: true });

const props = defineProps({
  format: {
    type: Object as PropType<IFormat>,
    required: false,
    default: null,
  },
});

const previewImage = computed(() => {
  if (!props.format) return '';
  return `/campaignFormats/previews/${props.format?.type.toLowerCase()}.svg`;
});
</script>
