<template>
  <div v-if="feedStatus === EnumsFeedStatus.Valid || feedStatus === EnumsFeedStatus.InvalidWithItems">
    <div class="sm:hidden">
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" @change="handleTabChange($event)">
        <option v-for="(tab, index) in tabs" @click="changeCurrent(index)" :key="index" :selected="tab.current">{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
        <a
          v-for="(tab, tabIdx) in tabs"
          @click="changeCurrent(tabIdx)"
          :key="tabIdx"
          :class="[
            tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
            tabIdx === 0 ? 'rounded-l-lg' : '',
            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative min-w-0 flex-1 cursor-pointer overflow-hidden bg-white p-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
        >
          <span>{{ tab.name }}</span>
          <span aria-hidden="true" :class="[tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
        </a>
      </nav>
    </div>
    <div class="mx-auto mt-5 w-full">
      <div v-if="tabs[0].current" class="w-auto">
        <Products :categoryFilterForProducts="categoryFilterForProducts" />
      </div>
      <div v-if="tabs[1].current" class="w-auto">
        <Categories @openProducts="openProductsWithCategoryFilter" />
      </div>
    </div>
  </div>
  <div v-else-if="feedStatus === EnumsFeedStatus.Waiting">
    <h2 class="text-center text-2xl font-bold leading-10 tracking-tight text-main dark:text-gray-300">{{ $t('productPrioritization.feedProcessing') }}</h2>
    <h3 class="dark:text-grex-300 mt-1 text-center text-xl font-bold leading-10 tracking-tight text-main">{{ $t('productPrioritization.feedProcessingDescription') }}</h3>
    <div class="loader mx-auto">
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__ball"></div>
    </div>
  </div>
  <div v-else-if="feedStatus === EnumsFeedStatus.InvalidWithoutItems">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <div>
          <h2 class="text-center text-2xl font-bold leading-10 tracking-tight text-main dark:text-gray-300">{{ $t('productPrioritization.feedIsInvalid') }}</h2>
          <h3 class="mt-5 text-xl font-bold leading-10 tracking-tight text-main dark:text-gray-300">{{ $t('productPrioritization.feedIsInvalidDescription') }}</h3>
        </div>
        <dl class="mt-1 space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div" v-for="(reason, index) in reasons" :key="index" class="pt-6" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900 dark:text-gray-300">
                <span class="text-base font-semibold leading-7">{{ reason.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
                  <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-gray-600 dark:text-gray-400">{{ reason.answer }}</p>
              <a v-if="reason.link" :href="reason.linkHref" class="text-base leading-7 text-blue-600 dark:text-blue-400">{{ reason.link }}</a>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { MinusIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'primevue/usetoast';
import { reactive, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ProductLinkCodebook from '@/modules/productPrioritization/codebooks/productLinkCodebook';
import { api } from '@/services/api';
import { useUserStore } from '@/stores/user';
import { EnumsFeedStatus } from '../../../../generated/api';
import Categories from '../components/Categories.vue';
import Products from '../components/Products.vue';

const toast = useToast();
const feedStatus = ref<EnumsFeedStatus>(EnumsFeedStatus.Waiting);
const userStore = useUserStore();
const router = useRouter();

onMounted(async () => {
  try {
    const { data } = await api.clientProductsGetFeedStatus();
    feedStatus.value = data.status;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
});

const { t } = useI18n();
const categoryFilterForProducts = ref('');
const tabs = reactive([
  { name: t('productPrioritization.productTabName'), href: '#', current: true },
  { name: t('productPrioritization.categoryTabName'), href: '#', current: false },
]);

const handleTabChange = (event) => {
  const selectedIndex = event.target.selectedIndex;
  tabs.forEach((tab, index) => {
    if (index === selectedIndex) {
      tab.current = true;
    } else {
      tab.current = false;
    }
  });
};

const reasons = [
  {
    question: t('productPrioritization.reason1'),
    answer: t('productPrioritization.answer1'),
    link: t('productPrioritization.answer1Link'),
    linkHref: ProductLinkCodebook[userStore.getLanguageCode]?.answer1Link || 'en',
  },
  {
    question: t('productPrioritization.reason2'),
    answer: t('productPrioritization.answer2'),
  },
  {
    question: t('productPrioritization.reason3'),
    answer: t('productPrioritization.answer3'),
  },
  {
    question: t('productPrioritization.reason4'),
    answer: t('productPrioritization.answer4'),
    link: t('productPrioritization.answer4Link'),
    linkHref: ProductLinkCodebook[userStore.getLanguageCode]?.answer4Link || 'en',
  },
];
const changeCurrent = (index: number) => {
  tabs.forEach((tab, idx) => {
    if (idx === index) {
      tab.current = true;
    } else {
      tab.current = false;
    }
  });
};

const openProductsWithCategoryFilter = (name) => {
  categoryFilterForProducts.value = name;
  changeCurrent(0);
};
</script>

<style lang="scss" scoped>
$bar-color: #075695;
$ball-color: #075695;
$bg-color: #ea4961;

.loader {
  position: relative;
  width: 75px;
  height: 100px;

  &__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: $bar-color;
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        left: ($i - 1) * 15px;
        transform: scale(1, $i * 0.2);
        animation: barUp#{$i} 4s infinite;
      }
    }
  }

  &__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $ball-color;
    border-radius: 50%;
    animation: ball 4s infinite;
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0.2);
  }
}
@keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }
  40% {
    transform: scale(1, 0.4);
  }
  50% {
    transform: scale(1, 0.8);
  }
  90% {
    transform: scale(1, 0.8);
  }
  100% {
    transform: scale(1, 0.4);
  }
}
@keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }
  100% {
    transform: scale(1, 0.6);
  }
}
@keyframes barUp4 {
  0% {
    transform: scale(1, 0.8);
  }
  40% {
    transform: scale(1, 0.8);
  }
  50% {
    transform: scale(1, 0.4);
  }
  90% {
    transform: scale(1, 0.4);
  }
  100% {
    transform: scale(1, 0.8);
  }
}
@keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.2);
  }
  90% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
