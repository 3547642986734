<template>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
            <p class="mt-6 text-base leading-7 text-gray-600">
                {{ $t('global.loading.text') }}
            </p>
        </div>
    </main>
</template>

<script setup lang="ts">
import {usePrimeVue} from "primevue/config";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import { useUserStore } from '@/stores/user';
import { handleLoginAndEshopList } from '@/utils/handleLoginAndEshopList';


// Iframe hack
const props = defineProps<{
    accessToken: string;
    refreshToken: string;
    selectedEshopId: string;
    theme: string;
}>();

const userStore = useUserStore();
const router = useRouter();
const primeVue = usePrimeVue();

onMounted(async () => {
    if (!props.accessToken || !props.refreshToken || !props.selectedEshopId) {
        if (userStore.getIsLoggedIn) {
            await router.push({ name: FULL_PAGE_ROUTES.RECHARGE_CREDIT_PAGE});
            return;
        } else {
            await router.push({ name: PUBLIC_ROUTES.LOGIN_PAGE });
            return;
        }
    } else {
        await handleLoginAndEshopList(primeVue, props.accessToken, props.refreshToken, props.selectedEshopId);
    }
});
</script>
