export const dialog = {
    root: ({ state }) => ({
        class: [
            'rounded-lg shadow-lg border-0',
            'max-h-[90vh] transform scale-100',
            'm-0 w-[21.875rem] md:w-[37.5rem] lg:w-[50rem]',
            'dark:border dark:border-blue-900/40 dark:bg-black',
            {
                'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized,
            },
        ],
    }),
    header: {
        class: ['flex items-center justify-between shrink-0', 'bg-bg_main text-gray-800 border-t-0  rounded-tl-lg rounded-tr-lg p-6', 'dark:text-white/80'],
    },
    headerTitle: 'font-bold text-lg',
    headerIcons: 'flex items-center',
    closeButton: {
        class: [
            'flex items-center justify-center overflow-hidden relative',
            'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
            'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
            'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        ],
    },
    closeButtonIcon: 'w-4 h-4 inline-block',
    content: ({ state }) => ({
        class: [
            'overflow-y-auto',
            'bg-bg_main text-gray-700 px-6 pb-8 pt-0',
            'dark:bg-gray-900  dark:text-white/80 ',
            {
                grow: state.maximized,
            },
        ],
    }),
    footer: {
        class: ['shrink-0 ', 'border-t-0 bg-bg_main text-gray-700 px-6 pb-6 text-right rounded-b-lg', 'dark:bg-gray-900  dark:text-white/80'],
    },
    mask: ({ props }) => ({
        class: ['transition duration-200', { 'bg-black/40 backdrop-blur-sm': props.modal }],
    }),
    transition: ({ props }) => {
        return props.position === 'top'
            ? {
                  enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
              }
            : props.position === 'bottom'
              ? {
                    enterFromClass: 'opacity-0 scale-75 translate-y-full',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
                }
              : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
                ? {
                      enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
                  }
                : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
                  ? {
                        enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                    }
                  : {
                        enterFromClass: 'opacity-0 scale-75',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75',
                    };
    },
};
