/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsClientPermission = {
    AiTextAssetsGeneration: 'AiTextAssetsGeneration',
    AiContentGeneration: 'AiContentGeneration',
    AiAssistentAccess: 'AiAssistentAccess',
    GetDetailedStatisticsOrders: 'GetDetailedStatisticsOrders',
    GetDetailedStatisticsProducts: 'GetDetailedStatisticsProducts',
    GetDetailedStatisticsCategories: 'GetDetailedStatisticsCategories',
    GetDetailedStatisticsVisitors: 'GetDetailedStatisticsVisitors',
    GetDetailedStatisticsCompMetrics: 'GetDetailedStatisticsCompMetrics',
    SetCampaignCustomBudget: 'SetCampaignCustomBudget',
    GetDividedStats: 'GetDividedStats'
} as const;

export type EnumsClientPermission = typeof EnumsClientPermission[keyof typeof EnumsClientPermission];



