<template>
  <div :class="[transition ? 'opacity-100' : 'opacity-0', 'rounded-lg transition-opacity delay-100 duration-1000']">
    <div class="mx-auto max-w-7xl rounded-lg px-4 py-10 sm:px-6 lg:px-8">
      <div class="relative rounded-lg bg-white shadow-tinyCard dark:bg-[#28334e] dark:text-[#cbd5e1]">
        <div class="grid grid-cols-1 lg:grid-cols-3">
          <!-- Contact information -->
          <div class="relative overflow-hidden rounded-l-lg bg-main px-6 py-10 dark:bg-[#28334e] sm:px-10 xl:p-12">
            <div class="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
              <svg class="absolute inset-0 h-full w-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" offset="" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 sm:block lg:hidden" aria-hidden="true">
              <svg class="absolute inset-0 h-full w-full" width="359" height="339" viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" offset="" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 lg:block" aria-hidden="true">
              <svg class="absolute inset-0 h-full w-full" width="160" height="678" viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" offset="" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h3 class="text-lg font-medium text-white">{{ $t('contact.pages.contact.infromation') }}</h3>
            <p class="mt-6 max-w-3xl text-base text-indigo-50">{{ $t('contact.pages.contact.contactAs') }}</p>
            <dl class="mt-8 space-y-6">
              <dd class="flex text-base text-indigo-50">
                <PhoneIcon class="h-6 w-6 shrink-0 text-indigo-200" aria-hidden="true" />
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                <span class="ml-3 font-bold">+420 602 744 262</span>
              </dd>
              <dd class="flex text-base text-indigo-50">
                <EnvelopeIcon class="h-6 w-6 shrink-0 text-indigo-200" aria-hidden="true" />
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                <span class="ml-3 font-bold">info@tanganica.com</span>
              </dd>
              <dd class="flex text-base text-indigo-50">
                <span class="font-bold">{{ $t('contact.pages.contact.contactText') }}</span>
              </dd>
            </dl>
          </div>

          <!-- Contact form -->
          <div class="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 class="text-lg font-medium text-gray-700 dark:text-[#cbd5e1]">{{ $t('contact.pages.contact.sedMessage') }}</h3>
            <form class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div class="sm:col-span-2">
                <label for="subject" class="block text-sm font-medium text-gray-700 dark:text-[#cbd5e1]">{{ $t('contact.pages.contact.subject') }}</label>
                <div class="mt-1">
                  <input v-model="formData.subject" type="text" name="subject" id="subject" class="block w-full rounded-lg border border-gray-300 px-4 py-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 lg:text-sm" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <p v-if="messageError" class="mb-2 block text-sm font-bold text-red-700">{{ $t('contact.pages.contact.messageError') }}</p>
                <div class="flex justify-between">
                  <label for="text" class="block text-sm font-medium text-gray-700 dark:text-[#cbd5e1]">{{ $t('contact.pages.contact.message') }}</label>
                </div>
                <div class="mt-1">
                  <textarea
                    v-model="formData.text"
                    id="message"
                    name="message"
                    rows="4"
                    class="block w-full rounded-lg border border-gray-300 px-4 py-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 lg:text-sm"
                    aria-describedby="message-max"
                  />
                </div>
              </div>
              <div class="flex items-end sm:col-span-2">
                <MainButton @click="sendFormData()" :text="$t('contact.pages.contact.send')" class="ml-auto mr-0 w-full px-6 py-4 sm:w-auto" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'primevue/usetoast';
import { reactive, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const toast = useToast();
const loadingStore = useLoadingStore();
const messageError = ref(false);
const { t } = useI18n();

const formData = reactive({
  subject: '',
  text: '',
});

const sendFormData = async () => {
  loadingStore.updateLoading(true);
  if (formData.subject === '' || formData.text === '') {
    toast.add({
      severity: 'error',
      summary: t('contact.pages.contact.formNotValid'),
      detail: t('contact.pages.contact.formMustFillAll'),
      life: 5000,
    });
    loadingStore.updateLoading(false);
    return;
  }
  try {
    await api.clientSurveySendContactForm(formData);
    formData.subject = '';
    formData.text = '';
    toast.add({
      severity: 'success',
      summary: t('contact.pages.contact.formSuccess'),
      detail: t('contact.pages.contact.formSuccessDescription'),
      life: 5000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const transition = ref(false);
onMounted(() => {
  setTimeout(function () {
    transition.value = true;
  }, 200);
});
</script>
