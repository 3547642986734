<template>
  <Dialog v-model:visible="visibleReallyOffDialog" :closable="false" modal :header="$t('subscription.text35')" :style="{ width: '28rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div class="px-5">
      <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
          <ExclamationTriangleIcon class="h-6 w-6 text-orange-700" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <p class="text-sm text-gray-500">{{ $t('subscription.text36') }}</p>
          </div>
          <div class="mt-8">
            <p class="text-sm font-bold text-gray-700">{{ $t('subscription.text37') }}</p>
          </div>
        </div>
      </div>
      <fieldset class="my-4 border-y border-gray-200">
        <div class="divide-y divide-gray-200">
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="generatedContentMismatch" class="font-medium text-gray-700">{{ $t('subscription.text38') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.generatedContentMismatch"
                id="generatedContentMismatch"
                aria-describedby="generatedContentMismatch-description"
                name="generatedContentMismatch"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="priceValueMismatch" class="font-medium text-gray-700">{{ $t('subscription.text39') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.priceValueMismatch"
                id="priceValueMismatch"
                aria-describedby="priceValueMismatch-description"
                name="priceValueMismatch"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="technicalIssues" class="font-medium text-gray-700">{{ $t('subscription.text40') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.technicalIssues"
                id="technicalIssues"
                aria-describedby="technicalIssues-description"
                name="technicalIssues"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <div>
        <label for="otherReason" class="block text-sm font-medium text-gray-700">{{ $t('subscription.text41') }}</label>
        <div class="mt-1">
          <textarea v-model="reallyOffDialogData.otherReason" rows="2" name="otherReason" id="otherReason" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="align-items-center justify-content-center inline-flex gap-2">
        <Button @click="visibleReallyOffDialog = false" type="button" severity="success" :label="$t('campaigns.modalOffCampaingChannel.campaignOn')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
        <Button @click="reallyOff()" type="button" severity="warning" :label="$t('campaigns.modalOffCampaingChannel.campaignOff')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const visibleReallyOffDialog = defineModel<boolean>({ required: true });
const emit = defineEmits(['reallyOffDialogCallback']);

const toast = useToast();
const { t } = useI18n();

const reallyOffDialogData = reactive({
  generatedContentMismatch: false,
  priceValueMismatch: false,
  technicalIssues: false,
  otherReason: '',
});

const reallyOff = () => {
  if (reallyOffDialogData.generatedContentMismatch || reallyOffDialogData.priceValueMismatch || reallyOffDialogData.technicalIssues || reallyOffDialogData.otherReason.length > 0) {
    visibleReallyOffDialog.value = false;
    emit('reallyOffDialogCallback', reallyOffDialogData);
  } else {
    toast.add({
      severity: 'error',
      summary: t('campaigns.modalOffCampaingChannel.reasonRequired'),
      life: 6000,
    });
  }
};
</script>
