/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsMicrosoftStoreStatus = {
    Created: 'Created',
    Waiting: 'Waiting',
    NotPlanned: 'NotPlanned'
} as const;

export type EnumsMicrosoftStoreStatus = typeof EnumsMicrosoftStoreStatus[keyof typeof EnumsMicrosoftStoreStatus];



