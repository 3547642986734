import { ThemeEnum } from '@/enums/ThemeEnum';
import { CallbackEnum } from '@/modules/campaigns/enums/CallbackEnum';
import { useUserStore } from '@/stores/user';

export const getRedirectUrl = (callbackType: CallbackEnum): string => {
  const userStore = useUserStore();
  let redirectUri = '';

  switch (userStore.theme) {
    case ThemeEnum.IFRAME: {
      if (callbackType === CallbackEnum.FACEBOOK_BUSINESS) {
        redirectUri = import.meta.env.VITE_FACEBOOK_BUSINESS_SHARE_CALLBACK_URL_IFRAME;
      } else {
        redirectUri = import.meta.env.VITE_FACEBOOK_PAGE_SHARE_CALLBACK_URL_IFRAME;
      }
      break;
    }
    case ThemeEnum.ESHOP_RYCHLE: {
      if (callbackType === CallbackEnum.FACEBOOK_BUSINESS) {
        redirectUri = import.meta.env.VITE_FACEBOOK_BUSINESS_SHARE_CALLBACK_URL_ESHOPRYCHLE;
      } else {
        redirectUri = import.meta.env.VITE_FACEBOOK_PAGE_SHARE_CALLBACK_URL_ESHOPRYCHLE;
      }
      break;
    }
    case ThemeEnum.EXIT_SHOP: {
      if (callbackType === CallbackEnum.FACEBOOK_BUSINESS) {
        redirectUri = import.meta.env.VITE_FACEBOOK_BUSINESS_SHARE_CALLBACK_URL_EXITSHOP;
      } else {
        redirectUri = import.meta.env.VITE_FACEBOOK_PAGE_SHARE_CALLBACK_URL_EXITSHOP;
      }
      break;
    }
    default: {
      if (callbackType === CallbackEnum.FACEBOOK_BUSINESS) {
        redirectUri = import.meta.env.VITE_FACEBOOK_BUSINESS_SHARE_CALLBACK_URL;
      } else {
        redirectUri = import.meta.env.VITE_FACEBOOK_PAGE_SHARE_CALLBACK_URL;
      }
    }
  }
  return redirectUri;
};
