import axios, { AxiosInstance } from 'axios';
import { requestInterceptor, errorInterceptor } from '@/services/api/interceptors';
import { TANGANICAApi } from '../../../generated/api';

// Create a new axios instance for the default API
const defaultAxiosInstance: AxiosInstance = axios.create();

defaultAxiosInstance.interceptors.request.use((config) => requestInterceptor(config));

// Cache the default axios instance
// TODO in future for caching
// // Cache the default axios instance
// setupCache(defaultAxiosInstance, {
//     methods: ['get', 'post'],
//     storage: buildMemoryStorage(/* cloneData default=*/ true, /* cleanupInterval default=*/ 1000 * 60 * 5, /* maxEntries default=*/ false),
//     debug: console.log,
//     generateKey: buildKeyGenerator((request) => {
//         return`${request.url}${JSON.stringify(request.params)}${JSON.stringify(request.data)}${JSON.stringify(request.headers ? request.headers['Authorization'] : '')}`;
//     })
// });

defaultAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
);

const api = new TANGANICAApi(undefined, import.meta.env.VITE_NET_BE_URL, defaultAxiosInstance);

export { api };
