import { PhoneNumberUtil } from 'google-libphonenumber';
import * as yup from 'yup';
import { ICountry } from '@/codebooks/CountriesCodebook';
import i18n from '@/plugins/i18n';

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
const { t } = i18n.global;

yup.addMethod(yup.string, 'phoneNumber', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('phoneNumber', t('validations.phoneNumber'), (value, context) => {

    if (!value || value.length === 0) {
      return true;
    }

    const country = context.parent.phoneCountry as ICountry;

    try {
      if (!country || !country.code) {
        return false;
      }

      const phoneNumber = phoneUtil.parse(value, country.code);
      return phoneUtil.isValidNumberForRegion(phoneNumber, country.code);
    } catch (e) {
      return false;
    }
  });
});

yup.addMethod(yup.string, 'password', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('password', t('validations.passwordComplexity'), (value) => {
    if (!value || value.length === 0) {
      return true;
    }
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value);
  });
});

yup.addMethod(yup.string, 'companyID', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('companyID', t('validations.company_id'), (value) => {
    if (!value || value.length === 0) {
      return true;
    }
    return /^\d{3,15}$/.test(value);
  });
});

yup.addMethod(yup.string, 'vatID', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('vatID', t('validations.vat_id'), (value) => {
    if (!value || value.length === 0) {
      return true;
    }
    return /^[A-Z][A-Z][a-zA-Z0-9]{2,}$/.test(value);
  });
});

yup.addMethod(yup.string, 'nameSurname', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('nameSurname', t('validations.name_and_surname'), (value) => {
    if (!value || value.length === 0) {
      return true;
    }
    return /^.+\s+.+$/.test(value);
  });
});

yup.addMethod(yup.string, 'eshopUrl', function () {
  // @ts-expect-error - i18n TS bug
  // eslint-disable-next-line no-invalid-this
  return this.test('nameSurname', t('validations.eshopURL'), (value) => {
    if (!value || value.length === 0) {
      return true;
    }
    const regex = new RegExp('^(?=.*\\.)[^\\s.]+\\.[^\\s]+$', 'i');
    return regex.test(value);
  });
});

export default yup;
