<template>
  <div class="flex h-screen flex-col bg-white p-1">
    <main class="m-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
      <img class="mx-auto h-10 w-auto sm:h-12" src="/favicon.png" alt="tanganica" />
      <div class="mx-auto mt-10 w-full max-w-2xl text-center sm:mt-14">
        <p class="mt-4 text-center text-3xl font-bold tracking-tight text-main sm:text-5xl">{{ t('stripeAutoTopUpCallback.paymentConfirmation') }}</p>
        <!-- Show Error -->
        <div v-if="isErrorDialogVisible">
          <div class="flex flex-col items-center justify-center">
            <p class="mt-10 flex items-center text-3xl text-red-700">{{ t('stripeAutoTopUpCallback.paymentNotSuccessful') }}<XCircleIcon class="ml-2 h-7 w-7" /></p>
            <p class="mt-5 text-xl text-main">{{ errorMessage }}</p>
          </div>
          <div v-if="templateErrorCode === 'insufficient_funds'" class="mt-10 flex justify-center">
            <div @click="refreshPage()" class="text-sm font-semibold leading-6 text-main">
              <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
              <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="t('stripeAutoTopUpCallback.refreshPayment')"></MainButton>
            </div>
          </div>
          <div class="mt-5 flex justify-center">
            <router-link :to="{ name: PUBLIC_ROUTES.LOGIN_PAGE }" class="text-sm font-semibold leading-6 text-main">
              <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
              <MainButton class="mx-auto min-w-[12.5rem] bg-main bg-gradient-to-r" :hover="false" :text="t('stripeAutoTopUpCallback.continueToApp')"></MainButton>
            </router-link>
          </div>
        </div>
        <div v-else-if="isSuccessDialogVisible">
          <div class="flex flex-col items-center justify-center">
            <p class="mt-10 flex items-center text-3xl text-green-600">{{ $t('rechargeCredit.stripeCallback.ok') }}<CheckCircleIcon class="ml-2 h-7 w-7" /></p>
            <p class="mt-2 text-xl text-main">{{ t('stripeAutoTopUpCallback.paymentSuccessful') }}</p>
          </div>
          <div class="mt-10 flex justify-center">
            <router-link :to="{ name: PUBLIC_ROUTES.LOGIN_PAGE }" class="text-sm font-semibold leading-6 text-main">
              <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
              <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="t('stripeAutoTopUpCallback.continueToApp')"></MainButton>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import { useLoadingStore } from '@/stores/loading';

const { t } = useI18n();
const toast = useToast();
const loadingStore = useLoadingStore();

const stripeInstance = ref();
const isErrorDialogVisible = ref(false);
const isSuccessDialogVisible = ref(false);
const errorMessage = ref('');
const templateErrorCode = ref();

const PARAM_SECRET = 'client_secret';

onMounted(async () => {
  await confirmPayment();
});

const confirmPayment = async () => {
  loadingStore.updateLoading(true);
  const url = new URL(window.location.href);
  const clientSecret = url.searchParams.get(PARAM_SECRET);

  if (!clientSecret) {
    handleError('missing_client_secret');
    return;
  }

  try {
    if (!stripeInstance.value) {
      stripeInstance.value = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    }

    if (!stripeInstance.value) {
      handleError('inicialization_payment_failed');
      return;
    }

    const result = await stripeInstance.value.confirmPayment({
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      handleError(result.error);
    } else if (result.payment_intent && result.payment_intent.status === 'succeeded') {
      isSuccessDialogVisible.value = true;
    }
  } catch (error) {
    handleError(error);
  } finally {
    loadingStore.updateLoading(false);
  }
};

const handleError = (error) => {
  if (error?.payment_intent?.last_payment_error?.decline_code) {
    errorMessage.value = handlePaymentError(error.payment_intent?.last_payment_error?.decline_code);
  } else {
    errorMessage.value = handlePaymentError(error);
  }
  isErrorDialogVisible.value = true;
  loadingStore.updateLoading(false);
};

function refreshPage() {
  window.location.reload();
}

function handlePaymentError(errorCode) {
  let message;
  templateErrorCode.value = errorCode;
  switch (errorCode) {
    case 'authentication_required':
      message = t('stripeAutoTopUpCallback.authentication_required');
      break;

    case 'approve_with_id':
      message = t('stripeAutoTopUpCallback.approve_with_id');
      break;

    case 'call_issuer':
      message = t('stripeAutoTopUpCallback.call_issuer');
      break;

    case 'card_not_supported':
      message = t('stripeAutoTopUpCallback.card_not_supported');
      break;

    case 'card_velocity_exceeded':
      message = t('stripeAutoTopUpCallback.card_velocity_exceeded');
      break;

    case 'currency_not_supported':
      message = t('stripeAutoTopUpCallback.currency_not_supported');
      break;

    case 'do_not_honor':
      message = t('stripeAutoTopUpCallback.do_not_honor');
      break;

    case 'do_not_try_again':
      message = t('stripeAutoTopUpCallback.do_not_try_again');
      break;

    case 'duplicate_transaction':
      message = t('stripeAutoTopUpCallback.duplicate_transaction');
      break;

    case 'expired_card':
      message = t('stripeAutoTopUpCallback.expired_card');
      break;

    case 'fraudulent':
      message = t('stripeAutoTopUpCallback.fraudulent');
      break;

    case 'generic_decline':
      message = t('stripeAutoTopUpCallback.generic_decline');
      break;

    case 'incorrect_number':
      message = t('stripeAutoTopUpCallback.incorrect_number');
      break;

    case 'incorrect_cvc':
      message = t('stripeAutoTopUpCallback.incorrect_cvc');
      break;

    case 'incorrect_pin':
      message = t('stripeAutoTopUpCallback.incorrect_pin');
      break;

    case 'incorrect_zip':
      message = t('stripeAutoTopUpCallback.incorrect_zip');
      break;

    case 'insufficient_funds':
      message = t('stripeAutoTopUpCallback.insufficient_funds');
      break;

    case 'invalid_account':
      message = t('stripeAutoTopUpCallback.invalid_account');
      break;

    case 'invalid_amount':
      message = t('stripeAutoTopUpCallback.invalid_amount');
      break;

    case 'invalid_cvc':
      message = t('stripeAutoTopUpCallback.invalid_cvc');
      break;

    case 'invalid_expiry_month':
      message = t('stripeAutoTopUpCallback.invalid_expiry_month');
      break;

    case 'invalid_expiry_year':
      message = t('stripeAutoTopUpCallback.invalid_expiry_year');
      break;

    case 'invalid_number':
      message = t('stripeAutoTopUpCallback.invalid_number');
      break;

    case 'invalid_pin':
      message = t('stripeAutoTopUpCallback.invalid_pin');
      break;

    case 'issuer_not_available':
      message = t('stripeAutoTopUpCallback.issuer_not_available');
      break;

    case 'lost_card':
      message = t('stripeAutoTopUpCallback.lost_card');
      break;

    case 'merchant_blacklist':
      message = t('stripeAutoTopUpCallback.merchant_blacklist');
      break;

    case 'new_account_information_available':
      message = t('stripeAutoTopUpCallback.new_account_information_available');
      break;

    case 'no_action_taken':
      message = t('stripeAutoTopUpCallback.no_action_taken');
      break;

    case 'not_permitted':
      message = t('stripeAutoTopUpCallback.not_permitted');
      break;

    case 'offline_pin_required':
      message = t('stripeAutoTopUpCallback.offline_pin_required');
      break;

    case 'online_or_offline_pin_required':
      message = t('stripeAutoTopUpCallback.online_or_offline_pin_required');
      break;

    case 'pickup_card':
      message = t('stripeAutoTopUpCallback.pickup_card');
      break;

    case 'pin_try_exceeded':
      message = t('stripeAutoTopUpCallback.pin_try_exceeded');
      break;

    case 'processing_error':
      message = t('stripeAutoTopUpCallback.processing_error');
      break;

    case 'reenter_transaction':
      message = t('stripeAutoTopUpCallback.reenter_transaction');
      break;

    case 'restricted_card':
      message = t('stripeAutoTopUpCallback.restricted_card');
      break;

    case 'revocation_of_all_authorizations':
      message = t('stripeAutoTopUpCallback.revocation_of_all_authorizations');
      break;

    case 'revocation_of_authorization':
      message = t('stripeAutoTopUpCallback.revocation_of_authorization');
      break;

    case 'security_violation':
      message = t('stripeAutoTopUpCallback.security_violation');
      break;

    case 'service_not_allowed':
      message = t('stripeAutoTopUpCallback.service_not_allowed');
      break;

    case 'stolen_card':
      message = t('stripeAutoTopUpCallback.stolen_card');
      break;

    case 'stop_payment_order':
      message = t('stripeAutoTopUpCallback.stop_payment_order');
      break;

    case 'testmode_decline':
      message = t('stripeAutoTopUpCallback.testmode_decline');
      break;

    case 'transaction_not_allowed':
      message = t('stripeAutoTopUpCallback.transaction_not_allowed');
      break;

    case 'try_again_later':
      message = t('stripeAutoTopUpCallback.try_again_later');
      break;

    case 'withdrawal_count_limit_exceeded':
      message = t('stripeAutoTopUpCallback.withdrawal_count_limit_exceeded');
      break;
    case 'missing_client_secret':
      message = 'Chyba při zpracování platby. Chybí parametr client_secret.';
      break;
    case 'inicialization_payment_failed':
      message = 'Chyba při inicializaci platby.';
      break;

    default:
      message = 'Došlo k neočekávané chybě.';
      break;
  }

  return message;
}
</script>
