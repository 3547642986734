<template>
  <div class="flex h-full w-full p-8">
    <div v-if="!isUserError" class="m-auto h-fit">
      <p class="mt-6 text-center text-base leading-7">
        {{ $t('global.facebookPageShare.pages.businessShareCallback.text') }}
      </p>
    </div>
    <div v-if="isUserError" class="m-auto h-fit">
      <p class="mb-2 mt-6 text-center text-base leading-7">
        {{ $t(userError?.text as string) }}
      </p>
      <div class="mb-8 mt-4 flex flex-col justify-items-center gap-2 xl:flex-row">
        <DynamicImage v-for="(image, index) in userError?.images" :src="image.url" :alt="image.alt" :isLocalized="true" :key="index" class="block h-full rounded-lg object-cover object-center md:max-w-[25rem]"></DynamicImage>
      </div>
      <div class="m-5 flex flex-row justify-center gap-4">
        <MainButton type="button" @click="closeWindow" :text="$t('global.facebookPageShare.pages.businessShareCallback.okButton')"> </MainButton>
        <MainButton type="button" class="bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :text="$t('global.facebookPageShare.pages.businessShareCallback.repeatButton')" :hover="false" @click="handleRetry">
        </MainButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import ErrorsCodebook, { IPageShareError } from '@/modules/campaigns/codebooks/ErrorsCodebook';
import { CallbackEnum } from '@/modules/campaigns/enums/CallbackEnum';
import { ErrorEnum } from '@/modules/campaigns/enums/ErrorEnum';
import { LocalStorageKeyEnum } from '@/modules/campaigns/enums/LocalStorageKeyEnum';
import { generateFacebookOauthUrl } from '@/modules/campaigns/utils/generateFacebookOauthUrl';
import { getFacebookBusinessOauthUrl } from '@/modules/campaigns/utils/getFacebookBusinessOauthUrl';
import { getRedirectUrl } from '@/modules/campaigns/utils/getRedirectUrl';
import DynamicImage from '@/modules/global/components/DynamicImage.vue';
import MainButton from '@/modules/global/MainButton.vue';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { generateRandomString } from '@/utils/generateRandomString';

const route = useRoute();
const loadingStore = useLoadingStore();

const isUserError = ref<boolean>(false);
const userError = ref<IPageShareError>();

onMounted(async () => {
  await handleBusinessShareCallback();
});

const handlePageShareError = (errorCode?: string | null) => {
  isUserError.value = true;
  const userErrorObj = ErrorsCodebook.find((error: IPageShareError) => error.code === errorCode);

  userError.value = userErrorObj || ErrorsCodebook.find((error: IPageShareError) => error.code === ErrorEnum.API_GENERAL_ERROR);

  // Remove the state from local storage
  localStorage.removeItem(LocalStorageKeyEnum.FACEBOOK_STATE);
};

const checkFacebookStatus = (): boolean => {
  if (route.query.error_reason && route.query.error_reason === 'user_denied') {
    handlePageShareError(ErrorEnum.USER_CANCELED);
    return false;
  } else if (route.query.error) {
    handlePageShareError();
    return false;
  }
  return true;
};

const checkState = (): boolean => {
  // Remove #_=_ from the state => Facebook bug? --TODO correct this
  const regex = /#_=_/g;
  const customQueryState = route.query.state?.toString().replace(regex, '');
  if (!route.query.state) {
    handlePageShareError();
    return false;
  } else if (customQueryState !== localStorage.getItem(LocalStorageKeyEnum.FACEBOOK_STATE)) {
    handlePageShareError();
    return false;
  }
  return true;
};

const checkCode = async (): Promise<boolean> => {
  if (!route.query.code) {
    handlePageShareError();
    return false;
  }

  try {
    const { data } = await api.clientCampaignsCheckAuthCodeForBusinessPermissions({
      authCode: route.query.code as string,
      redirectUri: getRedirectUrl(CallbackEnum.FACEBOOK_BUSINESS),
    });

    if (data.isValid) {
      return true;
    }

    handlePageShareError(data.errorDescription);

    return false;
  } catch (error) {
    handlePageShareError();
    return false;
  }
};

const redirectUserToFacebookPageShare = async () => {
  try {
    const { data } = await api.clientCampaignsGetFBPagesOauthUrl();
    // Create and set state parameter
    const state = generateRandomString();
    localStorage.setItem(LocalStorageKeyEnum.FACEBOOK_STATE, state);

    // Redirect user to Facebook Page Share
    window.location.href = generateFacebookOauthUrl(data.url, CallbackEnum.FACEBOOK_PAGE_SHARE);
    return;
  } catch (error) {
    handlePageShareError();
    return;
  }
};

const handleBusinessShareCallback = async () => {
  loadingStore.updateLoading(true);

  // Check if there is an error in url/state/code
  const facebookStatus = checkFacebookStatus();
  const stateStatus = checkState();
  const codeStatus = await checkCode();

  if (!facebookStatus || !stateStatus || !codeStatus) {
    loadingStore.updateLoading(false);
    return;
  }

  loadingStore.updateLoading(false);

  // Redirect the user to the facebook page share
  await redirectUserToFacebookPageShare();
};

const closeWindow = () => {
  window.close();
};

const handleRetry = async () => {
  isUserError.value = false;
  userError.value = undefined;
  const url = await getFacebookBusinessOauthUrl();
  if (!url) {
    handlePageShareError();
    return;
  }

  // Redirect user to business share
  window.location.href = url;
};
</script>
