<template>
  <p class="mt-5 text-center text-sm text-main xl:text-left">{{ $t('dashboard.problemSectionXmlFeed.incorrectUrlFormatText1') }}</p>
  <ul class="mx-auto mt-1 w-fit list-inside list-disc text-left text-sm text-main xl:ml-0">
    <li>{{ $t('dashboard.problemSectionXmlFeed.incorrectUrlFormatText2') }}</li>
    <li>
      {{ $t('dashboard.problemSectionXmlFeed.incorrectUrlFormatText3') }}
      <ul class="ml-5 list-inside list-disc">
        <li>{{ $t('dashboard.problemSectionXmlFeed.incorrectUrlFormatText4') }}</li>
      </ul>
    </li>
  </ul>
</template>
