import { useJwt } from '@vueuse/integrations/useJwt';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { redirectToCorrectStep } from '@/modules/wizard/utils/redirectToCorrectStep';
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { EnumsWizardStatus } from '../../generated/api';
import router from '../router';

export const handleLoginAndEshopList = async (primeVue, accessToken?: string | null, refreshToken?: string | null, selectedEshopId?: string, theme?: string) => {
    const authStore = useAuthStore();
    const eshopsStore = useEshopsStore();

    if (!accessToken || !refreshToken) {
        throw new Error('Missing tokens');
    }

    // Set user token
    authStore.setUserAccessToken(accessToken);

    // Set refresh token
    authStore.setRefreshToken(refreshToken);

    // Parse JWT and set user information
    await parseJwtAndSetUserInformation(accessToken, primeVue);

    // Set eshops
    const listOfEshopsResponse = await api.clientEshopListGetEshops({
        metadata: {
            accessToken: 'user',
        },
    });

    eshopsStore.setManagedEshops(listOfEshopsResponse.data.eshops);

    // For iframe login
    if (selectedEshopId) {
        const selectedEshop = listOfEshopsResponse.data.eshops?.find((eshop) => eshop.id === selectedEshopId);
        if (selectedEshop) {
            await eshopsStore.setSelectedEshop(selectedEshop);
            await router.push({
                name: FULL_PAGE_ROUTES.RECHARGE_CREDIT_PAGE
            });
        }
    } else if (listOfEshopsResponse.data.eshops?.length === 0) {
        await router.push({
            name: WIZARD_ROUTES.ADD_ESHOP_PAGE,
        });
    } else if (listOfEshopsResponse.data.eshops?.length === 1) {
        await eshopsStore.setSelectedEshop(listOfEshopsResponse.data.eshops[0] || {});
        await redirectToCorrectStep(listOfEshopsResponse.data.eshops[0]?.wizardStatus || EnumsWizardStatus.AddedPixel);
    } else {
        await router.push({
            name: CLIENT_ROUTES.ESHOP_LIST_PAGE,
        });
    }
};

const parseJwtAndSetUserInformation = async (token, primeVue) => {
    const { payload: decodedToken } = useJwt(token);
    const userStore = useUserStore();
    /*
  {
    "email": "svardala.j@gmail.com",
    "nameid": "adc57784-5677-4f56-b718-e6a61b3261d1",
    "TokenType": "UserAccessToken",
    "AuthProvider": "Tanganica",
    "role": [
        "Internal",
        "Test"
    ],
    "nbf": 1704795231,
    "exp": 1704831231,
    "iat": 1704795231,
    "iss": "tanganica.com",
    "aud": "Tanganica-API"
  }
  */
 // TODO AFFILIATE
    userStore.setEmail(decodedToken.value.email);
    userStore.setAffiliate(Array.isArray(decodedToken.value.role) ? decodedToken.value.role.includes('Affil') : decodedToken.value.role === 'Affil');
    userStore.setRole(decodedToken.value.role);
    userStore.setAuthProvider(decodedToken.value.AuthProvider);
    await userStore.setLanguage(decodedToken.value.Locale, primeVue);
    return;
};
