import {
    CpuChipIcon,
    ComputerDesktopIcon,
    CubeIcon,
    CodeBracketIcon,
    ChartBarIcon,
    AcademicCapIcon,
    CursorArrowRippleIcon,
    ClipboardIcon,
    ArrowTrendingUpIcon,
    CurrencyDollarIcon,
    HashtagIcon,
    MagnifyingGlassIcon,
    EnvelopeIcon,
    LightBulbIcon,
    AdjustmentsVerticalIcon,
    ChartPieIcon,
    PresentationChartLineIcon,
    CogIcon,
  } from '@heroicons/vue/24/outline';
  
  const aiQuestionTemplates = [
    {
      icon: CpuChipIcon,
      title: 'aiassistant.SetPNOSettingsHeader',
      question: 'aiassistant.SetPNOSettingsDescription',
    },
    {
      icon: ComputerDesktopIcon,
      title: 'aiassistant.AdFormatsHeader',
      question: 'aiassistant.AdFormatsDescription',
    },
    {
      icon: CubeIcon,
      title: 'aiassistant.PrepareMaterialsHeader',
      question: 'aiassistant.PrepareMaterialsDescription',
    },
    {
      icon: CodeBracketIcon,
      title: 'aiassistant.ImageDimensionsHeader',
      question: 'aiassistant.ImageDimensionsDescription',
    },
    {
      icon: ChartBarIcon,
      title: 'aiassistant.EvaluateResultsHeader',
      question: 'aiassistant.EvaluateResultsDescription',
    },
    {
      icon: AcademicCapIcon,
      title: 'aiassistant.LearningPhaseHeader',
      question: 'aiassistant.LearningPhaseDescription',
    },
    {
      icon: CogIcon,
      title: 'aiassistant.ShopSetupCheckHeader',
      question: 'aiassistant.ShopSetupCheckDescription',
    },
    {
      icon: CursorArrowRippleIcon,
      title: 'aiassistant.TanganicaPixelHeader',
      question: 'aiassistant.TanganicaPixelDescription',
    },
    {
      icon: ClipboardIcon,
      title: 'aiassistant.ProductStatusHeader',
      question: 'aiassistant.ProductStatusDescription',
    },
    {
      icon: ArrowTrendingUpIcon,
      title: 'aiassistant.IncreaseTrafficHeader',
      question: 'aiassistant.IncreaseTrafficDescription',
    },
    {
      icon: CurrencyDollarIcon,
      title: 'aiassistant.OptimizeBudgetHeader',
      question: 'aiassistant.OptimizeBudgetDescription',
    },
    {
      icon: HashtagIcon,
      title: 'aiassistant.SocialMediaHeader',
      question: 'aiassistant.SocialMediaDescription',
    },
    {
      icon: MagnifyingGlassIcon,
      title: 'aiassistant.ImproveSEOHeader',
      question: 'aiassistant.ImproveSEODescription',
    },
    {
      icon: EnvelopeIcon,
      title: 'aiassistant.EmailMarketingHeader',
      question: 'aiassistant.EmailMarketingDescription',
    },
    {
      icon: LightBulbIcon,
      title: 'aiassistant.EcommerceTrendsHeader',
      question: 'aiassistant.EcommerceTrendsDescription',
    },
    {
      icon: AdjustmentsVerticalIcon,
      title: 'aiassistant.OptimizeProductPagesHeader',
      question: 'aiassistant.OptimizeProductPagesDescription',
    },
    {
      icon: ChartPieIcon,
      title: 'aiassistant.IncreaseConversionRateHeader',
      question: 'aiassistant.IncreaseConversionRateDescription',
    },
    {
      icon: PresentationChartLineIcon,
      title: 'aiassistant.IncreaseConversionRatioHeader',
      question: 'aiassistant.IncreaseConversionRatioDescription',
    },
  ];
  
  export default aiQuestionTemplates;
  