<template>
    <div class="div-primary mx-auto gap-x-4 md:grid md:grid-cols-2 xl:grid-cols-4">
        <div class="col-span-2 mb-4 text-center xl:col-span-4 xl:text-left">
            <div class="items-baseline sm:flex">
                <h1 class="text-center text-xl font-bold text-main sm:text-left md:text-3xl 2xl:text-4xl" v-html="$t('dashboard.statistics.headerOne')"></h1>
                <h2 class="ml-3 text-center text-main sm:text-left" v-html="$t('dashboard.statistics.headerTwo')"></h2>
            </div>
        </div>
        <div v-for="stat in stats" :key="stat.title" class="relative mx-auto mt-8 h-32 w-full max-w-sm rounded-2xl border md:max-w-5xl">
            <div class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2">
                <h1 :class="['text-center text-lg font-bold md:text-xl', stat.color]">
                    {{ stat.title }}
                </h1>
                <h2 class="mt-4 text-center text-3xl text-main">
                    <LocalizedValue :value="stat.data"></LocalizedValue>
                </h2>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';

const toast = useToast();
const { t } = useI18n();
const eshopStore = useEshopsStore();
const statistics = ref();
onMounted(async () => {
    await initStatistics();
});

watch(
    () => eshopStore.selectedEshop.id,
    async () => {
        await initStatistics();
    }
);

const initStatistics = async () => {
    try {
        const { data } = await api.clientDashboardGetStatistics();
        statistics.value = data;
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    }
};

const stats = computed(() => [
    {
        title: t('dashboard.statistics.totalExpenses'),
        data: statistics.value?.totalExpenses,
        color: 'text-red-600',
    },
    {
        title: t('dashboard.statistics.generatedRevenue'),
        data: statistics.value?.generatedRevenue,
        color: 'text-[#359F03]',
    },
    {
        title: t('dashboard.statistics.orders'),
        data: statistics.value?.orderCount,
        color: 'text-main',
    },
    {
        title: t('dashboard.statistics.cos'),
        data: statistics.value?.cos,
        color: 'text-main',
    },
]);
</script>
