<template>
  <div class="lg:grid lg:grid-cols-2">
    <EshopSettingsSkeleton v-if="loading" />
  </div>
  <div class="lg:grid lg:grid-cols-2">
    <!-- TNGCA PIXEL -->
    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-100 duration-1000']">
      <TanganicaPixel />
    </div>

    <!-- XML FEED -->
    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-200 duration-1000']">
      <XMLFeed />
    </div>

    <!-- PNO -->
    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-300 duration-1000']">
      <COS />
    </div>

    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-500 duration-1000']">
      <Limit />
    </div>

    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-700 duration-1000']">
      <AddManager />
    </div>

    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-1000 duration-1000']">
      <UsersWithAssets />
    </div>

    <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary transition-opacity delay-1000 duration-1000']">
      <EmailNotification />
    </div>

    <!-- BUDGET future version
    <div :class="[transition ? 'opacity-100' : 'opacity-0','div-primary transition-opacity duration-1000 delay-400']">
        <Budget v-if="isLoaded" :budgetValue="budgetValue" :budgetType="budgetType"/>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import COS from '@/modules/eshopSettings/components/COS.vue';
import EshopSettingsSkeleton from '@/modules/eshopSettings/components/EshopSettingsSkeleton.vue';
import Limit from '@/modules/eshopSettings/components/Limit.vue';
import TanganicaPixel from '@/modules/eshopSettings/components/TanganicaPixel.vue';
import XMLFeed from '@/modules/eshopSettings/components/XmlFeed.vue';
import { sleep } from '@/utils/sleep';
import AddManager from '../components/AddManager.vue';
import EmailNotification from '../components/EmailNotification.vue';
import UsersWithAssets from '../components/UsersWithAssets.vue';

// Transition
const transition = ref(false);
const loading = ref(false);

onMounted(async () => {
  loading.value = true;
  await sleep(1500);
  loading.value = false;
  transition.value = true;
});
</script>
