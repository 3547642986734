<template>
    <div class="'div-primary transition-opacity delay-100 duration-500">
        <div class="mx-auto max-w-full px-4 py-12 sm:px-6 sm:py-16 lg:px-8">
            <div class="mx-auto max-w-full divide-y-2 divide-gray-200">
                <h2 class="text-center text-3xl font-extrabold text-gray-900 dark:text-[#cbd5e1] sm:text-4xl">
                    {{ $t('userSettings.userSettings.title') }}
                </h2>
                <dl class="mt-6 space-y-6 divide-y divide-gray-200">
                    <ChangeLanguage />
                    <ChangePhoneNumber />
                    <ChangeEmailComponent />
                    <ChangePasswordComponent v-if="userStore.getAuthProvider === 'Tanganica'" />
                </dl>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ChangeEmailComponent from '@/modules/userSettings/components/ChangeEmail.vue';
import ChangeLanguage from '@/modules/userSettings/components/ChangeLanguage.vue';
import ChangePasswordComponent from '@/modules/userSettings/components/ChangePassword.vue';
import { useUserStore } from '@/stores/user';
import ChangePhoneNumber from '../components/ChangePhoneNumber.vue';

const userStore = useUserStore();
</script>
