import { CallbackEnum } from '@/modules/campaigns/enums/CallbackEnum';
import { LocalStorageKeyEnum } from '@/modules/campaigns/enums/LocalStorageKeyEnum';
import { getRedirectUrl } from '@/modules/campaigns/utils/getRedirectUrl';
import { generateRandomString } from '@/utils/generateRandomString';

export const generateFacebookOauthUrl = (url: string, callbackType: CallbackEnum): string => {
  if (!url) return '';

  const REDIRECT_URI_PARAM = 'redirect_uri';
  const STATE_PARAM = 'state';

  const redirectUri = new URL(url);
  const redirectUriParameters = new URLSearchParams(redirectUri.searchParams);

  // Create and set redirect URI parameter
  redirectUriParameters.set(REDIRECT_URI_PARAM, getRedirectUrl(callbackType));

  // Create and set state parameter
  const state = generateRandomString();
  localStorage.setItem(LocalStorageKeyEnum.FACEBOOK_STATE, state);
  redirectUriParameters.set(STATE_PARAM, state);

  redirectUri.search = redirectUriParameters.toString();

  return redirectUri.toString();
};
