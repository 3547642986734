export const calculateDateRange = (value) => {
  const today = new Date();
  const yesterday = subtractDaysFromDate(today, 1);
  let startDate;
  let endDate;

  switch (value) {
    case "today":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
        999
      );
      break;
    case "yesterday":
      startDate = new Date(
        yesterday.getFullYear(),
        yesterday.getMonth(),
        yesterday.getDate(),
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        yesterday.getFullYear(),
        yesterday.getMonth(),
        yesterday.getDate(),
        23,
        59,
        59,
        999
      );
      break;
    case "last7Days":
      startDate = subtractDaysFromDate(yesterday, 6);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(yesterday);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "lastMonth":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
        999
      );
      break;
    case "last3Month":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate(),
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
        999
      );
      break;
    case "last6Month":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate(),
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
        999
      );
      break;
    case "lastYear":
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1,
        23,
        59,
        59,
        999
      );
      startDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
      break;
    default:
      startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1,
        0,
        0,
        0,
        0
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        0,
        23,
        59,
        59,
        999
      );
      break;
  }

  return { startDate, endDate };
};

const subtractDaysFromDate = (date, daysToSubtract) => {
  const result = new Date(date);
  result.setDate(result.getDate() - daysToSubtract);
  return result;
};
