<template>
  <ContentBlock :menuCardItems="menuCardItems" v-if="router.currentRoute.value.name === AICONTENT_ROUTES.BLOG" />
  <RouterView v-else />
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ContentBlock from '@/modules/aicontent/components/ContentBlock.vue';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
const { t } = useI18n();
const router = useRouter();
const menuCardItems = [
  {
    name: t('socialMedia.GenerateBlogGivenTopicHeader'),
    description: t('socialMedia.GenerateBlogGivenTopicDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_BLOG,
    type: 'GenerateBlogGivenTopic',
    imgsrc: '/socialContent/BlogGivenTopic.png',
  },
  {
    name: t('socialMedia.GenerateBlogStrategyHeader'),
    description: t('socialMedia.GenerateBlogStrategyDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_BLOG,
    type: 'GenerateBlogStrategy',
    imgsrc: '/socialContent/BlogStrategy.png',
  },
  {
    name: t('socialMedia.GenerateIdeasForBlogArticleHeader'),
    description: t('socialMedia.GenerateIdeasForBlogArticleDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_BLOG,
    type: 'GenerateIdeasForBlogArticle',
    imgsrc: '/socialContent/BlogIdeas.png',
  },
  {
    name: t('socialMedia.GenerateSeoForBlogHeader'),
    description: t('socialMedia.GenerateSeoForBlogDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_BLOG,
    type: 'GenerateSeoForBlog',
    imgsrc: '/socialContent/BlogSeo.png',
  },
];
</script>
