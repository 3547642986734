<template>
  <Dialog v-model:visible="showEditDialog" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <div v-if="store" class="flex items-center justify-center gap-10">
        <div v-for="(step, index) in store.steps" :key="index" @click="selectStep(index)" class="flex h-20 w-20 cursor-pointer flex-col justify-center rounded-xl border bg-white text-center shadow hover:border-main sm:h-32 sm:w-32">
          <span class="mb-1 text-sm text-main sm:text-base">{{ $t(step.title) }}</span>
          <component :is="step.icon" class="mx-auto h-10 w-10 sm:h-12 sm:w-12"></component>
        </div>
      </div>
    </div>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import { loadWizardStore } from '@/modules/campaigns/utils/loadWizardStore';
import {EnumsMarketingChannel} from "../../../../generated/api";

const props = defineProps({
  campaign: {
    type: String as PropType<EnumsMarketingChannel>,
    required: false,
    default: EnumsMarketingChannel.Google,
  },
  format: {
    type: Object as PropType<IFormat>,
    required: false,
    default: null,
  },
});

const showEditDialog = defineModel<boolean>({ required: true });

const router = useRouter();

let store = null;

if (props.format) {
  store = await loadWizardStore(props.campaign, props.format?.type);
}

const selectStep = (index: number) => {
  if (!store) return;

  router.push({
    name: store.steps[index].route.name,
    params: { campaign: props.campaign, format: props.format?.type, step: store.steps[index].route.params.step },
  });
};
</script>
