import { ref } from 'vue';

const blocked = ref(false);

export function useBlockDocumentUI() {
    const setBlocked = (blockState: boolean) => {
        blocked.value = blockState;
    };

    return {
        blocked,
        setBlocked,
    };
}
