type Metric = {
    value: number;
    type: string;
    currency?: string;
};

interface ICurrencyMetrics {
    cos: Metric;
    conversionFraction: Metric;
    orderValue: Metric;
    averageDailyExpense: Metric;
    averageDailyCashFlow: Metric;
}

type Currencies = 'CZK' | 'EUR';

export type ICompetitiveMetrics = {
    [key in Currencies]: ICurrencyMetrics;
};

const CompetitiveMetrics: ICompetitiveMetrics = {
    CZK: {
        cos: {
            value: 0.1423,
            type: 'Percent',
        },
        conversionFraction: {
            value: 0.0323,
            type: 'Percent',
        },
        orderValue: {
            value: 1250,
            type: 'Money',
            currency: 'CZK',
        },
        averageDailyExpense: {
            value: 843,
            type: 'Money',
            currency: 'CZK',
        },
        averageDailyCashFlow: {
            value: 5924,
            type: 'Money',
            currency: 'CZK',
        },
    },
    EUR: {
        cos: {
            value: 0.1423,
            type: 'Percent',
        },
        conversionFraction: {
            value: 0.0323,
            type: 'Percent',
        },
        orderValue: {
            value: 51,
            type: 'Money',
            currency: 'EUR',
        },
        averageDailyExpense: {
            value: 34,
            type: 'Money',
            currency: 'EUR',
        },
        averageDailyCashFlow: {
            value: 242,
            type: 'Money',
            currency: 'EUR',
        },
    },
};

export default CompetitiveMetrics;
