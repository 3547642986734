<template>
  <div class="">
    <div class="mb-5 mt-2 text-center">
      <p>{{ t('productPrioritization.productInfo') }}</p>
    </div>
    <div class="mb-5 mt-10 text-center text-main">
      <p>{{ t('productPrioritization.productInfoText2') }}</p>
    </div>
    <DataTable stripedRows showGridlines tableStyle="min-width: 50rem" class="!border-b-none" id="productsTable">
      <ColumnGroup type="header">
        <Row>
          <Column :key="10">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ t('productPrioritization.format') }}</span>
              </div>
            </template>
          </Column>
          <Column v-for="(status, index) in statuses" :key="index">
            <template #header>
              <div
                v-tooltip.top="{
                  value: getTooltipByStatus(status.value),
                  class: 'text-center text-xs w-fit border-none',
                  escape: false,
                }"
              >
                <span
                  :class="[
                    'text-center text-xs md:text-sm',
                    { 'text-green-600': status.value === 'success', 'text-main': status.value === 'waiting', 'text-orange-600': status.value === 'paused', 'text-red-600': status.value === 'error', 'text-red-400': status.value === 'invalid' },
                  ]"
                  >{{ status.label }}</span
                >
                <CheckCircleIcon v-if="status.value === 'success'" class="mx-auto h-5 w-5 text-center text-green-600"></CheckCircleIcon>
                <ClockIcon v-else-if="status.value === 'waiting'" class="mx-auto h-5 w-5 text-center text-main"></ClockIcon>
                <PauseCircleIcon v-else-if="status.value === 'paused'" class="mx-auto h-5 w-5 text-center text-orange-600"></PauseCircleIcon>
                <MinusCircleIcon v-else-if="status.value === 'invalid'" class="mx-auto h-5 w-5 text-center text-red-400"></MinusCircleIcon>
                <XCircleIcon v-else class="mx-auto h-5 w-5 text-center text-red-600"></XCircleIcon>
              </div>
            </template>
          </Column>
        </Row>
        <Row>
          <Column :key="0">
            <template #header>
              <div class="">
                <img :src="$filePath + '/logos/google-G.png'" alt="image" class="h-8 w-8 rounded-full" />
              </div>
            </template>
          </Column>
          <Column :key="1">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.googleApproved }}</span>
              </div>
            </template>
          </Column>
          <Column :key="2">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.googleDenied }}</span>
              </div>
            </template>
          </Column>
          <Column :key="3">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.googlePending }}</span>
              </div>
            </template>
          </Column>
          <Column :key="4">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.googleUnadvertised }}</span>
              </div>
            </template>
          </Column>
          <column :key="5">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.googleInvalid }}</span>
              </div>
            </template>
          </column>
        </Row>
        <Row>
          <Column :key="0">
            <template #header>
              <div class="">
                <img :src="$filePath + '/logos/meta-M.png'" alt="image" class="h-auto w-8" />
              </div>
            </template>
          </Column>
          <Column :key="1">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.metaApproved }}</span>
              </div>
            </template>
          </Column>
          <Column :key="2">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.metaDenied }}</span>
              </div>
            </template>
          </Column>
          <Column :key="3">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.metaPending }}</span>
              </div>
            </template>
          </Column>
          <Column :key="4">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.metaUnadvertised }}</span>
              </div>
            </template>
          </Column>
          <Column :key="5">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.metaInvalid }}</span>
              </div>
            </template>
          </Column>
        </Row>
        <Row>
          <Column :key="0">
            <template #header>
              <div class="">
                <img :src="$filePath + '/logos/Microsoft.png'" alt="image" class="h-8 w-8" />
              </div>
            </template>
          </Column>
          <Column :key="1">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.microsoftApproved }}</span>
              </div>
            </template>
          </Column>
          <Column :key="2">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.microsoftDenied }}</span>
              </div>
            </template>
          </Column>
          <Column :key="3">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.microsoftPending }}</span>
              </div>
            </template>
          </Column>
          <Column :key="4">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.microsoftUnadvertised }}</span>
              </div>
            </template>
          </Column>
          <Column :key="5">
            <template #header>
              <div class="">
                <span class="text-center text-xs md:text-sm">{{ productSummary?.microsoftInvalid }}</span>
              </div>
            </template>
          </Column>
        </Row>
      </ColumnGroup>
      <Column field="product" class="!border-none" />
    </DataTable>
    <div class="mt-2">
      <p>
        {{ t('productPrioritization.productSummary') }} <strong>{{ productSummary?.total }}</strong>
      </p>
    </div>

    <div class="mb-5 mt-10 text-center text-main">
      <p>{{ t('productPrioritization.productInfoText3') }}</p>
    </div>
    <div class="my-5 items-center gap-4 md:flex">
      <Button type="button" :label="$t('productPrioritization.productAction')" size="small" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
      <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>

    <div class="mx-auto overflow-auto md:max-w-[70vw] lg:max-w-[78vw]">
      <DataTable
        :loading="loadingTable"
        v-model:selection="selectedProduct"
        :select-all="false"
        :value="products"
        v-model:filters="filters"
        dataKey="itemId"
        showGridlines
        stripedRows
        size="small"
        paginator
        filterDisplay="row"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        ref="dt"
        :first="first"
        :lazy="true"
        :totalRecords="totalRecords"
        @selectAllChange="onSelectAllChange($event)"
        :selectAll="allSelected"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        :pt-options="{ mergeProps: true }"
        :pt="{
          column: {
            headercheckboxwrapper: {
              class: ['cursor-pointer inline-flex relative select-none align-bottom hidden', 'w-6 h-6'],
            },
          },
        }"
      >
        <Column selectionMode="multiple" headerStyle="width: 3rem;"></Column>
        <Column :filterMatchModeOptions="matchModeOptions" sortable field="title" :header="$t('productPrioritization.productNameLabel')" style="min-width: 13rem">
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              size="small"
              v-model="filterModel.value"
              type="text"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              :placeholder="$t('productPrioritization.productSearchByName')"
              :pt-options="{ mergeProps: true }"
              :pt="{
                root: ({ props, context }) => ({
                  class: [
                    'w-full',
                    'm-0',
                    'placeholder:!text-gray-500',
                    '!rounded-md',
                    {
                      'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                    },
                    {
                      '!text-xs px-2 !py-1': props.size === 'small',
                      'p-1 sm:!p-1 !text-xs': props.size == null,
                    },
                  ],
                }),
              }"
            />
          </template>
        </Column>
        <Column sortable :showFilterMenu="false" field="category" :header="$t('productPrioritization.productCategoryLabel')" style="min-width: 13rem">
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              size="small"
              v-model="filterModel.value"
              :options="allCategories"
              filter
              @change="filterCallback()"
              class="p-column-filter"
              :placeholder="$t('productPrioritization.productSearchByCategory')"
              :pt-options="{ mergeProps: true }"
              :pt="{
                label: ({ props }) => ({
                  class: [
                    '!p-1 transition duration-200 ',
                    {
                      '!p-1': props.display !== 'chip' && (props?.modelValue == null || false),
                      '!py-1.5 px-3': props.display == 'chip' && props?.modelValue !== null,
                      'p-1': props.display == 'chip' && props?.modelValue == null,
                    },
                  ],
                }),
              }"
            />
          </template>
          <template #body="slotProps">
            <span v-if="slotProps?.data?.category" class="text-xs">{{ slotProps.data.category.join(' > ') }}</span>
          </template>
        </Column>
        <Column :showFilterMenu="false" filt field="advertiseGoogle" :header="$t('productPrioritization.productAdvertisedGoogle')" style="width: 9rem">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              style="width: 9rem"
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
          <template #body="slotProps">
            <Dropdown
              @change="changeAdvertiseStatus(slotProps.data.itemId, slotProps.data.advertiseGoogle, 'google')"
              v-model="slotProps.data.advertiseGoogle"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
        </Column>
        <Column :showFilterMenu="false" field="advertiseFacebook" :header="$t('productPrioritization.productAdvertisedFacebook')" style="width: 9rem">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              style="width: 9rem"
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
          <template #body="slotProps">
            <Dropdown
              @change="changeAdvertiseStatus(slotProps.data.itemId, slotProps.data.advertiseFacebook, 'facebook')"
              v-model="slotProps.data.advertiseFacebook"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
        </Column>
        <Column :showFilterMenu="false" field="advertiseMicrosoft" :header="$t('productPrioritization.productAdvertisedMicrosoft')" style="width: 9rem">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              style="width: 9rem"
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
          <template #body="slotProps">
            <Dropdown
              @change="changeAdvertiseStatus(slotProps.data.itemId, slotProps.data.advertiseMicrosoft, 'microsoft')"
              v-model="slotProps.data.advertiseMicrosoft"
              :options="advertisingStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder=""
              class="md:max-w-11rem w-full"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            />
          </template>
        </Column>
        <Column :showFilterMenu="false" field="statusGoogle" :header="$t('productPrioritization.productValidOnGoogle')" style="width: 5rem" class="text-center">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statuses"
              placeholder=""
              class="p-column-filter"
              optionValue="value"
              optionLabel="label"
              style="min-width: 9rem"
              :showClear="false"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <span>{{ slotProps.option.label }}</span>
                  <CheckCircleIcon v-if="slotProps.option.value === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
                  <ClockIcon v-else-if="slotProps.option.value === 'waiting'" class="mx-auto h-5 w-5 cursor-pointer text-center text-main"></ClockIcon>
                  <PauseCircleIcon v-else-if="slotProps.option.value === 'paused'" class="mx-auto h-5 w-5 cursor-pointer text-center text-orange-600"></PauseCircleIcon>
                  <MinusCircleIcon v-else-if="slotProps.option.value === 'invalid'" class="mx-auto h-5 w-5 cursor-pointer text-center text-red-400"></MinusCircleIcon>
                  <XCircleIcon v-else class="mx-auto h-5 w-5 cursor-pointer text-center text-red-600 hover:text-red-400"></XCircleIcon>
                </div>
              </template>
            </Dropdown>
          </template>
          <template #body="slotProps">
            <CheckCircleIcon v-if="setProductGoogleStatus(slotProps.data.gmcProductStatus) === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductGoogleStatus(slotProps.data.gmcProductStatus) === 'waiting'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasGoogleIssue,
              }"
            >
              <ClockIcon
                @click="showErrorDetail(slotProps.data.hasGoogleIssue, EnumsAdvertisementPlatform.Google, slotProps.data.id)"
                :class="[slotProps.data.hasGoogleIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-main']"
              >
              </ClockIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductGoogleStatus(slotProps.data.gmcProductStatus) === 'paused'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasGoogleIssue,
              }"
            >
              <PauseCircleIcon
                @click="showErrorDetail(slotProps.data.hasGoogleIssue, EnumsAdvertisementPlatform.Google, slotProps.data.id)"
                :class="[slotProps.data.hasGoogleIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-orange-600']"
              ></PauseCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductGoogleStatus(slotProps.data.gmcProductStatus) === 'invalid'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasGoogleIssue,
              }"
            >
              <MinusCircleIcon
                @click="showErrorDetail(slotProps.data.hasGoogleIssue, EnumsAdvertisementPlatform.Google, slotProps.data.id)"
                :class="[slotProps.data.hasGoogleIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-red-400']"
              ></MinusCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasGoogleIssue,
              }"
            >
              <XCircleIcon
                :class="[slotProps.data.hasGoogleIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-red-600']"
                @click="showErrorDetail(slotProps.data.hasGoogleIssue, EnumsAdvertisementPlatform.Google, slotProps.data.id)"
              >
              </XCircleIcon>
            </div>
          </template>
        </Column>
        <Column :showFilterMenu="false" field="statusFacebook" :header="$t('productPrioritization.productValidOnFacebook')" class="text-center">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statuses"
              placeholder=""
              class="p-column-filter"
              optionValue="value"
              optionLabel="label"
              style="min-width: 9rem"
              :showClear="false"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <span>{{ slotProps.option.label }}</span>
                  <CheckCircleIcon v-if="slotProps.option.value === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
                  <div v-else-if="slotProps.option.value === 'waiting'" class="mx-auto w-fit">
                    <ClockIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-main"></ClockIcon>
                  </div>
                  <div v-else-if="slotProps.option.value === 'paused'" class="mx-auto w-fit">
                    <PauseCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-orange-600"></PauseCircleIcon>
                  </div>
                  <div v-else-if="slotProps.option.value === 'invalid'" class="mx-auto w-fit">
                    <MinusCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-red-400"></MinusCircleIcon>
                  </div>
                  <div v-else class="mx-auto w-fit">
                    <XCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-red-600 hover:text-red-400"></XCircleIcon>
                  </div>
                </div>
              </template>
            </Dropdown>
          </template>
          <template #body="slotProps">
            <CheckCircleIcon v-if="setProductFacebookStatus(slotProps.data.fbProductStatus) === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductFacebookStatus(slotProps.data.fbProductStatus) === 'waiting'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMetaIssue,
              }"
            >
              <ClockIcon
                @click="showErrorDetail(slotProps.data.hasMetaIssue, EnumsAdvertisementPlatform.Meta, slotProps.data.id)"
                :class="[slotProps.data.hasMetaIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-main']"
              ></ClockIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductFacebookStatus(slotProps.data.fbProductStatus) === 'paused'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMetaIssue,
              }"
            >
              <PauseCircleIcon
                @click="showErrorDetail(slotProps.data.hasMetaIssue, EnumsAdvertisementPlatform.Meta, slotProps.data.id)"
                :class="[slotProps.data.hasMetaIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 text-center text-orange-600']"
              ></PauseCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductFacebookStatus(slotProps.data.fbProductStatus) === 'invalid'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMetaIssue,
              }"
            >
              <MinusCircleIcon
                @click="showErrorDetail(slotProps.data.hasMetaIssue, EnumsAdvertisementPlatform.Meta, slotProps.data.id)"
                :class="[slotProps.data.hasMetaIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 text-center text-red-400']"
              ></MinusCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMetaIssue,
              }"
            >
              <XCircleIcon
                :class="[slotProps.data.hasMetaIssue ? 'animate-pulse cursor-pointer ' : '', 'mx-auto h-5 w-5 text-center text-red-600 hover:text-red-400']"
                @click="showErrorDetail(slotProps.data.hasMetaIssue, EnumsAdvertisementPlatform.Meta, slotProps.data.id)"
              ></XCircleIcon>
            </div>
          </template>
        </Column>
        <Column :showFilterMenu="false" field="statusMicrosoft" :header="$t('productPrioritization.productValidOnMicrosoft')" class="text-center">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statuses"
              placeholder=""
              class="p-column-filter"
              optionValue="value"
              optionLabel="label"
              style="min-width: 9rem"
              :showClear="false"
              :pt-options="{ mergeProps: true }"
              :pt="{
                input: () => ({
                  class: ['sm:!p-1 !text-xs'],
                }),
                item: ({ context }) => ({
                  class: [
                    '!p-1 !py-2',
                    'dark:text-white/80 dark:hover:bg-gray-800',
                    'hover:text-gray-700 hover:bg-gray-200',
                    {
                      'text-gray-700': !context.focused && !context.selected,
                      'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                      'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                    },
                  ],
                }),
                list: '!py-2 !text-xs',
              }"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <span>{{ slotProps.option.label }}</span>
                  <CheckCircleIcon v-if="slotProps.option.value === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
                  <div v-else-if="slotProps.option.value === 'waiting'" class="mx-auto w-fit">
                    <ClockIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-main"></ClockIcon>
                  </div>
                  <div v-else-if="slotProps.option.value === 'paused'" class="mx-auto w-fit">
                    <PauseCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-orange-600"></PauseCircleIcon>
                  </div>
                  <div v-else-if="slotProps.option.value === 'invalid'" class="mx-auto w-fit">
                    <MinusCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-red-400"></MinusCircleIcon>
                  </div>
                  <div v-else class="mx-auto w-fit">
                    <XCircleIcon class="mx-auto h-5 w-5 cursor-pointer text-center text-red-600 hover:text-red-400"></XCircleIcon>
                  </div>
                </div>
              </template>
            </Dropdown>
          </template>
          <template #body="slotProps">
            <CheckCircleIcon v-if="setProductMicrosoftStatus(slotProps.data.mcProductStatus) === 'success'" class="mx-auto h-5 w-5 cursor-pointer text-center text-green-600"></CheckCircleIcon>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductMicrosoftStatus(slotProps.data.mcProductStatus) === 'waiting'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMicrosoftIssue,
              }"
            >
              <ClockIcon
                @click="showErrorDetail(slotProps.data.hasMicrosoftIssue, EnumsAdvertisementPlatform.Microsoft, slotProps.data.id)"
                :class="[slotProps.data.hasMicrosoftIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 cursor-pointer text-center text-main']"
              ></ClockIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductMicrosoftStatus(slotProps.data.mcProductStatus) === 'paused'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMicrosoftIssue,
              }"
            >
              <PauseCircleIcon
                @click="showErrorDetail(slotProps.data.hasMicrosoftIssue, EnumsAdvertisementPlatform.Microsoft, slotProps.data.id)"
                :class="[slotProps.data.hasMicrosoftIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 text-center text-orange-600']"
              ></PauseCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else-if="setProductMicrosoftStatus(slotProps.data.mcProductStatus) === 'invalid'"
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMicrosoftIssue,
              }"
            >
              <MinusCircleIcon
                @click="showErrorDetail(slotProps.data.hasMicrosoftIssue, EnumsAdvertisementPlatform.Microsoft, slotProps.data.id)"
                :class="[slotProps.data.hasMicrosoftIssue ? 'animate-pulse cursor-pointer' : '', 'mx-auto h-5 w-5 text-center text-red-400']"
              ></MinusCircleIcon>
            </div>
            <div
              class="mx-auto w-fit"
              v-else
              v-tooltip.left="{
                value: $t('productPrioritization.clickForDetail'),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
                disabled: !slotProps.data.hasMicrosoftIssue,
              }"
            >
              <XCircleIcon
                :class="[slotProps.data.hasMicrosoftIssue ? 'animate-pulse cursor-pointer ' : '', 'mx-auto h-5 w-5 text-center text-red-600 hover:text-red-400']"
                @click="showErrorDetail(slotProps.data.hasMicrosoftIssue, EnumsAdvertisementPlatform.Microsoft, slotProps.data.id)"
              ></XCircleIcon>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <Dialog v-model:visible="visibleErrorDetail" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div class="">
      <p v-if="errorDetail?.tanganicaIssues?.length > 0" class="mb-2 text-left text-base font-bold text-main">{{ $t('productPrioritization.reasonsForDisapproval') }}</p>
      <div v-if="errorDetail?.tanganicaIssues?.length > 0" class="mb-5 rounded-lg bg-gray-50 p-3 shadow">
        <div v-for="(item, index) in errorDetail.tanganicaIssues" :key="index" class="mt-5 rounded-lg bg-white p-3 shadow first:mt-0">
          <div class="mb-2 flex items-center justify-between">
            <p class="text-left text-base font-bold text-main">{{ item.description }}</p>
            <Tag
              class="mr-3"
              v-tooltip.left="{
                value: getTooltipByCode(item.servability),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
              }"
              :key="item.servability"
              :severity="item.servability"
              :value="getTanganicaLabelByCode(item.servability)"
              :class="getTanganicaStatusColorByCode(item.servability)"
            ></Tag>
          </div>
          <ul class="ml-5 list-disc">
            <li v-if="item.attribute">
              <div class="flex items-center">
                <span class="text-left text-sm text-main">{{ $t('productPrioritization.attributeInfo') }}</span>
                <span class="ml-2 text-left text-sm text-main">{{ item.attribute }}</span>
              </div>
            </li>
            <li>
              <span class="text-left text-sm text-main">{{ item.detail }}</span>
            </li>
            <ul v-if="item.documentation" class="">
              <li>
                <p class="text-left text-sm text-gray-500">
                  {{ $t('productPrioritization.helpMistake') }}
                  <span class="cursor-pointer text-gray-700 hover:underline"
                    ><a :href="item.documentation" target="_blank">{{ $t('productPrioritization.helpMistakeHere') }}</a></span
                  >
                </p>
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <p v-if="errorDetail?.platformIssues?.length > 0" class="mb-2 text-left text-base font-bold text-main">{{ $t('productPrioritization.reasonsForInvalidity') }}</p>
      <div v-if="errorDetail?.platformIssues?.length > 0" class="rounded-lg bg-gray-50 p-3 shadow">
        <div v-for="(item, index) in errorDetail.platformIssues" :key="index" class="mt-5 rounded-lg bg-white p-3 shadow first:mt-0">
          <div class="mb-2 flex items-center justify-between">
            <p class="text-left text-base font-bold text-main">{{ item.description }}</p>
            <Tag
              class="mr-3"
              v-tooltip.left="{
                value: getTooltipByCode(item.servability),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
              }"
              :key="item.servability"
              :severity="item.servability"
              :value="getTanganicaLabelByCode(item.servability)"
              :class="getTanganicaStatusColorByCode(item.servability)"
            ></Tag>
          </div>
          <ul class="ml-5 list-disc">
            <li v-if="item.attribute">
              <div class="flex items-center">
                <span class="text-left text-sm text-main">{{ $t('productPrioritization.attributeInfo') }}</span>
                <span class="ml-2 text-left text-sm text-main">{{ item.attribute }}</span>
              </div>
            </li>
            <li>
              <span class="text-left text-sm text-main">{{ item.detail }}</span>
            </li>
            <ul v-if="item.documentation" class="">
              <li>
                <p class="text-left text-sm text-gray-500">
                  {{ $t('productPrioritization.helpMistake') }}
                  <span class="cursor-pointer text-gray-700 hover:underline"
                    ><a :href="item.documentation" target="_blank">{{ $t('productPrioritization.helpMistakeHere') }}</a></span
                  >
                </p>
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <p v-if="errorDetail?.platformIssues?.length < 1 && errorDetail?.tanganicaIssues?.length < 1" class="text-left text-base font-bold text-main">{{ errorDetail }}</p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon, ClockIcon, PauseCircleIcon, MinusCircleIcon } from '@heroicons/vue/24/outline';
import { FilterMatchMode } from 'primevue/api';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import Row from 'primevue/row';
import Tag from 'primevue/tag';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api/index';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { EnumsGMCProductStatus, EnumsFBProductStatus, EnumsAdvertisementPlatform, EnumsIssueServability, EnumsMMCProductStatus } from '../../../../generated/api';

const props = defineProps({
  categoryFilterForProducts: {
    type: String,
    default: '',
  },
});
const { t } = useI18n();
const eshopStore = useEshopsStore();
const loadingStore = useLoadingStore();
const productSummary = ref();

onMounted(async () => {
  loadingStore.updateLoading(true);

  lazyParams.value = {
    first: dt.value?.first || 0,
    rows: dt.value?.rows || 10,
    sortField: null,
    sortOrder: null,
    filters: filters.value,
    page: 0,
  };

  await getProductsSummary();
  await loadLazyData();
  removeTableTrash();
  loadingStore.updateLoading(false);
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await loadLazyData();
  }
);

const dt = ref();
const lazyParams = ref({});
const loadingTable = ref(false);
const first = ref(0);
const products = ref();
const allCategories = ref();
const totalRecords = ref(0);
const visibleErrorDetail = ref(false);
const errorDetail = ref();

const toast = useToast();

const matchModeOptions = ref([
  { label: t('productPrioritization.productFilterContains'), value: FilterMatchMode.CONTAINS },
  { label: t('productPrioritization.productFilterEquals'), value: FilterMatchMode.EQUALS },
]);

const statuses = ref([
  { label: t('productPrioritization.statusSuccess'), value: 'success' },
  { label: t('productPrioritization.statusError'), value: 'error' },
  { label: t('productPrioritization.statusWaiting'), value: 'waiting' },
  { label: t('productPrioritization.statusPaused'), value: 'paused' },
  { label: t('productPrioritization.statusInvalid'), value: 'invalid' },
]);

const filters = ref({
  title: { value: null, matchMode: FilterMatchMode.CONTAINS },
  category: { value: props.categoryFilterForProducts ? [props.categoryFilterForProducts] : null, matchMode: FilterMatchMode.IN },
  advertiseGoogle: { value: null, matchMode: FilterMatchMode.EQUALS },
  advertiseFacebook: { value: null, matchMode: FilterMatchMode.EQUALS },
  advertiseMicrosoft: { value: null, matchMode: FilterMatchMode.EQUALS },
  statusGoogle: { value: null, matchMode: FilterMatchMode.EQUALS },
  statusFacebook: { value: null, matchMode: FilterMatchMode.EQUALS },
  statusMicrosoft: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const selectedProduct = ref();

const advertisingStatuses = ref([
  { label: t('productPrioritization.productAdvertised'), value: true },
  { label: t('productPrioritization.productDontAdvertised'), value: false },
]);

const menu = ref();
const items = ref([
  {
    label: t('productPrioritization.productOptions'),
    items: [
      {
        label: t('productPrioritization.productAdvertiseEverywhere'),
        command: () => {
          settingsAdvertising('advertiseAll');
          // alert('Advertise selected everywhere');
        },
      },
      {
        label: t('productPrioritization.productDontAdvertiseEverywhere'),
        command: () => {
          settingsAdvertising('dontAdvertiseAll');
          // alert('Don t advertise selected everywhere');
        },
      },
      {
        label: t('productPrioritization.productAdvertiseSelectedOnGoogle'),
        command: () => {
          settingsAdvertising('advertiseGoogle');
          // alert('Advertise selected on google');
        },
      },
      {
        label: t('productPrioritization.productDontAdvertiseSelectedOnGoogle'),
        command: () => {
          settingsAdvertising('dontAdvertiseGoogle');
          // alert('Don t advertise selected on google');
        },
      },
      {
        label: t('productPrioritization.productAdvertiseSelectedOnFacebook'),
        command: () => {
          settingsAdvertising('advertiseFacebook');
          // alert('Advertise selected on facebook');
        },
      },
      {
        label: t('productPrioritization.productDontAdvertiseSelectedOnFacebook'),
        command: () => {
          settingsAdvertising('dontAdvertiseFacebook');
          // alert('Don t advertise selected on facebook');
        },
      },
      {
        label: t('productPrioritization.productAdvertiseSelectedOnMicrosoft'),
        command: () => {
          settingsAdvertising('advertiseMicrosoft');
          // alert('Advertise selected on microsoft');
        },
      },
      {
        label: t('productPrioritization.productDontAdvertiseSelectedOnMicrosoft'),
        command: () => {
          settingsAdvertising('dontAdvertiseMicrosoft');
          // alert('Don t advertise selected on microsoft');
        },
      },
    ],
  },
]);

const toggle = (event) => {
  menu.value.toggle(event);
};

const settingsAdvertising = async (type) => {
  if (selectedProduct.value === undefined || selectedProduct.value.length === 0) {
    toast.add({ severity: 'error', summary: t('productPrioritization.productYouHaveNotMarkedAnyProducts'), life: 5000 });
    return;
  }
  // remove all keys except itemId

  const selectedProducts = selectedProduct.value.map((product) => product.itemId);
  switch (type) {
    case 'advertiseAll':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Google', advertise: true });
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Facebook', advertise: true });
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Microsoft', advertise: true });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreSuccessfullyAdvertised'), life: 5000 });
        await loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'dontAdvertiseAll':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Google', advertise: false });
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Facebook', advertise: false });
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Microsoft', advertise: false });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreNotSuccessfullyAdvertised'), life: 5000 });
        await loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'advertiseGoogle':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Google', advertise: true });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreSuccessfullyAdvertisedOnGoogle'), life: 5000 });
        loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'dontAdvertiseGoogle':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Google', advertise: false });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreNotSuccessfullyAdvertisedOnGoogle'), life: 5000 });
        await loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'advertiseFacebook':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Facebook', advertise: true });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreSuccessfullyAdvertisedOnFacebook'), life: 5000 });
        loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'dontAdvertiseFacebook':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Facebook', advertise: false });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreNotSuccessfullyAdvertisedOnFacebook'), life: 5000 });
        await loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'advertiseMicrosoft':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Microsoft', advertise: true });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreSuccessfullyAdvertisedOnMicrosoft'), life: 5000 });
        loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
    case 'dontAdvertiseMicrosoft':
      try {
        loadingStore.updateLoading(true);
        await api.clientProductsSetMultipleProductsAdvertisementStatus({ ids: selectedProducts, platform: 'Microsoft', advertise: false });
        toast.add({ severity: 'success', summary: t('productPrioritization.productSelectedItemsAreNotSuccessfullyAdvertisedOnMicrosoft'), life: 5000 });
        await loadLazyData();
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.status >= 500) {
            toast.add({
              severity: 'error',
              summary: t('serverErrorTitle'),
              detail: error.response.data.requestId,
              life: 20000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: error.response.data.detail,
              life: 6000,
            });
          }
        } else {
          console.warn(error);
        }
      } finally {
        selectedProducts.value = [];
        allSelected.value = false;
        loadingStore.updateLoading(false);
      }
      break;
  }
  selectedProduct.value = [];
};

const changeAdvertiseStatus = async (itemId, value, type) => {
  try {
    loadingStore.updateLoading(true);
    await api.clientProductsSetProductAdvertisementStatus({ itemId: itemId, platform: type, advertise: value });
    toast.add({
      severity: 'success',
      summary: t('productPrioritization.categoryChangeAdvertiseStatusSuccess'),
      life: 6000,
    });
    await loadLazyData();
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

// Filters
const loadLazyData = async (event?) => {
  loadingTable.value = true;
  lazyParams.value = { ...lazyParams.value, first: event?.first || first.value, utcOffset: new Date().getTimezoneOffset() / -60 };
  // todo API call
  try {
    const { data: prod } = await api.clientProductsGetProducts(lazyParams.value);
    products.value = prod.products;

    const { data: cat } = await api.clientProductsGetCategories();
    allCategories.value = cat.categories;
    loadingTable.value = false;
    totalRecords.value = prod.totalRecords;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingTable.value = false;
  }
};
const onPage = (event) => {
  lazyParams.value = event;
  loadLazyData(event);
};
const onSort = (event) => {
  lazyParams.value = event;
  lazyParams.value.page = 0;
  loadLazyData(event);
};
const onFilter = (event) => {
  lazyParams.value.filters = filters.value;
  lazyParams.value.page = 0;
  loadLazyData(event);
};

const setProductGoogleStatus = (status) => {
  const statusMap = {
    [EnumsGMCProductStatus.Approved]: 'success',
    [EnumsGMCProductStatus.Unaffected]: 'success',
    [EnumsGMCProductStatus.Demoted]: 'success',
    [EnumsGMCProductStatus.Expiring]: 'success',
    [EnumsGMCProductStatus.Pending]: 'waiting',
    [EnumsGMCProductStatus.Waiting]: 'waiting',
    [EnumsGMCProductStatus.Invalid]: 'invalid',
    [EnumsGMCProductStatus.Disapproved]: 'error',
    [EnumsGMCProductStatus.Disabled]: 'paused',
  };

  return statusMap[status] || 'error';
};

const setProductFacebookStatus = (status) => {
  const statusMap = {
    [EnumsFBProductStatus.Approved]: 'success',
    [EnumsFBProductStatus.ApprovedWithIssues]: 'success',
    [EnumsFBProductStatus.Outdated]: 'success',
    [EnumsFBProductStatus.NoReview]: 'success',
    [EnumsFBProductStatus.Waiting]: 'waiting',
    [EnumsFBProductStatus.Pending]: 'waiting',
    [EnumsFBProductStatus.Unused]: 'waiting',
    [EnumsFBProductStatus.Invalid]: 'invalid',
    [EnumsFBProductStatus.Rejected]: 'error',
    [EnumsFBProductStatus.Disabled]: 'paused',
  };

  return statusMap[status] || 'error';
};

const setProductMicrosoftStatus = (status) => {
  const statusMap = {
    [EnumsMMCProductStatus.Approved]: 'success',
    [EnumsMMCProductStatus.Waiting]: 'waiting',
    [EnumsMMCProductStatus.Invalid]: 'invalid',
    [EnumsMMCProductStatus.Warning]: 'invalid',
    [EnumsMMCProductStatus.Disabled]: 'paused',
    [EnumsMMCProductStatus.Disapproved]: 'error',
  };

  return statusMap[status] || 'error';
};

const showErrorDetail = async (hasError, platform, productId) => {
  if (platform === EnumsAdvertisementPlatform.Microsoft) {
    return;
  }
  if (!hasError) {
    return;
  }
  try {
    const { data } = await api.clientProductsGetProductIssues({ productId, platform });
    if (data.tanganicaIssues.length !== 0 || data.platformIssues.length !== 0) {
      errorDetail.value = data;
      visibleErrorDetail.value = true;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }

  return;
};

const getProductsSummary = async () => {
  try {
    const { data } = await api.clientProductsGetProductsSummary();
    productSummary.value = data;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getTooltipByStatus = (status) => {
  switch (status) {
    case 'success':
      return t('productPrioritization.productTooltipSuccess');
    case 'waiting':
      return t('productPrioritization.productTooltipWaiting');
    case 'paused':
      return t('productPrioritization.productTooltipPaused');
    case 'invalid':
      return t('productPrioritization.productTooltipInvalid');
    default:
      return t('productPrioritization.productTooltipError');
  }
};

const removeTableTrash = () => {
  const table = document.getElementById('productsTable');

  if (!table) {
    return;
  }
  const tbody = table.querySelector('[data-pc-section="tbody"]');

  if (!tbody) {
    return;
  }
  tbody.parentNode.removeChild(tbody);
};

const getTanganicaStatusColorByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Disapproved:
      return 'bg-red-600';
    case EnumsIssueServability.Demoted:
      return 'bg-orange-600';
    case EnumsIssueServability.Unaffected:
      return 'bg-indigo-600';
    default:
      return 'bg-brown-600';
  }
};

const getTanganicaLabelByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Demoted:
      return t('conditionProducts.demoted');
    case EnumsIssueServability.Disapproved:
      return t('conditionProducts.disapproved');
    case EnumsIssueServability.Unaffected:
      return t('conditionProducts.unaffected');
    default:
      return t('conditionProducts.unknown');
  }
};

const getTooltipByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Demoted:
      return t('conditionProducts.demotedTooltip');
    case EnumsIssueServability.Disapproved:
      return t('conditionProducts.disapprovedTooltip');
    case EnumsIssueServability.Unaffected:
      return t('conditionProducts.unaffectedTooltip');
    default:
      return '';
  }
};

const allSelected = ref(false);

const onSelectAllChange = (selected) => {
  selectedProduct.value = selected.checked ? products.value : [];
  allSelected.value = selected.checked;
};
</script>

<style lang="scss" scoped></style>
