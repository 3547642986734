<template>
  <div class="my-5 rounded-lg bg-white p-6 shadow-lg">
    <h1 class="text-left text-main md:text-xl lg:text-3xl">{{ $t('subscription.text32') }}</h1>
    <h3 class="my-5 text-left text-xl text-[#FF914D]">{{ $t('subscription.tanganicaPremium') }}</h3>
    <p class="text-left text-main">{{ $t('subscription.text33', { trialPeriodDate: trialPeriodDate }) }}</p>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';

const trialPeriodDate = ref<number>(0);
const eshopsStore = useEshopsStore();

onMounted(async () => {
  await getTrialPeriod();
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getTrialPeriod();
  }
);

const getTrialPeriod = async () => {
  const { data } = await api.clientWizardGetRemainingTrialPeriod();
  trialPeriodDate.value = data.daysRemaining ?? 0;
};
</script>
