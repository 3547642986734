import { CallbackEnum } from '@/modules/campaigns/enums/CallbackEnum';
import { generateFacebookOauthUrl } from '@/modules/campaigns/utils/generateFacebookOauthUrl';
import { api } from '@/services/api';

export const getFacebookBusinessOauthUrl = async (): Promise<string> => {
  try {
    const { data } = await api.clientCampaignsGetFBBusinessOauthUrl();
    return generateFacebookOauthUrl(data.url, CallbackEnum.FACEBOOK_BUSINESS);
  } catch (error) {
    return '';
  }
};
