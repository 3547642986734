<template>
  <div v-show="loadingStore.loading" class="relative inset-0 z-[100000] m-auto flex items-center justify-center">
    <section class="fixed left-1/2 top-1/2 z-50 m-auto block max-w-sm -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-[#f3f3f3] p-10 shadow-2xl dark:bg-mainDark sm:w-96">
      <span class="m-auto block h-12 w-12 animate-spin rounded-full border-[0.375rem] border-gray-300 border-l-main text-center"></span>
      <span class="m-auto mt-5 block w-full animate-bounce text-center text-2xl font-bold leading-5 text-black dark:text-gray-300">
        {{ $t('global.loading.text') }}
      </span>
    </section>
    <div class="fixed inset-0 z-50 block h-full w-full"></div>
  </div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '@/stores/loading';

const loadingStore = useLoadingStore();
</script>
