<template>
    <dl class="mx-auto mt-10 max-w-2xl space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in questions" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt>
                <DisclosureButton class="flex w-full items-start justify-between text-left text-main">
                    <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                    <span class="ml-6 flex h-7 items-center">
                        <PlusIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                        <MinusIcon v-else class="h-6 w-6" aria-hidden="true" />
                    </span>
                </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
                <p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
                <p v-if="faq?.answer2" class="mt-4 text-base leading-7 text-gray-600">{{ faq.answer2 }}</p>
                <p v-if="faq?.answer3" class="mt-4 text-base leading-7 text-gray-600">{{ faq.answer3 }}</p>
            </DisclosurePanel>
        </Disclosure>
    </dl>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { MinusIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const questions = [
    {
        question: t('help.questions.question1'),
        answer: t('help.questions.answer1'),
        answer2: t('help.questions.answer1Second'),
    },
    {
        question: t('help.questions.question2'),
        answer: t('help.questions.answer2'),
        answer2: t('help.questions.answer2Second'),
    },
    {
        question: t('help.questions.question3'),
        answer: t('help.questions.answer3'),
        answer2: t('help.questions.answer3Second'),
    },
    {
        question: t('help.questions.question4'),
        answer: t('help.questions.answer4'),
        answer2: t('help.questions.answer4Second'),
    },
    {
        question: t('help.questions.question5'),
        answer: t('help.questions.answer5'),
        answer2: t('help.questions.answer5Second'),
        answer3: t('help.questions.answer5Third'),
    },
    {
        question: t('help.questions.question6'),
        answer: t('help.questions.answer6'),
        answer2: t('help.questions.answer6Second'),
        answer3: t('help.questions.answer6Third'),
    },
];
</script>
