<template>
  <nav aria-label="Progress" class="w-full">
    <ol role="list" class="mx-auto flex w-full items-center justify-center">
      <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-24 2xl:pr-28' : '', 'relative']">
        <template v-if="step.status === 'complete'">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-slate-200" />
          </div>
          <div class="relative flex h-8 w-8 items-center justify-center rounded-full bg-[#359F03] shadow-md shadow-gray-400 2xl:h-10 2xl:w-10">
            <span class="text-white">{{ step.name }}</span>
            <div class="absolute -bottom-10 left-1/2 hidden w-[8.75rem] -translate-x-1/2 text-center text-xs text-main sm:block 2xl:text-xs">
              {{ step.localization }}
            </div>
          </div>
        </template>
        <template v-else-if="step.status === 'current'">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-slate-200" />
          </div>
          <div class="relative flex h-8 w-8 items-center justify-center rounded-full bg-amber-500 shadow-md shadow-gray-400 2xl:h-10 2xl:w-10">
            <span class="text-white">{{ step.name }}</span>
            <span class="sr-only">{{ step.name }}</span>
            <div class="absolute -bottom-10 left-1/2 hidden w-[8.75rem] -translate-x-1/2 text-center text-xs text-main sm:block 2xl:text-xs">
              {{ step.localization }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-slate-200" />
          </div>
          <div class="relative flex h-8 w-8 items-center justify-center rounded-full bg-slate-100 shadow-md shadow-gray-400 2xl:h-10 2xl:w-10">
            <span class="text-white">{{ step.name }}</span>
            <span class="sr-only">{{ step.name }}</span>
            <div class="absolute -bottom-10 left-1/2 hidden w-[8.75rem] -translate-x-1/2 text-center text-xs text-main sm:block 2xl:text-xs">
              {{ step.localization }}
            </div>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { EnumsDashboardStatus } from '../../../../generated/api';

const { t } = useI18n();

const props = defineProps({
  dashboardStatus: {
    type: String,
    required: true,
  },
});

type Step = {
  name: string;
  status: 'complete' | 'current' | 'upcoming';
  localization: string;
};

const steps = ref<Step[]>([]);

const eshopStatuses = {
  [EnumsDashboardStatus.EshopNotSetUp]: {
    steps: [
      { name: '1', status: 'complete', localization: t('dashboard.firstSteps.register') },
      { name: '2', status: 'current', localization: t('dashboard.firstSteps.eshopSettings') },
      { name: '3', status: 'upcoming', localization: t('dashboard.firstSteps.adsCredit') },
      { name: '4', status: 'upcoming', localization: t('dashboard.firstSteps.onCampaigns') },
    ],
  },
  [EnumsDashboardStatus.EshopSetUpNoCredit]: {
    steps: [
      { name: '1', status: 'complete', localization: t('dashboard.firstSteps.register') },
      { name: '2', status: 'complete', localization: t('dashboard.firstSteps.eshopSettings') },
      { name: '3', status: 'current', localization: t('dashboard.firstSteps.adsCredit') },
      { name: '4', status: 'upcoming', localization: t('dashboard.firstSteps.onCampaigns') },
    ],
  },
  [EnumsDashboardStatus.EshopSetUpWithCreditNotTurnedOn]: {
    steps: [
      { name: '1', status: 'complete', localization: t('dashboard.firstSteps.register') },
      { name: '2', status: 'complete', localization: t('dashboard.firstSteps.eshopSettings') },
      { name: '3', status: 'complete', localization: t('dashboard.firstSteps.adsCredit') },
      { name: '4', status: 'current', localization: t('dashboard.firstSteps.onCampaigns') },
    ],
  },
};

watch(
  () => props.dashboardStatus,
  (newStatus) => {
    steps.value = eshopStatuses[newStatus]?.steps || [];
  },
  {
    immediate: true,
  }
);
</script>
