/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsGMCProductStatus = {
    Approved: 'APPROVED',
    Unaffected: 'UNAFFECTED',
    Demoted: 'DEMOTED',
    Expiring: 'EXPIRING',
    Waiting: 'WAITING',
    Pending: 'PENDING',
    Invalid: 'INVALID',
    Disapproved: 'DISAPPROVED',
    Disabled: 'DISABLED'
} as const;

export type EnumsGMCProductStatus = typeof EnumsGMCProductStatus[keyof typeof EnumsGMCProductStatus];



