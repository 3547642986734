<template>
  <Disclosure v-slot="{ open }" as="div" class="pt-6">
    <dt class="text-lg">
      <DisclosureButton class="flex w-full items-center justify-between text-left text-gray-400 dark:text-[#cbd5e1]">
        <div class="font-bold text-gray-900 dark:text-[#cbd5e1]">
          <div class="flex items-center align-baseline">
            <div class="relative mr-2 h-9 w-9 rounded-full bg-main">
              <PhoneIcon class="absolute left-1/2 top-1/2 block h-6 w-6 -translate-x-1/2 -translate-y-1/2 text-white"></PhoneIcon>
            </div>
            <h1 class="mr-2 text-2xl font-bold text-gray-800">
              {{ $t('userSettings.changePhone.title') }}
            </h1>
          </div>
        </div>
        <span class="ml-6 flex h-7 items-center">
          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6']" aria-hidden="true" />
        </span>
      </DisclosureButton>
    </dt>
    <DisclosurePanel as="dd" class="mt-2 pr-12 dark:text-[#cbd5e1]">
      <p class="text-gray-800 dark:text-[#cbd5e1]">
        {{ $t('userSettings.changePhone.currentPhone') }} <span class="font-bold">{{ currentPhone }}</span>
      </p>
      <form @submit.prevent="onSubmit" class="mt-5 max-w-[25rem]">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="phoneNumber">{{ $t('userSettings.changePhone.phone') }}<span class="ml-1 text-red-600">*</span></label>
        <div class="flex items-center">
          <Dropdown
            v-model="phoneCountry"
            :options="countries"
            filter
            optionLabel="name"
            placeholder="-"
            class="rounded-r-none"
            :ptOptions="{ mergeProps: true }"
            :pt="{
              root: () => {
                return {
                  class: ['max-w-[7.5rem] sm:max-w-[9.375rem]'],
                };
              },
              header: () => {
                return {
                  class: ['border'],
                };
              },
              input: () => {
                return {
                  class: ['!p-1 sm:!p-3'],
                };
              },
            }"
            :filterFields="[
              (e) => {
                return $t(e.name);
              },
              'phonePrefix',
              'code',
            ]"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="align-items-center flex">
                <img :alt="slotProps.value.label" :src="`${$filePath}/${slotProps.value.flag}`" class="mr-1 h-5 w-5 rounded-full sm:mr-2 sm:h-6 sm:w-6" />
                <div class="ml-1 sm:ml-2 sm:text-base">{{ slotProps.value.phonePrefix }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="align-items-center flex">
                <img :alt="slotProps.option.label" :src="`${$filePath}/${slotProps.option.flag}`" class="mr-1 h-5 w-5 rounded-full sm:mr-2 sm:h-6 sm:w-6" />
                <div>{{ $t(slotProps.option.name) }}</div>
                <div class="ml-1 sm:ml-2 sm:text-base">{{ slotProps.option.phonePrefix }}</div>
              </div>
            </template>
          </Dropdown>
          <InputNumber
            v-model="phoneNumber"
            :placeholder="$t('wizard.fillFieldPlaceholder')"
            autocomplete="tel"
            @input="refocus"
            :useGrouping="false"
            :ptOptions="{ mergeProps: true }"
            :pt="{
              input: {
                root: () => {
                  return {
                    class: ['rounded-l-none'],
                  };
                },
              },
            }"
          />
        </div>
        <small id="text-error" class="text-sm text-red-600">{{ errors.phoneNumber || '&nbsp;' }}</small>
        <MainButton type="submit" :text="$t('userSettings.changePhone.save')" class="my-5 block p-1 text-sm" />
      </form>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon, PhoneIcon } from '@heroicons/vue/24/outline';
import Dropdown from 'primevue/dropdown';
import InputNumber, { InputNumberInputEvent } from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import countriesCodebook from '@/codebooks/CountriesCodebook';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();
const userStore = useUserStore();

const currentPhone = ref();

onMounted(async () => {
  await getCurrentPhoneNumber();
  sortCountries();
  preselectPrefix();
});

const { defineField, handleSubmit, errors, resetForm, meta } = useForm({
  validationSchema: yup.object({
    phoneNumber: yup
      .string()
      .required(() => t('validations.required'))
      .phoneNumber(),
  }),
});

const [phoneCountry] = defineField('phoneCountry');
const [phoneNumber] = defineField('phoneNumber');

const refocus = ($event: InputNumberInputEvent) => {
  const target = $event.originalEvent.target as HTMLElement;
  target.blur();
  target.focus();
};

const onSubmit = handleSubmit(async (formData) => {
  if (!meta.value.dirty) return;

  loadingStore.updateLoading(true);

  const payload = {
    phoneNumber: formData.phoneCountry.phonePrefix + formData.phoneNumber,
  };

  try {
    await api.clientUserSettingsSetPhoneNumber(payload, {
      metadata: {
        accessToken: 'user',
      },
    });

    currentPhone.value = formData.phoneNumber;

    toast.add({
      severity: 'success',
      summary: t('userSettings.changePhone.phoneSuccess'),
      life: 3000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    resetForm();
    loadingStore.updateLoading(false);
  }
});

const getCurrentPhoneNumber = async () => {
  try {
    const { data } = await api.clientUserSettingsGetPhoneNumber({
      metadata: {
        accessToken: 'user',
      },
    });

    currentPhone.value = data.phoneNumber;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

// Custom switch case
const preselectPrefix = () => {
  let countryCode = '';
  switch (userStore.getLanguageCode) {
    case 'cs':
      countryCode = 'CZ';
      break;
    case 'da':
      countryCode = 'DK';
      break;
    case 'de':
      countryCode = 'DE';
      break;
    case 'el':
      countryCode = 'GR';
      break;
    case 'en':
      countryCode = 'GB';
      break;
    case 'es':
      countryCode = 'ES';
      break;
    case 'fi':
      countryCode = 'FI';
      break;
    case 'fr':
      countryCode = 'FR';
      break;
    case 'hu':
      countryCode = 'HU';
      break;
    case 'it':
      countryCode = 'IT';
      break;
    case 'nl':
      countryCode = 'NL';
      break;
    case 'nn':
      countryCode = 'NO';
      break;
    case 'pl':
      countryCode = 'PL';
      break;
    case 'pt':
      countryCode = 'PT';
      break;
    case 'ro':
      countryCode = 'RO';
      break;
    case 'sk':
      countryCode = 'SK';
      break;
    case 'sv':
      countryCode = 'SE';
      break;
    default:
      countryCode = 'CZ';
      break;
  }

  const country = countriesCodebook.find((country) => country.code === countryCode);
  if (country) {
    phoneCountry.value = country;
  }
};

watch(
  () => userStore.getLanguageCode,
  () => {
    preselectPrefix();
  }
);

const countries = ref();

const sortCountries = () => {
  const copy = [...countriesCodebook];

  // Define neighboring countries of Czechia
  const czechiaPrefix = '+420';
  const neighborsOfCzechia = new Set(['+421', '+43', '+49', '+48']);

  countries.value = copy.sort((a, b) => {
    const prefixA = a.phonePrefix;
    const prefixB = b.phonePrefix;

    // Calculate the length of digits in the prefix (excluding the + sign)
    const digitCountA = prefixA.length - 1;
    const digitCountB = prefixB.length - 1;

    // 1. Prioritize Czechia first
    if (prefixA === czechiaPrefix && prefixB !== czechiaPrefix) {
      return -1;
    }
    if (prefixA !== czechiaPrefix && prefixB === czechiaPrefix) {
      return 1;
    }

    // 2. Prioritize neighboring countries of Czechia
    const isANeighbor = neighborsOfCzechia.has(prefixA);
    const isBNeighbor = neighborsOfCzechia.has(prefixB);

    if (isANeighbor && !isBNeighbor) {
      return -1;
    }
    if (!isANeighbor && isBNeighbor) {
      return 1;
    }

    // 3. Within +4 group, prioritize longer prefixes over shorter ones
    const isPrefixA4 = prefixA.startsWith('+4');
    const isPrefixB4 = prefixB.startsWith('+4');
    const isPrefixA3 = prefixA.startsWith('+3');
    const isPrefixB3 = prefixB.startsWith('+3');

    if (isPrefixA4 && isPrefixB4) {
      if (digitCountA > digitCountB) {
        return -1;
      }
      if (digitCountA < digitCountB) {
        return 1;
      }
      return 0;
    }

    if (isPrefixA3 && isPrefixB3) {
      if (digitCountA > digitCountB) {
        return -1;
      }
      if (digitCountA < digitCountB) {
        return 1;
      }
      return 0;
    }

    // 4. Prioritize +4 group over +3 group
    if (isPrefixA4 && !isPrefixB4) {
      return -1;
    }
    if (!isPrefixA4 && isPrefixB4) {
      return 1;
    }

    if (isPrefixA3 && !isPrefixB3) {
      return -1;
    }
    if (!isPrefixA3 && isPrefixB3) {
      return 1;
    }

    // 5. Fallback: Sort alphabetically by code
    return a.code.localeCompare(b.code);
  });
};
</script>
