<template>
  <div class="relative h-fit">
    <div class="mx-auto mt-5 max-h-full w-full rounded-3xl text-center lg:left-0 lg:w-[70%] xl:mt-1">
      <div class="mx-auto max-w-3xl px-6 lg:w-full lg:px-8">
        <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-3xl">
          <h2 class="text-2xl font-bold tracking-tight text-main lg:text-4xl">
            {{ $t('wizard.pages.wizardXmlFeed.title') }}
          </h2>
          <p class="mt-2 text-xs text-main lg:mt-3 lg:text-base lg:leading-8">
            {{ $t('wizard.pages.wizardXmlFeed.subtitle') }}
          </p>
          <form @submit.prevent="onSubmit" class="mt-2 lg:mt-10">
            <div class="grid w-full grid-cols-1 gap-x-8 text-left sm:grid-cols-2">
              <div class="sm:col-span-2">
                <label class="block text-xs font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-sm lg:text-base" for="xmlFeed" v-html="$t('wizard.pages.wizardXmlFeed.url')"></label>
                <InputText id="xmlFeed" v-model="xmlFeed" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="xmlFeed" />
                <div class="flex flex-col">
                  <small id="text-error" class="text-sm text-red-600">{{ errors.xmlFeed || '&nbsp;' }}</small>
                </div>
                <div class="rounded-lg bg-white dark:bg-mainDark">
                  <div class="scrollbar mx-auto mt-2 h-56 overflow-scroll overflow-y-auto overflow-x-hidden p-3 lg:mt-5">
                    <p class="text-sm font-bold text-main sm:text-base">
                      {{ $t('wizard.pages.wizardXmlFeed.xmlFeedInfo') }}
                    </p>
                    <div v-for="(xmlFeedTutorial, index) in XMLFeedTutorialsCodebook[userStore.getLanguageCode]" :key="xmlFeedTutorial.name" class="border-b last:border-0">
                      <button type="button" @click="handleClick(index)" :ref="(el) => (buttonRefs[index] = el as HTMLButtonElement)" class="flex w-full items-start justify-between py-3 text-left text-gray-900 dark:text-gray-300">
                        <img :src="`${$filePath}${xmlFeedTutorial.logo}`" :alt="xmlFeedTutorial.name" class="w-1/8 h-9 rounded-lg p-1 dark:bg-gray-300" />
                        <span v-show="xmlFeedTutorial.name === 'Default'" class="my-auto ml-1 mr-auto block text-left text-sm text-main">{{ $t('wizard.pages.wizardXmlFeed.defaultInfo') }}</span>
                        <ChevronDownIcon v-if="activeIndex !== index" class="h-6 w-6" aria-hidden="true" />
                        <ChevronUpIcon v-else class="h-6 w-6" aria-hidden="true" />
                      </button>
                      <div v-show="activeIndex === index" class="pr-12 text-main">
                        <p v-for="(answer, index) in xmlFeedTutorial.answers" :key="index" class="ml-5 text-sm leading-7">
                          {{ $t(answer.value) }}
                        </p>
                        <!-- Show only when language is CS -->
                        <div v-show="xmlFeedTutorial.blog" class="my-2">
                          <a :href="xmlFeedTutorial.blog" target="_blank" class="my-4 ml-5 cursor-pointer rounded-full bg-main p-1 text-sm leading-7 text-white hover:opacity-60 hover:ring-1 sm:p-2">{{ $t('wizard.pages.wizardXmlFeed.moreHere') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <WizardButtons></WizardButtons>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { onMounted, ref, Ref, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import XMLFeedTutorialsCodebook from '@/modules/wizard/codebooks/XMLFeedTutorialsCodebook';
import WizardButtons from '@/modules/wizard/components/WizardButtons.vue';
import { IStepThreeValues } from '@/modules/wizard/interfaces/IStepThree';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';

const gtm = useGtm();
const wizardStore = useWizardStore();
const loadingStore = useLoadingStore();
const userStore = useUserStore();
const router = useRouter();
const toast = useToast();
const { t } = useI18n();
const dataSaving = ref(false);

const animate = ref(false);

const activeIndex = ref(-1);
const buttonRefs: Ref<(HTMLButtonElement | null)[]> = ref([]);

// Form
const { defineField, handleSubmit, errors } = useForm<IStepThreeValues>({
  validationSchema: yup.object({
    xmlFeed: yup.string().required(() => t('validations.required')),
  }),
  initialValues: wizardStore.getStepThree.values,
});

// Form fields
const [xmlFeed] = defineField('xmlFeed');

onMounted(() => {
  wizardStore.currentStep = 2;
});

const isFormChanged = (formData) => {
  return JSON.stringify(wizardStore.getStepThree.values) !== JSON.stringify(formData);
};

const handleClick = async (index: number) => {
  animate.value = false;
  setTimeout(() => {
    animate.value = true;
  }, 1000);
  activeIndex.value = activeIndex.value === index ? -1 : index;
  await nextTick();
  if (buttonRefs.value[index] !== null) {
    const element = buttonRefs.value[index] as HTMLButtonElement;

    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const onSubmit = handleSubmit(async (formData: IStepThreeValues) => {
  loadingStore.updateLoading(true);
  if (dataSaving.value) {
    return;
  }
  dataSaving.value = true;

  const isThereFormChanges = isFormChanged(formData);

  // If form is not changed, go to next step
  if (!isThereFormChanges) {
    wizardStore.steps[2].isValid = true;
    await router.push({ name: WIZARD_ROUTES.PIXEL_PAGE });

    loadingStore.updateLoading(false);
    return;
  }

  const payload = {
    feedLink: formData.xmlFeed,
  };

  try {
    await api.clientWizardSetProductFeedUrl(payload);

    gtm?.trackEvent({
      event: 'gtm.xmlFeed',
      value: payload,
    });

    // Update wizard store
    wizardStore.steps[2].values.xmlFeed = formData.xmlFeed;
    wizardStore.steps[2].isValid = true;

    toast.add({
      severity: 'success',
      summary: t('wizard.pages.wizardXmlFeed.messages.saveXMLFeedSuccessTitle'),
      detail: t('wizard.pages.wizardXmlFeed.messages.saveXMLFeedSuccessDescription'),
      life: 3000,
    });

    await router.push({ name: WIZARD_ROUTES.PIXEL_PAGE });

    wizardStore.resetStepsValidity();
  } catch (error: any) {
    wizardStore.resetStepsValidity();
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    dataSaving.value = false;
    loadingStore.updateLoading(false);
  }
});
</script>
