<template>
  <Disclosure v-slot="{ open }" as="div" class="pt-6">
    <dt class="text-lg">
      <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-400 dark:text-[#cbd5e1]">
        <div class="font-bold text-gray-900 dark:text-[#cbd5e1]">
          <div class="flex items-center align-baseline">
            <div class="relative mr-2 h-9 w-9 rounded-full bg-main">
              <EnvelopeIcon class="absolute left-1/2 top-1/2 block h-6 w-6 -translate-x-1/2 -translate-y-1/2 text-white"></EnvelopeIcon>
            </div>
            <h1 v-if="userStore.getAuthProvider === 'Tanganica'" class="mr-2 text-2xl font-bold text-gray-800">
              {{ $t('userSettings.changeEmail.title') }}
            </h1>
            <h1 v-else class="mr-2 text-2xl font-bold text-gray-800">
              {{ $t('userSettings.changeEmail.title') }}
            </h1>
          </div>
        </div>
        <span class="ml-6 flex h-7 items-center">
          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6']" aria-hidden="true" />
        </span>
      </DisclosureButton>
    </dt>
    <DisclosurePanel as="dd" class="mt-2 pr-12 dark:text-[#cbd5e1]">
      <p class="text-gray-800 dark:text-[#cbd5e1]">
        {{ $t('userSettings.changeEmail.currentEmail') }} <span class="font-bold">{{ currentEmail }}</span>
      </p>
      <div v-if="userStore.getAuthProvider === 'Tanganica'">
        <form @submit.prevent="onSubmit" class="mt-5 max-w-[25rem]">
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="email">{{ $t('userSettings.changeEmail.email') }}<span class="ml-1 text-red-600">*</span></label>
          <div>
            <InputText id="email" type="email" v-model="email" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="organization" />
            <small id="text-error" class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
          </div>
          <MainButton type="submit" :text="$t('userSettings.changeEmail.save')" class="m-auto my-5 inline-flex justify-center p-1 text-sm" />
        </form>
        <p class="mt-2 text-gray-800 dark:text-[#cbd5e1]">
          {{ $t('userSettings.changeEmail.emailText1') }}
        </p>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
import { useJwt } from '@vueuse/integrations/useJwt';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { ref, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const authStore = useAuthStore();
const userStore = useUserStore();
const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();

const currentEmail = ref();

onMounted(() => {
  currentEmail.value = getEmailFromToken();
});

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .required(() => t('validations.required'))
      .email(() => t('validations.email')),
  }),
});

const [email] = defineField('email');

const isChanged = computed(() => {
  return currentEmail.value !== email.value;
});

const onSubmit = handleSubmit(async (formData) => {
  if (!isChanged.value) return;

  loadingStore.updateLoading(true);

  const payload = {
    newEmail: formData.email,
    redirectUrl: import.meta.env.VITE_CHANGE_EMAIL_CALLBACK_URL as string,
  };
  try {
    await api.clientUserSettingsChangeEmail(payload, {
      metadata: {
        accessToken: 'user',
      },
    });

    toast.add({
      severity: 'success',
      summary: t('userSettings.changeEmail.emailSuccess'),
      life: 3000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    resetForm();
    loadingStore.updateLoading(false);
  }
});

const getEmailFromToken = () => {
  const token = authStore.getUserAccessToken;
  if (!token) return;
  const { payload } = useJwt(token);
  return payload.value?.email;
};
</script>

<style lang="scss" scoped></style>
