<template>
  <div class="flex h-full w-full p-8">
    <div v-if="!isUserError" class="m-auto h-fit">
      <p class="mt-6 text-center text-base leading-7">
        {{ $t('global.facebookPageShare.pages.pageShareCallback.text') }}
      </p>
    </div>
    <div v-if="isUserError" class="m-auto h-fit">
      <p class="mb-2 mt-6 text-center text-base leading-7">
        {{ $t(userError?.text as string) }}
      </p>
      <p class="mb-2 mt-6 text-center text-sm leading-7 text-gray-600">
        {{ detailError }}
      </p>
      <div class="mb-8 mt-4 flex flex-col justify-items-center gap-2 xl:flex-row">
        <DynamicImage v-for="(image, index) in userError?.images" :src="image.url" :alt="image.alt" :isLocalized="true" :key="index" class="block h-full rounded-lg object-cover object-center md:max-w-[25rem]"></DynamicImage>
      </div>
      <div class="m-3 flex flex-row justify-center gap-4">
        <MainButton type="button" @click="closeWindow" :text="$t('global.facebookPageShare.pages.pageShareCallback.okButton')"> </MainButton>
        <MainButton type="button" class="bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :text="$t('global.facebookPageShare.pages.pageShareCallback.repeatButton')" :hover="false" @click="handleRetry">
        </MainButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import ErrorsCodebook, { IPageShareError } from '@/modules/campaigns/codebooks/ErrorsCodebook';
import { CallbackEnum } from '@/modules/campaigns/enums/CallbackEnum';
import { ErrorEnum } from '@/modules/campaigns/enums/ErrorEnum';
import { LocalStorageKeyEnum } from '@/modules/campaigns/enums/LocalStorageKeyEnum';
import { getFacebookBusinessOauthUrl } from '@/modules/campaigns/utils/getFacebookBusinessOauthUrl';
import { getRedirectUrl } from '@/modules/campaigns/utils/getRedirectUrl';
import DynamicImage from '@/modules/global/components/DynamicImage.vue';
import MainButton from '@/modules/global/MainButton.vue';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const route = useRoute();
const loadingStore = useLoadingStore();

const isUserError = ref<boolean>(false);
const userError = ref<IPageShareError>();
const detailError = ref<string>();

const callbackSuccess = useStorage(LocalStorageKeyEnum.FACEBOOK_CALLBACK_SUCCESS, false);

onMounted(async () => {
  await handlePageShareCallback();
});

const handlePageShareError = (errorCode?: string | null, detail?: string | null) => {
  isUserError.value = true;
  const userErrorObj = ErrorsCodebook.find((error: IPageShareError) => error.code === errorCode);

  userError.value = userErrorObj || ErrorsCodebook.find((error: IPageShareError) => error.code === 'APIGeneralError');
  detailError.value = detail || '';
  // Remove the state from local storage
  localStorage.removeItem(LocalStorageKeyEnum.FACEBOOK_STATE);
};

const checkFacebookStatus = (): boolean => {
  if (route.query.error_reason && route.query.error_reason === 'user_denied') {
    handlePageShareError(ErrorEnum.USER_CANCELED);
    return false;
  } else if (route.query.error) {
    handlePageShareError(null, 'Error:' + route.query.error);
    return false;
  }
  return true;
};

const checkState = (): boolean => {
  // Remove #_=_ from the state => Facebook bug? --TODO correct this
  const regex = /#_=_/g;
  const customQueryState = route.query.state?.toString().replace(regex, '');
  if (!route.query.state) {
    handlePageShareError(null, 'Error: State is missing');
    return false;
  } else if (customQueryState !== localStorage.getItem(LocalStorageKeyEnum.FACEBOOK_STATE)) {
    handlePageShareError(null, 'Error: State is not valid');
    return false;
  }
  return true;
};

const shareFacebookPage = async (): Promise<boolean> => {
  if (!route.query.code) {
    handlePageShareError(null, 'Query code is missing: ' + route?.query);
    return false;
  }

  try {
    const { data } = await api.clientCampaignsShareFBPageWithTanganica({
      authCode: route.query.code as string,
      redirectUri: getRedirectUrl(CallbackEnum.FACEBOOK_PAGE_SHARE),
    });

    if (data.isValid) {
      return true;
    }

    handlePageShareError(data.errorDescription);

    return false;
  } catch (error) {
    handlePageShareError(null, 'Error catch: ' + error);
    return false;
  }
};

const handlePageShareCallback = async () => {
  loadingStore.updateLoading(true);

  // Check if there is an error in url/state/code
  const facebookStatus = checkFacebookStatus();
  const stateStatus = checkState();
  const shareStatus = await shareFacebookPage();

  if (!facebookStatus || !stateStatus || !shareStatus) {
    loadingStore.updateLoading(false);
    return;
  }

  // Set the callback success to true - local storage - watch in the page share component
  callbackSuccess.value = true;

  loadingStore.updateLoading(false);

  // If everything is ok, close the window
  closeWindow();
};

const closeWindow = () => {
  window.close();
};

const handleRetry = async () => {
  isUserError.value = false;
  userError.value = undefined;
  const url = await getFacebookBusinessOauthUrl();
  if (!url) {
    handlePageShareError(null, 'Error: URL is missing');
    return;
  }

  // Redirect user to business share
  window.location.href = url;
};
</script>
