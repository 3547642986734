<template>
    <div class="div-primary relative">
        <div class="absolute top-1/2 -translate-y-1/2">
            <div v-if="contributionAvailable">
                {{ $t('affiliateProgram.components.affiliateActivePartners.creditText') }}
                <LocalizedValue :value="contributionAvailable"></LocalizedValue>
            </div>
            <div v-if="contributionTotal">
                {{ $t('affiliateProgram.components.affiliateActivePartners.eshopTable.totalCommission') }}
                <LocalizedValue :value="contributionTotal"></LocalizedValue>
            </div>
        </div>
    </div>
    <div v-if="eshop" class="div-primary">
        <div class="flex">
            <h1 class="mb-2 mr-2 inline-flex flex-1 text-2xl font-bold text-gray-800">
                {{ eshop.eshopUrl }}
            </h1>
        </div>
        <p class="text-gray-800 dark:text-[#cbd5e1]">
            {{ $t('affiliateProgram.components.affiliateActivePartners.eshopWidgets.startAffiliate') }}
            <LocalizedValue :value="{ value: eshop.affilStartDate, type: 'Date', format: 'short' }"></LocalizedValue>
        </p>
        <p class="text-gray-800 dark:text-[#cbd5e1]">
            {{ $t('affiliateProgram.components.affiliateActivePartners.eshopWidgets.actualProvision') }}
            <LocalizedValue :value="eshop.currentContributionPercentage"></LocalizedValue>
        </p>
        <p class="text-gray-800 dark:text-[#cbd5e1]">
            {{ $t('affiliateProgram.components.affiliateActivePartners.eshopWidgets.nextTime') }}
            <LocalizedValue :value="eshop.daysUntilNextPhase"></LocalizedValue>
        </p>

        <Timeline v-if="events" :value="events" layout="horizontal" align="bottom" class="!items-center">
            <template #marker="slotProps">
                <CheckCircleIcon v-if="slotProps.item.status === 'Expired'" class="align-items-center justify-content-center h-5 w-5 rounded-full bg-main p-1 text-white sm:h-8 sm:w-8" />
                <LockClosedIcon v-else-if="slotProps.item.status === 'Upcoming'" class="align-items-center justify-content-center h-5 w-5 rounded-full bg-gray-400 p-1 text-white sm:h-8 sm:w-8" />
                <div v-else class="h-5 w-5 rounded-full border border-main bg-white p-1 sm:h-8 sm:w-8 sm:border-2 sm:p-2">
                    <div class="align-items-center justify-content-center h-full w-full rounded-full bg-main text-white"></div>
                </div>
            </template>
            <template #opposite="slotProps">
                <LocalizedValue :value="{ value: slotProps.item.phaseStartDate, type: 'Date', format: 'short' }"></LocalizedValue>
            </template>
            <template #content="slotProps">
                <span class="!py-2 text-center text-xs sm:text-sm">{{ slotProps.item.phaseTitle }}</span>
            </template>
        </Timeline>
    </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, LockClosedIcon } from '@heroicons/vue/24/outline';
import Timeline from 'primevue/timeline';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { ClientDTOGetAffilInfoResponse } from '../../../../generated/api';

const { t } = useI18n();
const toast = useToast();
const loadingStore = useLoadingStore();
const eshopsStore = useEshopsStore();

const eshop = ref<ClientDTOGetAffilInfoResponse>();
const isDataLoaded = ref(false);
const events = ref();

const contributionAvailable = ref();
const contributionTotal = ref();

onMounted(async () => {
    await affilReport();
});

watch(
    () => eshopsStore.getSelectedEshop?.id,
    () => {
        affilReport();
    }
);

const affilReport = async () => {
    loadingStore.updateLoading(true);

    try {
        const [contributionAvailableResponse, contributionTotalResponse, affiliateInformation, graphDataResponse] = await Promise.all([
            api.clientAffiliateReportGetAffilContributionAvailable(),
            api.clientAffiliateReportGetAffiliateContributionTotal(),
            api.clientAffiliateReportGetAffilInfo(),
            api.clientAffiliateReportGetAffilGraphData(),
        ]);

        contributionAvailable.value = contributionAvailableResponse.data.contributionAvailable;
        contributionTotal.value = contributionTotalResponse.data.contributionTotal;
        eshop.value = affiliateInformation.data;
        events.value = graphDataResponse.data.phaseList;
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    } finally {
        loadingStore.updateLoading(false);
        isDataLoaded.value = true;
    }
};
</script>

<style scoped></style>
