export enum PUBLIC_ROUTES {
    LOGIN_PAGE = 'routesLoginPage',
    REGISTER_PAGE = 'routesRegisterPage',
    FORGOTTEN_PASSWORD_PAGE = 'routesForgottenPasswordPage',
    RESET_PASSWORD_PAGE = 'routesResetPasswordPage',
    CHANGE_EMAIL_PAGE = 'routesResetEmailPage',
    LOGIN_FAILED_PAGE = 'routesLoginFailedPage',
    LOGIN_ADMIN_PAGE = 'routesLoginAdminPage',
    SOCIAL_LOGIN_PAGE = 'routesSocialLoginPage',
    SOCIAL_CALLBACK_PAGE = 'routesSocialAuthPage',
    STRIPE_AUTO_TOPUP_CALLBACK_PAGE = 'routesStripeAutoTopupCallbackPage',
}
