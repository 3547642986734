import { EnumsCurrency } from '../../generated/api';

export interface ICreditValue {
    currencyCode: string; // ISO 4217
    recommendCreditValue: number;
}

const CreditValuesCodebook: Array<ICreditValue> = [
    {
        currencyCode: EnumsCurrency.Czk,
        recommendCreditValue: 20000,
    },
    {
        currencyCode: EnumsCurrency.Eur,
        recommendCreditValue: 800,
    },
];

export default CreditValuesCodebook;
