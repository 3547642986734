<template>
  <div class="fixed inset-0 h-screen w-screen bg-white dark:bg-mainDark">
    <!-- Tanganica Logo -->
    <router-link to="/" class="absolute left-2 top-2 z-30 w-fit cursor-pointer sm:top-[0.938rem] lg:top-[0.938rem]">
      <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="jungle" class="h-full max-h-[2.5rem] w-auto object-cover object-left sm:max-h-[3.125rem] xl:max-h-[3.75rem]" />
    </router-link>
    <div class="relative left-1/2 top-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2">
      <h2 class="ml-5 h-fit justify-center text-sm font-bold text-[#00467D] dark:text-gray-300 sm:mb-5 sm:text-3xl md:text-4xl lg:flex lg:text-4xl">
        {{ $t('authentication.resetPasswordCallback.title') }}
      </h2>
      <div class="relative mx-5 h-fit rounded-[2.5rem] bg-white p-2 pt-5 dark:bg-lightDark sm:mx-10 sm:p-5 lg:mx-20 lg:px-12">
        <div class="w-full flex-none justify-center px-10 py-4 lg:px-20 xl:px-24">
          <div class="mx-auto w-80 sm:w-[37.5rem]">
            <form @submit.prevent="onSubmit" class="space-y-6">
              <div class="col-span-2 mt-1 sm:mt-0 lg:col-span-1">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="password">
                  {{ $t('authentication.resetPasswordCallback.password') }}
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  <span class="ml-1 text-red-600">*</span>
                </label>
                <Password
                  v-model="password"
                  :inputProps="{ autocomplete: 'password' }"
                  :strong-regex="strongPasswordRegex"
                  :medium-regex="mediumPasswordRegex"
                  toggleMask
                  :promptLabel="$t('passwordComplexity.prompt')"
                  :weakLabel="$t('passwordComplexity.weak')"
                  :mediumLabel="$t('passwordComplexity.weak')"
                  :strongLabel="$t('passwordComplexity.ok')"
                  :input-class="'!rounded-full'"
                >
                  <template #footer>
                    <div v-if="!allRequirementsMet">
                      <Divider />
                      <p class="mt-2 font-bold">
                        {{ $t('passwordComplexity.requirements') }}
                      </p>
                      <ul class="ml-2 mt-0 pl-2" style="line-height: 1.5">
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasLowercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule1') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasUppercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule2') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasNumeric" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule3') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="isMinLength" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule4') }}
                        </li>
                      </ul>
                    </div>
                  </template>
                </Password>
                <small class="text-sm text-red-600">{{ errors.password || '&nbsp;' }}</small>
              </div>
              <div class="col-span-2 mt-1 sm:mt-0 lg:col-span-1">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="password">
                  {{ $t('authentication.resetPasswordCallback.passwordAgain') }}
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  <span class="ml-1 text-red-600">*</span>
                </label>
                <Password v-model="passwordConfirmation" :feedback="false" :inputProps="{ autocomplete: 'new-password' }" :input-class="'!rounded-full'" />
                <small class="text-sm text-red-600">{{ errors.passwordConfirmation || '&nbsp;' }}</small>
              </div>
              <button type="submit" class="mx-auto mt-5 block w-3/4 rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] lg:w-2/4 lg:text-base">
                <strong>{{ $t('authentication.resetPasswordCallback.resetPass') }}</strong>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="openDialog" modal :closable="false" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t('authentication.resetPasswordCallback.dialogTitle')"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="$t('authentication.resetPasswordCallback.dialogDescription')"></p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton type="button" :text="$t('authentication.resetPasswordCallback.buttonTextLogin')" :hover="false" @click="redirectToLogin()" class="mx-auto mt-5 bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]">
        </MainButton>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Password from 'primevue/password';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { useLoadingStore } from '@/stores/loading';

onMounted(() => {
  tokenExist(token);
});

const toast = useToast();
const { t } = useI18n();
const url = new URL(window.location.href);
const token = url.searchParams.get('token');

const router = useRouter();
const authStore = useAuthStore();
const loadingStore = useLoadingStore();

// For Dialog
const openDialog = ref(false);

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: yup.object({
    password: yup.string().required(() => t('validations.required')),
    passwordConfirmation: yup
      .string()
      .required(() => t('validations.required'))
      .oneOf([yup.ref('password'), ''], () => t('validations.confirmed')),
  }),
  initialValues: {
    password: '',
    passwordConfirmation: '',
  },
});

// Form field
const [password] = defineField('password');
const [passwordConfirmation] = defineField('passwordConfirmation');

// Password for checking complexity
const passwordValue = computed(() => password.value);
const strongPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
const mediumPasswordRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
const hasLowercase = computed(() => /[a-z]/.test(passwordValue.value));
const hasUppercase = computed(() => /[A-Z]/.test(passwordValue.value));
const hasNumeric = computed(() => /\d/.test(passwordValue.value));
const isMinLength = computed(() => passwordValue.value.length >= 8);
const allRequirementsMet = computed(() => hasLowercase.value && hasUppercase.value && hasNumeric.value && isMinLength.value);

const onSubmit = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);

  try {
    const payload = {
      newPassword: formData.password,
      confirmNewPassword: formData.passwordConfirmation,
    };
    await api.clientCallbacksResetPassword(payload, {
      headers: {
        Authorization: `Bearer ${authStore.userAccessToken}`,
      },
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });
    openDialog.value = true;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});

const tokenExist = async (token: string | null) => {
  if (token) {
    authStore.setUserAccessToken(token);
  } else {
    await router.push({ name: PUBLIC_ROUTES.LOGIN_PAGE });
  }
};

const redirectToLogin = () => {
  router.push({ name: PUBLIC_ROUTES.LOGIN_PAGE });
};
</script>
