<template>
  <div class="m-auto w-full">
    <div ref="checkoutSetupRef"></div>
  </div>
</template>
<script setup lang="ts">
import { loadStripe } from '@stripe/stripe-js/pure';
import { useToast } from 'primevue/usetoast';
import { ref, onUnmounted, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { CREDIT_CHECKOUT_TYPES } from '@/modules/rechargeCredit/enums/CreditCheckoutTypes';
import { getStripeRedirectURL } from '@/modules/rechargeCredit/utils/getStripeRedirectURL';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';

const checkoutSetupRef = ref<HTMLElement | null>(null);
const loadingStore = useLoadingStore();
const eshopsStore = useEshopsStore();
const stripeInstance = ref();
const toast = useToast();
const { t } = useI18n();
const checkout = ref();

const props = defineProps({
  isSubscripion: {
    type: Boolean,
    required: false,
    default: false,
  },
  subscriptionType: {
    type: String,
    required: false,
    default: '',
  },
});

onMounted(() => {
  initCheckout();
});

const initCheckout = async () => {
  loadingStore.updateLoading(true);
  try {
    const checkoutType = props.isSubscripion ? CREDIT_CHECKOUT_TYPES.SUBSCRIPTION : CREDIT_CHECKOUT_TYPES.AUTOMATIC_RECHARGE;
    const { data } = await api.clientRechargeCreditCreateSetup({
      callbackUrl: getStripeRedirectURL(checkoutType, props?.subscriptionType),
    });

    if (data.clientSecret === '') {
      handleError();
      return;
    }

    // Init Stripe instance
    if (!stripeInstance.value) {
      stripeInstance.value = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    }

    if (!stripeInstance.value) {
      handleError();
      return;
    }

    // Destroy old checkout
    if (checkout.value) {
      checkout.value.destroy();
    }

    checkout.value = await stripeInstance.value.initEmbeddedCheckout({
      clientSecret: data.clientSecret,
    });

    checkout.value.mount(checkoutSetupRef.value);
  } catch (error) {
    handleError();
  } finally {
    loadingStore.updateLoading(false);
  }
};

const handleError = () => {
  toast.add({
    severity: 'error',
    summary: t('rechargeCredit.errorCheckoutTitle'),
    detail: '',
    life: 5000,
  });
};

onUnmounted(() => {
  if (checkout.value) {
    checkout.value.destroy();
  }
});
</script>
