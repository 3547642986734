import { defineStore } from 'pinia';

interface IState {
    name: string;
    email: string;
    googleId: string;
    facebookId: string;
    promoCode: string;
    provider: string;
}

export const useRegisterStore = defineStore('register', {
    state: (): IState => ({
        name: '',
        email: '',
        googleId: '',
        facebookId: '',
        promoCode: '',
        provider: '',
    }),
    actions: {
        reset() {
            this.$reset();
        },
    },
    persist: true,
});
