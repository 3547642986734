import { createWebHistory, createRouter } from 'vue-router';
import {CLIENT_ROUTES} from "@/enums/Routes/ClientRoutesEnum";
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import routes from '@/router/routes';
import { useUserStore } from '@/stores/user';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const langParameter = userStore.getLanguageCode;

    // If the route doesn't have a language parameter or language is different, redirect to the same route with the correct language
    if (!to.params.lang || to.params.lang !== langParameter) {
        return next(
            {
                name: to.name as string,
                params: {
                    ...to.params,
                    lang: langParameter
                },
                query: to.query
            });
    }

    // Redirect logic
    if (to.meta.client && !to.meta.guest && !userStore.getIsLoggedIn) {
        next({
            name: PUBLIC_ROUTES.LOGIN_PAGE,
            params: { lang: langParameter },
        });
    } else if (!to.meta.client && to.meta.guest && userStore.getIsLoggedIn) {
        next({
            name: CLIENT_ROUTES.DASHBOARD_PAGE,
            params: { lang: langParameter },
        });
    }  else {
    if (to.meta.permissions) {
        const permissions = userStore.permissions;
  
        if (to.meta.permissions.condition === 'AND') {
          if (to.meta.permissions.list.every((permission) => permissions.includes(permission))) {
            next();
          } else {
            userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog
            next({
              name:  from.name as string,
            });
          }
        } else {
          if (to.meta.permissions.list.some((permission) => permissions.includes(permission))) {
            next();
          } else {
            userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog
            next({
              name:  from.name as string,
            });
          }
        }
        } else {
            next();
        }
    }
});

export default router;
