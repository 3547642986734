import { RouteRecordRaw } from 'vue-router';
import { AI_ASSISTANT_ROUTES } from '@/modules/aiAssistant/enums/RoutesEnum';
import AiAssistantPage from '@/modules/aiAssistant/pages/Index.vue';
import { EnumsClientPermission } from '../../../../generated/api';


const routes: Array<RouteRecordRaw> = [
  {
    path: 'aiassistant',
    name: AI_ASSISTANT_ROUTES.INDEX,
    component: AiAssistantPage,
    meta: {
      permissions: {
        list: [EnumsClientPermission.AiContentGeneration],
        condition: 'or',
      },
   },
  },
];

export default routes;
