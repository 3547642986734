<template>
  <div class="mt-10 rounded-lg bg-white p-6 shadow">
    <h2 class="mb-5 text-left text-2xl text-main">{{ $t('subscription.text24') }}</h2>
    <div v-if="subscriptionHistory && subscriptionHistory?.length > 0">
      <ul id="HEADER" class="mt-5 flex w-full grow items-center justify-center">
        <li class="w-full text-center text-xs font-bold text-main sm:text-base">{{ $t('subscription.text25') }}</li>
        <li class="w-full text-center text-xs font-bold text-main sm:text-base">{{ $t('subscription.text26') }}</li>
        <li class="w-full text-center text-xs font-bold text-main sm:text-base">{{ $t('subscription.text27') }}</li>
        <li class="w-full text-center text-xs font-bold text-main sm:text-base">{{ $t('subscription.text28') }}</li>
      </ul>
      <ul id="BODY" v-for="(payment, index) in subscriptionHistory" :key="index" class="mt-3 flex w-full grow items-center justify-center">
        <li class="w-full text-center text-xs text-main sm:text-base"><LocalizedValue :value="{ value: payment.from, type: 'Date', format: 'short' }"></LocalizedValue></li>
        <li class="w-full text-center text-xs text-main sm:text-base">
          <p class="text-center text-xs text-main sm:text-base">{{ $t('subscription.tanganicaPremium') }}</p>
          <p class="text-center text-xs text-main sm:text-base">
            <LocalizedValue :value="{ value: payment.from, type: 'Date', format: 'short' }"></LocalizedValue> - <LocalizedValue :value="{ value: payment.to, type: 'Date', format: 'short' }"></LocalizedValue>
          </p>
        </li>
        <li class="w-full text-center text-xs text-main sm:text-base">{{ payment.isCardPayment ? $t('subscription.text30') : $t('subscription.text29') }}</li>
        <li class="w-full text-center text-xs text-main sm:text-base"><LocalizedValue :value="payment.amount"></LocalizedValue></li>
      </ul>
    </div>
    <div v-else class="mt-5 text-center text-main">{{ $t('subscription.text31') }}</div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { ClientDTOSubscriptionViewModel } from '../../../../generated/api';

const subscriptionHistory = ref<ClientDTOSubscriptionViewModel[]>();
const eshopsStore = useEshopsStore();

onMounted(async () => {
  await getSubscriptionHistory();
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getSubscriptionHistory();
  }
);

const getSubscriptionHistory = async () => {
  const { data } = await api.clientRechargeCreditGetSubscriptionHistory();
  subscriptionHistory.value = data?.subscriptionsHistory || [];
  console.log(data);
};
</script>
