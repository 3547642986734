/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsCountryOfAdvertisement = {
    Cz: 'CZ',
    Sk: 'SK',
    Pl: 'PL',
    Hu: 'HU',
    Ro: 'RO',
    It: 'IT',
    At: 'AT',
    Be: 'BE',
    Dk: 'DK',
    Fi: 'FI',
    Fr: 'FR',
    De: 'DE',
    Gr: 'GR',
    Ie: 'IE',
    Nl: 'NL',
    No: 'NO',
    Es: 'ES',
    Se: 'SE',
    Ch: 'CH',
    Gb: 'GB',
    Pt: 'PT'
} as const;

export type EnumsCountryOfAdvertisement = typeof EnumsCountryOfAdvertisement[keyof typeof EnumsCountryOfAdvertisement];



