<template>
  <div class="mt-10 rounded-lg bg-white p-6 shadow">
    <h1 class="text-left text-main md:text-xl lg:text-3xl">{{ $t('subscription.text34') }}</h1>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <div v-for="item in subscriptionFunction" :key="item.title" class="div-primary mx-auto w-full rounded-lg bg-white p-5 shadow-lg shadow-gray-500">
        <h2 class="text-center text-main sm:text-lg md:text-lg">{{ item.title }}</h2>
        <p class="mt-5 text-center text-gray-400">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const subscriptionFunction = [
  {
    title: t('subscription.detailedStatisticsTitle'),
    description: t('subscription.detailedStatisticsDescription'),
  },
  {
    title: t('subscription.campaignBudgetSettingsTitle'),
    description: t('subscription.campaignBudgetSettingsDescription'),
  },
  {
    title: t('subscription.splitCampaignStatisticsTitle'),
    description: t('subscription.splitCampaignStatisticsDescription'),
  },
  {
    title: t('subscription.aiContentCreationTitle'),
    description: t('subscription.aiContentCreationDescription'),
  },
  {
    title: t('subscription.aiMarketingAssistantTitle'),
    description: t('subscription.aiMarketingAssistantDescription'),
  },
];
</script>
