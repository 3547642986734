<template>
  <ContentBlock :menuCardItems="menuCardItems" v-if="router.currentRoute.value.name === AICONTENT_ROUTES.SOCIAL_MEDIA" />
  <RouterView v-else />
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ContentBlock from '@/modules/aicontent/components/ContentBlock.vue';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
const { t } = useI18n();
const router = useRouter();
const menuCardItems = [
  {
    name: t('socialMedia.GenerateSocialMediaContentIdeaFacebookHeader'),
    description: t('socialMedia.GenerateSocialMediaContentIdeaFacebookDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentIdeaFacebook',
    imgsrc: '/socialContent/ContentIdeaFacebook.png',
  },
  {
    name: t('socialMedia.GenerateSocialMediaContentIdeaInstagramHeader'),
    description: t('socialMedia.GenerateSocialMediaContentIdeaInstagramDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentIdeaInstagram',
    imgsrc: '/socialContent/ContentIdeaInstagram.png',
  },
  {
    name: t('socialMedia.GenerateSocialMediaContentIdeaLinkedinHeader'),
    description: t('socialMedia.GenerateSocialMediaContentIdeaLinkedinDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentIdeaLinkedin',
    imgsrc: '/socialContent/ContentIdeaLinkedin.png',
  },
  {
    name: t('socialMedia.GenerateSocialMediaContentFacebookHeader'),
    description: t('socialMedia.GenerateSocialMediaContentFacebookDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentFacebook',
    imgsrc: '/socialContent/ContentFacebook.png',
  },
  {
    name: t('socialMedia.GenerateSocialMediaContentInstagramHeader'),
    description: t('socialMedia.GenerateSocialMediaContentInstagramDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentInstagram',
    imgsrc: '/socialContent/ContentInstagram.png',
  },
  {
    name: t('socialMedia.GenerateSocialMediaContentLinkedinHeader'),
    description: t('socialMedia.GenerateSocialMediaContentLinkedinDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'GenerateSocialMediaContentLinkedin',
    imgsrc: '/socialContent/ContentLinkedin.png',
  },
  {
    name: t('socialMedia.YoutubeIdeaHeader'),
    description: t('socialMedia.YoutubeIdeaDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'YoutubeIdea',
    imgsrc: '/socialContent/YoutubeIdea.png',
  },
  {
    name: t('socialMedia.YoutubeIdeaNameHeader'),
    description: t('socialMedia.YoutubeIdeaNameDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'YoutubeIdeaName',
    imgsrc: '/socialContent/YoutubeIdeaName.png',
  },
  {
    name: t('socialMedia.ContentStrategyFacebookHeader'),
    description: t('socialMedia.ContentStrategyFacebookDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'ContentStrategyFacebook',
    imgsrc: '/socialContent/ContentStrategyFacebook.png',
  },
  {
    name: t('socialMedia.ContentStrategyInstagramHeader'),
    description: t('socialMedia.ContentStrategyInstagramDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'ContentStrategyInstagram',
    imgsrc: '/socialContent/ContentStrategyInsta.png',
  },
  {
    name: t('socialMedia.ContentStrategyLinkedinHeader'),
    description: t('socialMedia.ContentStrategyLinkedinDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'ContentStrategyLinkedin',
    imgsrc: '/socialContent/ContentStrategyLinkedin.png',
  },
  {
    name: t('socialMedia.ContentStrategyYoutubeHeader'),
    description: t('socialMedia.ContentStrategyYoutubeDescription'),
    hrefName: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
    type: 'ContentStrategyYoutube',
    imgsrc: '/socialContent/ContentStrategyYoutube.png',
  },
];
</script>
