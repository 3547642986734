<template>
  <div class="fixed inset-0 mx-auto h-screen w-screen overflow-auto bg-white dark:bg-mainDark">
    <div class="absolute right-0 top-0 z-10 mt-2 w-fit sm:mt-4">
      <SelectLanguage></SelectLanguage>
    </div>
    <!-- Tanganica Logo -->
    <router-link to="/" class="absolute left-2 top-2 z-30 w-fit cursor-pointer sm:top-[0.938rem] lg:top-[0.938rem]">
      <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="jungle" class="h-full max-h-[2.5rem] w-auto object-cover object-left sm:max-h-[3.125rem] xl:max-h-[3.75rem]" />
    </router-link>
    <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:mt-10 sm:px-6 lg:mt-0 lg:px-8">
      <div class="sm:mx-auto sm:w-full">
        <h1 class="text-center text-xl font-extrabold text-main dark:bg-mainDark sm:text-4xl lg:text-5xl">
          {{ $t('authentication.register.title') }}
        </h1>
        <h2 class="mt-2 text-center text-sm font-bold tracking-tight text-[#FF9900] lg:text-4xl">
          {{ $t('authentication.register.title2') }}
        </h2>
      </div>

      <div class="mt-0 sm:mx-auto sm:w-full sm:max-w-[37.5rem]">
        <div class="bg-white px-6 py-2 dark:bg-mainDark sm:rounded-lg sm:px-12 sm:py-6">
          <form @submit.prevent="onSubmit">
            <!-- User data grid form -->

            <div class="grid w-full grid-cols-2 gap-x-2 lg:gap-6 lg:gap-y-3">
              <div class="col-span-2 mt-0 lg:col-span-2">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="email">
                  {{ $t('authentication.register.email') }}
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  <span class="ml-1 text-red-600">*</span>
                </label>
                <InputText v-model="email" name="email" type="text" placeholder="" autocomplete="email" class="!rounded-full"></InputText>
                <small class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
              </div>
              <div class="col-span-2 mt-0 lg:col-span-2">
                <label class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="password">
                  {{ $t('authentication.register.password') }}
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  <span class="ml-1 text-red-600">*</span>
                </label>
                <Password
                  v-model="password"
                  name="password"
                  autocomplete="off"
                  :inputProps="{ autocomplete: 'password' }"
                  :strong-regexp="strongPasswordRegex"
                  :medium-regexp="mediumPasswordRegex"
                  toggleMask
                  :promptLabel="$t('passwordComplexity.prompt')"
                  :weakLabel="$t('passwordComplexity.weak')"
                  :mediumLabel="$t('passwordComplexity.weak')"
                  :strongLabel="$t('passwordComplexity.ok')"
                  :input-class="'!rounded-full mb-2'"
                >
                  <template #footer>
                    <div v-if="!allRequirementsMet">
                      <Divider />
                      <p class="mt-2 font-bold">
                        {{ $t('passwordComplexity.requirements') }}
                      </p>
                      <ul class="ml-2 mt-0 pl-2" style="line-height: 1.5">
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasLowercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule1') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasUppercase" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule2') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="hasNumeric" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule3') }}
                        </li>
                        <li class="flex items-center">
                          <CheckCircleIcon v-if="isMinLength" class="h-6 w-6 text-green-600"> </CheckCircleIcon>
                          <XCircleIcon v-else class="h-6 w-6 text-red-600"></XCircleIcon>
                          {{ $t('passwordComplexity.rule4') }}
                        </li>
                      </ul>
                    </div>
                  </template>
                </Password>
                <small class="absolute mb-5 text-sm text-red-600">{{ errors.password || '&nbsp;' }}</small>
                <InputText v-model="checkIsBotFirstInput" tabindex="-1" name="checkIsBotFirstInput" id="checkIsBotFirstInput" autocomplete="off" :class="'!rounded-full !opacity-0 !h-0 !w-0 absolute'" style="display: none"> </InputText>
                <InputText v-model="checkIsBotSecondInput" tabindex="-1" name="checkIsBotSecondInput" id="checkIsBotSecondInput" autocomplete="off" :class="'!rounded-full !opacity-0 !h-0 !w-0 absolute'" style="display: none"> </InputText>
              </div>
            </div>
            <p class="col-span-2 my-2 mt-5 text-xs font-bold text-gray-700 dark:text-[#cbd5e1] md:text-sm">
              {{ $t('authentication.register.registerConsent') }}
              <a href="https://www.tanganica.com/cs/gdpr" target="_blank" class="cursor-pointer underline hover:text-blue-400 hover:underline">{{ $t('authentication.register.gdpr') }} </a>
              <span> {{ $t('authentication.register.and') }} </span>
              <a href="https://www.tanganica.com/cs/terms-conditions" target="_blank" class="cursor-pointer underline hover:text-blue-400 hover:underline">{{ $t('authentication.register.vop') }} </a>
            </p>
            <button
              @click="toast.removeAllGroups()"
              type="submit"
              class="mx-auto mt-5 block w-3/4 rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] lg:w-2/4 lg:text-base"
            >
              <strong>{{ $t('authentication.register.register') }}</strong>
            </button>
          </form>
          <div v-if="$theme('socialLogin')">
            <div class="relative mt-5">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200" />
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-gray-900 dark:bg-mainDark dark:text-gray-300">{{ $t('authentication.register.orContinue') }}</span>
              </div>
            </div>

            <div class="mx-auto mt-5">
              <div v-if="$theme('socialLogin')">
                <SocialAuth :type="SocialAuthType.REGISTER" />
              </div>
            </div>
          </div>
          <p class="mt-5 text-center text-sm text-gray-500 2xl:mt-5">
            <router-link
              :to="{
                name: PUBLIC_ROUTES.LOGIN_PAGE,
              }"
              class="text-gray-400 hover:text-[#FF9900] hover:underline dark:text-gray-300 dark:hover:text-gray-400"
            >
              <span
                >{{ $t('authentication.register.logLink') }} <span class="text-[#FF9900]">{{ $t('authentication.register.logLinkText') }}</span></span
              >
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { usePrimeVue } from 'primevue/config';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import SelectLanguage from '@/modules/authentication/components/SelectLanguage.vue';
import SocialAuth from '@/modules/authentication/components/SocialAuth.vue';
import { SocialAuthType } from '@/modules/authentication/enums/SocialAuthType';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { handleLoginAndEshopList } from '@/utils/handleLoginAndEshopList';
import { EnumsLocale } from '../../../../generated/api';

const loadingStore = useLoadingStore();
const userStore = useUserStore();
const gtm = useGtm();
const toast = useToast();
const { t } = useI18n();
const primeVue = usePrimeVue();
const checkIsBotFirstInput = ref(null);
const checkIsBotSecondInput = ref(null);

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .email(() => t('validations.email'))
      .required(() => t('validations.required')),
    password: yup.string().required(() => t('validations.required')),
  }),
  initialValues: {
    email: '',
    password: '',
  },
});

// Form fields
const [email] = defineField('email');
const [password] = defineField('password');

// Password for checking complexity
const passwordValue = computed(() => password.value);
const strongPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
const mediumPasswordRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
const hasLowercase = computed(() => /[a-z]/.test(passwordValue.value));
const hasUppercase = computed(() => /[A-Z]/.test(passwordValue.value));
const hasNumeric = computed(() => /\d/.test(passwordValue.value));
const isMinLength = computed(() => passwordValue.value.length >= 8);

const allRequirementsMet = computed(() => hasLowercase.value && hasUppercase.value && hasNumeric.value && isMinLength.value);

const onSubmit = handleSubmit(async (formData) => {
  if (checkIsBotFirstInput.value !== null || checkIsBotSecondInput.value !== null) {

    gtm?.trackEvent({
      event: 'gtm.catchTheDeadBot',
      value: {
        email: email.value,
        password: password.value,
        checkIsBotFirstInput: checkIsBotFirstInput.value,
        checkIsBotSecondInput: checkIsBotSecondInput.value,
      },
    });
    return;
  }
  loadingStore.updateLoading(true);
  const postParams = {
    email: formData.email,
    password: formData.password,
    locale: userStore.getLanguageCode as EnumsLocale,
  };
  try {
    const { data: registerResponse } = await api.clientAuthRegister(postParams, {
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });

    await handleLoginAndEshopList(primeVue, registerResponse.accessToken, registerResponse.refreshToken);

    sendGtmEvent('OK', null, postParams);
  } catch (error: any) {
    sendGtmEvent('ERROR', error, postParams);
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});

const sendGtmEvent = (status, errorType, params) => {
  const url = new URL(window.location.href);
  const clickID = url.searchParams.get('_gac') ? url.searchParams.get('_gac')?.split('.').pop() : null;

  if (params.password) {
    params.password = '********';
  }
  gtm?.trackEvent({
    event: 'gtm.registerForm',
    status: status,
    errorType: errorType,
    value: {
      registerData: params,
      clickID: clickID,
    },
  });
};
</script>
<style scoped>
label {
  @apply dark:text-gray-300;
}
</style>
