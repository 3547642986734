export enum ErrorEnum {
    EXPIRED_AUTH_CODE = 'ExpiredAuthCode',
    USED_AUTH_CODE = 'UsedAuthCode',
    EXPIRED_TOKEN = 'ExpiredToken',
    INSUFFICIENT_BUSINESS_PERMISSIONS = 'InsufficientBusinessPermissions',
    INSUFFICIENT_PAGE_PERMISSIONS = 'InsufficientPagePermissions',
    USER_CANCELED = 'UserCanceled',
    NO_ACCOUNTS_SHARED = 'NoAccountsShared',
    TWO_PLUS_ACCOUNTS_SHARED = 'TwoPlusAccountsShared',
    API_GENERAL_ERROR = 'APIGeneralError',
}
