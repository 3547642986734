<template>
  <Dropdown
    v-model="selectedLanguage"
    @change="onChange"
    :options="LanguagesCodebook"
    optionLabel="text"
    class="w-full justify-items-center rounded-xl rounded-r-none"
    :ptOptions="{ mergeProps: true }"
    :pt="{ wrapper: '!max-h-[23.7rem] sm:!max-h-[23.12rem]', input: 'sm:!p-2' }"
  >
    <template #value="slotProps">
      <div class="align-items-center flex">
        <img :alt="slotProps.value.text" :src="`${$filePath}/${slotProps.value.flag}`" class="h-4 w-4 rounded-full sm:h-5 sm:w-5" />
      </div>
    </template>
    <template #option="slotProps">
      <div class="align-items-center flex items-center dark:bg-mainDark" style="border-radius: 35px; margin-right: 5px">
        <img :alt="slotProps.option.text" :src="`${$filePath}/${slotProps.option.flag}`" class="mr-2 h-4 w-4 sm:h-5 sm:w-5" />
        <div class="text-sm sm:text-base">{{ slotProps.option.text }}</div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { usePrimeVue } from 'primevue/config';
import Dropdown, { DropdownChangeEvent } from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LanguagesCodebook, { ILanguage } from '@/codebooks/LanguagesCodebook';
import { api } from '@/services/api';
import { useUserStore } from '@/stores/user';
import { EnumsLocale } from '../../../../generated/api';

const props = defineProps({
  saveToDb: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const router = useRouter();
const userStore = useUserStore();
const toast = useToast();
const { t } = useI18n();
const primeVue = usePrimeVue();

const selectedLanguage = ref<ILanguage>({
  code: '' as EnumsLocale,
  text: '',
  flag: '',
});

onMounted(() => {
  initLanguage();
});

const isChanged = computed(() => {
  return selectedLanguage.value.code !== userStore.getLanguageCode;
});

const initLanguage = () => {
  selectedLanguage.value = LanguagesCodebook.find((language: ILanguage) => {
    return userStore.getLanguageCode === language.code;
  }) as ILanguage;
};

const onChange = async (changeEvent: DropdownChangeEvent) => {
  if (!isChanged.value) {
    return;
  }

  await userStore.setLanguage(changeEvent.value.code, primeVue);

  if (props.saveToDb) {
    await saveLanguage(changeEvent.value.code as EnumsLocale);
  }

  await router.push({
    name: router.currentRoute.value.name as string,
    params: { lang: changeEvent.value.code },
  });
};

const saveLanguage = async (langCode: EnumsLocale) => {
  const payload = {
    locale: langCode,
  };

  try {
    await api.clientUserSettingsSetLocale(payload, {
      metadata: {
        accessToken: 'user',
      },
    });

    toast.add({
      severity: 'success',
      summary: t('global.selectLanguage.success'),
      life: 3000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};
</script>
