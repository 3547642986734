<template>
    <div class="h-full w-full">
        <h1 class="mb-3 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">{{ $t('eshopSettings.userWithAssets.userWithAssetsTitle') }}</h1>
        <div class="w-full shadow-lg">
            <DataTable :value="usersWithAssets" stripedRows>
                <Column field="email" header="Email"> </Column>
                <Column field="userId" header="">
                    <template #body="slotProps">
                        <XCircleIcon v-if="canRemove" @click="removeUserFromEshop(slotProps.data.userId)" class="h-6 w-6 cursor-pointer text-red-600 hover:text-red-700" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script setup lang="ts">
import { XCircleIcon } from '@heroicons/vue/24/solid';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const usersWithAssets = ref();
onMounted(async () => {
    await getUsersWithAssets();
});

const userStore = useUserStore();
const loadingStore = useLoadingStore();

const { t } = useI18n();
const toast = useToast();

const getUsersWithAssets = async () => {
    loadingStore.updateLoading(true);
    try {
        const { data } = await api.clientEshopSettingsGetUsersWithAccess();
        usersWithAssets.value = data.users;
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    } finally {
        loadingStore.updateLoading(false);
    }
};

const removeUserFromEshop = async (userId) => {
    loadingStore.updateLoading(true);

    const payload = {
        userId: userId,
    };
    try {
        await api.clientEshopSettingsRemoveUserFromEshop(payload);
        await getUsersWithAssets();
        toast.add({ severity: 'success', summary: t('eshopSettings.userWithAssets.userWithAssetsSuccess'), life: 3000 });
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    } finally {
        loadingStore.updateLoading(false);
    }
};

const canRemove = () => {
    return userStore.email !== slotProps.data.email || !userStore.role.includes('EshopOwner');
};
</script>
