<template>
  <div class="mt-4 rounded-md">
    <Menubar class="bg-main" :model="menuItems" :pt-options="{ mergeProps: true }" :pt="{ menu: '!gap-2', button: '!text-white !bg-main' }">
      <template #item="{ item, props }">
        <router-link v-if="item.route" v-slot="{ href, navigate, isActive }" :to="{ name: item.route }" custom>
          <a
            :href="href"
            v-bind="props.action"
            @click="navigate"
            :class="['flex items-center rounded-md text-main hover:text-main dark:text-gray-500 hover:dark:text-gray-500 sm:text-white', { 'bg-gray-300 !text-main dark:bg-gray-300 dark:text-gray-500 sm:bg-white sm:dark:bg-gray-200': isActive }]"
          >
            <component :is="item.icon" class="h-6 w-6"> </component>
            <span class="ml-2 h-full">{{ item.label }}</span>
          </a>
        </router-link>
      </template>
    </Menubar>

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { BanknotesIcon, QueueListIcon, DocumentTextIcon, SquaresPlusIcon } from '@heroicons/vue/24/outline';
import Menubar from 'primevue/menubar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const user = useUserStore();

const menuItems = ref([
  {
    label: t('rechargeCredit.rechargeCredit.title'),
    icon: BanknotesIcon,
    route: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.PAYMENT_GATEWAY_PAGE : CLIENT_ROUTES.PAYMENT_PAGE, // Iframe hacks
  },
  {
    label: t('rechargeCredit.rechargeCredit.subscription'),
    icon: SquaresPlusIcon,
    route: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.SUBSCRIPTION_PAGE : CLIENT_ROUTES.SUBSCRIPTION_PAGE,
  }, // Iframe hacks
  {
    label: t('rechargeCredit.rechargeCredit.paymentHistory'),
    icon: QueueListIcon,
    route: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.PAYMENT_HISTORY_PAGE : CLIENT_ROUTES.PAYMENT_HISTORY_PAGE,
  },
  {
    label: t('rechargeCredit.rechargeCredit.billingInfo'),
    icon: DocumentTextIcon,
    route: user.getTheme !== ThemeEnum.DEFAULT ? FULL_PAGE_ROUTES.BILLING_INFO_PAGE : CLIENT_ROUTES.BILLING_INFO_PAGE,
  },
]);
</script>
