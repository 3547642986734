import { RouteRecordRaw } from 'vue-router';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
import Blog from '@/modules/aicontent/pages/Blog.vue';
import ContentCreatePage from '@/modules/aicontent/pages/ContentCreate.vue';
import Email from '@/modules/aicontent/pages/Email.vue';
import AiContentPage from '@/modules/aicontent/pages/Index.vue';
import SocialMedia from '@/modules/aicontent/pages/SocialMedia.vue';
import { EnumsClientPermission } from '../../../../generated/api';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'aicontent',
    name: AICONTENT_ROUTES.INDEX,
    component: AiContentPage,
    meta: {
      permissions: {
        list: [EnumsClientPermission.AiContentGeneration],
        condition: 'or',
      },
    },
    children: [
      {
        path: 'socialMedia',
        name: AICONTENT_ROUTES.SOCIAL_MEDIA,
        component: SocialMedia,
        children: [
          {
            path: ':type',
            props: (route) => ({ type: route.params.type }),
            name: AICONTENT_ROUTES.CONTENT_CREATE_SOCIAL_MEDIA,
            component: ContentCreatePage,
          }
        ]
      },
      {
        path: 'blog',
        name: AICONTENT_ROUTES.BLOG,
        component: Blog,
        children: [
          {
            path: ':type',
            props: (route) => ({ type: route.params.type }),
            name: AICONTENT_ROUTES.CONTENT_CREATE_BLOG,
            component: ContentCreatePage,
          }
        ]
      },
      {
        path: 'email',
        name: AICONTENT_ROUTES.EMAIL,
        component: Email,
        children: [
          {
            path: ':type',
            props: (route) => ({ type: route.params.type }),
            name: AICONTENT_ROUTES.CONTENT_CREATE_EMAIL,
            component: ContentCreatePage,
          }
        ]
      },
    ]
  },
];

export default routes;
