export const inputswitch = {
    root: ({ props }) => ({
        class: [
            'inline-block relative',
            'w-12 h-7',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
            },
        ],
    }),
    slider: ({ props }) => ({
        class: [
            'absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent',
            'transition-colors duration-200 rounded-2xl',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            "before:absolute before:content-'' before:top-1/2 before:bg-white before:dark:bg-gray-900 before:w-5 before:h-5 before:left-1 before:-mt-2.5 before:rounded-full before:transition-duration-200",
            {
                'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 hover:dark:bg-gray-700 ': !props.modelValue,
                'bg-green-500 before:transform before:translate-x-5': props.modelValue,
            },
        ],
    }),
};
