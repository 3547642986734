<template>
  <div v-if="trialPeriodEndDate > 0 && isDataLoaded" class="div-primary relative mx-auto max-h-10 w-full rounded-full md:max-h-14">
    <div class="absolute top-1/2 flex -translate-y-1/2 text-main">
      <span class="mr-2 text-sm font-bold md:text-base">{{ $t('dashboard.trial.text') }}</span>
      <span v-if="trialPeriodEndDate >= 0" class="text-sm md:text-base">{{ $t('dashboard.trial.remainingTime', { days: trialPeriodEndDate }) }}</span>
      <span v-else class="text-sm text-red-600 md:text-base">{{ $t('dashboard.trial.end') }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';

const { t } = useI18n();
const toast = useToast();
const trialPeriodEndDate = ref(-5);
const isDataLoaded = ref(false);

onMounted(async () => {
  try {
    isDataLoaded.value = false;
    const { data } = await api.clientDashboardGetRemainingTrialPeriod();
    trialPeriodEndDate.value = data.daysRemaining ?? -5;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    isDataLoaded.value = true;
  }
});
</script>
