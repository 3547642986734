import { ErrorEnum } from '@/modules/campaigns/enums/ErrorEnum';

export interface IPageShareError {
  code: ErrorEnum;
  text: string; // i18n key
  images: {
    url: string;
    alt: string;
  }[];
}

const ErrorsCodebook: Array<IPageShareError> = [
  {
    code: ErrorEnum.EXPIRED_AUTH_CODE,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.expiredAuthCode',
    images: [],
  },
  {
    code: ErrorEnum.USED_AUTH_CODE,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.usedAuthCode',
    images: [],
  },
  {
    code: ErrorEnum.EXPIRED_TOKEN,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.expiredToken',
    images: [],
  },
  {
    code: ErrorEnum.INSUFFICIENT_BUSINESS_PERMISSIONS,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.insufficientBusinessPermissions',
    images: [
      {
        url: 'facebookPageShare/fb-oauth-pageShare-business-01-settings.png',
        alt: 'fb-oauth-pageShare-business-01-settings.png',
      },
      {
        url: 'facebookPageShare/fb-oauth-pageShare-business-02-perm.png',
        alt: 'fb-oauth-pageShare-business-02-permissions.png',
      },
    ],
  },
  {
    code: ErrorEnum.INSUFFICIENT_PAGE_PERMISSIONS,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.insufficientPagePermissions',
    images: [
      {
        url: 'facebookPageShare/fb-oauth-pageShare-05-page-perm.png',
        alt: 'fb-oauth-pageShare-05-page-perm.png',
      },
      {
        url: 'facebookPageShare/fb-oauth-pageShare-04-page-selection.png',
        alt: 'fb-oauth-pageShare-04-page-selection.png',
      },
    ],
  },
  {
    code: ErrorEnum.USER_CANCELED,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.userCanceled',
    images: [],
  },
  {
    code: ErrorEnum.NO_ACCOUNTS_SHARED,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.noAccountsShared',
    images: [
      {
        url: 'facebookPageShare/fb-oauth-pageShare-04-page-selection.png',
        alt: 'fb-oauth-pageShare-04-page-selection.png',
      },
    ],
  },
  {
    code: ErrorEnum.TWO_PLUS_ACCOUNTS_SHARED,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.twoPlusAccountsShared',
    images: [
      {
        url: 'facebookPageShare/fb-oauth-pageShare-04-page-selection.png',
        alt: 'fb-oauth-pageShare-04-page-selection.png',
      },
    ],
  },
  {
    code: ErrorEnum.API_GENERAL_ERROR,
    text: 'global.facebookPageShare.codebooks.errorsCodebook.apiGeneralError',
    images: [],
  },
];

export default ErrorsCodebook;
