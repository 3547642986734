<template>
  <div class="">
    <h1
      class="mb-3 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl"
    >
      {{ $t("eshopSettings.cos.pnoTitle") }}
    </h1>
  </div>
  <form class="mb-2 w-full space-y-2">
    <div class="mr-2 mt-5 w-full">
      <label for="range" class="form-label mb-2 flex items-center">
        <span class="mr-2">{{ $t("eshopSettings.cos.pnoMax") }}</span>
        <LocalizedValue :value="cosMaxStatic" />
      </label>
      <p class="my-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-base">
        {{
          $t("eshopSettings.cos.pnoExample", {
            value: $n(cosMax / 100, "percent"),
          })
        }}
        {{ $t("eshopSettings.cos.pnoExample2", { value: investmentValue }) }}
        <span class="font-bold" v-if="roi">
          {{
            $n(100 * roi, "currency", {
              key: "currency",
              currency: eshopsStore.getSelectedEshop?.currency,
            })
          }}
        </span>
      </p>
      <div class="mb-5 grid grid-cols-2">
        <Slider
          v-model="cosMax"
          class="range range-primary range-md col-span-2 mb-1 h-5 w-full cursor-pointer rounded-full"
          name="pno"
          :min="5"
          :max="50"
          :step="1"
        />
        <p class="col-span-1">{{ $n(0.05, "percent") }}</p>
        <p class="col-span-1 text-right">{{ $n(0.5, "percent") }}</p>
      </div>
    </div>
    <p class="mt-5 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">
      {{ $t("eshopSettings.cos.pnoText") }}
    </p>
    <div class="ml-auto mr-0 mt-5 w-fit">
      <MainButton
        @click="setCOS(cosMax)"
        :text="$t('eshopSettings.cos.save')"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import Slider from "primevue/slider";
import { useToast } from "primevue/usetoast";
import { ref, onMounted, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import LocalizedValue from "@/modules/global/components/LocalizedValue.vue";
import { api } from "@/services/api";
import { useEshopsStore } from "@/stores/eshops";

onMounted(() => {
  getCosMax();
});

const { t, n } = useI18n();
const roi = ref(0);
const cosMax = ref(0);
const cosMaxStatic = ref();
const toast = useToast();
const eshopsStore = useEshopsStore();

watch(
  () => cosMax.value,
  (newVal) => {
    // watch the getter
    roi.value = getRoiValue(newVal);
  }
);

// When the eshop change
watch(
  () => eshopsStore.getSelectedEshop?.id,
  () => {
    getCosMax();
  }
);

const investmentValue = computed(() => {
  if (eshopsStore.getSelectedEshop?.currency !== "CZK") {
    return n(10000, "currency", {
      key: "currency",
      currency: eshopsStore.getSelectedEshop?.currency,
    });
  } else {
    return n(10000, "currency", { key: "currency", currency: "CZK" });
  }
});

const getCosMax = async () => {
  try {
    const { data } = await api.clientEshopSettingsGetCosMax();
    if (data.cos.value) {
      cosMax.value = data.cos.value * 100;
      cosMaxStatic.value = data.cos;
    } else {
      cosMax.value = 0;
      cosMaxStatic.value = data.cos;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: "error",
          summary: t("serverErrorTitle"),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getRoiValue = (cosMax) => {
  if (cosMax === 0) {
    return 0;
  }
  return Math.round((1 / cosMax) * 10000);
};

const setCOS = async (cosMax) => {
  try {
    const payload = {
      cos: cosMax / 100,
    };
    await api.clientEshopSettingsSetCosMax(payload);
    toast.add({
      severity: "success",
      summary: t("eshopSettings.cos.cosSave"),
      life: 4000,
    });
    cosMaxStatic.value.value = cosMax / 100;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: "error",
          summary: t("serverErrorTitle"),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};
</script>
