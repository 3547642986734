<template>
  <div
    @click="doSomeAction(status)"
    :class="[
      'relative mx-auto mb-4 h-10 w-full max-w-7xl rounded-[1.875rem] md:h-14',
      status,
      status === EnumsDashboardStatus.EshopSetUpNoCredit || EnumsDashboardStatus.EshopInactiveBecauseOfCredit || EnumsDashboardStatus.EshopActiveUnderLimit ? 'cursor-pointer hover:opacity-90' : '',
    ]"
  >
    <span :class="['absolute left-1/2 top-1/2 hidden min-w-max max-w-full -translate-x-1/2 -translate-y-1/2 text-center text-[0.625rem] font-bold text-white sm:block sm:text-xs lg:text-base xl:text-xl']" v-html="message.desktop"></span>
    <span :class="['absolute left-1/2 top-1/2 block min-w-max max-w-full -translate-x-1/2 -translate-y-1/2 text-center text-[0.625rem] font-bold text-white sm:hidden sm:text-xs lg:text-base xl:text-xl']" v-html="message.mobile"></span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { EnumsDashboardStatus } from '../../../../generated/api';

const { t } = useI18n();
const router = useRouter();

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const doSomeAction = (status: EnumsDashboardStatus) => {
  if (status === EnumsDashboardStatus.EshopSetUpNoCredit || status === EnumsDashboardStatus.EshopInactiveBecauseOfCredit || status === EnumsDashboardStatus.EshopActiveUnderLimit) {
    router.push({ name: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE });
  }
};

const message = computed<{ desktop: string; mobile: string }>(() => {
  switch (props.status) {
    case EnumsDashboardStatus.EshopNotSetUp:
      return {
        desktop: t('dashboard.banner.eshopNotSetUp.desktop'),
        mobile: t('dashboard.banner.eshopNotSetUp.mobile'),
      };
    case EnumsDashboardStatus.EshopSetUpNoCredit:
      return {
        desktop: t('dashboard.banner.eshopSetUpNoCredit.desktop'),
        mobile: t('dashboard.banner.eshopSetUpNoCredit.mobile'),
      };
    case EnumsDashboardStatus.EshopSetUpWithCreditNotTurnedOn:
      return {
        desktop: t('dashboard.banner.eshopSetUpWithCreditNotTurnedOn.desktop'),
        mobile: t('dashboard.banner.eshopSetUpWithCreditNotTurnedOn.mobile'),
      };
    case EnumsDashboardStatus.EshopTurnedOnNoAdSpend:
      return {
        desktop: t('dashboard.banner.eshopTurnedOnNoAdSpend.desktop'),
        mobile: t('dashboard.banner.eshopTurnedOnNoAdSpend.mobile'),
      };
    case EnumsDashboardStatus.EshopActive:
      return {
        desktop: t('dashboard.banner.eshopActive.desktop'),
        mobile: t('dashboard.banner.eshopActive.mobile'),
      };
    case EnumsDashboardStatus.EshopInactiveBecauseOfCredit:
      return {
        desktop: t('dashboard.banner.eshopInactiveBecauseOfCredit.desktop'),
        mobile: t('dashboard.banner.eshopInactiveBecauseOfCredit.mobile'),
      };
    case EnumsDashboardStatus.EshopTurnedOff:
      return {
        desktop: t('dashboard.banner.eshopTurnedOff.desktop'),
        mobile: t('dashboard.banner.eshopTurnedOff.mobile'),
      };
    case EnumsDashboardStatus.EshopActiveUnderLimit:
      return {
        desktop: t('dashboard.banner.eshopActiveUnderLimit.desktop'),
        mobile: t('dashboard.banner.eshopActiveUnderLimit.mobile'),
      };
    case EnumsDashboardStatus.CampaignOffPixelIsNotSet:
      return {
        desktop: t('dashboard.banner.campaignOffPixelIsNotSet.desktop'),
        mobile: t('dashboard.banner.campaignOffPixelIsNotSet.mobile'),
      };
    case EnumsDashboardStatus.CampaignOffWrongProducts:
      return {
        desktop: t('dashboard.banner.campaignOffWrongProducts.desktop'),
        mobile: t('dashboard.banner.campaignOffWrongProducts.mobile'),
      };
    case EnumsDashboardStatus.FeedProcessing:
      return {
        desktop: t('dashboard.banner.feedProcessing.desktop'),
        mobile: t('dashboard.banner.feedProcessing.mobile'),
      };
    default:
      return {
        desktop: t('dashboard.banner.eshopNotSetUp.desktop'),
        mobile: t('dashboard.banner.eshopNotSetUp.mobile'),
      };
  }
});
</script>
<style scoped>
.EshopNotSetUp {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopSetUpNoCredit {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopSetUpWithCreditNotTurnedOn {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopTurnedOnNoAdSpend {
  background-color: #f90;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopActive {
  background-color: #359f03;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopInactiveBecauseOfCredit {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopTurnedOff {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.EshopActiveUnderLimit {
  background-color: #f90;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.CampaignOffPixelIsNotSet {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.CampaignOffWrongProducts {
  background-color: #dc2626;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.FeedProcessing {
  background-color: #6b7280;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}
</style>
