<template>
  <p class="mt-5 text-center text-sm text-main xl:text-left">{{ $t('dashboard.problemSectionXmlFeed.systemExceptionText1') }}</p>
  <ul class="mx-auto mt-2 max-w-[25rem] list-inside list-disc text-left text-sm text-main xl:ml-0 xl:w-fit">
    <li>{{ $t('dashboard.problemSectionXmlFeed.systemExceptionText2') }}</li>
    <li>{{ $t('dashboard.problemSectionXmlFeed.systemExceptionText3') }}</li>
    <li>{{ $t('dashboard.problemSectionXmlFeed.systemExceptionText4') }}</li>
    <li>{{ $t('dashboard.problemSectionXmlFeed.systemExceptionText5') }}</li>
    <li class="hover:underline">
      <a href="https://support.google.com/merchants/answer/7052112?hl" target="_blank"> {{ $t('dashboard.problemSectionXmlFeed.systemExceptionText6') }}</a>
    </li>
  </ul>
</template>
