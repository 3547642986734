<template>
  <div class="mb-3 flex w-full justify-center">
    <h1 class="mr-2 text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">
      {{ $t('eshopSettings.xmlFeed.feedTitle') }}
    </h1>
    <span v-if="feedStatus === null" v-tooltip.top="$t('eshopSettings.xmlFeed.xmlFeedInProcess')">
      <ArrowPathIcon class="block h-8 w-8 text-orange-600 xl:h-10 xl:w-10"></ArrowPathIcon>
    </span>
    <CheckCircleIcon v-else-if="feedStatus === true" class="h-8 w-8 text-green-700 xl:h-10 xl:w-10"></CheckCircleIcon>
    <XCircleIcon v-else class="h-8 w-8 text-red-700 xl:h-10 xl:w-10"></XCircleIcon>
  </div>
  <p @click="openHelpModal()" class="mx-auto mb-5 max-w-fit animate-pulse cursor-pointer text-center text-sm font-bold text-main hover:underline sm:text-base">
    {{ $t('eshopSettings.xmlFeed.feedText6') }}
  </p>
  <div v-if="currentXmlFeed" class="mb-1 max-w-[90%] text-xs font-bold text-gray-800 dark:text-[#cbd5e1] md:text-sm">
    {{ $t('eshopSettings.xmlFeed.feedText1') }}
    <a :href="currentXmlFeed" class="md:text-md break-words text-xs font-normal hover:text-blue-700 hover:underline sm:text-sm">{{ currentXmlFeed }}</a>
  </div>
  <form @submit.prevent="updateXMLFeed" class="space-y-5">
    <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="xmlFeed">{{ $t('eshopSettings.xmlFeed.url') }}</label>
    <InputText id="xmlFeed" v-model="xmlFeed" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="xmlFeed" />
    <small id="text-error" class="text-sm text-red-600">{{ errors.xmlFeed || '&nbsp;' }}</small>
    <p class="block w-full break-words text-center text-xs text-gray-800 dark:text-[#cbd5e1] lg:text-sm">
      {{ $t('eshopSettings.xmlFeed.feedText2') }}
    </p>
    <div class="flex justify-start">
      <MainButton type="submit" :text="$t('eshopSettings.xmlFeed.save')" class="ml-auto mr-0 mt-4 inline-flex justify-center" />
    </div>
  </form>
  <Dialog v-model:visible="isHelpModalOpen" modal :closable="true" :draggable="false">
    <template #header>
      <template v-if="$theme('shoptetInfo')">
        <p class="text-xs font-extrabold text-gray-900 dark:text-[#cbd5e1] md:text-base">
          {{ $t('eshopSettings.xmlFeed.feedText3') }}
        </p>
      </template>
      <template v-else>
        <p class="text-xs font-extrabold text-gray-900 dark:text-[#cbd5e1] md:text-base">
          {{ $t('eshopSettings.xmlFeed.feedText6') }}
        </p>
      </template>
    </template>
    <template #default>
      <template v-if="$theme('shoptetInfo')">
        <p class="mb-3 mt-1 text-xs font-bold text-gray-800 dark:text-[#cbd5e1] md:text-sm">
          {{ $t('eshopSettings.xmlFeed.feedText4') }}
        </p>
        <a href="https://www.tanganica.com/cs/blog" target="_blank" class="mb-3 text-xs text-gray-800 hover:cursor-pointer hover:text-blue-500 hover:underline dark:text-[#cbd5e1] md:text-sm">
          {{ $t('eshopSettings.xmlFeed.feedText5') }}
        </a>
      </template>
      <template v-else>
        <!-- eslint-disable -->
        <p class="mb-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">
          1. {{ $t('eshopSettings.xmlFeed.feedText7') }}<span class="text-blue-700 hover:underline">{{ $t('eshopSettings.xmlFeed.feedText13') }}</span>
        </p>
        <p class="mb-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">2. {{ $t('eshopSettings.xmlFeed.feedText8') }}</p>
        <p class="mb-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">3. {{ $t('eshopSettings.xmlFeed.feedText10') }}</p>
        <p class="mb-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">4. {{ $t('eshopSettings.xmlFeed.feedText11') }}</p>
        <p class="mb-2 text-xs text-gray-800 dark:text-[#cbd5e1] md:text-sm">5. {{ $t('eshopSettings.xmlFeed.feedText12') }}</p>
        <!-- eslint-enable -->
      </template>
    </template>
    <template #footer> </template>
  </Dialog>
  <Dialog v-model:visible="openChangeInfoDialog" modal :closable="true" :draggable="false">
    <template #header><span></span></template>
    <template #default>
      <p class="text-justify text-main" v-html="$t('eshopSettings.xmlFeed.updateFeedText')"></p>
    </template>
    <template #footer> </template>
  </Dialog>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { XCircleIcon, ArrowPathIcon } from '@heroicons/vue/24/solid';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';

const currentXmlFeed = ref('');
const eshopsStore = useEshopsStore();
const { t } = useI18n();
const loadingStore = useLoadingStore();
const toast = useToast();
const openChangeInfoDialog = ref(false);
const feedStatus = ref();

// For Modal
const isHelpModalOpen = ref(false);

onMounted(async () => {
  await getFeed();
  await getFeedStatus();
});

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    xmlFeed: yup.string().required(() => t('validations.required')),
  }),
  initialValues: {
    xmlFeed: '',
  },
});

const [xmlFeed] = defineField('xmlFeed');

// When the eshop change
watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getFeed();
    await getFeedStatus();
  }
);

const isChanged = computed(() => {
  return currentXmlFeed.value !== xmlFeed.value;
});

const getFeed = async () => {
  try {
    loadingStore.updateLoading(true);
    const { data } = await api.clientEshopSettingsGetProductFeedUrl();
    currentXmlFeed.value = data.url;
    resetForm({ values: { xmlFeed: data.url } });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const getFeedStatus = async () => {
  const { data } = await api.clientEshopSettingsGetFeedState();
  feedStatus.value = data.feedStatus;
};

const updateXMLFeed = handleSubmit(async (formData) => {
  if (!isChanged.value) return;

  loadingStore.updateLoading(true);

  const payload = {
    url: formData.xmlFeed,
  };

  try {
    await api.clientEshopSettingsSetProductFeedUrl(payload);

    currentXmlFeed.value = formData.xmlFeed;
    resetForm({ values: { xmlFeed: formData.xmlFeed } });

    toast.add({
      severity: 'success',
      summary: t('eshopSettings.xmlFeed.messages.updateXMLFeedSuccessTitle'),
      life: 3000,
    });
    openChangeInfoDialog.value = true;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});

const openHelpModal = () => {
  isHelpModalOpen.value = true;
};
</script>
