<template>
  <template v-if="isValid">
    <component v-if="value.type === 'Date'" :is="dateI18nConfig.tag">
      {{ $d(dateI18nConfig.value, dateI18nConfig.format, dateI18nConfig.locale) }}
    </component>
    <component v-else :is="numberI18nConfig.tag">
      {{ $n(numberI18nConfig.value, numberI18nConfig.format, numberI18nConfig.locale) }}
    </component>
  </template>
  <template v-else>
    <span class="text-xs sm:text-sm">--</span>
  </template>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';

type IValue = {
  type: ValueTypes;
  value: string | number | Date | undefined | null;
  format?: string | Intl.DateTimeFormatOptions | Intl.NumberFormatOptions;
  locale?: string;
  currency?: string;
};

interface IDateI18nConfig {
  tag: string;
  value: Date;
  format?: string | Intl.DateTimeFormatOptions;
  locale?: string;
}

interface INumberI18nConfig {
  tag: string;
  value: number;
  format?: string | Intl.NumberFormatOptions;
  locale?: string;
}

type ValueTypes = 'Date' | 'Decimal' | 'Percent' | 'Money';

const props = defineProps({
  value: {
    type: Object as PropType<IValue> | null | undefined,
    required: false,
    default: null,
  },
  tag: {
    type: String,
    default: 'span',
    required: false,
  },
});

const isValid = computed<boolean>(() => {
  return props.value && props.value.type && props.value.value !== undefined && props.value.value !== null;
});

const dateI18nConfig = computed<IDateI18nConfig>(() => {
  const dateConfig: IDateI18nConfig = {
    tag: props.tag,
    value: props.value.value ? new Date(props.value.value) : new Date(),
  };

  // LOCALE
  if (props.value.locale) {
    dateConfig.locale = props.value.locale;
  }

  // FORMAT
  if (props.value.format) {
    dateConfig.format = (props.value.format as Intl.DateTimeFormatOptions) || (props.value.format as string);
  }

  return dateConfig;
});

const numberI18nConfig = computed<INumberI18nConfig>(() => {
  const config: INumberI18nConfig = {
    tag: props.tag,
    value: Number(props.value.value),
  };

  // LOCALE
  if (props.value.locale) {
    config.locale = props.value.locale;
  }

  // DECIMAL
  else if (props.value.type === 'Decimal') {
    // Add format to config
    if (props.value.format) {
      config.format = props.value.format;
    } else {
      config.format = 'decimal';
    }
  }

  // PERCENT
  else if (props.value.type === 'Percent') {
    // Add format to config
    if (props.value.format) {
      config.format = (props.value.format as Intl.NumberFormatOptions) || (props.value.format as string);
    } else {
      config.format = 'percent';
    }
  }

  // MONEY
  else if (props.value.type === 'Money') {
    // Add format to config
    if (props.value.format) {
      config.format = props.value.format;
    } else {
      if (props.value.currency) {
        config.format = {
          key: 'currency',
          currency: props.value.currency,
        };
      } else {
        config.format = 'currency';
      }
    }
  }

  return config;
});
</script>
