<template>
  <Dropdown
    v-bind="$attrs"
    :modelValue="props.modelValue"
    @update:modelValue="(value) => emit('update:modelValue', value)"
    :options="countries"
    optionLabel="name"
    :placeholder="$t('global.selectCountry.selectCountry')"
    class="md:w-14rem w-full justify-items-center"
    :ptOptions="{ mergeProps: true }"
    :pt="{ wrapper: '!max-h-[18.75rem] sm:!max-h-[20.313rem]' }"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="align-items-center flex">
        <img :alt="slotProps.value.label" :src="`${$filePath}/${slotProps.value.flag}`" class="mr-2 h-6 w-6 rounded-full" />
        <div>{{ $t(slotProps.value.name) }}</div>
        <LockClosedIcon v-if="lockIcon" class="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-main opacity-75"></LockClosedIcon>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="align-items-center flex dark:bg-mainDark">
        <img :alt="slotProps.option.label" :src="`${$filePath}/${slotProps.option.flag}`" class="mr-2 h-6 w-6 rounded-full" />
        <div>{{ $t(slotProps.option.name) }}</div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { LockClosedIcon } from '@heroicons/vue/24/solid';
import Dropdown from 'primevue/dropdown';
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import CountriesCodebook, { ICountry } from '@/codebooks/CountriesCodebook';
import { EnumsCountryOfAdvertisement } from '../../../../generated/api';

const props = defineProps({
  modelValue: {
    type: Object as PropType<ICountry>,
    required: false,
    default: () => {},
  },
  lockIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useI18n();

const emit = defineEmits(['update:modelValue']);

const countries = computed(() => {
  return CountriesCodebook.filter((country) => {
    return Object.values(EnumsCountryOfAdvertisement).includes(country.code as EnumsCountryOfAdvertisement);
  }).sort((a, b) => t(a.name).localeCompare(t(b.name)));
});
</script>
