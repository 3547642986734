/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsFBProductStatus = {
    Approved: 'APPROVED',
    ApprovedWithIssues: 'APPROVED_WITH_ISSUES',
    Outdated: 'OUTDATED',
    NoReview: 'NO_REVIEW',
    Waiting: 'WAITING',
    Pending: 'PENDING',
    Unused: 'UNUSED',
    Invalid: 'INVALID',
    Rejected: 'REJECTED',
    Disabled: 'DISABLED'
} as const;

export type EnumsFBProductStatus = typeof EnumsFBProductStatus[keyof typeof EnumsFBProductStatus];



