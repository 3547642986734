/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsFeedErrorType = {
    None: 'NONE',
    IncorrectUrlFormat: 'INCORRECT_URL_FORMAT',
    CannotAccessPage: 'CANNOT_ACCESS_PAGE',
    InvalidContentType: 'INVALID_CONTENT_TYPE',
    ItemTagMissing: 'ITEM_TAG_MISSING',
    WrongFeedFormat: 'WRONG_FEED_FORMAT',
    SystemException: 'SYSTEM_EXCEPTION'
} as const;

export type EnumsFeedErrorType = typeof EnumsFeedErrorType[keyof typeof EnumsFeedErrorType];



