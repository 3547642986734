<template>
  <div v-if="isEshopOwner" class="div-primary mx-auto">
    <p class="text-center text-main dark:text-[#cbd5e1]">
      {{ $t('affiliateProgram.components.affiliatePartners.text') }}
    </p>
    <form>
      <div class="mx-auto mt-5 w-fit">
        <MainButton @click="interestAffil()" :text="$t('affiliateProgram.components.affiliatePartners.button')" class="inline-block w-full p-1 text-sm sm:w-auto lg:p-4" />
      </div>
    </form>
    <p class="mt-8 flex items-center justify-center text-center text-sm text-main dark:text-[#cbd5e1]">
      <span>{{ $t('affiliateProgram.components.affiliatePartners.textUnderButton') }}</span>
      <a :href="termAndConditions" target="_blank" class="ml-1 cursor-pointer font-bold underline hover:opacity-80">{{ $t('affiliateProgram.components.affiliatePartners.textUnderButton2') }}</a>
    </p>
  </div>
  <div v-else class="div-primary">
    <p class="text-center text-main dark:text-[#cbd5e1]">
      {{ $t('affiliateProgram.components.affiliatePartners.affiliateNotAllowed') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { useJwt } from '@vueuse/integrations/useJwt';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api/index';
import { useAuthStore } from '@/stores/auth';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const toast = useToast();
const askedForAffil = ref(false);
const loadingStore = useLoadingStore();
const authStore = useAuthStore();
const eshopStore = useEshopsStore();
const userStore = useUserStore();
const isEshopOwner = ref(false);
const termAndConditions = ref();

onMounted(async () => {
  getEshopAffilInfo();
  if (userStore.getLanguageCode !== 'cs') {
    termAndConditions.value = 'https://www.tanganica.com/en/terms-of-affiliate-program';
  } else {
    termAndConditions.value = 'https://www.tanganica.com/cs/terms-of-affiliate-program';
  }
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await getEshopAffilInfo();
  }
);

const getEshopAffilInfo = async () => {
  const token = authStore.getEshopAccessToken;
  if (token) {
    const { payload } = useJwt(token);
    isEshopOwner.value = payload.value?.role.includes('EshopOwner');
  }
};

const interestAffil = async () => {
  loadingStore.updateLoading(true);
  try {
    await api.clientAffiliateReportAskForAffil();
    askedForAffil.value = true;
    toast.add({
      severity: 'success',
      summary: t('affiliateProgram.successAsk'),
      life: 4000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};
</script>

<style lang="scss" scoped></style>
