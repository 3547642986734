<template>
  <div class="fixed inset-0 h-screen w-screen bg-white dark:bg-mainDark">
    <!-- Tanganica Logo -->
    <router-link to="/" class="absolute left-2 top-2 z-30 w-fit cursor-pointer sm:top-[0.938rem] lg:top-[0.938rem]">
      <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="jungle" class="h-full max-h-[2.5rem] w-auto object-cover object-left sm:max-h-[3.125rem] xl:max-h-[3.75rem]" />
    </router-link>
    <div class="relative left-1/2 top-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2">
      <h2 class="ml-5 h-fit justify-center text-sm font-bold text-[#00467D] dark:text-gray-300 sm:mb-5 sm:text-3xl md:text-4xl lg:flex lg:text-4xl">
        {{ $t('authentication.changeEmailCallback.title') }}
      </h2>
      <div class="relative mx-5 h-fit rounded-[2.5rem] bg-white p-2 pt-5 dark:bg-lightDark sm:mx-10 sm:p-5 lg:mx-20 lg:px-12">
        <div class="w-full flex-none justify-center px-10 py-4 lg:px-20 xl:px-24">
          <div class="mx-auto w-80 sm:w-[37.5rem]"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Continue to login -  -->
  <Dialog v-model:visible="openDialog" modal :closable="false" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t(dialogTitle)"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="$t(dialogDescription)"></p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton type="button" :text="$t('authentication.changeEmailCallback.buttonTextLogin')" :hover="false" @click="redirectToLogin()" class="mx-auto mt-5 bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]">
        </MainButton>
      </div>
    </template>
  </Dialog>

  <!-- Confirm change email - Password check  -->
  <Dialog v-model:visible="confirmDialog" modal :closable="false" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="dialogTitle"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="dialogDescription"></p>
    </div>
    <div class="mt-2">
      <Password v-model="password" :feedback="false" :inputProps="{ autocomplete: 'new-password' }" />
    </div>
    <small class="text-sm text-red-600">{{ errors.password || '&nbsp;' }}</small>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton type="button" :text="$t('authentication.changeEmailCallback.buttonConfirm')" :hover="false" @click="confirmResetEmail()" class="mx-auto mt-5 bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]">
        </MainButton>
      </div>
    </template>
  </Dialog>

  <!-- Confirm change email - Bad password  -->
  <Dialog v-model:visible="openDialogBadPassword" modal :closable="false" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="dialogTitle"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="dialogDescription"></p>
    </div>
    <template #footer>
      <div class="mt-2 flex min-w-full justify-items-center gap-x-5">
        <MainButton type="button" :text="$t('authentication.changeEmailCallback.buttonTryAgain')" :hover="false" @click="tryAgain()" class="mx-auto mt-5 bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]">
        </MainButton>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import { useForm } from 'vee-validate';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';

const { t } = useI18n();

onMounted(async () => {
  await tokenExist(token);
});

const { defineField, handleSubmit, errors, resetField } = useForm({
  validationSchema: yup.object({
    password: yup.string().required(() => t('validations.required')),
  }),
  initialValues: {
    password: '',
  },
});

const [password] = defineField('password');
const url = new URL(window.location.href);
const router = useRouter();
const token = url.searchParams.get('token');
const authStore = useAuthStore();

// For Dialog
const openDialogBadPassword = ref(false);
const openDialog = ref(false);
const confirmDialog = ref(false);
const dialogTitle = ref('');
const dialogDescription = ref('');

const tokenExist = async (token: string | null) => {
  if (token) {
    authStore.setUserAccessToken(token);
    dialogTitle.value = t('authentication.changeEmailCallback.dialogTitleConfirm');
    dialogDescription.value = t('authentication.changeEmailCallback.dialogDescriptionConfirm');
    confirmDialog.value = true;
  } else {
    dialogTitle.value = t('authentication.changeEmailCallback.dialogTitleError');
    dialogDescription.value = t('authentication.changeEmailCallback.dialogDescriptionError');
    openDialog.value = true;
  }
};

const confirmResetEmail = handleSubmit(async (formData) => {
  try {
    await api.clientCallbacksConfirmEmailChange(
      { password: formData.password },
      {
        headers: {
          Authorization: `Bearer ${authStore.userAccessToken}`,
        },
      }
    );
    dialogTitle.value = t('authentication.changeEmailCallback.dialogTitleSuccess');
    dialogDescription.value = t('authentication.changeEmailCallback.dialogDescriptionSuccess');
    confirmDialog.value = false;
    openDialog.value = true;
  } catch (error) {
    dialogTitle.value = t('authentication.changeEmailCallback.dialogTitleConfirmError');
    dialogDescription.value = t('authentication.changeEmailCallback.dialogDescriptionConfirmError');
    openDialogBadPassword.value = true;
  }
});

const tryAgain = () => {
  openDialogBadPassword.value = false;
  resetField('password');
  confirmDialog.value = true;
};

const redirectToLogin = () => {
  authStore.destroySession();
  router.push({ name: PUBLIC_ROUTES.LOGIN_PAGE });
};
</script>

<style lang="scss" scoped></style>
