import { EnumsCountryOfAdvertisement } from '../../generated/api';

export interface ICountry {
  code: EnumsCountryOfAdvertisement | string;
  name: string; // i18n key
  flag: string;
  isAdvertising?: boolean; // if false, the country is used for advertising
  phonePrefix: string;
}

const CountriesCodebook: Array<ICountry> = [
  {
    code: 'AC',
    name: 'codebooks.countries.ac',
    flag: 'flags/GB.png',
    isAdvertising: false,
    phonePrefix: '+247',
  },
  {
    code: 'AD',
    name: 'codebooks.countries.ad',
    flag: 'flags/AD.png',
    isAdvertising: false,
    phonePrefix: '+376',
  },
  {
    code: 'AE',
    name: 'codebooks.countries.ae',
    flag: 'flags/AE.png',
    isAdvertising: false,
    phonePrefix: '+971',
  },
  {
    code: 'AF',
    name: 'codebooks.countries.af',
    flag: 'flags/AF.png',
    isAdvertising: false,
    phonePrefix: '+93',
  },
  {
    code: 'AG',
    name: 'codebooks.countries.ag',
    flag: 'flags/AG.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'AI',
    name: 'codebooks.countries.ai',
    flag: 'flags/AI.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'AL',
    name: 'codebooks.countries.al',
    flag: 'flags/AL.png',
    isAdvertising: false,
    phonePrefix: '+355',
  },
  {
    code: 'AM',
    name: 'codebooks.countries.am',
    flag: 'flags/AM.png',
    isAdvertising: false,
    phonePrefix: '+374',
  },
  {
    code: 'AO',
    name: 'codebooks.countries.ao',
    flag: 'flags/AO.png',
    isAdvertising: false,
    phonePrefix: '+244',
  },
  {
    code: 'AR',
    name: 'codebooks.countries.ar',
    flag: 'flags/AR.png',
    isAdvertising: false,
    phonePrefix: '+54',
  },
  {
    code: 'AS',
    name: 'codebooks.countries.as',
    flag: 'flags/AS.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'AT',
    name: 'codebooks.countries.at',
    flag: 'flags/AT.png',
    isAdvertising: true,
    phonePrefix: '+43',
  },
  {
    code: 'AU',
    name: 'codebooks.countries.au',
    flag: 'flags/AU.png',
    isAdvertising: false,
    phonePrefix: '+61',
  },
  {
    code: 'AW',
    name: 'codebooks.countries.aw',
    flag: 'flags/AW.png',
    isAdvertising: false,
    phonePrefix: '+297',
  },
  {
    code: 'AX',
    name: 'codebooks.countries.ax',
    flag: 'flags/AX.png',
    isAdvertising: false,
    phonePrefix: '+358',
  },
  {
    code: 'AZ',
    name: 'codebooks.countries.az',
    flag: 'flags/AZ.png',
    isAdvertising: false,
    phonePrefix: '+994',
  },
  {
    code: 'BA',
    name: 'codebooks.countries.ba',
    flag: 'flags/BA.png',
    isAdvertising: false,
    phonePrefix: '+387',
  },
  {
    code: 'BB',
    name: 'codebooks.countries.bb',
    flag: 'flags/BB.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'BD',
    name: 'codebooks.countries.bd',
    flag: 'flags/BD.png',
    isAdvertising: false,
    phonePrefix: '+880',
  },
  {
    code: 'BE',
    name: 'codebooks.countries.be',
    flag: 'flags/BE.png',
    isAdvertising: true,
    phonePrefix: '+32',
  },
  {
    code: 'BF',
    name: 'codebooks.countries.bf',
    flag: 'flags/BF.png',
    isAdvertising: false,
    phonePrefix: '+226',
  },
  {
    code: 'BG',
    name: 'codebooks.countries.bg',
    flag: 'flags/BG.png',
    isAdvertising: false,
    phonePrefix: '+359',
  },
  {
    code: 'BH',
    name: 'codebooks.countries.bh',
    flag: 'flags/BH.png',
    isAdvertising: false,
    phonePrefix: '+973',
  },
  {
    code: 'BI',
    name: 'codebooks.countries.bi',
    flag: 'flags/BI.png',
    isAdvertising: false,
    phonePrefix: '+257',
  },
  {
    code: 'BJ',
    name: 'codebooks.countries.bj',
    flag: 'flags/BJ.png',
    isAdvertising: false,
    phonePrefix: '+229',
  },
  {
    code: 'BL',
    name: 'codebooks.countries.bl',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+590',
  },
  {
    code: 'BM',
    name: 'codebooks.countries.bm',
    flag: 'flags/BM.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'BN',
    name: 'codebooks.countries.bn',
    flag: 'flags/BN.png',
    isAdvertising: false,
    phonePrefix: '+673',
  },
  {
    code: 'BO',
    name: 'codebooks.countries.bo',
    flag: 'flags/BO.png',
    isAdvertising: false,
    phonePrefix: '+591',
  },
  {
    code: 'BQ',
    name: 'codebooks.countries.bq',
    flag: 'flags/BQ.png',
    isAdvertising: false,
    phonePrefix: '+599',
  },
  {
    code: 'BR',
    name: 'codebooks.countries.br',
    flag: 'flags/BR.png',
    isAdvertising: false,
    phonePrefix: '+55',
  },
  {
    code: 'BS',
    name: 'codebooks.countries.bs',
    flag: 'flags/BS.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'BT',
    name: 'codebooks.countries.bt',
    flag: 'flags/BT.png',
    isAdvertising: false,
    phonePrefix: '+975',
  },
  {
    code: 'BW',
    name: 'codebooks.countries.bw',
    flag: 'flags/BW.png',
    isAdvertising: false,
    phonePrefix: '+267',
  },
  {
    code: 'BY',
    name: 'codebooks.countries.by',
    flag: 'flags/BY.png',
    isAdvertising: false,
    phonePrefix: '+375',
  },
  {
    code: 'BZ',
    name: 'codebooks.countries.bz',
    flag: 'flags/BZ.png',
    isAdvertising: false,
    phonePrefix: '+501',
  },
  {
    code: 'CA',
    name: 'codebooks.countries.ca',
    flag: 'flags/CA.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'CC',
    name: 'codebooks.countries.cc',
    flag: 'flags/CC.png',
    isAdvertising: false,
    phonePrefix: '+61',
  },
  {
    code: 'CD',
    name: 'codebooks.countries.cd',
    flag: 'flags/CD.png',
    isAdvertising: false,
    phonePrefix: '+243',
  },
  {
    code: 'CF',
    name: 'codebooks.countries.cf',
    flag: 'flags/CF.png',
    isAdvertising: false,
    phonePrefix: '+236',
  },
  {
    code: 'CG',
    name: 'codebooks.countries.cg',
    flag: 'flags/CG.png',
    isAdvertising: false,
    phonePrefix: '+242',
  },
  {
    code: 'CH',
    name: 'codebooks.countries.ch',
    flag: 'flags/CH.png',
    isAdvertising: true,
    phonePrefix: '+41',
  },
  {
    code: 'CI',
    name: 'codebooks.countries.ci',
    flag: 'flags/CI.png',
    isAdvertising: false,
    phonePrefix: '+225',
  },
  {
    code: 'CK',
    name: 'codebooks.countries.ck',
    flag: 'flags/CK.png',
    isAdvertising: false,
    phonePrefix: '+682',
  },
  {
    code: 'CL',
    name: 'codebooks.countries.cl',
    flag: 'flags/CL.png',
    isAdvertising: false,
    phonePrefix: '+56',
  },
  {
    code: 'CM',
    name: 'codebooks.countries.cm',
    flag: 'flags/CM.png',
    isAdvertising: false,
    phonePrefix: '+237',
  },
  {
    code: 'CN',
    name: 'codebooks.countries.cn',
    flag: 'flags/CN.png',
    isAdvertising: false,
    phonePrefix: '+86',
  },
  {
    code: 'CO',
    name: 'codebooks.countries.co',
    flag: 'flags/CO.png',
    isAdvertising: false,
    phonePrefix: '+57',
  },
  {
    code: 'CR',
    name: 'codebooks.countries.cr',
    flag: 'flags/CR.png',
    isAdvertising: false,
    phonePrefix: '+506',
  },
  {
    code: 'CU',
    name: 'codebooks.countries.cu',
    flag: 'flags/CU.png',
    isAdvertising: false,
    phonePrefix: '+53',
  },
  {
    code: 'CV',
    name: 'codebooks.countries.cv',
    flag: 'flags/CV.png',
    isAdvertising: false,
    phonePrefix: '+238',
  },
  {
    code: 'CW',
    name: 'codebooks.countries.cw',
    flag: 'flags/CW.png',
    isAdvertising: false,
    phonePrefix: '+599',
  },
  {
    code: 'CX',
    name: 'codebooks.countries.cx',
    flag: 'flags/CX.png',
    isAdvertising: false,
    phonePrefix: '+61',
  },
  {
    code: 'CY',
    name: 'codebooks.countries.cy',
    flag: 'flags/CY.png',
    isAdvertising: false,
    phonePrefix: '+357',
  },
  {
    code: 'CZ',
    name: 'codebooks.countries.cz',
    flag: 'flags/CZ.png',
    isAdvertising: true,
    phonePrefix: '+420',
  },
  {
    code: 'DE',
    name: 'codebooks.countries.de',
    flag: 'flags/DE.png',
    isAdvertising: true,
    phonePrefix: '+49',
  },
  {
    code: 'DJ',
    name: 'codebooks.countries.dj',
    flag: 'flags/DJ.png',
    isAdvertising: false,
    phonePrefix: '+253',
  },
  {
    code: 'DK',
    name: 'codebooks.countries.dk',
    flag: 'flags/DK.png',
    isAdvertising: true,
    phonePrefix: '+45',
  },
  {
    code: 'DM',
    name: 'codebooks.countries.dm',
    flag: 'flags/DM.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'DO',
    name: 'codebooks.countries.do',
    flag: 'flags/DO.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'DZ',
    name: 'codebooks.countries.dz',
    flag: 'flags/DZ.png',
    isAdvertising: false,
    phonePrefix: '+213',
  },
  {
    code: 'EC',
    name: 'codebooks.countries.ec',
    flag: 'flags/EC.png',
    isAdvertising: false,
    phonePrefix: '+593',
  },
  {
    code: 'EE',
    name: 'codebooks.countries.ee',
    flag: 'flags/EE.png',
    isAdvertising: false,
    phonePrefix: '+372',
  },
  {
    code: 'EG',
    name: 'codebooks.countries.eg',
    flag: 'flags/EG.png',
    isAdvertising: false,
    phonePrefix: '+20',
  },
  {
    code: 'EH',
    name: 'codebooks.countries.eh',
    flag: 'flags/EH.png',
    isAdvertising: false,
    phonePrefix: '+212',
  },
  {
    code: 'ER',
    name: 'codebooks.countries.er',
    flag: 'flags/ER.png',
    isAdvertising: false,
    phonePrefix: '+291',
  },
  {
    code: 'ES',
    name: 'codebooks.countries.es',
    flag: 'flags/ES.png',
    isAdvertising: true,
    phonePrefix: '+34',
  },
  {
    code: 'ET',
    name: 'codebooks.countries.et',
    flag: 'flags/ET.png',
    isAdvertising: false,
    phonePrefix: '+251',
  },
  {
    code: 'FI',
    name: 'codebooks.countries.fi',
    flag: 'flags/FI.png',
    isAdvertising: true,
    phonePrefix: '+358',
  },
  {
    code: 'FJ',
    name: 'codebooks.countries.fj',
    flag: 'flags/FJ.png',
    isAdvertising: false,
    phonePrefix: '+679',
  },
  {
    code: 'FK',
    name: 'codebooks.countries.fk',
    flag: 'flags/FK.png',
    isAdvertising: false,
    phonePrefix: '+500',
  },
  {
    code: 'FM',
    name: 'codebooks.countries.fm',
    flag: 'flags/FM.png',
    isAdvertising: false,
    phonePrefix: '+691',
  },
  {
    code: 'FO',
    name: 'codebooks.countries.fo',
    flag: 'flags/FO.png',
    isAdvertising: false,
    phonePrefix: '+298',
  },
  {
    code: 'FR',
    name: 'codebooks.countries.fr',
    flag: 'flags/FR.png',
    isAdvertising: true,
    phonePrefix: '+33',
  },
  {
    code: 'GA',
    name: 'codebooks.countries.ga',
    flag: 'flags/GA.png',
    isAdvertising: false,
    phonePrefix: '+241',
  },
  {
    code: 'GB',
    name: 'codebooks.countries.gb',
    flag: 'flags/GB.png',
    isAdvertising: true,
    phonePrefix: '+44',
  },
  {
    code: 'GD',
    name: 'codebooks.countries.gd',
    flag: 'flags/GD.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'GE',
    name: 'codebooks.countries.ge',
    flag: 'flags/GE.png',
    isAdvertising: false,
    phonePrefix: '+995',
  },
  {
    code: 'GF',
    name: 'codebooks.countries.gf',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+594',
  },
  {
    code: 'GG',
    name: 'codebooks.countries.gg',
    flag: 'flags/GG.png',
    isAdvertising: false,
    phonePrefix: '+44',
  },
  {
    code: 'GH',
    name: 'codebooks.countries.gh',
    flag: 'flags/GH.png',
    isAdvertising: false,
    phonePrefix: '+233',
  },
  {
    code: 'GI',
    name: 'codebooks.countries.gi',
    flag: 'flags/GI.png',
    isAdvertising: false,
    phonePrefix: '+350',
  },
  {
    code: 'GL',
    name: 'codebooks.countries.gl',
    flag: 'flags/GL.png',
    isAdvertising: false,
    phonePrefix: '+299',
  },
  {
    code: 'GM',
    name: 'codebooks.countries.gm',
    flag: 'flags/GM.png',
    isAdvertising: false,
    phonePrefix: '+220',
  },
  {
    code: 'GN',
    name: 'codebooks.countries.gn',
    flag: 'flags/GN.png',
    isAdvertising: false,
    phonePrefix: '+224',
  },
  {
    code: 'GP',
    name: 'codebooks.countries.gp',
    flag: 'flags/GP.png',
    isAdvertising: false,
    phonePrefix: '+590',
  },
  {
    code: 'GQ',
    name: 'codebooks.countries.gq',
    flag: 'flags/GQ.png',
    isAdvertising: false,
    phonePrefix: '+240',
  },
  {
    code: 'GR',
    name: 'codebooks.countries.gr',
    flag: 'flags/GR.png',
    isAdvertising: true,
    phonePrefix: '+30',
  },
  {
    code: 'GT',
    name: 'codebooks.countries.gt',
    flag: 'flags/GT.png',
    isAdvertising: false,
    phonePrefix: '+502',
  },
  {
    code: 'GU',
    name: 'codebooks.countries.gu',
    flag: 'flags/GU.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'GW',
    name: 'codebooks.countries.gw',
    flag: 'flags/GW.png',
    isAdvertising: false,
    phonePrefix: '+245',
  },
  {
    code: 'GY',
    name: 'codebooks.countries.gy',
    flag: 'flags/GY.png',
    isAdvertising: false,
    phonePrefix: '+592',
  },
  {
    code: 'HK',
    name: 'codebooks.countries.hk',
    flag: 'flags/HK.png',
    isAdvertising: false,
    phonePrefix: '+852',
  },
  {
    code: 'HN',
    name: 'codebooks.countries.hn',
    flag: 'flags/HN.png',
    isAdvertising: false,
    phonePrefix: '+504',
  },
  {
    code: 'HR',
    name: 'codebooks.countries.hr',
    flag: 'flags/HR.png',
    isAdvertising: false,
    phonePrefix: '+385',
  },
  {
    code: 'HT',
    name: 'codebooks.countries.ht',
    flag: 'flags/HT.png',
    isAdvertising: false,
    phonePrefix: '+509',
  },
  {
    code: 'HU',
    name: 'codebooks.countries.hu',
    flag: 'flags/HU.png',
    isAdvertising: true,
    phonePrefix: '+36',
  },
  {
    code: 'ID',
    name: 'codebooks.countries.id',
    flag: 'flags/ID.png',
    isAdvertising: false,
    phonePrefix: '+62',
  },
  {
    code: 'IE',
    name: 'codebooks.countries.ie',
    flag: 'flags/IE.png',
    isAdvertising: true,
    phonePrefix: '+353',
  },
  {
    code: 'IL',
    name: 'codebooks.countries.il',
    flag: 'flags/IL.png',
    isAdvertising: false,
    phonePrefix: '+972',
  },
  {
    code: 'IM',
    name: 'codebooks.countries.im',
    flag: 'flags/GB.png',
    isAdvertising: false,
    phonePrefix: '+44',
  },
  {
    code: 'IN',
    name: 'codebooks.countries.in',
    flag: 'flags/IN.png',
    isAdvertising: false,
    phonePrefix: '+91',
  },
  {
    code: 'IO',
    name: 'codebooks.countries.io',
    flag: 'flags/GB.png',
    isAdvertising: false,
    phonePrefix: '+246',
  },
  {
    code: 'IQ',
    name: 'codebooks.countries.iq',
    flag: 'flags/IQ.png',
    isAdvertising: false,
    phonePrefix: '+964',
  },
  {
    code: 'IR',
    name: 'codebooks.countries.ir',
    flag: 'flags/IR.png',
    isAdvertising: false,
    phonePrefix: '+98',
  },
  {
    code: 'IS',
    name: 'codebooks.countries.is',
    flag: 'flags/IS.png',
    isAdvertising: false,
    phonePrefix: '+354',
  },
  {
    code: 'IT',
    name: 'codebooks.countries.it',
    flag: 'flags/IT.png',
    isAdvertising: true,
    phonePrefix: '+39',
  },
  {
    code: 'JE',
    name: 'codebooks.countries.je',
    flag: 'flags/JE.png',
    isAdvertising: false,
    phonePrefix: '+44',
  },
  {
    code: 'JM',
    name: 'codebooks.countries.jm',
    flag: 'flags/JM.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'JO',
    name: 'codebooks.countries.jo',
    flag: 'flags/JO.png',
    isAdvertising: false,
    phonePrefix: '+962',
  },
  {
    code: 'JP',
    name: 'codebooks.countries.jp',
    flag: 'flags/JP.png',
    isAdvertising: false,
    phonePrefix: '+81',
  },
  {
    code: 'KE',
    name: 'codebooks.countries.ke',
    flag: 'flags/KE.png',
    isAdvertising: false,
    phonePrefix: '+254',
  },
  {
    code: 'KG',
    name: 'codebooks.countries.kg',
    flag: 'flags/KG.png',
    isAdvertising: false,
    phonePrefix: '+996',
  },
  {
    code: 'KH',
    name: 'codebooks.countries.kh',
    flag: 'flags/KH.png',
    isAdvertising: false,
    phonePrefix: '+855',
  },
  {
    code: 'KI',
    name: 'codebooks.countries.ki',
    flag: 'flags/KI.png',
    isAdvertising: false,
    phonePrefix: '+686',
  },
  {
    code: 'KM',
    name: 'codebooks.countries.km',
    flag: 'flags/KM.png',
    isAdvertising: false,
    phonePrefix: '+269',
  },
  {
    code: 'KN',
    name: 'codebooks.countries.kn',
    flag: 'flags/KN.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'KP',
    name: 'codebooks.countries.kp',
    flag: 'flags/KP.png',
    isAdvertising: false,
    phonePrefix: '+850',
  },
  {
    code: 'KR',
    name: 'codebooks.countries.kr',
    flag: 'flags/KR.png',
    isAdvertising: false,
    phonePrefix: '+82',
  },
  {
    code: 'KW',
    name: 'codebooks.countries.kw',
    flag: 'flags/KW.png',
    isAdvertising: false,
    phonePrefix: '+965',
  },
  {
    code: 'KY',
    name: 'codebooks.countries.ky',
    flag: 'flags/KY.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'KZ',
    name: 'codebooks.countries.kz',
    flag: 'flags/KZ.png',
    isAdvertising: false,
    phonePrefix: '+7',
  },
  {
    code: 'LA',
    name: 'codebooks.countries.la',
    flag: 'flags/LA.png',
    isAdvertising: false,
    phonePrefix: '+856',
  },
  {
    code: 'LB',
    name: 'codebooks.countries.lb',
    flag: 'flags/LB.png',
    isAdvertising: false,
    phonePrefix: '+961',
  },
  {
    code: 'LC',
    name: 'codebooks.countries.lc',
    flag: 'flags/LC.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'LI',
    name: 'codebooks.countries.li',
    flag: 'flags/LI.png',
    isAdvertising: false,
    phonePrefix: '+423',
  },
  {
    code: 'LK',
    name: 'codebooks.countries.lk',
    flag: 'flags/LK.png',
    isAdvertising: false,
    phonePrefix: '+94',
  },
  {
    code: 'LR',
    name: 'codebooks.countries.lr',
    flag: 'flags/LR.png',
    isAdvertising: false,
    phonePrefix: '+231',
  },
  {
    code: 'LS',
    name: 'codebooks.countries.ls',
    flag: 'flags/LS.png',
    isAdvertising: false,
    phonePrefix: '+266',
  },
  {
    code: 'LT',
    name: 'codebooks.countries.lt',
    flag: 'flags/LT.png',
    isAdvertising: false,
    phonePrefix: '+370',
  },
  {
    code: 'LU',
    name: 'codebooks.countries.lu',
    flag: 'flags/LU.png',
    isAdvertising: false,
    phonePrefix: '+352',
  },
  {
    code: 'LV',
    name: 'codebooks.countries.lv',
    flag: 'flags/LV.png',
    isAdvertising: false,
    phonePrefix: '+371',
  },
  {
    code: 'LY',
    name: 'codebooks.countries.ly',
    flag: 'flags/LY.png',
    isAdvertising: false,
    phonePrefix: '+218',
  },
  {
    code: 'MA',
    name: 'codebooks.countries.ma',
    flag: 'flags/MA.png',
    isAdvertising: false,
    phonePrefix: '+212',
  },
  {
    code: 'MC',
    name: 'codebooks.countries.mc',
    flag: 'flags/MC.png',
    isAdvertising: false,
    phonePrefix: '+377',
  },
  {
    code: 'MD',
    name: 'codebooks.countries.md',
    flag: 'flags/MD.png',
    isAdvertising: false,
    phonePrefix: '+373',
  },
  {
    code: 'ME',
    name: 'codebooks.countries.me',
    flag: 'flags/ME.png',
    isAdvertising: false,
    phonePrefix: '+382',
  },
  {
    code: 'MF',
    name: 'codebooks.countries.mf',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+590',
  },
  {
    code: 'MG',
    name: 'codebooks.countries.mg',
    flag: 'flags/MG.png',
    isAdvertising: false,
    phonePrefix: '+261',
  },
  {
    code: 'MH',
    name: 'codebooks.countries.mh',
    flag: 'flags/MH.png',
    isAdvertising: false,
    phonePrefix: '+692',
  },
  {
    code: 'MK',
    name: 'codebooks.countries.mk',
    flag: 'flags/MK.png',
    isAdvertising: false,
    phonePrefix: '+389',
  },
  {
    code: 'ML',
    name: 'codebooks.countries.ml',
    flag: 'flags/ML.png',
    isAdvertising: false,
    phonePrefix: '+223',
  },
  {
    code: 'MM',
    name: 'codebooks.countries.mm',
    flag: 'flags/MM.png',
    isAdvertising: false,
    phonePrefix: '+95',
  },
  {
    code: 'MN',
    name: 'codebooks.countries.mn',
    flag: 'flags/MN.png',
    isAdvertising: false,
    phonePrefix: '+976',
  },
  {
    code: 'MO',
    name: 'codebooks.countries.mo',
    flag: 'flags/MO.png',
    isAdvertising: false,
    phonePrefix: '+853',
  },
  {
    code: 'MP',
    name: 'codebooks.countries.mp',
    flag: 'flags/MP.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'MQ',
    name: 'codebooks.countries.mq',
    flag: 'flags/MQ.png',
    isAdvertising: false,
    phonePrefix: '+596',
  },
  {
    code: 'MR',
    name: 'codebooks.countries.mr',
    flag: 'flags/MR.png',
    isAdvertising: false,
    phonePrefix: '+222',
  },
  {
    code: 'MS',
    name: 'codebooks.countries.ms',
    flag: 'flags/GB.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'MT',
    name: 'codebooks.countries.mt',
    flag: 'flags/MT.png',
    isAdvertising: false,
    phonePrefix: '+356',
  },
  {
    code: 'MU',
    name: 'codebooks.countries.mu',
    flag: 'flags/MU.png',
    isAdvertising: false,
    phonePrefix: '+230',
  },
  {
    code: 'MV',
    name: 'codebooks.countries.mv',
    flag: 'flags/MV.png',
    isAdvertising: false,
    phonePrefix: '+960',
  },
  {
    code: 'MW',
    name: 'codebooks.countries.mw',
    flag: 'flags/MW.png',
    isAdvertising: false,
    phonePrefix: '+265',
  },
  {
    code: 'MX',
    name: 'codebooks.countries.mx',
    flag: 'flags/MX.png',
    isAdvertising: false,
    phonePrefix: '+52',
  },
  {
    code: 'MY',
    name: 'codebooks.countries.my',
    flag: 'flags/MY.png',
    isAdvertising: false,
    phonePrefix: '+60',
  },
  {
    code: 'MZ',
    name: 'codebooks.countries.mz',
    flag: 'flags/MZ.png',
    isAdvertising: false,
    phonePrefix: '+258',
  },
  {
    code: 'NA',
    name: 'codebooks.countries.na',
    flag: 'flags/NA.png',
    isAdvertising: false,
    phonePrefix: '+264',
  },
  {
    code: 'NC',
    name: 'codebooks.countries.nc',
    flag: 'flags/NC.png',
    isAdvertising: false,
    phonePrefix: '+687',
  },
  {
    code: 'NE',
    name: 'codebooks.countries.ne',
    flag: 'flags/NE.png',
    isAdvertising: false,
    phonePrefix: '+227',
  },
  {
    code: 'NF',
    name: 'codebooks.countries.nf',
    flag: 'flags/NF.png',
    isAdvertising: false,
    phonePrefix: '+672',
  },
  {
    code: 'NG',
    name: 'codebooks.countries.ng',
    flag: 'flags/NG.png',
    isAdvertising: false,
    phonePrefix: '+234',
  },
  {
    code: 'NI',
    name: 'codebooks.countries.ni',
    flag: 'flags/NI.png',
    isAdvertising: false,
    phonePrefix: '+505',
  },
  {
    code: 'NL',
    name: 'codebooks.countries.nl',
    flag: 'flags/NL.png',
    isAdvertising: true,
    phonePrefix: '+31',
  },
  {
    code: 'NO',
    name: 'codebooks.countries.no',
    flag: 'flags/NO.png',
    isAdvertising: true,
    phonePrefix: '+47',
  },
  {
    code: 'NP',
    name: 'codebooks.countries.np',
    flag: 'flags/NP.png',
    isAdvertising: false,
    phonePrefix: '+977',
  },
  {
    code: 'NR',
    name: 'codebooks.countries.nr',
    flag: 'flags/NR.png',
    isAdvertising: false,
    phonePrefix: '+674',
  },
  {
    code: 'NU',
    name: 'codebooks.countries.nu',
    flag: 'flags/NU.png',
    isAdvertising: false,
    phonePrefix: '+683',
  },
  {
    code: 'NZ',
    name: 'codebooks.countries.nz',
    flag: 'flags/NZ.png',
    isAdvertising: false,
    phonePrefix: '+64',
  },
  {
    code: 'OM',
    name: 'codebooks.countries.om',
    flag: 'flags/OM.png',
    isAdvertising: false,
    phonePrefix: '+968',
  },
  {
    code: 'PA',
    name: 'codebooks.countries.pa',
    flag: 'flags/PA.png',
    isAdvertising: false,
    phonePrefix: '+507',
  },
  {
    code: 'PE',
    name: 'codebooks.countries.pe',
    flag: 'flags/PE.png',
    isAdvertising: false,
    phonePrefix: '+51',
  },
  {
    code: 'PF',
    name: 'codebooks.countries.pf',
    flag: 'flags/PF.png',
    isAdvertising: false,
    phonePrefix: '+689',
  },
  {
    code: 'PG',
    name: 'codebooks.countries.pg',
    flag: 'flags/PG.png',
    isAdvertising: false,
    phonePrefix: '+675',
  },
  {
    code: 'PH',
    name: 'codebooks.countries.ph',
    flag: 'flags/PH.png',
    isAdvertising: false,
    phonePrefix: '+63',
  },
  {
    code: 'PK',
    name: 'codebooks.countries.pk',
    flag: 'flags/PK.png',
    isAdvertising: false,
    phonePrefix: '+92',
  },
  {
    code: 'PL',
    name: 'codebooks.countries.pl',
    flag: 'flags/PL.png',
    isAdvertising: true,
    phonePrefix: '+48',
  },
  {
    code: 'PM',
    name: 'codebooks.countries.pm',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+508',
  },
  {
    code: 'PR',
    name: 'codebooks.countries.pr',
    flag: 'flags/PR.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'PS',
    name: 'codebooks.countries.ps',
    flag: 'flags/PS.png',
    isAdvertising: false,
    phonePrefix: '+970',
  },
  {
    code: 'PT',
    name: 'codebooks.countries.pt',
    flag: 'flags/PT.png',
    isAdvertising: true,
    phonePrefix: '+351',
  },
  {
    code: 'PW',
    name: 'codebooks.countries.pw',
    flag: 'flags/PW.png',
    isAdvertising: false,
    phonePrefix: '+680',
  },
  {
    code: 'PY',
    name: 'codebooks.countries.py',
    flag: 'flags/PY.png',
    isAdvertising: false,
    phonePrefix: '+595',
  },
  {
    code: 'QA',
    name: 'codebooks.countries.qa',
    flag: 'flags/QA.png',
    isAdvertising: false,
    phonePrefix: '+974',
  },
  {
    code: 'RE',
    name: 'codebooks.countries.re',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+262',
  },
  {
    code: 'RO',
    name: 'codebooks.countries.ro',
    flag: 'flags/RO.png',
    isAdvertising: true,
    phonePrefix: '+40',
  },
  {
    code: 'RS',
    name: 'codebooks.countries.rs',
    flag: 'flags/RS.png',
    isAdvertising: false,
    phonePrefix: '+381',
  },
  {
    code: 'RU',
    name: 'codebooks.countries.ru',
    flag: 'flags/RU.png',
    isAdvertising: false,
    phonePrefix: '+7',
  },
  {
    code: 'RW',
    name: 'codebooks.countries.rw',
    flag: 'flags/RW.png',
    isAdvertising: false,
    phonePrefix: '+250',
  },
  {
    code: 'SA',
    name: 'codebooks.countries.sa',
    flag: 'flags/SA.png',
    isAdvertising: false,
    phonePrefix: '+966',
  },
  {
    code: 'SB',
    name: 'codebooks.countries.sb',
    flag: 'flags/SB.png',
    isAdvertising: false,
    phonePrefix: '+677',
  },
  {
    code: 'SC',
    name: 'codebooks.countries.sc',
    flag: 'flags/SC.png',
    isAdvertising: false,
    phonePrefix: '+248',
  },
  {
    code: 'SD',
    name: 'codebooks.countries.sd',
    flag: 'flags/SD.png',
    isAdvertising: false,
    phonePrefix: '+249',
  },
  {
    code: 'SE',
    name: 'codebooks.countries.se',
    flag: 'flags/SE.png',
    isAdvertising: true,
    phonePrefix: '+46',
  },
  {
    code: 'SG',
    name: 'codebooks.countries.sg',
    flag: 'flags/SG.png',
    isAdvertising: false,
    phonePrefix: '+65',
  },
  {
    code: 'SH',
    name: 'codebooks.countries.sh',
    flag: 'flags/SH.png',
    isAdvertising: false,
    phonePrefix: '+290',
  },
  {
    code: 'SI',
    name: 'codebooks.countries.si',
    flag: 'flags/SI.png',
    isAdvertising: false,
    phonePrefix: '+386',
  },
  {
    code: 'SJ',
    name: 'codebooks.countries.sj',
    flag: 'flags/SJ.png',
    isAdvertising: false,
    phonePrefix: '+47',
  },
  {
    code: 'SK',
    name: 'codebooks.countries.sk',
    flag: 'flags/SK.png',
    isAdvertising: true,
    phonePrefix: '+421',
  },
  {
    code: 'SL',
    name: 'codebooks.countries.sl',
    flag: 'flags/SL.png',
    isAdvertising: false,
    phonePrefix: '+232',
  },
  {
    code: 'SM',
    name: 'codebooks.countries.sm',
    flag: 'flags/SM.png',
    isAdvertising: false,
    phonePrefix: '+378',
  },
  {
    code: 'SN',
    name: 'codebooks.countries.sn',
    flag: 'flags/SN.png',
    isAdvertising: false,
    phonePrefix: '+221',
  },
  {
    code: 'SO',
    name: 'codebooks.countries.so',
    flag: 'flags/SO.png',
    isAdvertising: false,
    phonePrefix: '+252',
  },
  {
    code: 'SR',
    name: 'codebooks.countries.sr',
    flag: 'flags/SR.png',
    isAdvertising: false,
    phonePrefix: '+597',
  },
  {
    code: 'SS',
    name: 'codebooks.countries.ss',
    flag: 'flags/SS.png',
    isAdvertising: false,
    phonePrefix: '+211',
  },
  {
    code: 'ST',
    name: 'codebooks.countries.st',
    flag: 'flags/ST.png',
    isAdvertising: false,
    phonePrefix: '+239',
  },
  {
    code: 'SV',
    name: 'codebooks.countries.sv',
    flag: 'flags/SV.png',
    isAdvertising: false,
    phonePrefix: '+503',
  },
  {
    code: 'SX',
    name: 'codebooks.countries.sx',
    flag: 'flags/SX.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'SY',
    name: 'codebooks.countries.sy',
    flag: 'flags/SY.png',
    isAdvertising: false,
    phonePrefix: '+963',
  },
  {
    code: 'SZ',
    name: 'codebooks.countries.sz',
    flag: 'flags/SZ.png',
    isAdvertising: false,
    phonePrefix: '+268',
  },
  {
    code: 'TA',
    name: 'codebooks.countries.ta',
    flag: 'flags/TA.png',
    isAdvertising: false,
    phonePrefix: '+290',
  },
  {
    code: 'TC',
    name: 'codebooks.countries.tc',
    flag: 'flags/TC.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'TD',
    name: 'codebooks.countries.td',
    flag: 'flags/TD.png',
    isAdvertising: false,
    phonePrefix: '+235',
  },
  {
    code: 'TG',
    name: 'codebooks.countries.tg',
    flag: 'flags/TG.png',
    isAdvertising: false,
    phonePrefix: '+228',
  },
  {
    code: 'TH',
    name: 'codebooks.countries.th',
    flag: 'flags/TH.png',
    isAdvertising: false,
    phonePrefix: '+66',
  },
  {
    code: 'TJ',
    name: 'codebooks.countries.tj',
    flag: 'flags/TJ.png',
    isAdvertising: false,
    phonePrefix: '+992',
  },
  {
    code: 'TK',
    name: 'codebooks.countries.tk',
    flag: 'flags/TK.png',
    isAdvertising: false,
    phonePrefix: '+690',
  },
  {
    code: 'TL',
    name: 'codebooks.countries.tl',
    flag: 'flags/TL.png',
    isAdvertising: false,
    phonePrefix: '+670',
  },
  {
    code: 'TM',
    name: 'codebooks.countries.tm',
    flag: 'flags/TM.png',
    isAdvertising: false,
    phonePrefix: '+993',
  },
  {
    code: 'TN',
    name: 'codebooks.countries.tn',
    flag: 'flags/TN.png',
    isAdvertising: false,
    phonePrefix: '+216',
  },
  {
    code: 'TO',
    name: 'codebooks.countries.to',
    flag: 'flags/TO.png',
    isAdvertising: false,
    phonePrefix: '+676',
  },
  {
    code: 'TR',
    name: 'codebooks.countries.tr',
    flag: 'flags/TR.png',
    isAdvertising: false,
    phonePrefix: '+90',
  },
  {
    code: 'TT',
    name: 'codebooks.countries.tt',
    flag: 'flags/TT.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'TV',
    name: 'codebooks.countries.tv',
    flag: 'flags/TV.png',
    isAdvertising: false,
    phonePrefix: '+688',
  },
  {
    code: 'TW',
    name: 'codebooks.countries.tw',
    flag: 'flags/TW.png',
    isAdvertising: false,
    phonePrefix: '+886',
  },
  {
    code: 'TZ',
    name: 'codebooks.countries.tz',
    flag: 'flags/TZ.png',
    isAdvertising: false,
    phonePrefix: '+255',
  },
  {
    code: 'UA',
    name: 'codebooks.countries.ua',
    flag: 'flags/UA.png',
    isAdvertising: false,
    phonePrefix: '+380',
  },
  {
    code: 'UG',
    name: 'codebooks.countries.ug',
    flag: 'flags/UG.png',
    isAdvertising: false,
    phonePrefix: '+256',
  },
  {
    code: 'US',
    name: 'codebooks.countries.us',
    flag: 'flags/US.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'UY',
    name: 'codebooks.countries.uy',
    flag: 'flags/UY.png',
    isAdvertising: false,
    phonePrefix: '+598',
  },
  {
    code: 'UZ',
    name: 'codebooks.countries.uz',
    flag: 'flags/UZ.png',
    isAdvertising: false,
    phonePrefix: '+998',
  },
  {
    code: 'VA',
    name: 'codebooks.countries.va',
    flag: 'flags/VA.png',
    isAdvertising: false,
    phonePrefix: '+379',
  },
  {
    code: 'VC',
    name: 'codebooks.countries.vc',
    flag: 'flags/VC.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'VE',
    name: 'codebooks.countries.ve',
    flag: 'flags/VE.png',
    isAdvertising: false,
    phonePrefix: '+58',
  },
  {
    code: 'VG',
    name: 'codebooks.countries.vg',
    flag: 'flags/VG.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'VI',
    name: 'codebooks.countries.vi',
    flag: 'flags/VI.png',
    isAdvertising: false,
    phonePrefix: '+1',
  },
  {
    code: 'VN',
    name: 'codebooks.countries.vn',
    flag: 'flags/VN.png',
    isAdvertising: false,
    phonePrefix: '+84',
  },
  {
    code: 'VU',
    name: 'codebooks.countries.vu',
    flag: 'flags/VU.png',
    isAdvertising: false,
    phonePrefix: '+678',
  },
  {
    code: 'WF',
    name: 'codebooks.countries.wf',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+681',
  },
  {
    code: 'WS',
    name: 'codebooks.countries.ws',
    flag: 'flags/WS.png',
    isAdvertising: false,
    phonePrefix: '+685',
  },
  {
    code: 'XK',
    name: 'codebooks.countries.xk',
    flag: 'flags/XK.png',
    isAdvertising: false,
    phonePrefix: '+383',
  },
  {
    code: 'YE',
    name: 'codebooks.countries.ye',
    flag: 'flags/YE.png',
    isAdvertising: false,
    phonePrefix: '+967',
  },
  {
    code: 'YT',
    name: 'codebooks.countries.yt',
    flag: 'flags/FR.png',
    isAdvertising: false,
    phonePrefix: '+262',
  },
  {
    code: 'ZA',
    name: 'codebooks.countries.za',
    flag: 'flags/ZA.png',
    isAdvertising: false,
    phonePrefix: '+27',
  },
  {
    code: 'ZM',
    name: 'codebooks.countries.zm',
    flag: 'flags/ZM.png',
    isAdvertising: false,
    phonePrefix: '+260',
  },
  {
    code: 'ZW',
    name: 'codebooks.countries.zw',
    flag: 'flags/ZW.png',
    isAdvertising: false,
    phonePrefix: '+263',
  },
];

export default CountriesCodebook;
