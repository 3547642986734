<template>
  <div class="mx-auto block h-[73vh] w-auto overflow-auto rounded-lg px-4 lg:px-4">
    <div class="flex h-full w-full flex-col overflow-hidden rounded-xl bg-white p-5 shadow">
      <div>
        <h1 class="mb-10 text-center text-2xl text-[#F67424]">{{ $t('socialMedia.' + type + 'Header') }}</h1>
      </div>
      <div class="mx-auto w-full grow overflow-y-auto px-5">
        <div class="mx-auto max-w-4xl">
          <div>
            <label v-if="showInput.targetAudience.sexes || showInput.targetAudience.ageGroups || showInput.targetAudience.customTargets" for="targetAudience" class="mb-2 block text-lg text-main">{{ $t('socialMedia.targetAudience') }}</label>
            <div class="pl-0">
              <label v-if="showInput.targetAudience.sexes" for="targetAudience" class="mb-2 block text-gray-700">{{ $t('socialMedia.sex') }}<span class="pl-1 text-red-600">*</span></label>
              <div v-if="showInput.targetAudience.sexes" class="card flex flex-wrap justify-start gap-4">
                <div v-for="item in targetAudienceSexOptions" :key="item" class="flex items-center">
                  <Checkbox v-model="values.targetAudience.sexes" :inputId="item" :name="item" :value="item" />
                  <label :for="item" class="ml-2"> {{ $t('socialMedia.' + item) }} </label>
                </div>
              </div>
              <small v-if="showInput.targetAudience.sexes" class="text-sm text-red-600">{{ errors.targetAudience.sexes ? $t('validations.required') : '&nbsp;' }}</small>
              <label v-if="showInput.targetAudience.ageGroups" for="targetAudience" class="mb-2 mt-5 block text-gray-700">{{ $t('socialMedia.age') }}<span class="pl-1 text-red-600">*</span></label>
              <div v-if="showInput.targetAudience.ageGroups" class="card flex flex-wrap justify-start gap-4">
                <div v-for="item in targetAudienceAgeGroupOptions" :key="item" class="flex items-center">
                  <Checkbox v-model="values.targetAudience.ageGroups" :inputId="item" :name="item" :value="item" />
                  <label :for="item" class="ml-2"> {{ $t('socialMedia.' + item) }} </label>
                </div>
              </div>
              <small v-if="showInput.targetAudience.ageGroups" class="text-sm text-red-600">{{ errors.targetAudience.ageGroups ? $t('validations.required') : '&nbsp;' }}</small>
              <Chips v-if="showInput.targetAudience.customTargets" class="mt-5" v-model="values.targetAudience.customTargets" placeholder="Vlastní cílení" />
              <small v-if="showInput.targetAudience.customTargets" class="text-sm text-red-600">{{ errors.targetAudience.customTargets ? $t('validations.required') : '&nbsp;' }}</small>
            </div>
          </div>
          <div v-if="showInput.targetAudience.types" class="mt-5">
            <label for="types" class="mb-2 block text-lg text-main">{{ $t('socialMedia.types') }}<span class="pl-1 text-red-600">*</span></label>
            <div class="card flex flex-wrap justify-start gap-4">
              <div v-for="item in targetAudienceTypeOptions" :key="item" class="flex items-center">
                <Checkbox v-model="values.targetAudience.types" :inputId="item" :name="item" :value="item" />
                <label :for="item" class="ml-2"> {{ $t('socialMedia.' + item) }} </label>
              </div>
            </div>
            <small v-if="showInput.targetAudience.types" class="text-sm text-red-600">{{ errors.targetAudience.types ? $t('validations.required') : '&nbsp;' }}</small>
          </div>
          <div v-if="showInput.goal" class="mt-5">
            <label for="goal" class="mb-2 block text-lg text-main">{{ $t('socialMedia.goal') }}<span class="pl-1 text-red-600">*</span></label>
            <div class="card flex flex-wrap justify-start gap-4 placeholder:text-gray-500">
              <Dropdown
                v-model="values.goal"
                :options="contentGoalOptions"
                :ptOptions="{ mergeProps: true }"
                :pt="{
                  input: '!placeholder-gray-500 placeholder:text-gray-500',
                }"
              >
                <template #option="{ option }">
                  <div class="flex items-center">
                    <span>{{ $t('socialMedia.' + option) }}</span>
                  </div>
                </template>
                <template #value="{ value }">
                  <div v-if="value" class="flex items-center">
                    <span>{{ $t('socialMedia.' + value) }}</span>
                  </div>
                  <div v-else class="flex items-center text-gray-500">
                    <span>{{ $t('socialMedia.chooseGoal') }}</span>
                  </div>
                </template>
              </Dropdown>
            </div>
            <small v-if="showInput.goal" class="text-sm text-red-600">{{ errors.goal ? $t('validations.required') : '&nbsp;' }}</small>
          </div>
          <div v-if="showInput.communicationStyle" class="mt-5">
            <label for="communicationStyle" class="mb-2 block text-lg text-main">{{ $t('socialMedia.communicationStyle') }}<span class="pl-1 text-red-600">*</span></label>
            <div class="card flex flex-wrap justify-start gap-4">
              <Dropdown v-model="values.communicationStyle" :options="contentCommunicationStyleOptions">
                <template #option="{ option }">
                  <div class="flex items-center">
                    <span>{{ $t('socialMedia.' + option) }}</span>
                  </div>
                </template>
                <template #value="{ value }">
                  <div v-if="value" class="flex items-center">
                    <span>{{ $t('socialMedia.' + value) }}</span>
                  </div>
                  <div v-else class="flex items-center text-gray-500">
                    <span>{{ $t('socialMedia.chooseCommunicationStyleContent') }}</span>
                  </div>
                </template>
              </Dropdown>
            </div>
            <small v-if="showInput.communicationStyle" class="text-sm text-red-600">{{ errors.communicationStyle ? $t('validations.required') : '&nbsp;' }}</small>
          </div>
          <div v-if="showInput.contentDescription">
            <label for="contentDescription" class="mt-5 block text-lg text-main">{{ $t('socialMedia.contentDescription') }}<span class="pl-1 text-red-600">*</span></label>
            <Textarea maxlength="300" v-model="values.contentDescription" rows="3" class="w-full" :placeholder="$t('socialMedia.contentDescriptionPlaceholder')" />
            <span v-if="values.contentDescription" class="ml-auto mr-0 block w-fit font-bold text-main">{{ values.contentDescription ? values.contentDescription.length : 0 }} / {{ 300 }}</span>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span v-else class="ml-auto mr-0 block w-fit font-bold text-main">0 / {{ 300 }}</span>
            <div>
              <small v-if="showInput.contentDescription" class="text-sm text-red-600">{{ errors.contentDescription ? $t('validations.required') : '&nbsp;' }}</small>
            </div>
          </div>
          <div v-if="showInput.content" class="col-span-2 mt-5 w-full">
            <label for="content" class="mb-2 block text-lg text-main">{{ $t('socialMedia.content') }}<span class="pl-1 text-red-600">*</span></label>
            <Textarea v-model="values.content" rows="3" class="w-full" :placeholder="$t('socialMedia.contentPlaceholder')" />
            <div>
              <small v-if="showInput.content" class="text-sm text-red-600">{{ errors.content ? $t('validations.required') : '&nbsp;' }}</small>
            </div>
          </div>
          <div v-if="showInput.details" class="col-span-2 mt-5 w-full">
            <label for="details" class="mb-2 block text-lg text-main">{{ $t('socialMedia.details') }}</label>
            <Textarea maxlength="300" v-model="values.details" rows="3" class="w-full" :placeholder="$t('socialMedia.descriptionPlaceholder')" />
            <span v-if="values.details" class="ml-auto mr-0 block w-fit font-bold text-main">{{ values.details ? values.details.length : 0 }} / {{ 300 }}</span>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span v-else class="ml-auto mr-0 block w-fit font-bold text-main">0 / {{ 300 }}</span>
            <div>
              <small v-if="showInput.details" class="text-sm text-red-600">{{ errors.details ? $t('validations.required') : '&nbsp;' }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-end flex pt-5">
        <button
          @click="generate()"
          class="ml-auto mr-0 w-3/4 max-w-[18.75rem] rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base"
        >
          {{ $t('socialMedia.generate') }}
        </button>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showContentPreview" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header"></h1>
    </template>
    <div class="dialogStyle" v-html="contentPreview"></div>
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { Errors } from '@/modules/aicontent/interfaces/Errors';
import { Values } from '@/modules/aicontent/interfaces/Values';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { EnumsContentTargetAudienceSex, EnumsContentTargetAudienceAgeGroup, EnumsContentTargetAudienceType, EnumsSocialMedia, EnumsContentGoal, EnumsContentCommunicationStyle } from '../../../../generated/api';
const props = defineProps<{
  type: string;
}>();

const values = ref<Values>({
  targetAudience: {
    sexes: [],
    ageGroups: [],
    customTargets: [],
    types: [],
  }, //
  goal: '',
  communicationStyle: '',
  details: '',
  contentDescription: '',
  content: '',
  type: props.type,
});

const errors = ref<Errors>({
  targetAudience: {
    sexes: false,
    ageGroups: false,
    customTargets: false,
    types: false,
  },
  goal: false,
  communicationStyle: false,
  details: false,
  contentDescription: false,
  content: false,
});

const showInput = ref({
  targetAudience: {
    sexes: true,
    ageGroups: true,
    customTargets: true,
    types: true,
  },
  goal: true,
  communicationStyle: true,
  details: true,
  contentDescription: true,
  content: true,
  blogContent: true,
});

const showContentPreview = ref(false);
const contentPreview = ref();
const toast = useToast();
const loadingStore = useLoadingStore();

// create options from enums
const targetAudienceSexOptions = Object.values(EnumsContentTargetAudienceSex);
const targetAudienceAgeGroupOptions = Object.values(EnumsContentTargetAudienceAgeGroup);
const targetAudienceTypeOptions = Object.values(EnumsContentTargetAudienceType);
const contentGoalOptions = Object.values(EnumsContentGoal);
// create content
const contentCommunicationStyleOptions = Object.values(EnumsContentCommunicationStyle);

onMounted(() => {
  configureInputs();
});

const generate = async () => {
  loadingStore.updateLoading(true);

  try {
    const { data } = await api.clientEshopSettingsGetEshopDescription();

    if (!data.description) {
      await api.clientEshopSettingsCreateEshopDescription();
    }

    const generateContentFunction = getGenerateFunctionByType(props.type);
    await generateContentFunction();
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const getGenerateFunctionByType = (type: string) => {
  const functions: { [key: string]: () => Promise<void> } = {
    GenerateSocialMediaContentIdeaFacebook: () => generateSocialMediaContentIdea(EnumsSocialMedia.Facebook),
    GenerateSocialMediaContentIdeaInstagram: () => generateSocialMediaContentIdea(EnumsSocialMedia.Instagram),
    GenerateSocialMediaContentIdeaLinkedin: () => generateSocialMediaContentIdea(EnumsSocialMedia.LinkedIn),
    GenerateSocialMediaContentIdeaTwitter: () => generateSocialMediaContentIdea(EnumsSocialMedia.Twitter),
    GenerateSocialMediaContentIdeaPinterest: () => generateSocialMediaContentIdea(EnumsSocialMedia.Pinterest),
    GenerateSocialMediaContentIdeaTikTok: () => generateSocialMediaContentIdea(EnumsSocialMedia.TikTok),
    GenerateSocialMediaContentIdeaYouTube: () => generateSocialMediaContentIdea(EnumsSocialMedia.YouTube),
    GenerateSocialMediaContentFacebook: () => generateSocialMediaContent(EnumsSocialMedia.Facebook),
    GenerateSocialMediaContentInstagram: () => generateSocialMediaContent(EnumsSocialMedia.Instagram),
    GenerateSocialMediaContentLinkedin: () => generateSocialMediaContent(EnumsSocialMedia.LinkedIn),
    GenerateSocialMediaContentTwitter: () => generateSocialMediaContent(EnumsSocialMedia.Twitter),
    GenerateSocialMediaContentPinterest: () => generateSocialMediaContent(EnumsSocialMedia.Pinterest),
    GenerateSocialMediaContentTikTok: () => generateSocialMediaContent(EnumsSocialMedia.TikTok),
    YoutubeIdea: () => generateSocialMediaContentIdea(EnumsSocialMedia.YouTube),
    YoutubeIdeaName: () => generateSocialMediaContentHeadline(EnumsSocialMedia.YouTube),
    ContentStrategyFacebook: () => generateSocialMediaContentStrategy(EnumsSocialMedia.Facebook),
    ContentStrategyInstagram: () => generateSocialMediaContentStrategy(EnumsSocialMedia.Instagram),
    ContentStrategyLinkedin: () => generateSocialMediaContentStrategy(EnumsSocialMedia.LinkedIn),
    ContentStrategyYoutube: () => generateSocialMediaContentStrategy(EnumsSocialMedia.YouTube),
    GenerateBlogGivenTopic: () => generateBlogContent(),
    GenerateBlogStrategy: () => generateBlogContentStrategy(),
    GenerateIdeasForBlogArticle: () => generateBlogContentIdea(),
    GenerateSeoForBlog: () => generateBlogContentSeo(),
    GenerateEmailIdeasForNewsletter: () => generateNewsletterIdea(),
    GenerateEmailIdeasForNewsletterText: () => generateNewsletter(),
    GenerateEmailContentStrategy: () => generateEmailContentStrategy(),
  };

  return functions[type];
};

const generateSocialMediaContentIdea = async (socialMedia) => {
  const isValid = validateForm('ContentIdea');
  if (!isValid) {
    return;
  }
  const postParams = {
    socialMedia,
    targetAudience: values.value.targetAudience,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateSocialMediaContentIdea(postParams);
    contentPreview.value = data.postIdea;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateSocialMediaContent = async (socialMedia) => {
  const isValid = validateForm('ClearContent');
  if (!isValid) {
    return;
  }
  const postParams = {
    socialMedia,
    targetAudience: values.value.targetAudience,
    goal: values.value.goal,
    communicationStyle: values.value.communicationStyle,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateSocialMediaContent(postParams);
    contentPreview.value = data.post;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateSocialMediaContentHeadline = async (socialMedia) => {
  const isValid = validateForm('Headline');
  if (!isValid) {
    return;
  }
  const postParams = {
    socialMedia,
    contentDescription: values.value.contentDescription,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateSocialMediaContentHeadline(postParams);
    contentPreview.value = data.headlines;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateSocialMediaContentStrategy = async (socialMedia) => {
  const isValid = validateForm('ContentStrategy');
  if (!isValid) {
    return;
  }
  const postParams = {
    socialMedia,
    targetAudience: values.value.targetAudience,
    details: values.value.details,
    goal: values.value.goal,
  };
  try {
    const { data } = await api.clientContentGenerateSocialMediaContentStrategy(postParams);
    contentPreview.value = data.strategy;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateBlogContent = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    contentDescription: values.value.contentDescription,
    targetAudience: values.value.targetAudience,
    communicationStyle: values.value.communicationStyle,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateBlogContent(postParams);
    contentPreview.value = data.blogPost;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateBlogContentStrategy = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    targetAudience: values.value.targetAudience,
    goal: values.value.goal,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateBlogContentStrategy(postParams);
    contentPreview.value = data.strategy;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateBlogContentIdea = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    targetAudience: values.value.targetAudience,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateBlogContentIdea(postParams);
    contentPreview.value = data.contentIdea;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateBlogContentSeo = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    content: values.value.content,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentOptimizeBlogContent(postParams);
    contentPreview.value = data.optimizedBlogPost;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateNewsletterIdea = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    targetAudience: values.value.targetAudience,
    details: values.value.details,
  };
  try {
    const { data } = await api.clientContentGenerateNewsletterIdea(postParams);
    contentPreview.value = data.newsletterIdea;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateNewsletter = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    targetAudience: values.value.targetAudience,
    details: values.value.details,
    communicationStyle: values.value.communicationStyle,
    contentDescription: values.value.contentDescription,
  };
  try {
    const { data } = await api.clientContentGenerateNewsletter(postParams);
    contentPreview.value = data.newsletter;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const generateEmailContentStrategy = async () => {
  const isValid = validateForm(props.type);
  if (!isValid) {
    return;
  }
  const postParams = {
    targetAudience: values.value.targetAudience,
    details: values.value.details,
    goal: values.value.goal,
  };
  try {
    const { data } = await api.clientContentGenerateEmailContentStrategy(postParams);
    contentPreview.value = data.strategy;
    showContentPreview.value = true;
    console.log(data);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const validateForm = (type) => {
  if (type === 'ContentIdea') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.targetAudience.types.length === 0 ? ((errors.value.targetAudience.types = true), (isValid = false)) : (errors.value.targetAudience.types = false);
    return isValid;
  } else if (type === 'ClearContent') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.goal === '' ? ((errors.value.goal = true), (isValid = false)) : (errors.value.goal = false);
    values.value.communicationStyle === '' ? ((errors.value.communicationStyle = true), (isValid = false)) : (errors.value.communicationStyle = false);
    return isValid;
  } else if (type === 'Headline') {
    let isValid = true;
    values.value.contentDescription === '' ? ((errors.value.contentDescription = true), (isValid = false)) : (errors.value.contentDescription = false);
    return isValid;
  } else if (type === 'ContentStrategy') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.targetAudience.types.length === 0 ? ((errors.value.targetAudience.types = true), (isValid = false)) : (errors.value.targetAudience.types = false);
    values.value.goal === '' ? ((errors.value.goal = true), (isValid = false)) : (errors.value.goal = false);
    return isValid;
  } else if (type === 'GenerateBlogGivenTopic') {
    let isValid = true;
    values.value.contentDescription === '' ? ((errors.value.contentDescription = true), (isValid = false)) : (errors.value.contentDescription = false);
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.communicationStyle === '' ? ((errors.value.communicationStyle = true), (isValid = false)) : (errors.value.communicationStyle = false);
    return isValid;
  } else if (type === 'GenerateBlogStrategy') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.targetAudience.types.length === 0 ? ((errors.value.targetAudience.types = true), (isValid = false)) : (errors.value.targetAudience.types = false);
    values.value.goal === '' ? ((errors.value.goal = true), (isValid = false)) : (errors.value.goal = false);
    return isValid;
  } else if (type === 'GenerateIdeasForBlogArticle') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.targetAudience.types.length === 0 ? ((errors.value.targetAudience.types = true), (isValid = false)) : (errors.value.targetAudience.types = false);
    return isValid;
  } else if (type === 'GenerateSeoForBlog') {
    let isValid = true;
    values.value.content.length === 0 ? ((errors.value.content = true), (isValid = false)) : (errors.value.content = false);
    return isValid;
  } else if (type === 'GenerateEmailIdeasForNewsletter') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    return isValid;
  } else if (type === 'GenerateEmailIdeasForNewsletterText') {
    let isValid = true;
    values.value.contentDescription === '' ? ((errors.value.contentDescription = true), (isValid = false)) : (errors.value.contentDescription = false);
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.communicationStyle === '' ? ((errors.value.communicationStyle = true), (isValid = false)) : (errors.value.communicationStyle = false);
    return isValid;
  } else if (type === 'GenerateEmailContentStrategy') {
    let isValid = true;
    values.value.targetAudience.sexes.length === 0 ? ((errors.value.targetAudience.sexes = true), (isValid = false)) : (errors.value.targetAudience.sexes = false);
    values.value.targetAudience.ageGroups.length === 0 ? ((errors.value.targetAudience.ageGroups = true), (isValid = false)) : (errors.value.targetAudience.ageGroups = false);
    values.value.goal === '' ? ((errors.value.goal = true), (isValid = false)) : (errors.value.goal = false);
    return isValid;
  }
  return false;
};

const configureInputs = () => {
  const contentTypesWithGoalAndStyle = [
    'GenerateSocialMediaContentIdeaFacebook',
    'GenerateSocialMediaContentIdeaInstagram',
    'GenerateSocialMediaContentIdeaLinkedin',
    'GenerateSocialMediaContentIdeaTwitter',
    'GenerateSocialMediaContentIdeaPinterest',
    'GenerateSocialMediaContentIdeaTikTok',
    'GenerateSocialMediaContentIdeaYouTube',
    'YoutubeIdea',
  ];

  const contentTypesWithAudienceTypes = [
    'GenerateSocialMediaContentFacebook',
    'GenerateSocialMediaContentInstagram',
    'GenerateSocialMediaContentLinkedin',
    'GenerateSocialMediaContentTwitter',
    'GenerateSocialMediaContentPinterest',
    'GenerateSocialMediaContentTikTok',
  ];

  const contentSocialMediaStrategy = ['ContentStrategyFacebook', 'ContentStrategyInstagram', 'ContentStrategyLinkedin', 'ContentStrategyYoutube'];

  if (contentTypesWithGoalAndStyle.includes(props.type)) {
    showInput.value.goal = false;
    showInput.value.communicationStyle = false;
    showInput.value.contentDescription = false;
    showInput.value.content = false;
  } else if (contentTypesWithAudienceTypes.includes(props.type)) {
    showInput.value.targetAudience.types = false;
    showInput.value.contentDescription = false;
    showInput.value.content = false;
  } else if (props.type === 'YoutubeIdeaName') {
    // show only details and contentDescription
    showInput.value.communicationStyle = false;
    showInput.value.goal = false;
    showInput.value.targetAudience.ageGroups = false;
    showInput.value.targetAudience.customTargets = false;
    showInput.value.targetAudience.sexes = false;
    showInput.value.targetAudience.types = false;
    showInput.value.content = false;
  } else if (contentSocialMediaStrategy.includes(props.type)) {
    showInput.value.communicationStyle = false;
    showInput.value.contentDescription = false;
    showInput.value.content = false;
  } else if (props.type === 'GenerateBlogGivenTopic') {
    showInput.value.goal = false;
    showInput.value.targetAudience.types = false;
    showInput.value.content = false;
  } else if (props.type === 'GenerateBlogStrategy') {
    showInput.value.contentDescription = false;
    showInput.value.communicationStyle = false;
    showInput.value.content = false;
  } else if (props.type === 'GenerateIdeasForBlogArticle') {
    showInput.value.goal = false;
    showInput.value.communicationStyle = false;
    showInput.value.contentDescription = false;
    showInput.value.content = false;
  } else if (props.type === 'GenerateSeoForBlog') {
    showInput.value.goal = false;
    showInput.value.communicationStyle = false;
    showInput.value.contentDescription = false;
    showInput.value.targetAudience.ageGroups = false;
    showInput.value.targetAudience.customTargets = false;
    showInput.value.targetAudience.sexes = false;
    showInput.value.targetAudience.types = false;
  } else if (props.type === 'GenerateEmailIdeasForNewsletter') {
    showInput.value.goal = false;
    showInput.value.communicationStyle = false;
    showInput.value.contentDescription = false;
    showInput.value.content = false;
    showInput.value.targetAudience.types = false;
  } else if (props.type === 'GenerateEmailIdeasForNewsletterText') {
    showInput.value.goal = false;
    showInput.value.targetAudience.types = false;
    showInput.value.content = false;
  } else if (props.type === 'GenerateEmailContentStrategy') {
    showInput.value.contentDescription = false;
    showInput.value.content = false;
    showInput.value.targetAudience.types = false;
    showInput.value.communicationStyle = false;
  } else {
    console.log('default');
  }
};
</script>
<style>
div.dialogStyle p {
  margin-top: 2rem !important;
}

.dialogStyle h2 {
  margin-top: 2rem !important;
  font-size: 2rem !important;
  font-weight: 800 !important;
}
</style>
