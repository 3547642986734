/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsLocale = {
    Cs: 'cs',
    En: 'en',
    Sk: 'sk',
    De: 'de',
    It: 'it',
    Nl: 'nl',
    Fr: 'fr',
    Da: 'da',
    Fi: 'fi',
    El: 'el',
    Nn: 'nn',
    Pt: 'pt',
    Es: 'es',
    Sv: 'sv',
    Hu: 'hu',
    Pl: 'pl',
    Ro: 'ro'
} as const;

export type EnumsLocale = typeof EnumsLocale[keyof typeof EnumsLocale];



