<template>
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="mt-6 text-base leading-7 text-gray-600">
        {{ $t('authentication.logout.text') }}
      </p>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import { useAuthStore } from '@/stores/auth';

const router = useRouter();
const authStore = useAuthStore();
const toast = useToast();
const { t } = useI18n();

const props = defineProps({
  expiredRefreshToken: {
    type: String,
    required: false,
    default: null,
  },
});

onMounted(async () => {
  await handleLogout();
});

const handleLogout = async () => {
  authStore.destroySession();
  if (props.expiredRefreshToken) {
    toast.add({
      severity: 'warn',
      summary: t('authentication.logout.expiredRefreshToken'),
      life: 3000,
    });
  } else {
    toast.add({
      severity: 'success',
      summary: t('authentication.logout.success'),
      life: 3000,
    });
  }
  await router.push({ name: PUBLIC_ROUTES.LOGIN_PAGE });
};
</script>
