<template>
  <div class="mt-5 flex min-w-full gap-x-5 pb-5 lg:mt-5">
    <button
      type="button"
      @click="goBackAStep"
      :disabled="wizardStore.getCurrentStep === 0"
      :class="[
        wizardStore.getCurrentStep === 0 ? 'opacity-0' : 'opacity-100',
        'flex w-1/4 max-w-[6.25rem] items-center justify-center rounded-full bg-gray-300 text-center text-sm font-bold text-main hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base',
      ]"
    >
      <ArrowLeftIcon class="mr-1 w-6 dark:text-black"></ArrowLeftIcon><span class="">{{ $t('wizard.components.wizardButtons.back') }}</span>
    </button>
    <button
      type="submit"
      :disabled="props.isSubmitDisabled"
      v-if="wizardStore.getCurrentStep !== 3"
      class="ml-auto mr-0 w-3/4 max-w-[18.75rem] rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base"
    >
      {{ buttonTextContinue }}
    </button>
    <button
      type="submit"
      :disabled="props.isSubmitDisabled"
      v-else
      class="ml-auto mr-0 w-3/4 max-w-[18.75rem] rounded-full bg-gradient-to-r from-[#FF9900] to-[#FF7294] px-3 py-2 text-center text-sm font-bold text-white hover:from-[#FF7294] hover:to-[#FF9900] dark:text-black sm:text-base"
    >
      {{ $t('wizard.components.wizardButtons.finish') }}
    </button>
  </div>
</template>
<script setup lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { useWizardStore } from '@/stores/wizard';

const props = defineProps({
  isSubmitDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const wizardStore = useWizardStore();
const router = useRouter();
const { t } = useI18n();

const buttonTextContinue = computed(() => {
  if (wizardStore.getStepOne.isValid && wizardStore.getCurrentStep === 0) {
    return t('wizard.components.wizardButtons.continue');
  }
  return t('wizard.components.wizardButtons.saveAndContinue');
});

const goBackAStep = () => {
  switch (wizardStore.getCurrentStep) {
    case 1:
      router.push({ name: WIZARD_ROUTES.ADD_ESHOP_PAGE });
      break;
    case 2:
      router.push({ name: WIZARD_ROUTES.BILLINFO_PAGE });
      break;
    case 3:
      router.push({ name: WIZARD_ROUTES.XML_PAGE });
      break;
  }
};
</script>
