<template>
  <div class="grid grid-cols-2 gap-4 sm:block">
    <div @click="getOAuthUrl(ProviderEnum.GOOGLE)" class="m-auto w-full cursor-pointer">
      <div class="flex w-full items-center justify-center gap-1 rounded-full ring-1 ring-gray-400 hover:bg-gray-50 sm:p-1">
        <div class="p-0.5 pl-2">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" class="mx-auto h-7 w-7">
            <defs>
              <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"></path>
            </defs>
            <clipPath id="b">
              <use overflow="visible" xlink:href="#a"></use>
            </clipPath>
            <path fill="#FBBC05" d="M0 37V11l17 13z" clip-path="url(#b)"></path>
            <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" clip-path="url(#b)"></path>
            <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" clip-path="url(#b)"></path>
            <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" clip-path="url(#b)"></path>
          </svg>
        </div>
        <div class="w-full text-gray-700 dark:text-gray-300 sm:text-center">
          <span class="hidden sm:block">{{ props.type === SocialAuthType.LOGIN ? $t('authentication.socialAuth.googleLogin') : $t('authentication.socialAuth.googleRegister') }}</span>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <span class="block text-sm sm:hidden">Google</span>
        </div>
      </div>
    </div>
    <div @click="getOAuthUrl(ProviderEnum.FACEBOOK)" class="m-auto w-full cursor-pointer sm:mt-4">
      <div class="flex w-full items-center gap-1 rounded-full ring-1 ring-gray-400 hover:bg-gray-50 sm:p-1">
        <div class="p-0.5 pl-2">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mx-auto h-7 w-7" aria-hidden="true" viewBox="0 0 1024 1024" version="1.1">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-3" fill="#1877F2">
                <path
                  d="M1024,512 C1024,229.23 794.77,0 512,0 C229.23,0 0,229.23 0,512 C0,767.554 187.231,979.37 432,1017.78 L432,660 L302,660 L302,512 L432,512 L432,399.2 C432,270.88 508.438,200 625.39,200 C681.407,200 740,210 740,210 L740,336 L675.438,336 C611.835,336 592,375.467 592,415.957 L592,512 L734,512 L711.3,660 L592,660 L592,1017.78 C836.769,979.37 1024,767.554 1024,512"
                  id="Fill-1"
                ></path>
              </g>
              <path
                d="M711.3,660 L734,512 L592,512 L592,415.957 C592,375.467 611.835,336 675.438,336 L740,336 L740,210 C740,210 681.407,200 625.39,200 C508.438,200 432,270.88 432,399.2 L432,512 L302,512 L302,660 L432,660 L432,1017.78 C458.067,1021.87 484.784,1024 512,1024 C539.216,1024 565.933,1021.87 592,1017.78 L592,660 L711.3,660"
                id="Fill-4"
                fill="#FFFFFF"
              ></path>
            </g>
          </svg>
        </div>
        <div class="w-full text-gray-700 dark:text-gray-300 sm:text-center">
          <span class="hidden sm:block">{{ props.type === SocialAuthType.LOGIN ? $t('authentication.socialAuth.facebookLogin') : $t('authentication.socialAuth.facebookRegister') }}</span>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <span class="block text-sm sm:hidden">Facebook</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { ProviderEnum } from '@/enums/ProviderEnum';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { EnumsLocale } from '../../../../generated/api';
import { SocialAuthType } from '../enums/SocialAuthType';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

const userStore = useUserStore();
const toast = useToast();
const loadingStore = useLoadingStore();
const { t } = useI18n();

const getOAuthUrl = async (social: ProviderEnum) => {
  switch (social) {
    case ProviderEnum.GOOGLE:
      await googleOAuth();
      break;
    case ProviderEnum.FACEBOOK:
      await facebookOAuth();
      break;
  }
};

const googleOAuth = async () => {
  loadingStore.updateLoading(true);
  const payload = {
    locale: userStore.getLanguageCode as EnumsLocale,
    redirectUri: import.meta.env.VITE_GOOGLE_CALLBACK_URL as string,
    isRegister: props.type === SocialAuthType.REGISTER,
  };
  try {
    const { data: googleOAuthResponse } = await api.clientAuthGetGoogleOAuthUrl(payload, {
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });
    if (googleOAuthResponse.url) {
      window.location.href = googleOAuthResponse.url;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const facebookOAuth = async () => {
  loadingStore.updateLoading(true);
  const payload = {
    locale: userStore.getLanguageCode as EnumsLocale,
    redirectUri: import.meta.env.VITE_FACEBOOK_CALLBACK_URL as string,
    isRegister: props.type === SocialAuthType.REGISTER,
  };
  try {
    const { data: facebookOAuthResponse } = await api.clientAuthGetFacebookOAuthUrl(payload, {
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });
    if (facebookOAuthResponse.url) {
      window.location.href = facebookOAuthResponse.url;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};
</script>

<style scoped></style>
