<template>
  <div class="bg-white dark:bg-mainDark">
    <main class="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
      <img class="mx-auto h-10 w-auto sm:h-12" src="/favicon.png" alt="tanganica" />
      <div v-if="statusError" class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
        <p class="mt-4 text-3xl font-bold tracking-tight text-red-600 sm:text-5xl">{{ $t('rechargeCredit.stripeCallback.error') }}</p>
      </div>
      <div v-else class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
        <div class="flex flex-col items-center justify-center">
          <p class="mt-4 text-3xl font-bold tracking-tight text-main sm:text-5xl">{{ $t('rechargeCredit.stripeCallback.ok') }}</p>
          <!--  <img class="mt-4 h-48 w-auto" src="https://i.kym-cdn.com/photos/images/original/001/838/708/ae9.gif" alt="gif" /> -->
        </div>
        <!-- <p class="mt-4 text-base leading-7 text-main sm:mt-6 sm:text-lg sm:leading-8">{{ $t('rechargeCredit.stripeCallback.thankYou') }}</p> -->
      </div>
      <div v-if="userStore.getTheme === ThemeEnum.DEFAULT" class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <ul role="list" class="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
          <li v-for="(link, linkIdx) in links" :key="linkIdx" class="relative flex gap-x-6 py-6">
            <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
              <component :is="link.icon" class="h-6 w-6 text-main" aria-hidden="true" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-main">
                <router-link :to="{ name: link.href }">
                  <span class="absolute inset-0" aria-hidden="true" />
                  {{ link.name }}
                </router-link>
              </h3>
              <p class="mt-2 text-sm leading-6 text-main">{{ link.description }}</p>
            </div>
            <div class="flex-none self-center">
              <ChevronRightIcon class="h-5 w-5 text-main" aria-hidden="true" />
            </div>
          </li>
        </ul>
        <div class="mt-10 flex justify-center">
          <router-link :to="{ name: paramsRouterLink }" class="text-sm font-semibold leading-6 text-main">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('rechargeCredit.stripeCallback.home')"></MainButton>
          </router-link>
        </div>
      </div>
      <div v-else class="mx-auto max-w-2xl text-center">
        <p v-show="countdown > 0" class="mt-4 text-base leading-7 text-main sm:mt-6 sm:text-lg sm:leading-8">{{ $t('rechargeCredit.stripeCallback.windowCloseInfo', { value: countdown }) }}</p>
        <MainButton class="mx-auto min-w-[12.5rem] bg-gradient-to-r from-[#FF9900] to-[#FF7294] hover:from-[#FF7294] hover:to-[#FF9900]" :hover="false" :text="$t('rechargeCredit.stripeCallback.closeWindow')" @click="closeTab()"></MainButton>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import { AdjustmentsVerticalIcon, ChartBarIcon, CreditCardIcon, SquaresPlusIcon } from '@heroicons/vue/24/outline';
import { ChevronRightIcon } from '@heroicons/vue/24/solid';
import { useBroadcastChannel } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { api } from '@/services/api';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const paramsRouterLink = ref();
const url = new URL(window.location.href);
const ROUTER_TO = 'routerTo';

const links = [
  {
    name: t('rechargeCredit.stripeCallback.tanganicaPremium'),
    href: CLIENT_ROUTES.SUBSCRIPTION_PAGE,
    description: t('subscription.text42'),
    icon: SquaresPlusIcon,
  },
  {
    name: t('rechargeCredit.stripeCallback.campaigns'),
    href: CAMPAIGNS_ROUTES.INDEX,
    description: t('rechargeCredit.stripeCallback.campaignsDescription'),
    icon: AdjustmentsVerticalIcon,
  },
  {
    name: t('rechargeCredit.stripeCallback.rechargeCredit'),
    href: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE,
    description: t('rechargeCredit.stripeCallback.rechargeCreditDescription'),
    icon: CreditCardIcon,
  },
  {
    name: t('rechargeCredit.stripeCallback.stats'),
    href: CLIENT_ROUTES.STATISTICS_PAGE,
    description: t('rechargeCredit.stripeCallback.statsDescription'),
    icon: ChartBarIcon,
  },
];

const userStore = useUserStore();
const { post } = useBroadcastChannel({ name: 'credit' });
const status = ref();
const statusError = ref<boolean>(false);
const countdown = ref(30);

onMounted(async () => {
  await getCheckoutStatus();
  if (url.searchParams.has(ROUTER_TO)) {
    paramsRouterLink.value = url.searchParams.get(ROUTER_TO) || CLIENT_ROUTES.DASHBOARD_PAGE;
    console.log('paramcallback', paramsRouterLink.value);
  }
  if (userStore.getTheme !== ThemeEnum.DEFAULT) {
    countDownTimer();
  }
});

const getCheckoutStatus = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');

  try {
    const { data: checkoutStatus } = await api.clientRechargeCreditGetSessionStatus({
      sessionId: sessionId,
    });

    status.value = checkoutStatus.status;

    if (checkoutStatus.status === 'open') {
      statusError.value = true;
    } else {
      statusError.value = false;
      post(sessionId);
    }
  } catch (error) {
    console.warn(error);
    statusError.value = true;
  }
};

const countDownTimer = () => {
  if (countdown.value > 0) {
    setTimeout(() => {
      countdown.value -= 1;
      countDownTimer();
    }, 1000);
  } else {
    window.close();
  }
};

const closeTab = () => {
  window.close();
};
</script>
