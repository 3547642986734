<template>
  <p v-if="props.issueSource !== EnumsProductIssueSource.Dotnet" class="mb-2 text-left text-base font-bold text-main">{{ $t('conditionProducts.reasonsForInvalidity') }}</p>
  <p v-if="props.issueSource === EnumsProductIssueSource.Dotnet" class="mb-2 text-left text-base font-bold text-main">{{ $t('conditionProducts.reasonsForDisapproval') }}</p>
  <div v-if="mountedLoading">
    <DataTable :value="new Array(4)">
      <Column field="attribute" :header="$t('conditionProducts.column0')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
      <Column field="description" :header="$t('conditionProducts.column1')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
      <Column field="detail" :header="$t('conditionProducts.column2')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
      <Column field="documentation" :header="$t('conditionProducts.column3')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
      <Column field="count" :header="$t('conditionProducts.column4')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
      <Column field="servability" :header="$t('conditionProducts.column5')">
        <template #body>
          <Skeleton class="!animate-none"></Skeleton>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else>
    <div class="mb-10 flex" v-if="onLoadIssuesLength === 0">
      <span class="text-left text-gray-600">{{ $t('conditionProducts.noData') }}</span>
    </div>
    <DataTable
      v-if="onLoadIssuesLength > 0"
      :value="issues"
      v-model:filters="filters"
      stripedRows
      showGridlines
      filterDisplay="row"
      dataKey="id"
      size="small"
      scrollable
      :loading="loading"
      :lazy="true"
      @sort="onSort($event)"
      @filter="onFilter()"
      ref="dt"
    >
      <template #empty>
        <span class="text-main">{{ $t('conditionProducts.noRecords') }}</span>
      </template>
      <Column sortable field="attribute" :header="$t('conditionProducts.column0')"> </Column>
      <Column field="description" :header="$t('conditionProducts.column1')"> </Column>
      <Column field="detail" :header="$t('conditionProducts.column2')"> </Column>
      <Column field="documentation" :header="$t('conditionProducts.column3')">
        <template #body="slotProps">
          <a v-if="slotProps.data.documentation" :href="slotProps.data.documentation" target="_blank" class="text-blue-500 underline">{{ $t('conditionProducts.here') }}</a>
          <span v-else> - </span>
        </template>
      </Column>
      <Column sortable field="count" :header="$t('conditionProducts.column4')"> </Column>
      <Column :showFilterMenu="false" field="servability" :header="$t('conditionProducts.column5')">
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            :class="['max-w-[6.25rem]']"
            size="small"
            v-model="filterModel.value"
            :options="servabilityOptions"
            :optionLabel="'label'"
            :optionValue="'value'"
            placeholder="Filter"
            filter
            @change="filterCallback()"
            :pt-options="{ mergeProps: true }"
            :pt="{
              label: ({ props }) => ({
                class: ['!p-1 transition duration-200 '],
              }),
              item: {
                class: ['flex items-center'],
              },
            }"
          >
            <template #option="slotProps">
              <div class="">
                <Tag class="ml-3" :severity="slotProps.option.value" :value="slotProps.option.label" :class="getTanganicaStatusColorByCode(slotProps.option.value)"></Tag>
              </div>
            </template>
          </MultiSelect>
        </template>
        <template #body="slotProps">
          <div>
            <Tag
              v-tooltip.left="{
                value: getTooltipByCode(slotProps.data.servability),
                class: 'text-center text-xs w-fit border-none cursor-pointer',
                escape: false,
              }"
              :key="slotProps.data.servability"
              :severity="slotProps.data.servability"
              :value="getTanganicaLabelByCode(slotProps.data.servability)"
              :class="getTanganicaStatusColorByCode(slotProps.data.servability)"
            ></Tag>
          </div> </template
      ></Column>
    </DataTable>
  </div>
  <div v-if="onLoadIssuesLength > 0" class="mb-10"></div>
</template>
<script setup lang="ts">
import { FilterMatchMode } from 'primevue/api';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import MultiSelect from 'primevue/multiselect';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';
import { sleep } from '@/utils/sleep';
import { EnumsProductIssueSource, EnumsIssueServability } from '../../../../generated/api';

const props = defineProps({
  issueSource: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const toast = useToast();

const issues = ref();
const onLoadIssuesLength = ref(0);
const lazyParams = ref();
const dt = ref();
const loading = ref(false);
const mountedLoading = ref(false);

const servabilityOptions = [
  { label: t('conditionProducts.demoted'), value: EnumsIssueServability.Demoted },
  { label: t('conditionProducts.disapproved'), value: EnumsIssueServability.Disapproved },
  { label: t('conditionProducts.unaffected'), value: EnumsIssueServability.Unaffected },
];

const filters = ref({
  servability: {
    value: null,
    matchMode: FilterMatchMode.IN,
  },
});

onMounted(async () => {
  mountedLoading.value = true;
  lazyParams.value = {
    sortField: null,
    sortOrder: null,
    filters: filters.value,
  };
  await loadLazyData();
  onLoadIssuesLength.value = issues.value.length;
  await sleep(1500);
  mountedLoading.value = false;
});

const loadLazyData = async () => {
  loading.value = true;
  lazyParams.value = {
    ...lazyParams.value,
    source: props.issueSource,
  };
  try {
    const { data } = await api.clientDashboardGetProductsIssuesSummary(lazyParams.value);
    issues.value = data.issuesSummary || [];
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    }
  } finally {
    loading.value = false;
  }
};

const getTanganicaStatusColorByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Disapproved:
      return 'bg-red-600';
    case EnumsIssueServability.Demoted:
      return 'bg-orange-600';
    case EnumsIssueServability.Unaffected:
      return 'bg-indigo-600';
    default:
      return 'bg-brown-600';
  }
};

const getTanganicaLabelByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Demoted:
      return t('conditionProducts.demoted');
    case EnumsIssueServability.Disapproved:
      return t('conditionProducts.disapproved');
    case EnumsIssueServability.Unaffected:
      return t('conditionProducts.unaffected');
    default:
      return t('conditionProducts.unknown');
  }
};

const getTooltipByCode = (code) => {
  switch (code) {
    case EnumsIssueServability.Demoted:
      return t('conditionProducts.demotedTooltip');
    case EnumsIssueServability.Disapproved:
      return t('conditionProducts.disapprovedTooltip');
    case EnumsIssueServability.Unaffected:
      return t('conditionProducts.unaffectedTooltip');
    default:
      return '';
  }
};

const onSort = (event) => {
  lazyParams.value = { ...lazyParams.value, ...event };
  loadLazyData();
};
const onFilter = () => {
  lazyParams.value.filters = filters.value;
  loadLazyData();
};
</script>
