<template>
    <div class="h-full w-full">
        <h1 class="mb-5 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">{{ $t('eshopSettings.limit.limitTitle') }}</h1>
        <div class="w-full">
            <p class="mb-5 text-xs font-extrabold text-gray-900 dark:text-[#cbd5e1] md:text-base">
                {{ $t('eshopSettings.limit.limitText') }}
            </p>
            <label for="limit" class="mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('eshopSettings.limit.limitLabel') }}</label>
            <InputNumber class="" v-if="creditCurrency" name="limit" v-model="credit" mode="currency" :currency="creditCurrency" :locale="userStore.getLanguageCode" :step="100" :pt-options="{ mergeProps: true }" />
            <div class="ml-auto mr-0 mt-5 w-fit">
                <MainButton @click="saveLimit()" :text="$t('eshopSettings.limit.save')" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import InputNumber from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';

onMounted(() => {
    getLimit();
});

const { t } = useI18n();
const credit = ref(0);
const creditCurrency = ref();
const userStore = useUserStore();
const eshopsStore = useEshopsStore();
const toast = useToast();

// When the eshop change
watch(
    () => eshopsStore.getSelectedEshop?.id,
    () => {
        getLimit();
    }
);

const saveLimit = async () => {
    const payload = {
        creditLimit: credit.value,
    };
    try {
        await api.clientEshopSettingsSetCreditNotificationLimit(payload);
        toast.add({
            severity: 'success',
            summary: t('eshopSettings.limit.limitSave'),
            life: 4000,
        });
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    }
};

const getLimit = async () => {
    try {
        const { data } = await api.clientEshopSettingsGetCreditNotificationLimit();
        credit.value = data.creditLimit.value || 0;
        creditCurrency.value = data.creditLimit.currency;
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    }
};
</script>
