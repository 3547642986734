<template>
  <div class="relative mx-auto grid h-full grid-cols-2 gap-4">
    <div class="mx-auto w-full rounded-lg bg-white p-5 shadow-lg shadow-gray-500">
      <h2 class="text-center text-main sm:text-lg md:text-xl xl:text-2xl">{{ $t('subscription.text20') }}</h2>
      <p class="mt-5 text-center text-lg text-main">
        <span class="font-bold sm:text-lg md:text-xl xl:text-2xl"><LocalizedValue :value="monthlySubscriptionAmount"></LocalizedValue></span>{{ $t('subscription.text23') }}
      </p>
      <p class="text-center text-sm text-gray-400">{{ $t('subscription.text14') }}</p>
      <button @click="openCheckoutDialog(EnumsSubscriptionType.Monthly)" class="mx-auto mt-5 block w-fit rounded-full bg-[#FF914D] px-7 py-1 text-lg text-white shadow hover:scale-110">{{ $t('subscription.text22') }}</button>
    </div>
    <div class="relative mx-auto w-full rounded-lg bg-white p-5 shadow-lg shadow-gray-500">
      <div class="shadow-gray-40 absolute -top-2 left-1/2 w-fit -translate-x-1/2 rounded-full bg-[#FF914D] px-6 sm:-top-2">
        <p class="text-center text-xs font-bold text-white sm:text-sm">-20 %</p>
      </div>
      <h2 class="text-center text-main sm:text-lg md:text-xl xl:text-2xl">{{ $t('subscription.text21') }}</h2>
      <p class="mt-5 text-center text-lg text-main">
        <span class="font-bold sm:text-lg md:text-xl xl:text-2xl"><LocalizedValue :value="yearlySubscriptionAmount"></LocalizedValue></span>{{ $t('subscription.text23') }}
      </p>
      <p class="text-center text-sm text-gray-400">{{ $t('subscription.text14') }}</p>
      <button @click="openCheckoutDialog(EnumsSubscriptionType.Yearly)" class="mx-auto mt-5 block w-fit rounded-full bg-[#FF914D] px-7 py-1 text-lg text-white shadow hover:scale-110">{{ $t('subscription.text22') }}</button>
    </div>
  </div>
  <Dialog v-model:visible="openCheckout" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
    </template>
    <template #default>
      <Checkout :subscriptionType="subscriptionType" />
    </template>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
  <Dialog v-model:visible="openSaveCard" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="text-center text-3xl text-main xl:text-4xl">{{ $t('subscription.tanganicaPremium') }}</h1>
    </template>
    <template #default>
      <SaveCard :isSubscripion="true" :subscriptionType="subscriptionType" />
    </template>
    <template #footer>
      <span></span>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { ref } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import Checkout from '@/modules/rechargeCredit/components/Checkout.vue';
import SaveCard from '@/modules/rechargeCredit/components/SaveCard.vue';
import { EnumsSubscriptionType } from '../../../../generated/api';
const props = defineProps({
  type: {
    type: String,
    required: false,
    default: EnumsSubscriptionType.Monthly,
  },
  hasSaveCard: {
    type: Boolean,
    required: false,
    default: true,
  },
  monthlySubscriptionAmount: {
    type: Number,
    required: true,
    default: null,
  },
  yearlySubscriptionAmount: {
    type: Number,
    required: true,
    default: null,
  },
});
const openSaveCard = ref(false);
const openCheckout = ref(false);
const subscriptionType = ref<EnumsSubscriptionType>(EnumsSubscriptionType.Monthly);

const openCheckoutDialog = (subsType) => {
  subscriptionType.value = subsType;
  if (props.hasSaveCard) {
    openCheckout.value = true;
  } else {
    openSaveCard.value = true;
  }
};
</script>
