import { RouteRecordRaw } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import CampaignEditPage from '@/modules/campaigns/pages/CampaignEdit.vue';
import CampaignFormatsPage from '@/modules/campaigns/pages/CampaignFormats.vue';
import CampaignsPage from '@/modules/campaigns/pages/Index.vue';
import Step from '@/modules/campaigns/pages/Step.vue';
import { EnumsMarketingChannel, EnumsMarketingFormat } from '../../../../generated/api';

function isValidCampaign(campaign: string): boolean {
  return Object.values(EnumsMarketingChannel).includes(campaign as EnumsMarketingChannel);
}

function isValidFormat(format: string): boolean {
  return Object.values(EnumsMarketingFormat).includes(format as EnumsMarketingFormat);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: 'campaigns',
    name: CAMPAIGNS_ROUTES.INDEX,
    component: CampaignsPage,
    redirect: () => ({ name: CAMPAIGNS_ROUTES.FORMATS, params: { campaign: EnumsMarketingChannel.Google } }),
    children: [
      {
        path: ':campaign/formats',
        name: CAMPAIGNS_ROUTES.FORMATS,
        component: CampaignFormatsPage,
        props: (route) => ({
          campaign: route.params.campaign as EnumsMarketingChannel,
        }),
        beforeEnter: (to, from, next) => {
          if (isValidCampaign(to.params.campaign as EnumsMarketingChannel)) {
            next();
          } else {
            next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
          }
        },
        children: [
          {
            path: ':format/edit',
            name: CAMPAIGNS_ROUTES.EDIT,
            props: (route) => ({
              campaign: route.params.campaign as EnumsMarketingChannel,
              format: route.params.format as EnumsMarketingFormat,
            }),
            component: CampaignEditPage,
            beforeEnter: async (to, from, next) => {
              if (isValidCampaign(to.params.campaign as EnumsMarketingChannel) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
                next();
              } else {
                next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
              }
            },
            children: [
              {
                path: ':step',
                name: CAMPAIGNS_ROUTES.EDIT_STEP,
                component: Step,
                props: (route) => ({
                  campaign: route.params.campaign as EnumsMarketingChannel,
                  format: route.params.format as EnumsMarketingFormat,
                  step: route.params.step,
                }),
                beforeEnter: (to, from, next) => {
                  if (isValidCampaign(to.params.campaign as EnumsMarketingChannel) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
                    next();
                  } else {
                    next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
                  }
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
