<template>
  <div :class="[transition ? 'opacity-100' : 'opacity-0', 'div-primary mx-auto transition-opacity  duration-1000']">
    <div class="grid text-center sm:grid-cols-2 sm:grid-rows-1">
      <!-- Learning Phase -->
      <div class="row-start-2 mx-5 mt-5 sm:row-start-auto">
        <div class="flex items-baseline justify-center">
          <h1 class="text-lg font-bold text-main sm:text-xl">
            {{ $t('statistics.pages.statistics.campaignsTypes') }}
          </h1>
        </div>
        <div>
          <Dropdown v-model="selectCampaign" :options="campaignTypes" optionDisabled="true" class="mx-auto mt-1 !h-10 w-52 !rounded-full md:w-64 lg:w-56 xl:w-72" @change="onDropdownChange()">
            <template #value="slotProps">
              <div class="align-items-center flex items-center">
                <img v-if="slotProps.value.src" :src="$filePath + slotProps.value.src" class="mr-2 h-6 w-6" alt="logo" />
                <span class="my-auto text-xs text-main">{{ slotProps.value.label }}</span>
              </div>
            </template>
            <template #option="slotProps">
              <div class="align-items-center flex items-center" style="border-radius: 35px; margin-right: 5px">
                <img v-if="slotProps.option.src" :src="$filePath + slotProps.option.src" class="mr-2 h-6 w-6" alt="logo" />
                <span class="text-sm text-gray-900">{{ slotProps.option.label }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="mx-auto mt-10 flex w-fit items-center">
          <h1 class="text-lg font-bold text-main sm:text-xl">
            {{ $t('statistics.pages.statistics.exportText') }}
          </h1>
          <StatisticsExport :dates="params" :marketingFormat="selectCampaign?.value" statisticsType="campaign" />
        </div>
      </div>
      <div class="row-start-1 mx-auto mt-5 text-center sm:row-start-auto">
        <h1 class="text-lg font-bold text-main sm:mb-2 sm:text-xl">
          {{ $t('statistics.pages.statistics.mainTitle') }}
        </h1>
        <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
          <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
            <option value="today">
              {{ $t('statistics.pages.statistics.today') }}
            </option>
            <option value="yesterday">
              {{ $t('statistics.pages.statistics.yesterday') }}
            </option>
            <option value="last7Days">
              {{ $t('statistics.pages.statistics.last7Days') }}
            </option>
            <option value="lastMonth">
              {{ $t('statistics.pages.statistics.lastMonth') }}
            </option>
            <option value="last3Month">
              {{ $t('statistics.pages.statistics.last3Month') }}
            </option>
            <option value="last6Month">
              {{ $t('statistics.pages.statistics.last6Month') }}
            </option>
            <option value="lastYear">
              {{ $t('statistics.pages.statistics.lastYear') }}
            </option>
            <option value="custom">{{ $t('statistics.pages.statistics.custom') }}</option>
          </select>
        </div>
        <Datepicker
          class="relative mb-5 block w-full min-w-fit rounded-full border-none px-0 !text-xs dark:bg-[#28334e] lg:w-72"
          :lang="userStore.languageCode"
          :locale="userStore.languageCode"
          :format="datetimeFormats[userStore.languageCode].format"
          type="date"
          :selectText="$t('statistics.pages.statistics.select')"
          :cancelText="$t('statistics.pages.statistics.cancel')"
          :clearable="false"
          v-model:model-value="date"
          :disabled-dates="disabledDates"
          :enable-time-picker="true"
          :auto-position="false"
          range
          :teleport="true"
          @update:model-value="setCustomSelectedValue"
        >
        </Datepicker>

        <!--<MainButton @click="captureAndSave()" :text="$t('statistics.export')" />-->
      </div>
    </div>
  </div>
  <div id="contentToCapture" class="mx-auto max-w-7xl pt-5">
    <div class="mx-auto mb-5 mt-12 max-w-7xl lg:mx-5">
      <div class="container max-w-full">
        <div class="mb-4 grid grid-cols-1 gap-x-5 gap-y-10 sm:grid-cols-2 lg:grid-cols-2">
          <div :class="[transition ? 'opacity-100' : 'opacity-0', 'mb-10 transition-opacity delay-200 duration-1000 lg:mb-0']">
            <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
              <div class="undefined border-b border-gray-200">
                <div class="shadow-lg-pink mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#FF5858] to-[#971111] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                  <PresentationChartLineIcon class="h-full w-full"></PresentationChartLineIcon>
                </div>
                <div @click="openInformationDialog('expenses')" class="absolute left-2 top-2">
                  <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                </div>
                <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                  <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                    {{ $t('statistics.pages.statistics.totalExp') }}
                  </h5>
                  <LocalizedValue :value="statistics?.totalExpenses"></LocalizedValue>
                </div>
              </div>
            </div>
          </div>
          <div :class="[transition ? 'opacity-100' : 'opacity-0', 'mb-10 transition-opacity delay-300 duration-1000 lg:mb-0']">
            <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
              <div class="undefined border-b border-gray-200">
                <div class="shadow-lg-orange mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-orange-500 to-orange-700 py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                  <ChartBarIcon class="h-full w-full"></ChartBarIcon>
                </div>
                <div @click="openInformationDialog('cos')" class="absolute left-2 top-2">
                  <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                </div>
                <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                  <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                    <span v-if="selectCampaign === null">{{ $t('statistics.pages.statistics.pnos') }}</span>
                    <span v-else>{{ $t('statistics.pages.statistics.pno') }}</span>
                  </h5>
                  <LocalizedValue :value="statistics?.cos"></LocalizedValue>
                </div>
              </div>
            </div>
          </div>
          <div :class="[transition ? 'opacity-100' : 'opacity-0', 'mb-10 transition-opacity delay-500 duration-1000 lg:mb-0']">
            <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
              <div class="undefined border-b border-gray-200">
                <div class="shadow-lg-purple mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#49CF00] to-[#297200] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                  <!-- <span class="material-icons text-white text-3xl leading-none">obrat</span> -->
                  <CurrencyDollarIcon class="h-full w-full"></CurrencyDollarIcon>
                </div>
                <div @click="openInformationDialog('generatedRevenue')" class="absolute left-2 top-2">
                  <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                </div>
                <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                  <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                    {{ $t('statistics.pages.statistics.generatedSales') }}
                  </h5>
                  <LocalizedValue :value="statistics?.generatedRevenue"></LocalizedValue>
                </div>
              </div>
            </div>
          </div>
          <div :class="[transition ? 'opacity-100' : 'opacity-0', 'transition-opacity delay-700 duration-1000 lg:mb-0']">
            <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
              <div class="undefined border-b border-gray-200">
                <div class="shadow-lg-blue mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#4C8CBF] to-[#075695] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                  <!-- <span class="material-icons text-white text-3xl leading-none">Asist</span>-->
                  <BanknotesIcon class="h-full w-full"></BanknotesIcon>
                </div>
                <div @click="openInformationDialog('assistedRevenue')" class="absolute left-2 top-2">
                  <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                </div>
                <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                  <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                    {{ $t('statistics.pages.statistics.asistSales') }}
                  </h5>
                  <LocalizedValue :value="statistics?.assistedRevenue"></LocalizedValue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDataLoaded" :class="[transition ? 'opacity-100' : 'opacity-0', 'transition-opacity delay-700 duration-1000']">
      <div class="lg:div-primary" id="chart">
        <VueApexCharts ref="expenes" type="area" height="350" :options="chartOptions" :series="series"></VueApexCharts>
      </div>

      <div class="mx-auto mb-5 mt-12 max-w-7xl lg:mx-5">
        <div class="container max-w-full">
          <div class="mb-4 grid grid-cols-1 gap-x-5 gap-y-10 sm:grid-cols-2 lg:grid-cols-2">
            <div class="mb-10 lg:mb-0">
              <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
                <div class="undefined border-b border-gray-200">
                  <div class="shadow-lg-pink mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#5F83DF] to-[#00179F] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                    <!--<span class="material-icons text-white text-3xl leading-none">up</span>-->
                    <UsersIcon class="h-full w-full"></UsersIcon>
                  </div>
                  <div @click="openInformationDialog('users')" class="absolute left-2 top-2">
                    <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                  </div>
                  <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                    <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                      {{ $t('statistics.pages.statistics.users') }}
                    </h5>
                    <LocalizedValue :value="statistics?.visitorCount"></LocalizedValue>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-10 lg:mb-0">
              <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
                <div class="undefined border-b border-gray-200">
                  <div class="shadow-lg-blue mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-green-400 to-green-800 py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                    <!-- <span class="material-icons text-white text-3xl leading-none">Asist</span>-->
                    <CursorArrowRaysIcon class="h-full w-full"></CursorArrowRaysIcon>
                  </div>
                  <div @click="openInformationDialog('click')" class="absolute left-2 top-2">
                    <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                  </div>
                  <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                    <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                      {{ $t('statistics.pages.statistics.clicks') }}
                    </h5>
                    <LocalizedValue :value="statistics?.clickCount"></LocalizedValue>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-10 lg:mb-0">
              <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
                <div class="undefined border-b border-gray-200">
                  <div class="shadow-lg-purple mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#A971C3] to-[#500074] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                    <!-- <span class="material-icons text-white text-3xl leading-none">obrat</span> -->
                    <ShoppingCartIcon class="h-full w-full"></ShoppingCartIcon>
                  </div>
                  <div @click="openInformationDialog('orders')" class="absolute left-2 top-2">
                    <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                  </div>
                  <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                    <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                      {{ $t('statistics.pages.statistics.numOfOrder') }}
                    </h5>
                    <LocalizedValue :value="statistics?.orderCount"></LocalizedValue>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-10 lg:mb-0">
              <div class="overflow-hdden relative w-full rounded-xl bg-white p-4 shadow-tinyCard dark:bg-[#28334e]">
                <div class="undefined border-b border-gray-200">
                  <div class="shadow-lg-blue mx-auto -mt-10 mb-4 grid h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-[#E370A7] to-[#A1034F] py-3 text-white lg:h-16 lg:w-16 lg:py-4">
                    <!-- <span class="material-icons text-white text-3xl leading-none">Asist</span>-->
                    <BanknotesIcon class="h-full w-full"></BanknotesIcon>
                  </div>
                  <div @click="openInformationDialog('conversionRate')" class="absolute left-2 top-2">
                    <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
                  </div>
                  <div class="undefined mb-2 w-full max-w-full flex-1 grow text-center">
                    <h5 class="mb-1 text-[0.563rem] font-black tracking-wide text-gray-900 dark:text-[#cbd5e1] lg:text-sm lg:font-semibold">
                      {{ $t('statistics.pages.statistics.convRatio') }}
                    </h5>
                    <LocalizedValue :value="statistics?.conversionRatio"></LocalizedValue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:div-primary" id="chart">
        <VueApexCharts ref="expenes" type="area" height="350" :options="chartOptionsSecond" :series="seriesSecond"> </VueApexCharts>
      </div>
      <div class="lg:div-primary" id="chart">
        <VueApexCharts ref="expenes" type="area" height="350" :options="chartOptionsThird" :series="seriesThird"> </VueApexCharts>
      </div>
      <div>
        <a href="https://www.tanganica.com/cs/blog/how-are-statistics-measured-in-tanganica" target="_blank" class="mx-auto block w-full cursor-pointer text-center text-sm font-bold text-main hover:underline">
          {{ $t('statistics.pages.statistics.measureTitle') }}</a
        >
      </div>
    </div>
  </div>

  <!-- INFORMATION DIALOG -->
  <Dialog v-model:visible="openInformation" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="informationDialogTitle"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="informationDialogDescription"></p>
    </div>
    <template #footer>
      <a href="https://www.tanganica.com/cs/blog/how-are-statistics-measured-in-tanganica" target="_blank" class="mx-auto block w-full cursor-pointer text-center text-sm font-bold text-main hover:underline">
        {{ $t('statistics.pages.statistics.measureTitle') }}</a
      >
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { PresentationChartLineIcon, BanknotesIcon, CurrencyDollarIcon, ChartBarIcon, UsersIcon, ShoppingCartIcon, CursorArrowRaysIcon } from '@heroicons/vue/24/outline';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid';
import Datepicker from '@vuepic/vue-datepicker';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';
import '@vuepic/vue-datepicker/dist/main.css';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { datetimeFormats } from '@/plugins/i18n';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { can } from '@/utils/can';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';
import { ClientDTOGetCampaignStatsResponse, EnumsMarketingFormat, EnumsClientPermission } from '../../../../generated/api';

const toast = useToast();
const { t, d } = useI18n();
const date = ref();
const transition = ref(false);
const disabledDates = ref([]);
const loadingStore = useLoadingStore();
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const params = reactive({
  dateFrom: null,
  dateTo: null,
});

const statistics = ref<ClientDTOGetCampaignStatsResponse | null>(null);

// For Information Dialog
const openInformation = ref(false);
const informationDialogTitle = ref('');
const informationDialogDescription = ref('');

const isDataLoaded = ref(false);

// DatePicker
const today = new Date();
const startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0);
const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

const selectDays = ref('lastMonth');
const campaignTypes = computed(() => {
  return [
    { label: t('statistics.pages.statistics.allCampaigns'), value: null, src: null },
    { label: t('campaigns.googleShopping'), value: EnumsMarketingFormat.Pla, src: '/logos/Google.png' },
    { label: t('campaigns.performanceMax'), value: EnumsMarketingFormat.Prx, src: '/logos/Google.png' },
    { label: t('campaigns.dynamicTextAds'), value: EnumsMarketingFormat.Dsa, src: '/logos/Google.png' },
    { label: t('campaigns.dynamicProductAds'), value: EnumsMarketingFormat.Dpa, src: '/logos/Meta.png' },
    { label: t('campaigns.microsftShopping'), value: EnumsMarketingFormat.BingPla, src: '/logos/MicrosoftLogo.png' },
    { label: t('campaigns.performanceMax'), value: EnumsMarketingFormat.BingPrx, src: '/logos/MicrosoftLogo.png' },
    { label: t('campaigns.dynamicTextAds'), value: EnumsMarketingFormat.BingDsa, src: '/logos/MicrosoftLogo.png' },
  ];
});

const selectCampaign = ref(campaignTypes.value[0]);

// Information Dialof Cos text
const cosPercentage = ref();

onMounted(async () => {
  date.value = [startDate, endDate];

  params.dateFrom = date.value[0];
  params.dateTo = date.value[1];
  // Set Disable Dates
  setDisableDates();
  // Api call
  await getCotributionPercentage();
  await getData(params);
  setTimeout(function () {
    transition.value = true;
  }, 200);
});

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    params.dateFrom = date.value[0];
    params.dateTo = date.value[1];
    await userStore.getPermissions();
    if (!can(EnumsClientPermission.GetDividedStats)) {
      selectCampaign.value = campaignTypes.value[0];
    }
    await getData(params);
  }
);

watch(
  () => date.value,
  (val) => {
    // watch the date
    if (date.value && date.value.length === 2) {
      if (params.dateFrom !== date.value[0] || params.dateTo !== date.value[1]) {
        params.dateFrom = date.value[0];
        params.dateTo = date.value[1];
        getData(params);
      }
    }
  }
);

// Vue watch funkce
watch(
  () => selectDays.value,
  (selectedValue) => {
    if (selectedValue === 'custom') {
      return;
    }
    const { startDate, endDate } = calculateDateRange(selectedValue);

    date.value = [startDate, endDate];

    params.dateFrom = startDate.toISOString();
    params.dateTo = endDate.toISOString();
  }
);

const setCustomSelectedValue = () => {
  selectDays.value = 'custom';
};

const getCotributionPercentage = async () => {
  try {
    const { data } = await api.clientDashboardGetTgncaContributionPercentage();
    if (data.contributionPercentage?.value) {
      cosPercentage.value = data.contributionPercentage?.value * 100;
    } else {
      cosPercentage.value = 0;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getData = async (dates) => {
  loadingStore.updateLoading(true);
  const params = {
    dateFrom: dates.dateFrom,
    dateTo: dates.dateTo,
    marketingFormat: selectCampaign.value?.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };

  try {
    statistics.value = (await api.clientStatisticsGetCampaignStats(params)).data;
    const grapgData = (await api.clientStatisticsGetCampaignStatsGraphData(params)).data;
    updateApexChart(t('statistics.pages.statistics.expenses'), grapgData?.expenses?.labels, grapgData?.expenses?.values, t('statistics.pages.statistics.trnover'), grapgData?.revenues?.values);
    updateSecondApexChart(t('statistics.pages.statistics.users'), grapgData?.visitors?.labels, grapgData?.visitors?.values, t('statistics.pages.statistics.numOfOrder'), grapgData?.orders?.values);

    updateThirdApexChart(t('statistics.pages.statistics.clicks'), grapgData?.clicks?.labels, grapgData?.clicks?.values);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
    isDataLoaded.value = true;
  }
};

function setDisableDates() {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
}

const openInformationDialog = (type) => {
  switch (type) {
    case 'expenses':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.expenses.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.expenses.description');
      openInformation.value = true;
      break;
    case 'cos':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.cos.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.cos.description', { cosValue: cosPercentage.value.toFixed(2) });
      openInformation.value = true;
      break;
    case 'generatedRevenue':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.generatedRevenue.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.generatedRevenue.description');
      openInformation.value = true;
      break;
    case 'assistedRevenue':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.assistedRevenue.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.assistedRevenue.description');
      openInformation.value = true;
      break;
    case 'users':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.users.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.users.description');
      openInformation.value = true;
      break;
    case 'click':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.click.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.click.description');
      openInformation.value = true;
      break;
    case 'orders':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.orders.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.orders.description');
      openInformation.value = true;
      break;
    case 'conversionRate':
      informationDialogTitle.value = t('statistics.pages.statistics.informationDialog.conversionRate.title');
      informationDialogDescription.value = t('statistics.pages.statistics.informationDialog.conversionRate.description');
      openInformation.value = true;
      break;
  }
};

// APEX CHARTS
const series = ref([
  {
    name: 'series1',
    data: [],
  },
  {
    name: 'series2',
    data: [],
  },
]);
const chartOptions = ref({
  chart: {
    height: 450,
    type: 'area',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'category',
    labels: {
      show: true,
      style: {
        colors: ['gray'],
      },
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: 'gray',
      },
    },
  },
  colors: ['#ce3636', '#3eae00'],
});

function updateApexChart(name, labels, data, name2, data2) {
  series.value[0].data = [];
  for (let i = 0; i < data.length; i++) {
    series.value[0].data.push({ x: labels[i], y: data[i].toFixed(2) });
    chartOptions.value.xaxis.labels.style.colors[i] = 'gray';
  }
  series.value[1].data = [];
  for (let i = 0; i < data2.length; i++) {
    series.value[1].data.push({ x: labels[i], y: data2[i].toFixed(2) });
  }
  series.value[0].name = name;
  // series.value[0].data = data;
  series.value[1].name = name2;
  // series.value[1].data = data2;
}

// SECOND APEX CHART
const seriesSecond = ref([
  {
    name: 'series1',
    data: [],
  },
  {
    name: 'series2',
    data: [],
  },
]);
const chartOptionsSecond = ref({
  chart: {
    height: 450,
    type: 'area',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'category',
    labels: {
      show: true,
      style: {
        colors: ['gray'],
      },
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: 'gray',
      },
    },
  },
  colors: ['#3856c5', '#8f50ac'],
});

function updateSecondApexChart(name, labels, data, name2, data2) {
  seriesSecond.value[0].data = [];
  for (let i = 0; i < data.length; i++) {
    seriesSecond.value[0].data.push({ x: labels[i], y: data[i].toFixed(2) });
    chartOptionsSecond.value.xaxis.labels.style.colors[i] = 'gray';
  }
  seriesSecond.value[1].data = [];
  for (let i = 0; i < data2.length; i++) {
    seriesSecond.value[1].data.push({ x: labels[i], y: data2[i].toFixed(2) });
  }
  seriesSecond.value[0].name = name;
  // series.value[0].data = data;
  seriesSecond.value[1].name = name2;
  // series.value[1].data = data2;
}

const seriesThird = ref([
  {
    name: 'series1',
    data: [],
  },
  {
    name: '',
    data: [],
  },
]);
const chartOptionsThird = ref({
  chart: {
    height: 450,
    type: 'area',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'category',
    labels: {
      show: true,
      style: {
        colors: ['gray'],
      },
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: 'gray',
      },
    },
  },
  colors: ['#16a34a', 'white'],
});

const updateThirdApexChart = (name, labels, data) => {
  seriesThird.value[0].data = [];
  for (let i = 0; i < data.length; i++) {
    seriesThird.value[0].data.push({ x: labels[i], y: data[i].toFixed(2) });
    chartOptionsThird.value.xaxis.labels.style.colors[i] = 'gray';
  }
  seriesThird.value[0].name = name;
};

const onDropdownChange = () => {
  if (!selectCampaign.value?.disabled) {
    getData(params);
  } else {
    selectCampaign.value = { label: t('statistics.pages.statistics.allCampaigns'), value: null, disabled: false };
    userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog;
  }
};
</script>
<style>
.dark .vue-apexcharts .apexcharts-tooltip.apexcharts-theme-light {
  color: #fff;
  background: rgba(30, 30, 30, 0.8);
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px rgba(30, 30, 30, 0.8);
  cursor: default;
  font-size: 14px;
  left: 62px;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
}

.dark .vue-apexcharts .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #333;
}

.dark .vue-apexcharts .apexcharts-xaxistooltip.apexcharts-theme-light {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
}

.dark .vue-apexcharts .apexcharts-legend-text {
  color: #cbd5e1 !important;
}

.dark .vue-apexcharts .apexcharts-yaxis-texts-g {
  color: white;
}

.dp__input {
  border-radius: 99999px;
}

.dp__action_row {
  color: #fff;
  display: block;
  margin: auto;
}

.dp__action_buttons {
  display: flex;
  justify-content: center;
}

.dp__selection_preview {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
}

.datepicker .dp__action_buttons .dp__action_select {
  background-color: #1976d2;
  display: flex;
  padding: 0.8rem;
  align-items: center;
  margin-left: 3px;
}

.dp__action_button.dp__action_select {
  background-color: #1976d2;
  display: flex;
  padding: 0.8rem;
  align-items: center;
  margin-left: 3px;
}

.dp__action_button.dp__action_cancel {
  display: flex;
  padding: 0.8rem;
  align-items: center;
  margin-right: 3px;
}

.dp__action_button.dp__action_select:hover {
  background-color: #1565c0;
}

.dp__action_button.dp__action_cancel:hover {
  background-color: #f1f1f1;
}

.datepicker .dp__action_buttons .dp__action_select:hover {
  background-color: #1565c0;
}

.datepicker .dp__action_buttons .dp__action_cancel {
  display: flex;
  padding: 0.8rem;
  align-items: center;
  margin-right: 3px;
}

.dp__input {
  font-size: 0.775rem;
}
</style>
