export const tabview = {
    navContainer: ({ props: e }) => ({ class: ['relative', { 'overflow-hidden': e.scrollable }] }),
    navContent: {
        class: ['overflow-y-hidden overscroll-contain', 'overscroll-auto', 'scroll-smooth', '[&::-webkit-scrollbar]:hidden'],
    },
    previousButton: {
        class: ['flex items-center justify-center', '!absolute', 'top-0 left-0', 'z-20', 'h-full w-12', 'rounded-none', 'bg-surface-0 dark:bg-mainDark', 'text-primary-500 dark:text-primary-400', 'shadow-md'],
    },
    nextButton: {
        class: ['flex items-center justify-center', '!absolute', 'top-0 right-0', 'z-20', 'h-full w-12', 'rounded-none', 'bg-surface-0 dark:bg-mainDark', 'text-primary-500 dark:text-primary-400', 'shadow-md'],
    },
    nav: {
        class: ['flex flex-1', 'list-none', 'p-0 m-0', 'bg-surface-0 dark:bg-mainDark', 'border-b-2 border-surface-200 dark:border-surface-700', 'text-surface-900 dark:text-surface-0/80'],
    },
    tabpanel: {
        header: ({ props: e }) => ({ class: ['mr-0', { 'opacity-60 cursor-default user-select-none select-none pointer-events-none': e == null ? void 0 : e.disabled }] }),
        headerAction: ({ parent: e, context: r }) => ({
            class: [
                'relative',
                'font-bold',
                'flex items-center',
                'p-5',
                '-mb-[0.125]',
                'border-b-2',
                'rounded-t-md',
                {
                    'border-surface-200 dark:border-gray-500': e.state.d_activeIndex !== r.index,
                    'bg-surface-0 !dark:bg-mainDark': e.state.d_activeIndex !== r.index,
                    'text-surface-700 dark:text-gray-500': e.state.d_activeIndex !== r.index,
                    'bg-surface-0 dark:bg-mainDark': e.state.d_activeIndex === r.index,
                    'border-primary-500 dark:border-gray-300': e.state.d_activeIndex === r.index,
                    'text-primary-500 dark:text-gray-300': e.state.d_activeIndex === r.index,
                },
                'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset',
                'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
                {
                    'hover:bg-surface-0 dark:bg-mainDark': e.state.d_activeIndex !== r.index,
                    'hover:border-surface-400 dark:hover:border-gray-400': e.state.d_activeIndex !== r.index,
                    'hover:text-surface-900 dark:hover:text-gray-300': e.state.d_activeIndex !== r.index,
                },
                'transition-all duration-200',
                'cursor-pointer select-none text-decoration-none',
                'overflow-hidden',
                'user-select-none',
            ],
        }),
        headerTitle: {
            class: ['leading-none', 'whitespace-nowrap'],
        },
        content: {
            class: ['p-5', 'rounded-b-md', 'bg-surface-0 dark:bg-mainDark', 'text-surface-700 dark:text-surface-0/80', 'border-0'],
        },
    },
};
