<template>
  <div class="h-full w-full">
    <h1 class="mb-3 text-center text-xl font-bold text-main sm:text-2xl lg:text-3xl xl:text-4xl">{{ $t('eshopSettings.addManager.addAdmin') }}</h1>
    <form @submit.prevent="addNewEshopManager" class="w-full">
      <label for="email" class="mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('eshopSettings.addManager.addAdminEmail') }}</label>
      <InputText name="email" v-model="email" />
      <small class="text-sm text-red-600">{{ errors.email || '&nbsp;' }}</small>
      <div class="ml-auto mr-0 mt-5 w-fit">
        <MainButton type="submit" :text="$t('eshopSettings.addManager.save')" />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';

const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .required(() => t('validations.required'))
      .email(() => t('validations.email')),
  }),
  initialValues: {
    email: '',
  },
});

const [email] = defineField('email');

const addNewEshopManager = handleSubmit(async (formData) => {
  loadingStore.updateLoading(true);

  const payload = {
    invitedUserEmail: formData.email,
    roleName: 'EshopAdmin',
    redirectUrl: import.meta.env.VITE_ADD_ESHOP_MANAGER_CALLBACK_URL as string,
  };

  try {
    await api.clientEshopSettingsInviteUser(payload);
    toast.add({
      severity: 'success',
      summary: t('eshopSettings.addManager.addAdminSuccess'),
      life: 7000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    resetForm();
    loadingStore.updateLoading(false);
  }
});
</script>
