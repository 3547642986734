import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { EnumsWizardStatus } from '../../../../generated/api';
import router from '../../../router';

export async function redirectToCorrectStep(wizardStatus: EnumsWizardStatus | undefined) {
    if (!wizardStatus) return;
    switch (wizardStatus) {
        case EnumsWizardStatus.AddedEshop:
            await router.push({ name: WIZARD_ROUTES.BILLINFO_PAGE });
            break;
        case EnumsWizardStatus.AddedBillingInfo:
            await router.push({ name: WIZARD_ROUTES.XML_PAGE });
            break;
        case EnumsWizardStatus.AddedFeed:
            await router.push({ name: WIZARD_ROUTES.PIXEL_PAGE });
            break;
        default:
            await router.push({ name: WIZARD_ROUTES.ADD_ESHOP_PAGE });
            break;
    }
}
