import {EnumsLocale} from "../../../../generated/api";

interface ProductLinkCodebook {
  answer1Link: string;
  answer4Link: string;
}

type ProductLinksCodebook = {
  [key in EnumsLocale]: ProductLinkCodebook;
};

const ProductLinkCodebook: ProductLinksCodebook = {
  cs: {
    answer1Link:
      "https://www.tanganica.com/cs/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=cs",
  },
  en: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  hu: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  pl: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  ro: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  it: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  sk: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=sk",
  },
  da: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  de: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  el: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  es: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  fi: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  fr: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  nl: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  no: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  pt: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
  sv: {
    answer1Link:
      "https://www.tanganica.com/en/blog/how-to-connect-xml-feed-for-shoptet",
    answer4Link: "https://support.google.com/merchants/answer/7052112?hl=en",
  },
};

export default ProductLinkCodebook;
