<template>
  <div v-if="can(EnumsClientPermission.GetDividedStats)" class="relative flex h-full overflow-hidden rounded-lg bg-white">
    <!-- Sidebar -->
    <div :class="[showHistoryMenu ? 'block w-40' : 'hidden w-0', 'absolute h-full overflow-y-auto rounded-md rounded-r-none border-r bg-white']">
      <!-- Sidebar Header -->
      <header class="sticky top-0 z-10 flex w-full items-center justify-between border-b bg-white p-2 text-white">
        <Bars3Icon @click="hideThreadMenu()" class="h-6 w-6 cursor-pointer stroke-2 text-main hover:opacity-80" />
        <PlusIcon @click="prepareThreadOnly()" class="h-6 w-6 cursor-pointer stroke-2 text-main hover:opacity-80" />
      </header>

      <!-- History List -->
      <div class="h-fit overflow-y-auto p-3">
        <div
          v-for="thread in threads"
          :key="thread.threadId"
          @click="changeThread(thread.threadId)"
          :class="[selectedThread === thread.threadId ? 'bg-gray-100' : '', 'relative my-4 flex cursor-pointer items-center rounded-md p-2 hover:bg-gray-100']"
        >
          <TrashIcon v-if="thread.threadId !== 'new thread'" @click.stop="deleteThread(thread.threadId)" class="absolute -left-1 -top-2 h-4 w-4 cursor-pointer text-red-600 hover:text-red-700" />
          <div class="flex-1">
            <p class="text-gray-600">{{ thread.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Chat Area -->
    <div :class="[showHistoryMenu ? 'pl-40' : 'pl-0', 'flex w-full flex-1 flex-col']">
      <!-- Chat Messages -->
      <div v-if="isLoadedMessages" ref="chatContainer" class="relative h-full w-full overflow-y-auto px-4">
        <div v-if="!showHistoryMenu" class="sticky left-0 top-0 flex h-10 w-full gap-4 bg-white p-2">
          <Bars3Icon @click="showThreadMenu()" class="h-6 w-6 cursor-pointer stroke-2 text-main hover:opacity-80" />
          <PlusIcon @click="prepareThreadOnly()" class="h-6 w-6 cursor-pointer stroke-2 text-main hover:opacity-80" />
        </div>
        <div class="mb-4 w-3/4 pt-10">
          <div class="grid">
            <div class="mb-3 flex flex-1 gap-2.5">
              <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
                <img src="/favicon.png" alt="User Avatar" class="h-full min-w-9 object-contain p-1.5" />
              </div>
              <div class="grid">
                <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ $t('aiassistant.aiAssistant') }}</h5>
                <div class="">
                  <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-gray-100 px-3.5 py-2">
                    <h5 class="text-sm font-normal leading-snug text-gray-900">
                      {{ $t('aiassistant.welcomeMessage') }}
                    </h5>
                  </div>
                  <div class="flex justify-end">
                    <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: new Date(), type: 'Date', format: 'long' }"></LocalizedValue></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Assistant -->
        <div v-for="message in messages" :key="message.messageId">
          <div v-if="message.role === EnumsAIMessageRole.Assistant" class="mb-4 w-3/4">
            <div class="grid">
              <div class="mb-3 flex flex-1 gap-2.5">
                <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
                  <img src="/favicon.png" alt="User Avatar" class="h-full min-w-9 object-contain p-1.5" />
                </div>
                <div class="grid">
                  <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ $t('aiassistant.aiAssistant') }}</h5>
                  <div class="">
                    <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-gray-100 px-3.5 py-2">
                      <h5 class="text-sm font-normal leading-snug text-gray-900" v-html="message?.content.replace(/【.*?】/g, '')"></h5>
                    </div>
                    <div class="flex justify-end">
                      <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: transformDate(message.createdAt), type: 'Date', format: 'long' }"></LocalizedValue></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- User -->
          <div v-if="message.role === EnumsAIMessageRole.User" class="mb-4 flex cursor-pointer justify-end xl:pl-20">
            <div class="grid">
              <div class="mb-3 flex flex-1 gap-2.5">
                <div class="mr-2 flex h-9 w-9">
                  <UserCircleIcon class="h-9 w-9 text-main" />
                </div>
                <div class="grid">
                  <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ userStore.email }}</h5>
                  <div class="">
                    <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-main px-3.5 py-2">
                      <h5 class="text-sm font-normal leading-snug text-white" v-html="message.content"></h5>
                    </div>
                    <div class="flex justify-end">
                      <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: transformDate(message.createdAt), type: 'Date', format: 'long' }"></LocalizedValue></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Waiting for answer -->
        <div v-if="isWaitingForAnswer" class="mb-4 flex cursor-pointer">
          <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
            <img src="/favicon.png" alt="User Avatar" class="W-full h-fullobject-contain p-1.5" />
          </div>
          <div class="flex max-w-96 gap-3 rounded-lg bg-gray-100 p-3">
            <div class="flex gap-2">
              <div class="h-3 w-3 animate-pulse rounded-full bg-main"></div>
              <div class="h-3 w-3 animate-pulse rounded-full bg-main delay-500"></div>
              <div class="h-3 w-3 animate-pulse rounded-full bg-main delay-1000"></div>
            </div>
          </div>
        </div>

        <!-- Ideas for questions -->
        <div v-if="!isWaitingForAnswer && messages?.length === 0" class="mt-10 w-full px-5">
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
            <div v-for="template in getRandomTemplates()" :key="template.label" @click="addUserMessage($t(template.question))" class="cursor-pointer rounded-xl border border-main p-1 hover:scale-105 sm:p-2">
              <component :is="template.icon" class="ml-0 h-5 text-main sm:w-5" />
              <p class="text-center text-xs font-bold text-main sm:mt-2 sm:text-sm md:text-base">{{ $t(template.title) }}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="mx-auto mb-2 mt-auto h-fit w-[90%] rounded-full border p-1">
        <div class="mx-auto flex h-fit w-full max-w-full items-center p-1">
          <textarea
            :disabled="isWaitingForAnswer"
            ref="textarea"
            rows="1"
            type="text"
            :placeholder="$t('aiassistant.typeMessage')"
            class="max-h-32 w-full resize-none overflow-y-auto !border-none !shadow-none !outline-none focus:border-none focus:shadow-none focus:outline-none focus:ring-0 focus:ring-transparent"
            @input="autoResize"
            @keydown.enter.prevent="addUserMessage()"
          ></textarea>
          <PaperAirplaneIcon @click="addUserMessage()" class="mb-0 mt-auto h-10 w-10 -rotate-45 rounded-full border bg-main p-1 text-white" />
        </div>
      </footer>
    </div>
  </div>
  <div v-else>
    <AiAssistantSkeleton />
  </div>
</template>
<script setup lang="ts">
import { PaperAirplaneIcon, Bars3Icon, PlusIcon, UserCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import AiAssistantSkeleton from '@/modules/aiAssistant/components/AiAssistantSkeleton.vue';
import aiQuestionTemplates from '@/modules/aiAssistant/utils/aiQuestionTemplates';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { can } from '@/utils/can';
import { EnumsAIMessageRole, EnumsClientPermission } from '../../../../generated/api';

const textarea = ref<HTMLTextAreaElement | null>(null);
const chatContainer = ref<HTMLDivElement | null>(null);

const userStore = useUserStore();
const eshopStore = useEshopsStore();
const toast = useToast();
const { t } = useI18n();

const isLoadedMessages = ref(true);
const showHistoryMenu = ref(true);
const isWaitingForAnswer = ref(false);
const selectedAssistant = ref(null);
const selectedThread = ref(null);
const threads = ref([]);
const messages = ref([]);

const templates = aiQuestionTemplates;

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    // restart chat
    selectedAssistant.value = null;
    selectedThread.value = null;
    threads.value = [];
    messages.value = [];
    await getAssistants();
    await getThreads();
    await getMessages();
  }
);

onMounted(async () => {
  await getAssistants();
  await getThreads();
  await getMessages();

  scrollToBottom();
  if (textarea.value) {
    textarea.value.style.height = 'auto';
    textarea.value.style.height = `${textarea.value.scrollHeight}px`;
  }
});

// Get random 4 items from templates
const getRandomTemplates = () => {
  const randomTemplates = [];
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * templates.length);
    randomTemplates.push(templates[randomIndex]);
  }
  return randomTemplates;
};

const autoResize = () => {
  if (textarea.value) {
    textarea.value.style.height = 'auto'; // Reset height
    textarea.value.style.height = `${textarea.value.scrollHeight}px`; // New height based on content
  }
};

const scrollToBottom = () => {
  if (chatContainer.value) {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
  }
};

const getAssistants = async () => {
  try {
    const { data } = await api.clientAssistantGetAssistants();
    // Now get first assistant
    selectedAssistant.value = data?.assistants[0].assistantId;
  } catch (error: any) {
    handleError(error);
  }
};

const getThreads = async () => {
  try {
    const { data } = await api.clientAssistantGetThreads({ assistantId: selectedAssistant.value });
    threads.value = data.threads || [];
    if (threads.value.length > 0) {
      selectedThread.value = threads.value[0].threadId;
    }
  } catch (error: any) {
    handleError(error);
  }
};

const createThread = async (name) => {
  try {
    const { data } = await api.clientAssistantCreateThread({ assistantId: selectedAssistant.value, name });
    threads.value.unshift({ threadId: data.threadId, name: name });
    selectedThread.value = data.threadId;
    return true;
  } catch (error: any) {
    handleError(error);
  }
};

const changeThread = async (threadId) => {
  isLoadedMessages.value = false;
  selectedThread.value = threadId;
  threads.value = threads.value.filter((thread) => thread.threadId !== 'new thread');
  if (threadId === 'new thread') {
    messages.value = [];
    isLoadedMessages.value = true;
    return;
  }
  await getMessages();
  isLoadedMessages.value = true;
  nextTick(() => {
    scrollToBottom();
  });
};

const prepareThreadOnly = () => {
  if (selectedThread.value === 'new thread') {
    return;
  }
  threads.value.unshift({ threadId: 'new thread', name: t('aiassistant.newThread') });
  selectedThread.value = 'new thread';
  messages.value = [];
};

const deleteThread = async (threadId) => {
  try {
    await api.clientAssistantDeleteThread({ threadId, assistantId: selectedAssistant.value });
    threads.value = threads.value.filter((thread) => thread.threadId !== threadId);
    if (selectedThread.value === threadId) {
      selectedThread.value = threads.value[0]?.threadId || null;
      if (selectedThread.value) {
        await getMessages();
      } else {
        messages.value = [];
      }
    }
    toast.add({
      severity: 'success',
      summary: t('aiassistant.threadDeleted'),
      life: 6000,
    });
  } catch (error: any) {
    handleError(error);
  }
};

const getMessages = async () => {
  if (!selectedThread.value) {
    messages.value = [];
    return;
  }
  try {
    const { data } = await api.clientAssistantGetMessages({ threadId: selectedThread.value });
    // sort messages by createdAt
    const sortedMessages = data.messages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    messages.value = sortedMessages || [];
  } catch (error: any) {
    handleError(error);
  }
};

const addUserMessage = async (defaultQuestionMessage = undefined) => {
  if (!textarea.value) return;

  const message = textarea.value.value || defaultQuestionMessage;
  textarea.value.value = '';

  if (!message) return;

  await handleNewThread(message);

  try {
    isWaitingForAnswer.value = true;
    const newMessage = createMessage(message);
    messages.value.push(newMessage);

    await nextTick();
    scrollToBottom();

    const aiResponse = await sendUserMessage(message);
    isWaitingForAnswer.value = false;
    // remove last client message
    messages.value = messages.value.filter((msg) => msg.messageId !== 'randomID');
    if (aiResponse && aiResponse.length > 0) {
      messages.value.push(...aiResponse);
    }

    autoResize();
    await nextTick();
    scrollToBottom();
  } catch (error) {
    handleError(error);
  } finally {
    isWaitingForAnswer.value = false;
  }
};

const handleNewThread = async (message) => {
  if (selectedThread.value && selectedThread.value !== 'new thread') return;

  const threadName = message.substring(0, 10) + '...';
  const isThreadCreated = await createThread(threadName);

  if (isThreadCreated) {
    threads.value = threads.value.filter((thread) => thread.threadId !== 'new thread');
  }
};

const createMessage = (content) => ({
  messageId: 'randomID',
  content,
  role: EnumsAIMessageRole.User,
  createdAt: new Date(),
});

const sendUserMessage = async (message) => {
  const lastMessageId = messages.value[messages.value.length - 2]?.messageId || null;
  try {
    return (
      await api.clientAssistantAddUserMessage({
        assistantId: selectedAssistant.value,
        threadId: selectedThread.value,
        message,
        lastMessageId,
      })
    ).data.messages;
  } catch (error: any) {
    handleError(error);
  }
};

const handleError = (error) => {
  if (error.response) {
    if (error.response.data.status >= 500) {
      toast.add({
        severity: 'error',
        summary: t('serverErrorTitle'),
        detail: error.response.data.requestId,
        life: 20000,
      });
    } else {
      toast.add({
        severity: 'error',
        summary: error.response.data.detail,
        life: 6000,
      });
    }
  } else {
    console.warn(error);
  }
};

const hideThreadMenu = () => {
  showHistoryMenu.value = false;
};

const showThreadMenu = () => {
  showHistoryMenu.value = true;
};

const transformDate = (date) => {
  const utcDate = new Date(date + 'Z');
  return utcDate;
};
</script>
<style>
b {
  font-weight: 900;
  color: black;
  font-size: 0.9rem;
}
</style>
